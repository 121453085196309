import { MEDUSA_BACKEND_URL, queryClient } from "@lib/config"
import { AccountProvider } from "@lib/context/account-context"
import { CartDropdownProvider } from "@lib/context/cart-dropdown-context"
import { MobileMenuProvider } from "@lib/context/mobile-menu-context"
import { StoreProvider } from "@lib/context/store-context"
import { Hydrate } from "@tanstack/react-query"
import { CartProvider, MedusaProvider } from "medusa-react"
import "styles/globals.css"
import "styles/mega_menu.css"
import 'instantsearch.css/themes/satellite.css';
import {ViewportProvider} from "@byq/lib/hooks/use-viewport"
import { useEffect} from "react"

import { AppPropsWithLayout } from "types/global"


import {MenuDropdownProvider} from "@byq/lib/context/menu-dropdown-context"
import analytics from "@byq/lib/segment"
import { Analytics } from '@vercel/analytics/react';
import {publishableApiKey} from "@byq/config"
import { useRouter } from "next/router"
import { useMemo } from "react"

import FreshWorks from "@byq/lib/freshworks"
import Smartlook from "@byq/lib/smartlook"
import GoogleAnalytics from "@byq/lib/googleAnalytics"

function App({
  Component,
  pageProps,
}: AppPropsWithLayout<{ dehydratedState?: unknown }>) {
  const getLayout = Component.getLayout ?? ((page) => page)

  const router = useRouter()
  const {asPath, pathname} = useRouter()
  useMemo(() => {
    const prefetch = router.prefetch
    router.prefetch = async () => { }
    return () => { router.prefetch = prefetch }
  }, [router])

  useEffect(() => {
    const handleRouteChange = url => {
      const title = document.title
      analytics.page(title, pathname, {
        title: title,
        url: url,
        path: asPath,
        pathName: pathname,
        referrer: window.frames.top.document.referrer,
      });

    };
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [asPath, pathname]);
  /*
  import analytics from "@byq/lib/segment"
  analytics.track(
  "event", {
  revenue: 30,
  currency: "USD",
  user_actual_id: 12345
  })*/

  return (
    <>

      <MedusaProvider
        baseUrl={MEDUSA_BACKEND_URL}
        queryClientProviderProps={{
          client: queryClient,
        }}

      >
        <Hydrate state={pageProps.dehydratedState}>
          <MenuDropdownProvider>
            <CartDropdownProvider>
              <MobileMenuProvider>
                <CartProvider>
                  <StoreProvider>
                    <ViewportProvider>
                      <AccountProvider>

                        {getLayout(<Component {...pageProps} />)}
                      </AccountProvider>
                    </ViewportProvider>
                  </StoreProvider>
                </CartProvider>
              </MobileMenuProvider>
            </CartDropdownProvider>
          </MenuDropdownProvider>
        </Hydrate>
      </MedusaProvider>
      <Analytics />
      <FreshWorks />
      <Smartlook />
      <GoogleAnalytics />
    </>
  )
}

export default App
