import {
  StoreCollectionsRes,
  StoreCollectionsListRes,
  StoreGetCollectionsParams,
} from "@medusajs/medusa-js"
import qs from "qs"
import { ResponsePromise } from "@medusajs/medusa-js/dist/typings"
import {createPromise} from "@byq/utils"
import {categoriesObject} from "@byq/medusa-js/categoriesObject"


import queryCache from "@byq/medusa-js/categoriesQuery"



// TODO(refactor): To use react-query
// To make the code generic see https://chat.openai.com/chat/2c363c66-b8b0-408d-9e08-ad1f7be85940

const getCategoriesWithNoParent = (categories) => {
  const parsed = []
  for(var category of categories) {
    const {parent_category} = category
    if(parent_category === null) {
      parsed.push(category)
    }
  }
}

class CategoriesResource {
  constructor(medusaClient) {
    this.client = medusaClient
    const promiseList = createPromise()
    this.promiseList = promiseList
    this.promiseCalled = false
  }
  async childrenOfCategory({categoryId}) {
    if (this.categoriesChildren === undefined) {
      this.categoriesChildren = {}
    }

    if(categoryId in this.categoriesChildren) {
      //return this.categoriesChildren[categoryId]
    }

    await this.list()
    let childrenIds = []
    for(let category of this.categories) {
      const {parent_category_id, id, handle} = category
      if(categoryId === parent_category_id) {
        childrenIds.push(id)
      }
    }
    this.categoriesChildren[categoryId] = childrenIds

    return childrenIds
  }

  async retrieve({handle, name, id})  {
    let item

    await this.list()

    // TODO: Is this needed?
    if(this.categoriesByHandle === undefined || this.categoriesByName === undefined) {
      return {}
    }

    if(handle !== undefined) {
      item = this.categoriesByHandle[handle]
    } else if(name !== undefined) {
      item = this.categoriesByName[name]
    } else if(id !== undefined) {
      item = this.categoriesById[id]
    } else {
      throw("Error need to supply either name or handle or id")
    }


    if(item === undefined) {
      return {}
    }

    return item
  }


  async listByName() {
    await this.list()
    return this.categoriesByName
  }

  async list({query, customHeaders, categoriesByKey = null} = {}) {
    let [promise, promiseResolve, promiseReject] = this.promiseList

    if(!this.promiseCalled) {
      this.promiseCalled = true
    } else {
      return promise
    }

    let path = `/store/product-categories/`




    if (this.categories === undefined) {
      this.categories = []
    }
    if(this.categories.length > 1) {
      resolve()
    }

    const categories = categoriesObject.product_categories
    this.categories = categories

    this.categoriesByHandle = {}
    this.categoriesByName = {}
    this.categoriesById = {}
    getCategoriesWithNoParent(categories)
    for(var category of categories) {

      const {handle, name, id} = category
      this.categoriesByHandle[handle] = category
      this.categoriesByName[name] = category
      this.categoriesById[id] = category
    }

    promiseResolve(this.categories)
    // TODO: Handle promisereject

    return promise
  }

}

export default CategoriesResource
//
