import Analytics from 'analytics'
import segmentPlugin from '@analytics/segment'


const analytics = Analytics({
  app: 'javascript storefront',
  plugins: [
    segmentPlugin({
      writeKey: 'qmCQPau22BHhxirMX0kuJsfjjw2D9zEx',
      integrations: { "Segment.io": { apiHost: "a.leky.hr" }}
    })
  ]
})


const analyticsOur = {}
let disabled = false
if(process.env.NODE_ENV === "development") {
  disabled = true

}
analyticsOur.track = (eventName, eventData) => {
  if(!disabled) {
    analytics.track(eventName, eventData)
  } else {
  }
}

analyticsOur.page = (eventName, eventSomething, eventData) => {
  if(!disabled) {
    analytics.page(eventName, eventSomething, eventData)
  } else {
  }
}


export default analyticsOur
