import { medusaClient } from "@lib/config"
import CategoriesResource from "./categories"
import qs from "qs"

// TODO(performance): this is re-created for each import?
const client = medusaClient.client
medusaClient.categories = new CategoriesResource(client)
function asd(query) {
  let path = `/store/products`

  if (query) {
    const queryString = qs.stringify(query)
    path = `/store/products?${queryString}`
  }
  return this.client.request("GET", path, undefined, {})
}

medusaClient.products.listByq = asd
export default medusaClient
