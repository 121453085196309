export default function Smartlook({children}) {
let js = `
<script type='text/javascript'>
  window.smartlook||(function(d) {
    var o=smartlook=function(){ o.api.push(arguments)},h=d.getElementsByTagName('head')[0];
    var c=d.createElement('script');o.api=new Array();c.async=true;c.type='text/javascript';
    c.charset='utf-8';c.src='https://web-sdk.smartlook.com/recorder.js';h.appendChild(c);
    })(document);
    smartlook('init', 'e046fd6c22199dd47cc93ef505c01fed29bda306', { region: 'eu' });
</script>
    `
  if(process.env.NODE_ENV === "development") {
    js = ""
  }
  return (
    <>
      { children }
      <div dangerouslySetInnerHTML={{__html:js}} />
    </>
  );
}
