import useToggleState from "@lib/hooks/use-toggle-state"
import { createContext, useContext, useEffect, useState } from "react"
import medusaClientByq from "@byq/medusa-js"
//import {fetchCategories} from "@byq/lib/data"

export const MenuDropdownContext = createContext(null)

export const MenuDropdownProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const [categories, setCategories] = useState([])
  useEffect(() => {
    medusaClientByq.categories.listByName().then((categories) => {
      setCategories(categories)
    })
  }, [])

  return (
    <MenuDropdownContext.Provider
      value={{ categories: categories }}
    >
      {children}
    </MenuDropdownContext.Provider>
  )
}

export const useMenuDropdown = () => {
  const context = useContext(MenuDropdownContext)

  if (context === null) {
    throw new Error(
      "useMenuropdown must be used within a MenuDropdownProvider"
    )
  }

  return context
}
