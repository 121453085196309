export function sleep(s) {
  return new Promise(resolve => setTimeout(resolve, s*1000));
}

let called = false
export const trackConversionGA4 = async (orderId) => {
  /*<!-- Event snippet for Website sale conversion page -->*/
  console.log("called")

  while(true) {
    await sleep(1)
    try {
      if(called) return
      called = true
      window.gtag('event', 'conversion', {
      'send_to': 'AW-1009704293/1D4UCNO4-58YEOW6u-ED',
    'transaction_id': orderId,
    });
      console.log("works")
      break

    } catch(e) {
    }

  }

}

export default function GoogleAnalytics({children}) {
  let js = `
<script async src="https://www.googletagmanager.com/gtag/js?id=G-YD0QQ552RY"></script>
<script>
/* Google tag (gtag.js)  G-ANALYTICS */
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-YD0QQ552RY');
</script>

<script async src="https://www.googletagmanager.com/gtag/js?id=AW-1009704293"></script>
<script>
/* Google tag (gtag.js) --> GOOGLE ADS */
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'AW-1009704293');
</script>
    `
  if(process.env.NODE_ENV === "development") {
    js = ""
  }
  return (
    <>
      { children }
      <div dangerouslySetInnerHTML={{__html:js}} />
    </>
  );
}
