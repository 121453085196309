export const categoriesObject = {
    "count": 472,
    "product_categories": [
      {
      "id": "pcat_01HK7WKD82QDK29ZG9ZA9NV18N",
      "created_at": "2024-01-03T14:45:52.001Z",
      "updated_at": "2024-01-03T14:45:52.001Z",
      "name": "Balzami",
      "description": "",
      "handle": "aftershave-balzami",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WKAZX7KTPB76GCBMFEHGP",
      "rank": 0,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WKAZX7KTPB76GCBMFEHGP",
        "created_at": "2024-01-03T14:45:49.684Z",
        "updated_at": "2024-01-03T14:45:49.684Z",
        "name": "Aftershave",
        "description": "",
        "handle": "za-muskarce-aftershave",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK4WXV52RYH206J4P8TD0",
        "rank": 4,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK9VTHAJTS8VVR8H2VYBE",
      "created_at": "2024-01-03T14:45:48.534Z",
      "updated_at": "2024-01-03T14:45:48.534Z",
      "name": "Četke",
      "description": "",
      "handle": "alati-i-pribor-za-sminkanje-cetke",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK57XS2NQS0EGT358BW0Q",
      "rank": 0,
      "metadata": null,
      "category_children": [
        {
          "id": "pcat_01HK7WKCRNVXZATFZC314BBJP0",
          "created_at": "2024-01-03T14:45:51.507Z",
          "updated_at": "2024-01-03T14:45:51.507Z",
          "name": "Kistovi za usne",
          "description": "",
          "handle": "cetke-kistovi-za-usne",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK9VTHAJTS8VVR8H2VYBE",
          "rank": 0,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKCS54NGMD5M9TQBEP8JN",
          "created_at": "2024-01-03T14:45:51.522Z",
          "updated_at": "2024-01-03T14:45:51.522Z",
          "name": "Setovi kistova",
          "description": "",
          "handle": "cetke-setovi-kistova",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK9VTHAJTS8VVR8H2VYBE",
          "rank": 1,
          "metadata": null
        }
      ],
      "parent_category": {
        "id": "pcat_01HK7WK57XS2NQS0EGT358BW0Q",
        "created_at": "2024-01-03T14:45:43.802Z",
        "updated_at": "2024-01-03T14:45:43.802Z",
        "name": "Alati i pribor za šminkanje",
        "description": "",
        "handle": "pribor-i-dodaci-alati-i-pribor-za-sminkanje",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK45W0F9X0VMBBCAVDPWD",
        "rank": 2,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKCSJFVQKG8MXY718C1Q0",
      "created_at": "2024-01-03T14:45:51.536Z",
      "updated_at": "2024-01-03T14:45:51.536Z",
      "name": "Aplikatori za prah",
      "description": "",
      "handle": "aplikatori-za-prah_18839",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK9WEZEVWDMWDPK8W460Y",
      "rank": 0,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK9WEZEVWDMWDPK8W460Y",
        "created_at": "2024-01-03T14:45:48.552Z",
        "updated_at": "2024-01-03T14:45:48.552Z",
        "name": "Lice",
        "description": "",
        "handle": "alati-i-pribor-za-sminkanje-lice",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK57XS2NQS0EGT358BW0Q",
        "rank": 1,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK7HD6DRSG3HTFZX6CZQG",
      "created_at": "2024-01-03T14:45:46.155Z",
      "updated_at": "2024-01-03T14:45:46.155Z",
      "name": "Pribor za bojanje",
      "description": "",
      "handle": "bojanje-kose-pribor-za-bojanje",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4KQVZQK6XPGZJEJ50QZ",
      "rank": 0,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4KQVZQK6XPGZJEJ50QZ",
        "created_at": "2024-01-03T14:45:43.157Z",
        "updated_at": "2024-01-03T14:45:43.157Z",
        "name": "Bojanje kose",
        "description": "",
        "handle": "njega-kose-bojanje-kose",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK43BQJST1XZ18R4BST01",
        "rank": 2,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK46D6H08A70HPE8S7SCQ",
      "created_at": "2024-01-03T14:45:42.731Z",
      "updated_at": "2024-01-03T14:45:42.731Z",
      "name": "Ručno brijanje",
      "description": "",
      "handle": "brijanje-i-depilacija-rucno-brijanje",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK41MNZ3C795B2JN5JW0G",
      "rank": 0,
      "metadata": null,
      "category_children": [
        {
          "id": "pcat_01HK7WK5EWA9P9TTPVVM3FFT1X",
          "created_at": "2024-01-03T14:45:44.026Z",
          "updated_at": "2024-01-03T14:45:44.026Z",
          "name": "Pribor",
          "description": "",
          "handle": "rucno-brijanje-pribor",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK46D6H08A70HPE8S7SCQ",
          "rank": 0,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK5FHHQR6TNHWK6MYMBRV",
          "created_at": "2024-01-03T14:45:44.044Z",
          "updated_at": "2024-01-03T14:45:44.044Z",
          "name": "Za muškarce",
          "description": "",
          "handle": "rucno-brijanje-za-muskarce",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK46D6H08A70HPE8S7SCQ",
          "rank": 1,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK5G6G274F8G2WFZ816SN",
          "created_at": "2024-01-03T14:45:44.067Z",
          "updated_at": "2024-01-03T14:45:44.067Z",
          "name": "Za žene",
          "description": "",
          "handle": "rucno-brijanje-za-zene",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK46D6H08A70HPE8S7SCQ",
          "rank": 2,
          "metadata": null
        }
      ],
      "parent_category": {
        "id": "pcat_01HK7WK41MNZ3C795B2JN5JW0G",
        "created_at": "2024-01-03T14:45:42.579Z",
        "updated_at": "2024-01-03T14:45:42.579Z",
        "name": "Brijanje i depilacija",
        "description": "",
        "handle": "ljepota-brijanje-i-depilacija",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": null,
        "rank": 0,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKB32TXGJCE8WVBT87HR0",
      "created_at": "2024-01-03T14:45:49.792Z",
      "updated_at": "2024-01-03T14:45:49.792Z",
      "name": "Setovi kistova",
      "description": "",
      "handle": "cetke-i-pribor-za-sminkanje-setovi-kistova",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK5D0544YXJQT9G60XVED",
      "rank": 0,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK5D0544YXJQT9G60XVED",
        "created_at": "2024-01-03T14:45:43.966Z",
        "updated_at": "2024-01-03T14:45:43.966Z",
        "name": "Četke i pribor za šminkanje",
        "description": "",
        "handle": "pribor-i-dodaci-cetke-i-pribor-za-sminkanje",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK45W0F9X0VMBBCAVDPWD",
        "rank": 3,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKCRNVXZATFZC314BBJP0",
      "created_at": "2024-01-03T14:45:51.507Z",
      "updated_at": "2024-01-03T14:45:51.507Z",
      "name": "Kistovi za usne",
      "description": "",
      "handle": "cetke-kistovi-za-usne",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK9VTHAJTS8VVR8H2VYBE",
      "rank": 0,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK9VTHAJTS8VVR8H2VYBE",
        "created_at": "2024-01-03T14:45:48.534Z",
        "updated_at": "2024-01-03T14:45:48.534Z",
        "name": "Četke",
        "description": "",
        "handle": "alati-i-pribor-za-sminkanje-cetke",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK57XS2NQS0EGT358BW0Q",
        "rank": 0,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKDSWN0SP7XZH515KDKD9",
      "created_at": "2024-01-03T14:45:52.569Z",
      "updated_at": "2024-01-03T14:45:52.569Z",
      "name": "Patrone",
      "description": "",
      "handle": "ciscenje-patrone",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WKBECX73T18N8C8Z8EJZZ",
      "rank": 0,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WKBECX73T18N8C8Z8EJZZ",
        "created_at": "2024-01-03T14:45:50.154Z",
        "updated_at": "2024-01-03T14:45:50.154Z",
        "name": "Čišćenje",
        "description": "",
        "handle": "pribor-ciscenje_1038",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK5GP4MZXQ69EPS0WG1AR",
        "rank": 3,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKCZK3RJ0ZFS1JKA7PPVC",
      "created_at": "2024-01-03T14:45:51.729Z",
      "updated_at": "2024-01-03T14:45:51.729Z",
      "name": "Krpe i maramice",
      "description": "",
      "handle": "cistaci-krpe-i-maramice",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WKA2274BVZPY0TZ6SN7Z9",
      "rank": 0,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WKA2274BVZPY0TZ6SN7Z9",
        "created_at": "2024-01-03T14:45:48.735Z",
        "updated_at": "2024-01-03T14:45:48.735Z",
        "name": "Čistači",
        "description": "",
        "handle": "lice-cistaci_20934",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK4CQ8JZ3NZ6QJC2MQW11",
        "rank": 5,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKBGP5ESN8QMWKSJS2N7S",
      "created_at": "2024-01-03T14:45:50.222Z",
      "updated_at": "2024-01-03T14:45:50.222Z",
      "name": "Trake za depilaciju voskom",
      "description": "",
      "handle": "depilacija-voskom-trake-za-depilaciju-voskom",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK649EWGYVF36CJ6071RM",
      "rank": 0,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK649EWGYVF36CJ6071RM",
        "created_at": "2024-01-03T14:45:44.706Z",
        "updated_at": "2024-01-03T14:45:44.706Z",
        "name": "Depilacija voskom",
        "description": "",
        "handle": "uklanjanje-dlaka-depilacija-voskom",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK493C3GNJNECHNPJ0K2N",
        "rank": 1,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKBR598DYYEJ6Y4MF9HP7",
      "created_at": "2024-01-03T14:45:50.467Z",
      "updated_at": "2024-01-03T14:45:50.467Z",
      "name": "Pribor i rezervni dijelovi",
      "description": "",
      "handle": "depilatori-i-dodaci-pribor-i-rezervni-dijelovi",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK68D07TWPZME6GB2Q1K0",
      "rank": 0,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK68D07TWPZME6GB2Q1K0",
        "created_at": "2024-01-03T14:45:44.839Z",
        "updated_at": "2024-01-03T14:45:44.839Z",
        "name": "Depilatori i dodaci",
        "description": "",
        "handle": "uklanjanje-dlaka-depilatori-i-dodaci",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK493C3GNJNECHNPJ0K2N",
        "rank": 4,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKBQ7QX0P63QRMDM7VXYW",
      "created_at": "2024-01-03T14:45:50.437Z",
      "updated_at": "2024-01-03T14:45:50.437Z",
      "name": "Zamjenski ulošci za epilatore s impulsnim svjetlom",
      "description": "",
      "handle": "depilatori-s-impulsnim-svjetlom-zamjenski-ulosci-za-epilatore-s-impulsnim-svjetlom",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK65RQ00RY9H3YFT5TEA6",
      "rank": 0,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK65RQ00RY9H3YFT5TEA6",
        "created_at": "2024-01-03T14:45:44.751Z",
        "updated_at": "2024-01-03T14:45:44.751Z",
        "name": "Depilatori s impulsnim svjetlom",
        "description": "",
        "handle": "uklanjanje-dlaka-depilatori-s-impulsnim-svjetlom",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK493C3GNJNECHNPJ0K2N",
        "rank": 2,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK8QT38HTXECJJZ3XSY34",
      "created_at": "2024-01-03T14:45:47.384Z",
      "updated_at": "2024-01-03T14:45:47.384Z",
      "name": "Pribor za ukrašavanje noktiju",
      "description": "",
      "handle": "dizajni-za-nokte-pribor-za-ukrasavanje-noktiju",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4PDYPCE7H5D1FAFMHNK",
      "rank": 0,
      "metadata": null,
      "category_children": [
        {
          "id": "pcat_01HK7WKCDV66XD2MJEVKWVTNV4",
          "created_at": "2024-01-03T14:45:51.160Z",
          "updated_at": "2024-01-03T14:45:51.160Z",
          "name": "Zaštitna ljepila",
          "description": "",
          "handle": "pribor-za-ukrasavanje-noktiju-zastitna-ljepila",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK8QT38HTXECJJZ3XSY34",
          "rank": 0,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKCE9XX8TTKT117V92S0F",
          "created_at": "2024-01-03T14:45:51.175Z",
          "updated_at": "2024-01-03T14:45:51.175Z",
          "name": "Šljokice",
          "description": "",
          "handle": "pribor-za-ukrasavanje-noktiju-sljokice_11502",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK8QT38HTXECJJZ3XSY34",
          "rank": 1,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKCESF4BQW27NQ6GV472A",
          "created_at": "2024-01-03T14:45:51.191Z",
          "updated_at": "2024-01-03T14:45:51.191Z",
          "name": "Naljepnice i samoljepljivi artikli",
          "description": "",
          "handle": "pribor-za-ukrasavanje-noktiju-naljepnice-i-samoljepljivi-artikli",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK8QT38HTXECJJZ3XSY34",
          "rank": 2,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKCF63J4RPKF4CRRZWHSS",
          "created_at": "2024-01-03T14:45:51.204Z",
          "updated_at": "2024-01-03T14:45:51.204Z",
          "name": "Nakit",
          "description": "",
          "handle": "nakit_11505",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK8QT38HTXECJJZ3XSY34",
          "rank": 3,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKCFKXJ6TBDPBDPBY2AHJ",
          "created_at": "2024-01-03T14:45:51.216Z",
          "updated_at": "2024-01-03T14:45:51.216Z",
          "name": "Kompleti za ukrašavanje",
          "description": "",
          "handle": "pribor-za-ukrasavanje-noktiju-kompleti-za-ukrasavanje",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK8QT38HTXECJJZ3XSY34",
          "rank": 4,
          "metadata": null
        }
      ],
      "parent_category": {
        "id": "pcat_01HK7WK4PDYPCE7H5D1FAFMHNK",
        "created_at": "2024-01-03T14:45:43.244Z",
        "updated_at": "2024-01-03T14:45:43.244Z",
        "name": "Dizajni za nokte",
        "description": "",
        "handle": "manikura-i-pedikura-dizajni-za-nokte",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK43VEK8SG6M4T81C3W37",
        "rank": 1,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKDXTFKM2A66290CXRRH0",
      "created_at": "2024-01-03T14:45:52.695Z",
      "updated_at": "2024-01-03T14:45:52.695Z",
      "name": "Kreme",
      "description": "",
      "handle": "dnevna-njega-kreme",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WKBS3M1Y3H3YRT1SGTGNX",
      "rank": 0,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WKBS3M1Y3H3YRT1SGTGNX",
        "created_at": "2024-01-03T14:45:50.496Z",
        "updated_at": "2024-01-03T14:45:50.496Z",
        "name": "Dnevna njega",
        "description": "",
        "handle": "za-hidrataciju-dnevna-njega",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK6SZXFJCA60BBDC8S5W7",
        "rank": 0,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK6JCH3GRER3MFHB8V6HT",
      "created_at": "2024-01-03T14:45:45.161Z",
      "updated_at": "2024-01-03T14:45:45.161Z",
      "name": "Ulja",
      "description": "",
      "handle": "dodaci-za-kupke-ulja",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4AMW72YGS9N8YSBWJ37",
      "rank": 0,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4AMW72YGS9N8YSBWJ37",
        "created_at": "2024-01-03T14:45:42.866Z",
        "updated_at": "2024-01-03T14:45:42.866Z",
        "name": "Dodaci za kupke",
        "description": "",
        "handle": "kupanje-i-osobna-higijena-dodaci-za-kupke",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK42BQ7XFW617X5XP4BAR",
        "rank": 1,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK5GP4MZXQ69EPS0WG1AR",
      "created_at": "2024-01-03T14:45:44.084Z",
      "updated_at": "2024-01-03T14:45:44.084Z",
      "name": "Pribor",
      "description": "",
      "handle": "elektricni-brijaci-pribor",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK46YG69NRB3HQKNMNZ0C",
      "rank": 0,
      "metadata": null,
      "category_children": [
        {
          "id": "pcat_01HK7WKBCXBJYXFY682K0PCVSC",
          "created_at": "2024-01-03T14:45:50.107Z",
          "updated_at": "2024-01-03T14:45:50.107Z",
          "name": "Zamjenske glave za električne brijače",
          "description": "",
          "handle": "pribor-zamjenske-glave-za-elektricne-brijace",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK5GP4MZXQ69EPS0WG1AR",
          "rank": 0,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKBDJ9G2DBHYDZCHR9CCC",
          "created_at": "2024-01-03T14:45:50.128Z",
          "updated_at": "2024-01-03T14:45:50.128Z",
          "name": "Punjači i napajanja za električne brijače",
          "description": "",
          "handle": "pribor-punjaci-i-napajanja-za-elektricne-brijace",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK5GP4MZXQ69EPS0WG1AR",
          "rank": 1,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKBDZ19NMKFVE43ZZ5J44",
          "created_at": "2024-01-03T14:45:50.141Z",
          "updated_at": "2024-01-03T14:45:50.141Z",
          "name": "Futrole za električne brijače aparate",
          "description": "",
          "handle": "pribor-futrole-za-elektricne-brijace-aparate",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK5GP4MZXQ69EPS0WG1AR",
          "rank": 2,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKBECX73T18N8C8Z8EJZZ",
          "created_at": "2024-01-03T14:45:50.154Z",
          "updated_at": "2024-01-03T14:45:50.154Z",
          "name": "Čišćenje",
          "description": "",
          "handle": "pribor-ciscenje_1038",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK5GP4MZXQ69EPS0WG1AR",
          "rank": 3,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKBESB8GYGN0HD4SRNN21",
          "created_at": "2024-01-03T14:45:50.167Z",
          "updated_at": "2024-01-03T14:45:50.167Z",
          "name": "Zamjenske oštrice",
          "description": "",
          "handle": "zamjenske-osztrice",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK5GP4MZXQ69EPS0WG1AR",
          "rank": 4,
          "metadata": null
        }
      ],
      "parent_category": {
        "id": "pcat_01HK7WK46YG69NRB3HQKNMNZ0C",
        "created_at": "2024-01-03T14:45:42.747Z",
        "updated_at": "2024-01-03T14:45:42.747Z",
        "name": "Električni brijači",
        "description": "",
        "handle": "brijanje-i-depilacija-elektricni-brijaci",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK41MNZ3C795B2JN5JW0G",
        "rank": 1,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKBF7YG39DFNYPWYMZRJV",
      "created_at": "2024-01-03T14:45:50.181Z",
      "updated_at": "2024-01-03T14:45:50.181Z",
      "name": "Električni brijači s oštricama za muškarce",
      "description": "",
      "handle": "elektricni-brijaci-za-muskarce-elektricni-brijaci-s-ostricama-za-muskarce",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK5H8GFDPH0Z1TTQXAYJQ",
      "rank": 0,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK5H8GFDPH0Z1TTQXAYJQ",
        "created_at": "2024-01-03T14:45:44.101Z",
        "updated_at": "2024-01-03T14:45:44.101Z",
        "name": "Električni brijači za muškarce",
        "description": "",
        "handle": "elektricni-brijaci-elektricni-brijaci-za-muskarce",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK46YG69NRB3HQKNMNZ0C",
        "rank": 1,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKD5BRHP1FEVTC01KN1VD",
      "created_at": "2024-01-03T14:45:51.913Z",
      "updated_at": "2024-01-03T14:45:51.913Z",
      "name": "Difuzori",
      "description": "",
      "handle": "fenovi-i-difuzori-difuzori",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WKAA0GJVNVKHFMBVWE9GZ",
      "rank": 0,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WKAA0GJVNVKHFMBVWE9GZ",
        "created_at": "2024-01-03T14:45:48.986Z",
        "updated_at": "2024-01-03T14:45:48.986Z",
        "name": "Fenovi i difuzori",
        "description": "",
        "handle": "frizerski-uredaji-i-pribor-fenovi-i-difuzori",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK4K84XGGV7XG3TFC6W69",
        "rank": 13,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKCC16Y30VKY4XX0NQEK9",
      "created_at": "2024-01-03T14:45:51.101Z",
      "updated_at": "2024-01-03T14:45:51.101Z",
      "name": "Difuzori",
      "description": "",
      "handle": "fenovi-za-kosu-difuzori",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK7H0CCVR0D8RJQJFTVCE",
      "rank": 0,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK7H0CCVR0D8RJQJFTVCE",
        "created_at": "2024-01-03T14:45:46.142Z",
        "updated_at": "2024-01-03T14:45:46.142Z",
        "name": "Fenovi za kosu",
        "description": "",
        "handle": "frizerski-uredaji-i-pribor-fenovi-za-kosu",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK4K84XGGV7XG3TFC6W69",
        "rank": 11,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK7TA7H0FK4DBGPW6WKP6",
      "created_at": "2024-01-03T14:45:46.434Z",
      "updated_at": "2024-01-03T14:45:46.434Z",
      "name": "Vreće i kutije za alate",
      "description": "",
      "handle": "frizerski-namjestaj-i-potrepstine-vrece-i-kutije-za-alate",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4M7JE8S0KGQCMWF47WJ",
      "rank": 0,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4M7JE8S0KGQCMWF47WJ",
        "created_at": "2024-01-03T14:45:43.173Z",
        "updated_at": "2024-01-03T14:45:43.173Z",
        "name": "Frizerski namještaj i potrepštine",
        "description": "",
        "handle": "njega-kose-frizerski-namjestaj-i-potrepstine",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK43BQJST1XZ18R4BST01",
        "rank": 3,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK7BJZ1ZZXEBW846YVEA6",
      "created_at": "2024-01-03T14:45:45.968Z",
      "updated_at": "2024-01-03T14:45:45.968Z",
      "name": "Četke na vrući zrak",
      "description": "",
      "handle": "frizerski-uredaji-i-pribor-cetke-na-vruci-zrak",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4K84XGGV7XG3TFC6W69",
      "rank": 0,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4K84XGGV7XG3TFC6W69",
        "created_at": "2024-01-03T14:45:43.141Z",
        "updated_at": "2024-01-03T14:45:43.141Z",
        "name": "Frizerski uređaji i pribor",
        "description": "",
        "handle": "njega-kose-frizerski-uredaji-i-pribor",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK43BQJST1XZ18R4BST01",
        "rank": 1,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKCQSH9ZZWK4D67Y3WV8N",
      "created_at": "2024-01-03T14:45:51.479Z",
      "updated_at": "2024-01-03T14:45:51.479Z",
      "name": "Kutije i držači",
      "description": "",
      "handle": "futrole-za-sminku-kutije-i-drzaci",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK9V466Q4VJEZJ17KXJAP",
      "rank": 0,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK9V466Q4VJEZJ17KXJAP",
        "created_at": "2024-01-03T14:45:48.513Z",
        "updated_at": "2024-01-03T14:45:48.513Z",
        "name": "Futrole za šminku",
        "description": "",
        "handle": "torbe-i-futrole-futrole-za-sminku",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK571RGN4X4BE7Q8XRZ7N",
        "rank": 0,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKB0X3GAGPNE1SK30HRDG",
      "created_at": "2024-01-03T14:45:49.723Z",
      "updated_at": "2024-01-03T14:45:49.723Z",
      "name": "Za žene",
      "description": "",
      "handle": "kompleti-za-zene",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK5CFK2YJ8SJ5H0G76M0D",
      "rank": 0,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK5CFK2YJ8SJ5H0G76M0D",
        "created_at": "2024-01-03T14:45:43.949Z",
        "updated_at": "2024-01-03T14:45:43.949Z",
        "name": "Kompleti",
        "description": "",
        "handle": "parfemi-i-mirisi-kompleti",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK45CQPDHWCYPEW3W862G",
        "rank": 9,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKA7CPSVGX00GKN7Y8S32",
      "created_at": "2024-01-03T14:45:48.905Z",
      "updated_at": "2024-01-03T14:45:48.905Z",
      "name": "Sredstva za samotamnjenje",
      "description": "",
      "handle": "kreme-za-suncanje-i-tamnjenje-sredstva-za-samotamnjenje",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK59M5WTCRNSKRG8JBN56",
      "rank": 0,
      "metadata": null,
      "category_children": [
        {
          "id": "pcat_01HK7WKD3NSADR4AJZB3DY8JWH",
          "created_at": "2024-01-03T14:45:51.859Z",
          "updated_at": "2024-01-03T14:45:51.859Z",
          "name": "Sredstva za samotamnjenje za tijelo",
          "description": "",
          "handle": "sredstva-za-samotamnjenje-sredstva-za-samotamnjenje-za-tijelo_25059",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WKA7CPSVGX00GKN7Y8S32",
          "rank": 0,
          "metadata": null
        }
      ],
      "parent_category": {
        "id": "pcat_01HK7WK59M5WTCRNSKRG8JBN56",
        "created_at": "2024-01-03T14:45:43.857Z",
        "updated_at": "2024-01-03T14:45:43.857Z",
        "name": "Kreme za sunčanje i tamnjenje",
        "description": "",
        "handle": "njega-koze-kreme-za-suncanje-i-tamnjenje",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK42VB8WYE6GKG3FEJ41V",
        "rank": 8,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK4A2XE81CJFBQCJE5EC2",
      "created_at": "2024-01-03T14:45:42.848Z",
      "updated_at": "2024-01-03T14:45:42.848Z",
      "name": "Kupaonski pribor",
      "description": "",
      "handle": "kupanje-i-osobna-higijena-kupaonski-pribor",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK42BQ7XFW617X5XP4BAR",
      "rank": 0,
      "metadata": null,
      "category_children": [
        {
          "id": "pcat_01HK7WK6ECNH3VXJVFYKW72713",
          "created_at": "2024-01-03T14:45:45.032Z",
          "updated_at": "2024-01-03T14:45:45.032Z",
          "name": "Četke za tijelo",
          "description": "",
          "handle": "kupaonski-pribor-cetke-za-tijelo",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4A2XE81CJFBQCJE5EC2",
          "rank": 0,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK6FF5A6ZVMAYJF47RXSE",
          "created_at": "2024-01-03T14:45:45.066Z",
          "updated_at": "2024-01-03T14:45:45.066Z",
          "name": "Spužve",
          "description": "",
          "handle": "kupaonski-pribor-spuzve",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4A2XE81CJFBQCJE5EC2",
          "rank": 1,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK6GJXN0CE59ZS6ENV401",
          "created_at": "2024-01-03T14:45:45.101Z",
          "updated_at": "2024-01-03T14:45:45.101Z",
          "name": "Cvjetne spužve",
          "description": "",
          "handle": "kupaonski-pribor-cvjetne-spuzve",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4A2XE81CJFBQCJE5EC2",
          "rank": 2,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK6H8PGVT1S2QMK2XZRAG",
          "created_at": "2024-01-03T14:45:45.124Z",
          "updated_at": "2024-01-03T14:45:45.124Z",
          "name": "Kape za tuširanje",
          "description": "",
          "handle": "kupaonski-pribor-kape-za-tusiranje_2169",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4A2XE81CJFBQCJE5EC2",
          "rank": 3,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK6HT888PX65C1S7EMMPN",
          "created_at": "2024-01-03T14:45:45.144Z",
          "updated_at": "2024-01-03T14:45:45.144Z",
          "name": "Rukavice za piling",
          "description": "",
          "handle": "kupaonski-pribor-rukavice-za-piling",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4A2XE81CJFBQCJE5EC2",
          "rank": 4,
          "metadata": null
        }
      ],
      "parent_category": {
        "id": "pcat_01HK7WK42BQ7XFW617X5XP4BAR",
        "created_at": "2024-01-03T14:45:42.602Z",
        "updated_at": "2024-01-03T14:45:42.602Z",
        "name": "Kupanje i osobna higijena",
        "description": "",
        "handle": "ljepota-kupanje-i-osobna-higijena",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": null,
        "rank": 1,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK6ECNH3VXJVFYKW72713",
      "created_at": "2024-01-03T14:45:45.032Z",
      "updated_at": "2024-01-03T14:45:45.032Z",
      "name": "Četke za tijelo",
      "description": "",
      "handle": "kupaonski-pribor-cetke-za-tijelo",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4A2XE81CJFBQCJE5EC2",
      "rank": 0,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4A2XE81CJFBQCJE5EC2",
        "created_at": "2024-01-03T14:45:42.848Z",
        "updated_at": "2024-01-03T14:45:42.848Z",
        "name": "Kupaonski pribor",
        "description": "",
        "handle": "kupanje-i-osobna-higijena-kupaonski-pribor",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK42BQ7XFW617X5XP4BAR",
        "rank": 0,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKDCJZ7CJN6M5Z7JA4V0X",
      "created_at": "2024-01-03T14:45:52.144Z",
      "updated_at": "2024-01-03T14:45:52.144Z",
      "name": "Apsorpcijski papir",
      "description": "",
      "handle": "lice-apsorpcijski-papir_25080",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WKB4GBXA5NY5NKJJMS67V",
      "rank": 0,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WKB4GBXA5NY5NKJJMS67V",
        "created_at": "2024-01-03T14:45:49.837Z",
        "updated_at": "2024-01-03T14:45:49.837Z",
        "name": "Lice",
        "description": "",
        "handle": "cetke-i-pribor-za-sminkanje-lice",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK5D0544YXJQT9G60XVED",
        "rank": 3,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK6QKQKER5JVZ18WJ9V0Z",
      "created_at": "2024-01-03T14:45:45.322Z",
      "updated_at": "2024-01-03T14:45:45.322Z",
      "name": "Uređaji za toniranje",
      "description": "",
      "handle": "lice-uredaji-za-toniranje",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4CQ8JZ3NZ6QJC2MQW11",
      "rank": 0,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4CQ8JZ3NZ6QJC2MQW11",
        "created_at": "2024-01-03T14:45:42.933Z",
        "updated_at": "2024-01-03T14:45:42.933Z",
        "name": "Lice",
        "description": "",
        "handle": "njega-koze-lice_5325",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK42VB8WYE6GKG3FEJ41V",
        "rank": 0,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK91FDSS9XXAR6T9X22CG",
      "created_at": "2024-01-03T14:45:47.693Z",
      "updated_at": "2024-01-03T14:45:47.693Z",
      "name": "Završna šminka",
      "description": "",
      "handle": "lice-zavrsna-sminka",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4QT18DTEY1R94HB0RRX",
      "rank": 0,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4QT18DTEY1R94HB0RRX",
        "created_at": "2024-01-03T14:45:43.284Z",
        "updated_at": "2024-01-03T14:45:43.284Z",
        "name": "Lice",
        "description": "",
        "handle": "sminka-lice_11703",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK4495D212GWWBGHX6D9H",
        "rank": 0,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK41MNZ3C795B2JN5JW0G",
      "created_at": "2024-01-03T14:45:42.579Z",
      "updated_at": "2024-01-03T14:45:42.579Z",
      "name": "Brijanje i depilacija",
      "description": "",
      "handle": "ljepota-brijanje-i-depilacija",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": null,
      "rank": 0,
      "metadata": null,
      "category_children": [
        {
          "id": "pcat_01HK7WK46D6H08A70HPE8S7SCQ",
          "created_at": "2024-01-03T14:45:42.731Z",
          "updated_at": "2024-01-03T14:45:42.731Z",
          "name": "Ručno brijanje",
          "description": "",
          "handle": "brijanje-i-depilacija-rucno-brijanje",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK41MNZ3C795B2JN5JW0G",
          "rank": 0,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK46YG69NRB3HQKNMNZ0C",
          "created_at": "2024-01-03T14:45:42.747Z",
          "updated_at": "2024-01-03T14:45:42.747Z",
          "name": "Električni brijači",
          "description": "",
          "handle": "brijanje-i-depilacija-elektricni-brijaci",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK41MNZ3C795B2JN5JW0G",
          "rank": 1,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK47G3STXHFMNMZ4MMQ7G",
          "created_at": "2024-01-03T14:45:42.766Z",
          "updated_at": "2024-01-03T14:45:42.766Z",
          "name": "Škare za kosu, brijači i aparati za brijanje tijela",
          "description": "",
          "handle": "brijanje-i-depilacija-skare-za-kosu-brijaci-i-aparati-za-brijanje-tijela",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK41MNZ3C795B2JN5JW0G",
          "rank": 2,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK481HCTWDV2XH5M4VNWK",
          "created_at": "2024-01-03T14:45:42.782Z",
          "updated_at": "2024-01-03T14:45:42.782Z",
          "name": "Njega nakon depilacije i brijanja",
          "description": "",
          "handle": "brijanje-i-depilacija-njega-nakon-depilacije-i-brijanja",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK41MNZ3C795B2JN5JW0G",
          "rank": 3,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK48MV187B5ZHKEDJWPEG",
          "created_at": "2024-01-03T14:45:42.801Z",
          "updated_at": "2024-01-03T14:45:42.801Z",
          "name": "Njega prije depilacije i brijanja",
          "description": "",
          "handle": "brijanje-i-depilacija-njega-prije-depilacije-i-brijanja",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK41MNZ3C795B2JN5JW0G",
          "rank": 4,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK493C3GNJNECHNPJ0K2N",
          "created_at": "2024-01-03T14:45:42.817Z",
          "updated_at": "2024-01-03T14:45:42.817Z",
          "name": "Uklanjanje dlaka",
          "description": "",
          "handle": "brijanje-i-depilacija-uklanjanje-dlaka",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK41MNZ3C795B2JN5JW0G",
          "rank": 5,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK49KK07E1DMRZYHFY1HD",
          "created_at": "2024-01-03T14:45:42.833Z",
          "updated_at": "2024-01-03T14:45:42.833Z",
          "name": "Škare",
          "description": "",
          "handle": "brijanje-i-depilacija-skare",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK41MNZ3C795B2JN5JW0G",
          "rank": 6,
          "metadata": null
        }
      ],
      "parent_category": null
    },
    {
      "id": "pcat_01HK7WK4P0YJC78YM99J3AQX3F",
      "created_at": "2024-01-03T14:45:43.230Z",
      "updated_at": "2024-01-03T14:45:43.230Z",
      "name": "Njega ruku i stopala",
      "description": "",
      "handle": "manikura-i-pedikura-njega-ruku-i-stopala",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK43VEK8SG6M4T81C3W37",
      "rank": 0,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK43VEK8SG6M4T81C3W37",
        "created_at": "2024-01-03T14:45:42.650Z",
        "updated_at": "2024-01-03T14:45:42.650Z",
        "name": "Manikura i pedikura",
        "description": "",
        "handle": "ljepota-manikura-i-pedikura",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": null,
        "rank": 4,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKBYCZ8V7TPP4XFGMJF0G",
      "created_at": "2024-01-03T14:45:50.661Z",
      "updated_at": "2024-01-03T14:45:50.661Z",
      "name": "Opuštajuće antistres maske",
      "description": "",
      "handle": "maske-opustajuce-antistres-maske",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK6V1EVEFGH0FNVJKPN1D",
      "rank": 0,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK6V1EVEFGH0FNVJKPN1D",
        "created_at": "2024-01-03T14:45:45.439Z",
        "updated_at": "2024-01-03T14:45:45.439Z",
        "name": "Maske",
        "description": "",
        "handle": "lice-maske",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK4CQ8JZ3NZ6QJC2MQW11",
        "rank": 3,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK4JRZ92REX0CX4M9JWEM",
      "created_at": "2024-01-03T14:45:43.125Z",
      "updated_at": "2024-01-03T14:45:43.125Z",
      "name": "Pribor za friziranje",
      "description": "",
      "handle": "njega-kose-pribor-za-friziranje",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK43BQJST1XZ18R4BST01",
      "rank": 0,
      "metadata": null,
      "category_children": [
        {
          "id": "pcat_01HK7WK77V7NYEXTF0XQBTC438",
          "created_at": "2024-01-03T14:45:45.849Z",
          "updated_at": "2024-01-03T14:45:45.849Z",
          "name": "Kopče za kosu",
          "description": "",
          "handle": "pribor-za-friziranje-kopce-za-kosu",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4JRZ92REX0CX4M9JWEM",
          "rank": 0,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK78AEKY7AFS5R2QMW62J",
          "created_at": "2024-01-03T14:45:45.864Z",
          "updated_at": "2024-01-03T14:45:45.864Z",
          "name": "Trake za kosu",
          "description": "",
          "handle": "pribor-za-friziranje-trake-za-kosu",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4JRZ92REX0CX4M9JWEM",
          "rank": 1,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK78QJ6WPTT3VV6C3V55Y",
          "created_at": "2024-01-03T14:45:45.877Z",
          "updated_at": "2024-01-03T14:45:45.877Z",
          "name": "Gumice za kosu",
          "description": "",
          "handle": "pribor-za-friziranje-gumice-za-kosu",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4JRZ92REX0CX4M9JWEM",
          "rank": 2,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK794ETJK0B56S2SCAB7Q",
          "created_at": "2024-01-03T14:45:45.890Z",
          "updated_at": "2024-01-03T14:45:45.890Z",
          "name": "Gumice za friziranje",
          "description": "",
          "handle": "pribor-za-friziranje-gumice-za-friziranje",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4JRZ92REX0CX4M9JWEM",
          "rank": 3,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK79HGWWQVTTSXYPCBEBX",
          "created_at": "2024-01-03T14:45:45.903Z",
          "updated_at": "2024-01-03T14:45:45.903Z",
          "name": "Kopče",
          "description": "",
          "handle": "pribor-za-friziranje-kopce",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4JRZ92REX0CX4M9JWEM",
          "rank": 4,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK7A3MV7XAN0JBY0XCSGX",
          "created_at": "2024-01-03T14:45:45.920Z",
          "updated_at": "2024-01-03T14:45:45.920Z",
          "name": "Češljevi",
          "description": "",
          "handle": "pribor-za-friziranje-cesljevi",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4JRZ92REX0CX4M9JWEM",
          "rank": 5,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK7AN3CS0HG6B7VNDKXJE",
          "created_at": "2024-01-03T14:45:45.938Z",
          "updated_at": "2024-01-03T14:45:45.938Z",
          "name": "Pinceta",
          "description": "",
          "handle": "pribor-za-friziranje-pinceta",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4JRZ92REX0CX4M9JWEM",
          "rank": 6,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK7B4ECD28K577P20QFWY",
          "created_at": "2024-01-03T14:45:45.952Z",
          "updated_at": "2024-01-03T14:45:45.952Z",
          "name": "Ručnici za sušenje kose",
          "description": "",
          "handle": "pribor-za-friziranje-rucnici-za-susenje-kose",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4JRZ92REX0CX4M9JWEM",
          "rank": 7,
          "metadata": null
        }
      ],
      "parent_category": {
        "id": "pcat_01HK7WK43BQJST1XZ18R4BST01",
        "created_at": "2024-01-03T14:45:42.635Z",
        "updated_at": "2024-01-03T14:45:42.635Z",
        "name": "Njega kose",
        "description": "",
        "handle": "ljepota-njega-kose",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": null,
        "rank": 3,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK4CQ8JZ3NZ6QJC2MQW11",
      "created_at": "2024-01-03T14:45:42.933Z",
      "updated_at": "2024-01-03T14:45:42.933Z",
      "name": "Lice",
      "description": "",
      "handle": "njega-koze-lice_5325",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK42VB8WYE6GKG3FEJ41V",
      "rank": 0,
      "metadata": null,
      "category_children": [
        {
          "id": "pcat_01HK7WK6QKQKER5JVZ18WJ9V0Z",
          "created_at": "2024-01-03T14:45:45.322Z",
          "updated_at": "2024-01-03T14:45:45.322Z",
          "name": "Uređaji za toniranje",
          "description": "",
          "handle": "lice-uredaji-za-toniranje",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4CQ8JZ3NZ6QJC2MQW11",
          "rank": 0,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK6SZXFJCA60BBDC8S5W7",
          "created_at": "2024-01-03T14:45:45.388Z",
          "updated_at": "2024-01-03T14:45:45.388Z",
          "name": "Za hidrataciju",
          "description": "",
          "handle": "lice-za-hidrataciju",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4CQ8JZ3NZ6QJC2MQW11",
          "rank": 1,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK6TKWN0PFYN9S0Y0JEG0",
          "created_at": "2024-01-03T14:45:45.425Z",
          "updated_at": "2024-01-03T14:45:45.425Z",
          "name": "Sredstva za čišćenje i piling",
          "description": "",
          "handle": "lice-sredstva-za-ciscenje-i-piling",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4CQ8JZ3NZ6QJC2MQW11",
          "rank": 2,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK6V1EVEFGH0FNVJKPN1D",
          "created_at": "2024-01-03T14:45:45.439Z",
          "updated_at": "2024-01-03T14:45:45.439Z",
          "name": "Maske",
          "description": "",
          "handle": "lice-maske",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4CQ8JZ3NZ6QJC2MQW11",
          "rank": 3,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK6VGVXCDR5PH9GWTZJWJ",
          "created_at": "2024-01-03T14:45:45.453Z",
          "updated_at": "2024-01-03T14:45:45.453Z",
          "name": "Toneri",
          "description": "",
          "handle": "lice-toneri",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4CQ8JZ3NZ6QJC2MQW11",
          "rank": 4,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKA2274BVZPY0TZ6SN7Z9",
          "created_at": "2024-01-03T14:45:48.735Z",
          "updated_at": "2024-01-03T14:45:48.735Z",
          "name": "Čistači",
          "description": "",
          "handle": "lice-cistaci_20934",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4CQ8JZ3NZ6QJC2MQW11",
          "rank": 5,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKA3187RTGD687FKX5FEJ",
          "created_at": "2024-01-03T14:45:48.765Z",
          "updated_at": "2024-01-03T14:45:48.765Z",
          "name": "Polirke",
          "description": "",
          "handle": "lice-polirke",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4CQ8JZ3NZ6QJC2MQW11",
          "rank": 6,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKA3KYX77SWK0S59V8X08",
          "created_at": "2024-01-03T14:45:48.784Z",
          "updated_at": "2024-01-03T14:45:48.784Z",
          "name": "Pilinzi",
          "description": "",
          "handle": "lice-pilinzi",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4CQ8JZ3NZ6QJC2MQW11",
          "rank": 7,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKA4A9X2P8V3NRCBSCAWK",
          "created_at": "2024-01-03T14:45:48.806Z",
          "updated_at": "2024-01-03T14:45:48.806Z",
          "name": "Tretmani za mrlje",
          "description": "",
          "handle": "lice-tretmani-za-mrlje",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4CQ8JZ3NZ6QJC2MQW11",
          "rank": 8,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKA4YQGBBC7EX1RK5HXNT",
          "created_at": "2024-01-03T14:45:48.827Z",
          "updated_at": "2024-01-03T14:45:48.827Z",
          "name": "Trake",
          "description": "",
          "handle": "lice-trake",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4CQ8JZ3NZ6QJC2MQW11",
          "rank": 9,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKA5H4XTD85V9HS4FQ1VT",
          "created_at": "2024-01-03T14:45:48.846Z",
          "updated_at": "2024-01-03T14:45:48.846Z",
          "name": "Toneri za lice i adstringenti",
          "description": "",
          "handle": "lice-toneri-za-lice-i-adstringenti",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4CQ8JZ3NZ6QJC2MQW11",
          "rank": 10,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKA65JRAC0236SKZJ0C3F",
          "created_at": "2024-01-03T14:45:48.866Z",
          "updated_at": "2024-01-03T14:45:48.866Z",
          "name": "Serumi",
          "description": "",
          "handle": "lice-serumi",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4CQ8JZ3NZ6QJC2MQW11",
          "rank": 11,
          "metadata": null
        }
      ],
      "parent_category": {
        "id": "pcat_01HK7WK42VB8WYE6GKG3FEJ41V",
        "created_at": "2024-01-03T14:45:42.618Z",
        "updated_at": "2024-01-03T14:45:42.618Z",
        "name": "Njega kože",
        "description": "",
        "handle": "ljepota-njega-koze",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": null,
        "rank": 2,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK6Y7XG165YC5CEFQ3X9Q",
      "created_at": "2024-01-03T14:45:45.541Z",
      "updated_at": "2024-01-03T14:45:45.541Z",
      "name": "Ubrzivači i optimizatori tamnjenja",
      "description": "",
      "handle": "njega-koze-suncem-i-tamnjenjem-ubrzivaci-i-optimizatori-tamnjenja",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4E2GJJTAKSG9K2YD30C",
      "rank": 0,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4E2GJJTAKSG9K2YD30C",
        "created_at": "2024-01-03T14:45:42.976Z",
        "updated_at": "2024-01-03T14:45:42.976Z",
        "name": "Njega kože suncem i tamnjenjem",
        "description": "",
        "handle": "njega-koze-njega-koze-suncem-i-tamnjenjem",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK42VB8WYE6GKG3FEJ41V",
        "rank": 3,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKD6VP1PKDY9MWS0RQFAF",
      "created_at": "2024-01-03T14:45:51.961Z",
      "updated_at": "2024-01-03T14:45:51.961Z",
      "name": "Kreme",
      "description": "",
      "handle": "njega-kutikula-kreme",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WKAMBXQ4F92A7N7VK9M86",
      "rank": 0,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WKAMBXQ4F92A7N7VK9M86",
        "created_at": "2024-01-03T14:45:49.314Z",
        "updated_at": "2024-01-03T14:45:49.314Z",
        "name": "Njega kutikula",
        "description": "",
        "handle": "tretmani-za-nokte-njega-kutikula",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK4PWXATZSWB566TB87WB",
        "rank": 4,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK5NFMQG8XVM3BKEG546T",
      "created_at": "2024-01-03T14:45:44.237Z",
      "updated_at": "2024-01-03T14:45:44.237Z",
      "name": "Aftershave",
      "description": "",
      "handle": "njega-nakon-depilacije-i-brijanja-aftershave",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK481HCTWDV2XH5M4VNWK",
      "rank": 0,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK481HCTWDV2XH5M4VNWK",
        "created_at": "2024-01-03T14:45:42.782Z",
        "updated_at": "2024-01-03T14:45:42.782Z",
        "name": "Njega nakon depilacije i brijanja",
        "description": "",
        "handle": "brijanje-i-depilacija-njega-nakon-depilacije-i-brijanja",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK41MNZ3C795B2JN5JW0G",
        "rank": 3,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK5YE2AVK0FAHS3JSZCH9",
      "created_at": "2024-01-03T14:45:44.523Z",
      "updated_at": "2024-01-03T14:45:44.523Z",
      "name": "Ulja",
      "description": "",
      "handle": "njega-prije-depilacije-i-brijanja-ulja",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK48MV187B5ZHKEDJWPEG",
      "rank": 0,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK48MV187B5ZHKEDJWPEG",
        "created_at": "2024-01-03T14:45:42.801Z",
        "updated_at": "2024-01-03T14:45:42.801Z",
        "name": "Njega prije depilacije i brijanja",
        "description": "",
        "handle": "brijanje-i-depilacija-njega-prije-depilacije-i-brijanja",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK41MNZ3C795B2JN5JW0G",
        "rank": 4,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKDZ2A1A1PSMY46N4JR2W",
      "created_at": "2024-01-03T14:45:52.735Z",
      "updated_at": "2024-01-03T14:45:52.735Z",
      "name": "Kreme",
      "description": "",
      "handle": "nocna-njega-kreme",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WKBSGXTDPPGA4ZZRT7ZNX",
      "rank": 0,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WKBSGXTDPPGA4ZZRT7ZNX",
        "created_at": "2024-01-03T14:45:50.510Z",
        "updated_at": "2024-01-03T14:45:50.510Z",
        "name": "Noćna njega",
        "description": "",
        "handle": "za-hidrataciju-nocna-njega",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK6SZXFJCA60BBDC8S5W7",
        "rank": 1,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKCVDSTPC75MGVA0DG7GZ",
      "created_at": "2024-01-03T14:45:51.595Z",
      "updated_at": "2024-01-03T14:45:51.595Z",
      "name": "Aplikatori",
      "description": "",
      "handle": "oci-aplikatori",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK9X10701060SA690EKN6",
      "rank": 0,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK9X10701060SA690EKN6",
        "created_at": "2024-01-03T14:45:48.574Z",
        "updated_at": "2024-01-03T14:45:48.574Z",
        "name": "Oči",
        "description": "",
        "handle": "alati-i-pribor-za-sminkanje-oci",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK57XS2NQS0EGT358BW0Q",
        "rank": 2,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKDEDDZ809FD0XJS3G80K",
      "created_at": "2024-01-03T14:45:52.203Z",
      "updated_at": "2024-01-03T14:45:52.203Z",
      "name": "Aplikatori",
      "description": "",
      "handle": "oci-aplikatori_25084",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WKB41CSS4HCT9G53TPNK8",
      "rank": 0,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WKB41CSS4HCT9G53TPNK8",
        "created_at": "2024-01-03T14:45:49.823Z",
        "updated_at": "2024-01-03T14:45:49.823Z",
        "name": "Oči",
        "description": "",
        "handle": "cetke-i-pribor-za-sminkanje-oci",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK5D0544YXJQT9G60XVED",
        "rank": 2,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK75B9R0PH5ZR1S16HTGN",
      "created_at": "2024-01-03T14:45:45.769Z",
      "updated_at": "2024-01-03T14:45:45.769Z",
      "name": "Kreme",
      "description": "",
      "handle": "oci-kreme_5392",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4J1NW5Q3B0PAC2JEQ2N",
      "rank": 0,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4J1NW5Q3B0PAC2JEQ2N",
        "created_at": "2024-01-03T14:45:43.101Z",
        "updated_at": "2024-01-03T14:45:43.101Z",
        "name": "Oči",
        "description": "",
        "handle": "njega-koze-oci_5391",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK42VB8WYE6GKG3FEJ41V",
        "rank": 6,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK9BHTCNMT54TDR5D4AXX",
      "created_at": "2024-01-03T14:45:48.011Z",
      "updated_at": "2024-01-03T14:45:48.011Z",
      "name": "Protiv podočnjaka",
      "description": "",
      "handle": "oci-protiv-podocnjaka",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4T4JAQRP4VBRRAJR36J",
      "rank": 0,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4T4JAQRP4VBRRAJR36J",
        "created_at": "2024-01-03T14:45:43.362Z",
        "updated_at": "2024-01-03T14:45:43.362Z",
        "name": "Oči",
        "description": "",
        "handle": "sminka-oci_11745",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK4495D212GWWBGHX6D9H",
        "rank": 4,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKB5VJMSGQTBDVWYDW3AN",
      "created_at": "2024-01-03T14:45:49.882Z",
      "updated_at": "2024-01-03T14:45:49.882Z",
      "name": "Aparati za tetoviranje",
      "description": "",
      "handle": "oprema-za-tetoviranje-aparati-za-tetoviranje",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK5E7TWKXSDKE1B6B7FCQ",
      "rank": 0,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK5E7TWKXSDKE1B6B7FCQ",
        "created_at": "2024-01-03T14:45:44.005Z",
        "updated_at": "2024-01-03T14:45:44.005Z",
        "name": "Oprema za tetoviranje",
        "description": "",
        "handle": "pribor-i-dodaci-oprema-za-tetoviranje",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK45W0F9X0VMBBCAVDPWD",
        "rank": 5,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKCNYWC66PZGHBF0589MS",
      "created_at": "2024-01-03T14:45:51.418Z",
      "updated_at": "2024-01-03T14:45:51.418Z",
      "name": "Igle",
      "description": "",
      "handle": "oprema-za-tetoviranje-igle",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK97BH9JQSNRK7NH98XRD",
      "rank": 0,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK97BH9JQSNRK7NH98XRD",
        "created_at": "2024-01-03T14:45:47.879Z",
        "updated_at": "2024-01-03T14:45:47.879Z",
        "name": "Oprema za tetoviranje",
        "description": "",
        "handle": "tijelo-oprema-za-tetoviranje",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK4RBTHMFSNA47WTF4D6J",
        "rank": 2,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK6N5WSRHATDWQ8AHA2MG",
      "created_at": "2024-01-03T14:45:45.250Z",
      "updated_at": "2024-01-03T14:45:45.250Z",
      "name": "Ulja za tuširanje",
      "description": "",
      "handle": "osobna-cistoca-ulja-za-tusiranje",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4C6VG3TJWR51GB1ZQJW",
      "rank": 0,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4C6VG3TJWR51GB1ZQJW",
        "created_at": "2024-01-03T14:45:42.916Z",
        "updated_at": "2024-01-03T14:45:42.916Z",
        "name": "Osobna čistoća",
        "description": "",
        "handle": "kupanje-i-osobna-higijena-osobna-cistoca",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK42BQ7XFW617X5XP4BAR",
        "rank": 4,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK4V5YV6J6PV0DFNVKD6N",
      "created_at": "2024-01-03T14:45:43.395Z",
      "updated_at": "2024-01-03T14:45:43.395Z",
      "name": "Esencijalna ulja ",
      "description": "",
      "handle": "parfemi-i-mirisi-esencijalna-ulja",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK45CQPDHWCYPEW3W862G",
      "rank": 0,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK45CQPDHWCYPEW3W862G",
        "created_at": "2024-01-03T14:45:42.699Z",
        "updated_at": "2024-01-03T14:45:42.699Z",
        "name": "Parfemi i mirisi",
        "description": "",
        "handle": "ljepota-parfemi-i-mirisi",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": null,
        "rank": 6,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK9ZCEMS8FR42Y1078E5P",
      "created_at": "2024-01-03T14:45:48.649Z",
      "updated_at": "2024-01-03T14:45:48.649Z",
      "name": "Hidratantni sprejevi za tijelo",
      "description": "",
      "handle": "pilinzi-i-tretmani-za-tijelo-hidratantni-sprejevi-za-tijelo",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK58KGH06FH7809X0WYY9",
      "rank": 0,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK58KGH06FH7809X0WYY9",
        "created_at": "2024-01-03T14:45:43.824Z",
        "updated_at": "2024-01-03T14:45:43.824Z",
        "name": "Pilinzi i tretmani za tijelo",
        "description": "",
        "handle": "kupanje-i-osobna-higijena-pilinzi-i-tretmani-za-tijelo",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK42BQ7XFW617X5XP4BAR",
        "rank": 5,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKE07WSQKSZAKYXAXPDJP",
      "created_at": "2024-01-03T14:45:52.774Z",
      "updated_at": "2024-01-03T14:45:52.774Z",
      "name": "Kreme",
      "description": "",
      "handle": "post-tretmani-za-kozu-kreme_11732",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WKCQ6P3AB7MR7NPKAT3NA",
      "rank": 0,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WKCQ6P3AB7MR7NPKAT3NA",
        "created_at": "2024-01-03T14:45:51.459Z",
        "updated_at": "2024-01-03T14:45:51.459Z",
        "name": "Post-tretmani za kožu",
        "description": "",
        "handle": "oprema-za-tetoviranje-post-tretmani-za-kozu",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK97BH9JQSNRK7NH98XRD",
        "rank": 2,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKB6SR3KAH5ZDH3ZVS6HM",
      "created_at": "2024-01-03T14:45:49.911Z",
      "updated_at": "2024-01-03T14:45:49.911Z",
      "name": "Četke za brijanje",
      "description": "",
      "handle": "pribor-cetke-za-brijanje",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK5EWA9P9TTPVVM3FFT1X",
      "rank": 0,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK5EWA9P9TTPVVM3FFT1X",
        "created_at": "2024-01-03T14:45:44.026Z",
        "updated_at": "2024-01-03T14:45:44.026Z",
        "name": "Pribor",
        "description": "",
        "handle": "rucno-brijanje-pribor",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK46D6H08A70HPE8S7SCQ",
        "rank": 0,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKCG154WJVKWMPJYN62MQ",
      "created_at": "2024-01-03T14:45:51.231Z",
      "updated_at": "2024-01-03T14:45:51.231Z",
      "name": "Četke za nokte",
      "description": "",
      "handle": "pribor-cetke-za-nokte",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK8TNZ6RWPNFYABFMKJ6W",
      "rank": 0,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK8TNZ6RWPNFYABFMKJ6W",
        "created_at": "2024-01-03T14:45:47.475Z",
        "updated_at": "2024-01-03T14:45:47.475Z",
        "name": "Pribor",
        "description": "",
        "handle": "dizajni-za-nokte-pribor",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK4PDYPCE7H5D1FAFMHNK",
        "rank": 7,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK8X7JG89BQEYJQ7V3NKF",
      "created_at": "2024-01-03T14:45:47.558Z",
      "updated_at": "2024-01-03T14:45:47.558Z",
      "name": "Kliješta za nokte",
      "description": "",
      "handle": "pribor-i-dodaci-klijesta-za-nokte",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4Q9J4VMNM7KX6F1K8RQ",
      "rank": 0,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4Q9J4VMNM7KX6F1K8RQ",
        "created_at": "2024-01-03T14:45:43.271Z",
        "updated_at": "2024-01-03T14:45:43.271Z",
        "name": "Pribor i dodaci",
        "description": "",
        "handle": "manikura-i-pedikura-pribor-i-dodaci",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK43VEK8SG6M4T81C3W37",
        "rank": 3,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK567FCGG2X517C1WNA99",
      "created_at": "2024-01-03T14:45:43.747Z",
      "updated_at": "2024-01-03T14:45:43.747Z",
      "name": "Kuglice, pamučni jastučići i pamučni štapići",
      "description": "",
      "handle": "pribor-i-dodaci-kuglice-pamucni-jastucici-i-pamucni-stapici",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK45W0F9X0VMBBCAVDPWD",
      "rank": 0,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK45W0F9X0VMBBCAVDPWD",
        "created_at": "2024-01-03T14:45:42.715Z",
        "updated_at": "2024-01-03T14:45:42.715Z",
        "name": "Pribor i dodaci",
        "description": "",
        "handle": "ljepota-pribor-i-dodaci",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": null,
        "rank": 7,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK77V7NYEXTF0XQBTC438",
      "created_at": "2024-01-03T14:45:45.849Z",
      "updated_at": "2024-01-03T14:45:45.849Z",
      "name": "Kopče za kosu",
      "description": "",
      "handle": "pribor-za-friziranje-kopce-za-kosu",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4JRZ92REX0CX4M9JWEM",
      "rank": 0,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4JRZ92REX0CX4M9JWEM",
        "created_at": "2024-01-03T14:45:43.125Z",
        "updated_at": "2024-01-03T14:45:43.125Z",
        "name": "Pribor za friziranje",
        "description": "",
        "handle": "njega-kose-pribor-za-friziranje",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK43BQJST1XZ18R4BST01",
        "rank": 0,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKBCXBJYXFY682K0PCVSC",
      "created_at": "2024-01-03T14:45:50.107Z",
      "updated_at": "2024-01-03T14:45:50.107Z",
      "name": "Zamjenske glave za električne brijače",
      "description": "",
      "handle": "pribor-zamjenske-glave-za-elektricne-brijace",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK5GP4MZXQ69EPS0WG1AR",
      "rank": 0,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK5GP4MZXQ69EPS0WG1AR",
        "created_at": "2024-01-03T14:45:44.084Z",
        "updated_at": "2024-01-03T14:45:44.084Z",
        "name": "Pribor",
        "description": "",
        "handle": "elektricni-brijaci-pribor",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK46YG69NRB3HQKNMNZ0C",
        "rank": 0,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK8P5KP8N7HD1N7H4W2EJ",
      "created_at": "2024-01-03T14:45:47.331Z",
      "updated_at": "2024-01-03T14:45:47.331Z",
      "name": "Futrole za noževe, oštrice i britvice",
      "description": "",
      "handle": "pribor-za-sisanje-futrole-za-nozeve-ostrice-i-britvice",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4NHTG89E1DN79HZD0W5",
      "rank": 0,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4NHTG89E1DN79HZD0W5",
        "created_at": "2024-01-03T14:45:43.215Z",
        "updated_at": "2024-01-03T14:45:43.215Z",
        "name": "Pribor za šišanje",
        "description": "",
        "handle": "njega-kose-pribor-za-sisanje",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK43BQJST1XZ18R4BST01",
        "rank": 6,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKCDV66XD2MJEVKWVTNV4",
      "created_at": "2024-01-03T14:45:51.160Z",
      "updated_at": "2024-01-03T14:45:51.160Z",
      "name": "Zaštitna ljepila",
      "description": "",
      "handle": "pribor-za-ukrasavanje-noktiju-zastitna-ljepila",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK8QT38HTXECJJZ3XSY34",
      "rank": 0,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK8QT38HTXECJJZ3XSY34",
        "created_at": "2024-01-03T14:45:47.384Z",
        "updated_at": "2024-01-03T14:45:47.384Z",
        "name": "Pribor za ukrašavanje noktiju",
        "description": "",
        "handle": "dizajni-za-nokte-pribor-za-ukrasavanje-noktiju",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK4PDYPCE7H5D1FAFMHNK",
        "rank": 0,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK83BS0K28E3C66X8GJJ7",
      "created_at": "2024-01-03T14:45:46.729Z",
      "updated_at": "2024-01-03T14:45:46.729Z",
      "name": "Za raščešljavanje",
      "description": "",
      "handle": "proizvodi-za-friziranje-za-rascesljavanje",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4MPDDG329H66PT1KDYF",
      "rank": 0,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4MPDDG329H66PT1KDYF",
        "created_at": "2024-01-03T14:45:43.187Z",
        "updated_at": "2024-01-03T14:45:43.187Z",
        "name": "Proizvodi za friziranje",
        "description": "",
        "handle": "njega-kose-proizvodi-za-friziranje",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK43BQJST1XZ18R4BST01",
        "rank": 4,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK88JKR3GYNG6J8Z8PP3K",
      "created_at": "2024-01-03T14:45:46.896Z",
      "updated_at": "2024-01-03T14:45:46.896Z",
      "name": "Pribor za ravnanje",
      "description": "",
      "handle": "proizvodi-za-njegu-kose-pribor-za-ravnanje",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4N31HG0EQ98AA4ZHV8C",
      "rank": 0,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4N31HG0EQ98AA4ZHV8C",
        "created_at": "2024-01-03T14:45:43.201Z",
        "updated_at": "2024-01-03T14:45:43.201Z",
        "name": "Proizvodi za njegu kose",
        "description": "",
        "handle": "njega-kose-proizvodi-za-njegu-kose",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK43BQJST1XZ18R4BST01",
        "rank": 5,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK5EWA9P9TTPVVM3FFT1X",
      "created_at": "2024-01-03T14:45:44.026Z",
      "updated_at": "2024-01-03T14:45:44.026Z",
      "name": "Pribor",
      "description": "",
      "handle": "rucno-brijanje-pribor",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK46D6H08A70HPE8S7SCQ",
      "rank": 0,
      "metadata": null,
      "category_children": [
        {
          "id": "pcat_01HK7WKB6SR3KAH5ZDH3ZVS6HM",
          "created_at": "2024-01-03T14:45:49.911Z",
          "updated_at": "2024-01-03T14:45:49.911Z",
          "name": "Četke za brijanje",
          "description": "",
          "handle": "pribor-cetke-za-brijanje",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK5EWA9P9TTPVVM3FFT1X",
          "rank": 0,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKB79NHNXKS41RJQRYVCD",
          "created_at": "2024-01-03T14:45:49.927Z",
          "updated_at": "2024-01-03T14:45:49.927Z",
          "name": "Zdjelice za sapun za brijanje",
          "description": "",
          "handle": "pribor-zdjelice-za-sapun-za-brijanje",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK5EWA9P9TTPVVM3FFT1X",
          "rank": 1,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKB7TFAVCEGPW7M29E5FC",
          "created_at": "2024-01-03T14:45:49.944Z",
          "updated_at": "2024-01-03T14:45:49.944Z",
          "name": "Futrole",
          "description": "",
          "handle": "pribor-futrole_1024",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK5EWA9P9TTPVVM3FFT1X",
          "rank": 2,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKB8BPXMAMPPN0M89MBZD",
          "created_at": "2024-01-03T14:45:49.961Z",
          "updated_at": "2024-01-03T14:45:49.961Z",
          "name": "Držači za pribor za ručno brijanje",
          "description": "",
          "handle": "pribor-drzaci-za-pribor-za-rucno-brijanje",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK5EWA9P9TTPVVM3FFT1X",
          "rank": 3,
          "metadata": null
        }
      ],
      "parent_category": {
        "id": "pcat_01HK7WK46D6H08A70HPE8S7SCQ",
        "created_at": "2024-01-03T14:45:42.731Z",
        "updated_at": "2024-01-03T14:45:42.731Z",
        "name": "Ručno brijanje",
        "description": "",
        "handle": "brijanje-i-depilacija-rucno-brijanje",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK41MNZ3C795B2JN5JW0G",
        "rank": 0,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKAGBAQ5GGNNW409FPQ0D",
      "created_at": "2024-01-03T14:45:49.192Z",
      "updated_at": "2024-01-03T14:45:49.192Z",
      "name": "Kreme za stopala",
      "description": "",
      "handle": "ruke-i-stopala-kreme-za-stopala_20958",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK5ARJD927HV0B1E6F91V",
      "rank": 0,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK5ARJD927HV0B1E6F91V",
        "created_at": "2024-01-03T14:45:43.893Z",
        "updated_at": "2024-01-03T14:45:43.893Z",
        "name": "Ruke i stopala",
        "description": "",
        "handle": "manikura-i-pedikura-ruke-i-stopala",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK43VEK8SG6M4T81C3W37",
        "rank": 4,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK70XHF1HJJACBCFM5286",
      "created_at": "2024-01-03T14:45:45.627Z",
      "updated_at": "2024-01-03T14:45:45.627Z",
      "name": "Ulja i kreme za zanoktice",
      "description": "",
      "handle": "ruke-i-stopala-ulja-i-kreme-za-zanoktice",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4G7CJB2WFRCRPS0131Q",
      "rank": 0,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4G7CJB2WFRCRPS0131Q",
        "created_at": "2024-01-03T14:45:43.032Z",
        "updated_at": "2024-01-03T14:45:43.032Z",
        "name": "Ruke i stopala",
        "description": "",
        "handle": "njega-koze-ruke-i-stopala",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK42VB8WYE6GKG3FEJ41V",
        "rank": 5,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK6CJ0ZAFR886W35NE5FM",
      "created_at": "2024-01-03T14:45:44.974Z",
      "updated_at": "2024-01-03T14:45:44.974Z",
      "name": "Škare za bradu i brkove",
      "description": "",
      "handle": "skare-skare-za-bradu-i-brkove",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK49KK07E1DMRZYHFY1HD",
      "rank": 0,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK49KK07E1DMRZYHFY1HD",
        "created_at": "2024-01-03T14:45:42.833Z",
        "updated_at": "2024-01-03T14:45:42.833Z",
        "name": "Škare",
        "description": "",
        "handle": "brijanje-i-depilacija-skare",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK41MNZ3C795B2JN5JW0G",
        "rank": 6,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK5J88H7Q94FFN2RD1WZG",
      "created_at": "2024-01-03T14:45:44.134Z",
      "updated_at": "2024-01-03T14:45:44.134Z",
      "name": "Ulja za čišćenje",
      "description": "",
      "handle": "skare-za-kosu-brijaci-i-aparati-za-brijanje-tijela-ulja-za-ciscenje",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK47G3STXHFMNMZ4MMQ7G",
      "rank": 0,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK47G3STXHFMNMZ4MMQ7G",
        "created_at": "2024-01-03T14:45:42.766Z",
        "updated_at": "2024-01-03T14:45:42.766Z",
        "name": "Škare za kosu, brijači i aparati za brijanje tijela",
        "description": "",
        "handle": "brijanje-i-depilacija-skare-za-kosu-brijaci-i-aparati-za-brijanje-tijela",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK41MNZ3C795B2JN5JW0G",
        "rank": 2,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK4QT18DTEY1R94HB0RRX",
      "created_at": "2024-01-03T14:45:43.284Z",
      "updated_at": "2024-01-03T14:45:43.284Z",
      "name": "Lice",
      "description": "",
      "handle": "sminka-lice_11703",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4495D212GWWBGHX6D9H",
      "rank": 0,
      "metadata": null,
      "category_children": [
        {
          "id": "pcat_01HK7WK91FDSS9XXAR6T9X22CG",
          "created_at": "2024-01-03T14:45:47.693Z",
          "updated_at": "2024-01-03T14:45:47.693Z",
          "name": "Završna šminka",
          "description": "",
          "handle": "lice-zavrsna-sminka",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4QT18DTEY1R94HB0RRX",
          "rank": 0,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK91YZ2GP018FD1FQBH06",
          "created_at": "2024-01-03T14:45:47.708Z",
          "updated_at": "2024-01-03T14:45:47.708Z",
          "name": "Baze",
          "description": "",
          "handle": "lice-baze",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4QT18DTEY1R94HB0RRX",
          "rank": 1,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK92ECJB7S3BF325WHGPN",
          "created_at": "2024-01-03T14:45:47.724Z",
          "updated_at": "2024-01-03T14:45:47.724Z",
          "name": "Bronzeri i highlighteri",
          "description": "",
          "handle": "lice-bronzeri-i-highlighteri",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4QT18DTEY1R94HB0RRX",
          "rank": 2,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK92W85MN7B2C5P25M51Q",
          "created_at": "2024-01-03T14:45:47.738Z",
          "updated_at": "2024-01-03T14:45:47.738Z",
          "name": "Rumenila",
          "description": "",
          "handle": "lice-rumenila",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4QT18DTEY1R94HB0RRX",
          "rank": 3,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK939NYQVC03WYH7K74JR",
          "created_at": "2024-01-03T14:45:47.751Z",
          "updated_at": "2024-01-03T14:45:47.751Z",
          "name": "Korektivne i kreme protiv nesavršenosti",
          "description": "",
          "handle": "lice-korektivne-i-kreme-protiv-nesavrsenosti",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4QT18DTEY1R94HB0RRX",
          "rank": 4,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK93RFWCDBWV1PPF4R8KQ",
          "created_at": "2024-01-03T14:45:47.766Z",
          "updated_at": "2024-01-03T14:45:47.766Z",
          "name": "Iluminatori",
          "description": "",
          "handle": "lice-iluminatori",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4QT18DTEY1R94HB0RRX",
          "rank": 5,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK945ZMSDXSSF5A65SBJ2",
          "created_at": "2024-01-03T14:45:47.779Z",
          "updated_at": "2024-01-03T14:45:47.779Z",
          "name": "Korektivna šminka",
          "description": "",
          "handle": "lice-korektivna-sminka",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4QT18DTEY1R94HB0RRX",
          "rank": 6,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK94NQVVCDE2JW4B4KZ5D",
          "created_at": "2024-01-03T14:45:47.794Z",
          "updated_at": "2024-01-03T14:45:47.794Z",
          "name": "Puderi",
          "description": "",
          "handle": "lice-puderi",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4QT18DTEY1R94HB0RRX",
          "rank": 7,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK952DX31E0SKK6G6JTPS",
          "created_at": "2024-01-03T14:45:47.808Z",
          "updated_at": "2024-01-03T14:45:47.808Z",
          "name": "Primeri",
          "description": "",
          "handle": "lice-primeri",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4QT18DTEY1R94HB0RRX",
          "rank": 8,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKANQ83E7JVVVTGY79E5F",
          "created_at": "2024-01-03T14:45:49.359Z",
          "updated_at": "2024-01-03T14:45:49.359Z",
          "name": "BB kreme",
          "description": "",
          "handle": "lice-bb-kreme",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4QT18DTEY1R94HB0RRX",
          "rank": 9,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKAQ7PY2VM50R4VSNMDBM",
          "created_at": "2024-01-03T14:45:49.408Z",
          "updated_at": "2024-01-03T14:45:49.408Z",
          "name": "CC kreme",
          "description": "",
          "handle": "lice-cc-kreme",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4QT18DTEY1R94HB0RRX",
          "rank": 10,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKARMX1C6H2KE2Z9Z67WR",
          "created_at": "2024-01-03T14:45:49.449Z",
          "updated_at": "2024-01-03T14:45:49.449Z",
          "name": "Bronzeri",
          "description": "",
          "handle": "lice-bronzeri",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4QT18DTEY1R94HB0RRX",
          "rank": 11,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKATD9VZX528NQ0CM2Z8Z",
          "created_at": "2024-01-03T14:45:49.505Z",
          "updated_at": "2024-01-03T14:45:49.505Z",
          "name": "Iluminatori i luminizatori",
          "description": "",
          "handle": "lice-iluminatori-i-luminizatori",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4QT18DTEY1R94HB0RRX",
          "rank": 12,
          "metadata": null
        }
      ],
      "parent_category": {
        "id": "pcat_01HK7WK4495D212GWWBGHX6D9H",
        "created_at": "2024-01-03T14:45:42.665Z",
        "updated_at": "2024-01-03T14:45:42.665Z",
        "name": "Šminka",
        "description": "",
        "handle": "ljepota-sminka",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": null,
        "rank": 5,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKC9ME9V0BFDKK0S3RNTD",
      "created_at": "2024-01-03T14:45:51.025Z",
      "updated_at": "2024-01-03T14:45:51.025Z",
      "name": "Solarni filtri za tijelo",
      "description": "",
      "handle": "solarni-filtri-solarni-filtri-za-tijelo",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK6ZJ0V95JGS57SVR77VS",
      "rank": 0,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK6ZJ0V95JGS57SVR77VS",
        "created_at": "2024-01-03T14:45:45.584Z",
        "updated_at": "2024-01-03T14:45:45.584Z",
        "name": "Solarni filtri",
        "description": "",
        "handle": "njega-koze-suncem-i-tamnjenjem-solarni-filtri",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK4E2GJJTAKSG9K2YD30C",
        "rank": 3,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKD43FCE3QZMAH1TC1V21",
      "created_at": "2024-01-03T14:45:51.871Z",
      "updated_at": "2024-01-03T14:45:51.871Z",
      "name": "Solarni filtri za tijelo",
      "description": "",
      "handle": "solarni-filtri-solarni-filtri-za-tijelo_25061",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WKA8MEFNXMV7G5QH0DGE2",
      "rank": 0,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WKA8MEFNXMV7G5QH0DGE2",
        "created_at": "2024-01-03T14:45:48.945Z",
        "updated_at": "2024-01-03T14:45:48.945Z",
        "name": "Solarni filtri",
        "description": "",
        "handle": "kreme-za-suncanje-i-tamnjenje-solarni-filtri",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK59M5WTCRNSKRG8JBN56",
        "rank": 2,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKBVP6GSWEWFFT08YC04C",
      "created_at": "2024-01-03T14:45:50.576Z",
      "updated_at": "2024-01-03T14:45:50.576Z",
      "name": "Četke i uređaji za čišćenje",
      "description": "",
      "handle": "sredstva-za-ciscenje-i-piling-cetke-i-uredaji-za-ciscenje",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK6TKWN0PFYN9S0Y0JEG0",
      "rank": 0,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK6TKWN0PFYN9S0Y0JEG0",
        "created_at": "2024-01-03T14:45:45.425Z",
        "updated_at": "2024-01-03T14:45:45.425Z",
        "name": "Sredstva za čišćenje i piling",
        "description": "",
        "handle": "lice-sredstva-za-ciscenje-i-piling",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK4CQ8JZ3NZ6QJC2MQW11",
        "rank": 2,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKC878KCPFEWN40Q5B8MY",
      "created_at": "2024-01-03T14:45:50.979Z",
      "updated_at": "2024-01-03T14:45:50.979Z",
      "name": "Sredstva za samotamnjenje za tijelo",
      "description": "",
      "handle": "sredstva-za-samotamnjenje-sredstva-za-samotamnjenje-za-tijelo",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK6Z40CPVFT96RJA3DA69",
      "rank": 0,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK6Z40CPVFT96RJA3DA69",
        "created_at": "2024-01-03T14:45:45.569Z",
        "updated_at": "2024-01-03T14:45:45.569Z",
        "name": "Sredstva za samotamnjenje",
        "description": "",
        "handle": "njega-koze-suncem-i-tamnjenjem-sredstva-za-samotamnjenje",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK4E2GJJTAKSG9K2YD30C",
        "rank": 2,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKD3NSADR4AJZB3DY8JWH",
      "created_at": "2024-01-03T14:45:51.859Z",
      "updated_at": "2024-01-03T14:45:51.859Z",
      "name": "Sredstva za samotamnjenje za tijelo",
      "description": "",
      "handle": "sredstva-za-samotamnjenje-sredstva-za-samotamnjenje-za-tijelo_25059",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WKA7CPSVGX00GKN7Y8S32",
      "rank": 0,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WKA7CPSVGX00GKN7Y8S32",
        "created_at": "2024-01-03T14:45:48.905Z",
        "updated_at": "2024-01-03T14:45:48.905Z",
        "name": "Sredstva za samotamnjenje",
        "description": "",
        "handle": "kreme-za-suncanje-i-tamnjenje-sredstva-za-samotamnjenje",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK59M5WTCRNSKRG8JBN56",
        "rank": 0,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKAXCX1T94W3GASAMDBQ5",
      "created_at": "2024-01-03T14:45:49.605Z",
      "updated_at": "2024-01-03T14:45:49.605Z",
      "name": "Sredstva za uklanjanje šminke s očiju",
      "description": "",
      "handle": "sredstva-za-uklanjanje-sminke-sredstva-za-uklanjanje-sminke-s-ociju",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK5B70957TDHQA8K7KARN",
      "rank": 0,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK5B70957TDHQA8K7KARN",
        "created_at": "2024-01-03T14:45:43.909Z",
        "updated_at": "2024-01-03T14:45:43.909Z",
        "name": "Sredstva za uklanjanje šminke",
        "description": "",
        "handle": "sminka-sredstva-za-uklanjanje-sminke",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK4495D212GWWBGHX6D9H",
        "rank": 6,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK9STN15MB61Y62XR7C5G",
      "created_at": "2024-01-03T14:45:48.471Z",
      "updated_at": "2024-01-03T14:45:48.471Z",
      "name": "Kućne esencije",
      "description": "",
      "handle": "svijece-i-esencije-za-dom-kucne-esencije",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK5549S5WEVDCDA22RP2N",
      "rank": 0,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK5549S5WEVDCDA22RP2N",
        "created_at": "2024-01-03T14:45:43.707Z",
        "updated_at": "2024-01-03T14:45:43.707Z",
        "name": "Svijeće i esencije za dom",
        "description": "",
        "handle": "parfemi-i-mirisi-svijece-i-esencije-za-dom",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK45CQPDHWCYPEW3W862G",
        "rank": 7,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK95GEZ1A1RP0TF0TF5WZ",
      "created_at": "2024-01-03T14:45:47.822Z",
      "updated_at": "2024-01-03T14:45:47.822Z",
      "name": "Korektori",
      "description": "",
      "handle": "tijelo-korektori",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4RBTHMFSNA47WTF4D6J",
      "rank": 0,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4RBTHMFSNA47WTF4D6J",
        "created_at": "2024-01-03T14:45:43.304Z",
        "updated_at": "2024-01-03T14:45:43.304Z",
        "name": "Tijelo",
        "description": "",
        "handle": "sminka-tijelo_11713",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK4495D212GWWBGHX6D9H",
        "rank": 1,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK6VXG6KXMQ9X38KYNYX4",
      "created_at": "2024-01-03T14:45:45.467Z",
      "updated_at": "2024-01-03T14:45:45.467Z",
      "name": "Pilinzi",
      "description": "",
      "handle": "tijelo-pilinzi",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4DMG2Q4J3RMF32RA7Y1",
      "rank": 0,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4DMG2Q4J3RMF32RA7Y1",
        "created_at": "2024-01-03T14:45:42.962Z",
        "updated_at": "2024-01-03T14:45:42.962Z",
        "name": "Tijelo",
        "description": "",
        "handle": "njega-koze-tijelo_5355",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK42VB8WYE6GKG3FEJ41V",
        "rank": 2,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK9V466Q4VJEZJ17KXJAP",
      "created_at": "2024-01-03T14:45:48.513Z",
      "updated_at": "2024-01-03T14:45:48.513Z",
      "name": "Futrole za šminku",
      "description": "",
      "handle": "torbe-i-futrole-futrole-za-sminku",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK571RGN4X4BE7Q8XRZ7N",
      "rank": 0,
      "metadata": null,
      "category_children": [
        {
          "id": "pcat_01HK7WKCQSH9ZZWK4D67Y3WV8N",
          "created_at": "2024-01-03T14:45:51.479Z",
          "updated_at": "2024-01-03T14:45:51.479Z",
          "name": "Kutije i držači",
          "description": "",
          "handle": "futrole-za-sminku-kutije-i-drzaci",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK9V466Q4VJEZJ17KXJAP",
          "rank": 0,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKCR83GH6GREEVBV51G70",
          "created_at": "2024-01-03T14:45:51.494Z",
          "updated_at": "2024-01-03T14:45:51.494Z",
          "name": "Torbice za šminku",
          "description": "",
          "handle": "futrole-za-sminku-torbice-za-sminku",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK9V466Q4VJEZJ17KXJAP",
          "rank": 1,
          "metadata": null
        }
      ],
      "parent_category": {
        "id": "pcat_01HK7WK571RGN4X4BE7Q8XRZ7N",
        "created_at": "2024-01-03T14:45:43.774Z",
        "updated_at": "2024-01-03T14:45:43.774Z",
        "name": "Torbe i futrole",
        "description": "",
        "handle": "pribor-i-dodaci-torbe-i-futrole",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK45W0F9X0VMBBCAVDPWD",
        "rank": 1,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK8VM7KP958E9108V388R",
      "created_at": "2024-01-03T14:45:47.502Z",
      "updated_at": "2024-01-03T14:45:47.502Z",
      "name": "Izbjeljivači",
      "description": "",
      "handle": "tretmani-za-nokte-izbjeljivaci",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4PWXATZSWB566TB87WB",
      "rank": 0,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4PWXATZSWB566TB87WB",
        "created_at": "2024-01-03T14:45:43.258Z",
        "updated_at": "2024-01-03T14:45:43.258Z",
        "name": "Tretmani za nokte",
        "description": "",
        "handle": "manikura-i-pedikura-tretmani-za-nokte",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK43VEK8SG6M4T81C3W37",
        "rank": 2,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK62WN0JV5Y8QHXV1J1X3",
      "created_at": "2024-01-03T14:45:44.665Z",
      "updated_at": "2024-01-03T14:45:44.665Z",
      "name": "Kreme za depilaciju",
      "description": "",
      "handle": "uklanjanje-dlaka-kreme-za-depilaciju",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK493C3GNJNECHNPJ0K2N",
      "rank": 0,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK493C3GNJNECHNPJ0K2N",
        "created_at": "2024-01-03T14:45:42.817Z",
        "updated_at": "2024-01-03T14:45:42.817Z",
        "name": "Uklanjanje dlaka",
        "description": "",
        "handle": "brijanje-i-depilacija-uklanjanje-dlaka",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK41MNZ3C795B2JN5JW0G",
        "rank": 5,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKAAPT097NHX021CNFSTB",
      "created_at": "2024-01-03T14:45:49.011Z",
      "updated_at": "2024-01-03T14:45:49.011Z",
      "name": "Ljepila",
      "description": "",
      "handle": "umeci-za-kosu-perike-i-dodaci-ljepila_20948",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK5A5XWEMK8CFBX8HWCSH",
      "rank": 0,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK5A5XWEMK8CFBX8HWCSH",
        "created_at": "2024-01-03T14:45:43.874Z",
        "updated_at": "2024-01-03T14:45:43.874Z",
        "name": "Umeci za kosu, perike i dodaci",
        "description": "",
        "handle": "njega-kose-umeci-za-kosu-perike-i-dodaci",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK43BQJST1XZ18R4BST01",
        "rank": 7,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKCKM0XSZ333TEVKYVTQJ",
      "created_at": "2024-01-03T14:45:51.346Z",
      "updated_at": "2024-01-03T14:45:51.346Z",
      "name": "Gelovi",
      "description": "",
      "handle": "umjetni-nokti-i-pribor-gelovi",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK8V224HR9CV9810ZE5S6",
      "rank": 0,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK8V224HR9CV9810ZE5S6",
        "created_at": "2024-01-03T14:45:47.487Z",
        "updated_at": "2024-01-03T14:45:47.487Z",
        "name": "Umjetni nokti i pribor",
        "description": "",
        "handle": "dizajni-za-nokte-umjetni-nokti-i-pribor",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK4PDYPCE7H5D1FAFMHNK",
        "rank": 8,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK701S9B9XXQVGG7NGVZJ",
      "created_at": "2024-01-03T14:45:45.599Z",
      "updated_at": "2024-01-03T14:45:45.599Z",
      "name": "Balzami",
      "description": "",
      "handle": "usne-balzami",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4EKHB2R4FXMDWSTMP8X",
      "rank": 0,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4EKHB2R4FXMDWSTMP8X",
        "created_at": "2024-01-03T14:45:42.991Z",
        "updated_at": "2024-01-03T14:45:42.991Z",
        "name": "Usne",
        "description": "",
        "handle": "njega-koze-usne",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK42VB8WYE6GKG3FEJ41V",
        "rank": 4,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK98JPE14XZWYMHW5KK7N",
      "created_at": "2024-01-03T14:45:47.919Z",
      "updated_at": "2024-01-03T14:45:47.919Z",
      "name": "Baze",
      "description": "",
      "handle": "usne-baze",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4SPEA6NYM5SZ5DDC5A8",
      "rank": 0,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4SPEA6NYM5SZ5DDC5A8",
        "created_at": "2024-01-03T14:45:43.348Z",
        "updated_at": "2024-01-03T14:45:43.348Z",
        "name": "Usne",
        "description": "",
        "handle": "sminka-usne_11739",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK4495D212GWWBGHX6D9H",
        "rank": 3,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKBS3M1Y3H3YRT1SGTGNX",
      "created_at": "2024-01-03T14:45:50.496Z",
      "updated_at": "2024-01-03T14:45:50.496Z",
      "name": "Dnevna njega",
      "description": "",
      "handle": "za-hidrataciju-dnevna-njega",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK6SZXFJCA60BBDC8S5W7",
      "rank": 0,
      "metadata": null,
      "category_children": [
        {
          "id": "pcat_01HK7WKDXTFKM2A66290CXRRH0",
          "created_at": "2024-01-03T14:45:52.695Z",
          "updated_at": "2024-01-03T14:45:52.695Z",
          "name": "Kreme",
          "description": "",
          "handle": "dnevna-njega-kreme",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WKBS3M1Y3H3YRT1SGTGNX",
          "rank": 0,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKDY8GYJ4SYZ8XEV46K7C",
          "created_at": "2024-01-03T14:45:52.710Z",
          "updated_at": "2024-01-03T14:45:52.710Z",
          "name": "Gelovi",
          "description": "",
          "handle": "dnevna-njega-gelovi",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WKBS3M1Y3H3YRT1SGTGNX",
          "rank": 1,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKDYM1WJ8C37HSQRWSSMV",
          "created_at": "2024-01-03T14:45:52.723Z",
          "updated_at": "2024-01-03T14:45:52.723Z",
          "name": "Serumi i tekućine",
          "description": "",
          "handle": "dnevna-njega-serumi-i-tekucine",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WKBS3M1Y3H3YRT1SGTGNX",
          "rank": 2,
          "metadata": null
        }
      ],
      "parent_category": {
        "id": "pcat_01HK7WK6SZXFJCA60BBDC8S5W7",
        "created_at": "2024-01-03T14:45:45.388Z",
        "updated_at": "2024-01-03T14:45:45.388Z",
        "name": "Za hidrataciju",
        "description": "",
        "handle": "lice-za-hidrataciju",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK4CQ8JZ3NZ6QJC2MQW11",
        "rank": 1,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKC2E9KQTERQ290SGNPVF",
      "created_at": "2024-01-03T14:45:50.791Z",
      "updated_at": "2024-01-03T14:45:50.791Z",
      "name": "Ulja",
      "description": "",
      "handle": "za-hidrataciju-ulja",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK6WA7CDBMWAWAHDXNKA8",
      "rank": 0,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK6WA7CDBMWAWAHDXNKA8",
        "created_at": "2024-01-03T14:45:45.480Z",
        "updated_at": "2024-01-03T14:45:45.480Z",
        "name": "Za hidrataciju",
        "description": "",
        "handle": "tijelo-za-hidrataciju",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK4DMG2Q4J3RMF32RA7Y1",
        "rank": 1,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK9H0P481ZJ4J1B6Z0TJ7",
      "created_at": "2024-01-03T14:45:48.190Z",
      "updated_at": "2024-01-03T14:45:48.190Z",
      "name": "Kolonjska voda",
      "description": "",
      "handle": "za-muskarce-kolonjska-voda",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4WXV52RYH206J4P8TD0",
      "rank": 0,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4WXV52RYH206J4P8TD0",
        "created_at": "2024-01-03T14:45:43.446Z",
        "updated_at": "2024-01-03T14:45:43.446Z",
        "name": "Za muškarce",
        "description": "",
        "handle": "parfemi-i-mirisi-za-muskarce_14094",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK45CQPDHWCYPEW3W862G",
        "rank": 2,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKB8VGMYF9B990VK0MC17",
      "created_at": "2024-01-03T14:45:49.977Z",
      "updated_at": "2024-01-03T14:45:49.977Z",
      "name": "Muške oštrice za brijanje ",
      "description": "",
      "handle": "za-muskarce-muske-ostrice-za-brijanje",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK5FHHQR6TNHWK6MYMBRV",
      "rank": 0,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK5FHHQR6TNHWK6MYMBRV",
        "created_at": "2024-01-03T14:45:44.044Z",
        "updated_at": "2024-01-03T14:45:44.044Z",
        "name": "Za muškarce",
        "description": "",
        "handle": "rucno-brijanje-za-muskarce",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK46D6H08A70HPE8S7SCQ",
        "rank": 1,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKBAFT89YDBHRXCD8E2Y0",
      "created_at": "2024-01-03T14:45:50.028Z",
      "updated_at": "2024-01-03T14:45:50.028Z",
      "name": "Britvice za brijanje za žene",
      "description": "",
      "handle": "za-zene-britvice-za-brijanje-za-zene",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK5G6G274F8G2WFZ816SN",
      "rank": 0,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK5G6G274F8G2WFZ816SN",
        "created_at": "2024-01-03T14:45:44.067Z",
        "updated_at": "2024-01-03T14:45:44.067Z",
        "name": "Za žene",
        "description": "",
        "handle": "rucno-brijanje-za-zene",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK46D6H08A70HPE8S7SCQ",
        "rank": 2,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK9JHDR43FGH963119W2Y",
      "created_at": "2024-01-03T14:45:48.240Z",
      "updated_at": "2024-01-03T14:45:48.240Z",
      "name": "Mirisna ulja",
      "description": "",
      "handle": "za-zene-mirisna-ulja",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4YNQSS786T0PHYXE7FP",
      "rank": 0,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4YNQSS786T0PHYXE7FP",
        "created_at": "2024-01-03T14:45:43.492Z",
        "updated_at": "2024-01-03T14:45:43.492Z",
        "name": "Za žene",
        "description": "",
        "handle": "parfemi-i-mirisi-za-zene_14100",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK45CQPDHWCYPEW3W862G",
        "rank": 3,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKD9ESHRY3TEM1R9B598G",
      "created_at": "2024-01-03T14:45:52.038Z",
      "updated_at": "2024-01-03T14:45:52.038Z",
      "name": "Gelovi",
      "description": "",
      "handle": "aftershave-gelovi",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WKAZX7KTPB76GCBMFEHGP",
      "rank": 1,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WKAZX7KTPB76GCBMFEHGP",
        "created_at": "2024-01-03T14:45:49.684Z",
        "updated_at": "2024-01-03T14:45:49.684Z",
        "name": "Aftershave",
        "description": "",
        "handle": "za-muskarce-aftershave",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK4WXV52RYH206J4P8TD0",
        "rank": 4,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK9WEZEVWDMWDPK8W460Y",
      "created_at": "2024-01-03T14:45:48.552Z",
      "updated_at": "2024-01-03T14:45:48.552Z",
      "name": "Lice",
      "description": "",
      "handle": "alati-i-pribor-za-sminkanje-lice",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK57XS2NQS0EGT358BW0Q",
      "rank": 1,
      "metadata": null,
      "category_children": [
        {
          "id": "pcat_01HK7WKCSJFVQKG8MXY718C1Q0",
          "created_at": "2024-01-03T14:45:51.536Z",
          "updated_at": "2024-01-03T14:45:51.536Z",
          "name": "Aplikatori za prah",
          "description": "",
          "handle": "aplikatori-za-prah_18839",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK9WEZEVWDMWDPK8W460Y",
          "rank": 0,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKCT0REQ5YJGNGGVMHEE2",
          "created_at": "2024-01-03T14:45:51.550Z",
          "updated_at": "2024-01-03T14:45:51.550Z",
          "name": "Četke",
          "description": "",
          "handle": "lice-cetke",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK9WEZEVWDMWDPK8W460Y",
          "rank": 1,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKCTFBMZT6A2CQY1JZ06R",
          "created_at": "2024-01-03T14:45:51.565Z",
          "updated_at": "2024-01-03T14:45:51.565Z",
          "name": "Spužve",
          "description": "",
          "handle": "lice-spuzve",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK9WEZEVWDMWDPK8W460Y",
          "rank": 2,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKCTZQFKC9GDQ5Z161NWM",
          "created_at": "2024-01-03T14:45:51.581Z",
          "updated_at": "2024-01-03T14:45:51.581Z",
          "name": "Apsorpcijski papir",
          "description": "",
          "handle": "lice-apsorpcijski-papir",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK9WEZEVWDMWDPK8W460Y",
          "rank": 3,
          "metadata": null
        }
      ],
      "parent_category": {
        "id": "pcat_01HK7WK57XS2NQS0EGT358BW0Q",
        "created_at": "2024-01-03T14:45:43.802Z",
        "updated_at": "2024-01-03T14:45:43.802Z",
        "name": "Alati i pribor za šminkanje",
        "description": "",
        "handle": "pribor-i-dodaci-alati-i-pribor-za-sminkanje",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK45W0F9X0VMBBCAVDPWD",
        "rank": 2,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK7HS3A8G7QRX198A256W",
      "created_at": "2024-01-03T14:45:46.167Z",
      "updated_at": "2024-01-03T14:45:46.167Z",
      "name": "Aditivi i punjenja za boje",
      "description": "",
      "handle": "bojanje-kose-aditivi-i-punjenja-za-boje",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4KQVZQK6XPGZJEJ50QZ",
      "rank": 1,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4KQVZQK6XPGZJEJ50QZ",
        "created_at": "2024-01-03T14:45:43.157Z",
        "updated_at": "2024-01-03T14:45:43.157Z",
        "name": "Bojanje kose",
        "description": "",
        "handle": "njega-kose-bojanje-kose",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK43BQJST1XZ18R4BST01",
        "rank": 2,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK46YG69NRB3HQKNMNZ0C",
      "created_at": "2024-01-03T14:45:42.747Z",
      "updated_at": "2024-01-03T14:45:42.747Z",
      "name": "Električni brijači",
      "description": "",
      "handle": "brijanje-i-depilacija-elektricni-brijaci",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK41MNZ3C795B2JN5JW0G",
      "rank": 1,
      "metadata": null,
      "category_children": [
        {
          "id": "pcat_01HK7WK5GP4MZXQ69EPS0WG1AR",
          "created_at": "2024-01-03T14:45:44.084Z",
          "updated_at": "2024-01-03T14:45:44.084Z",
          "name": "Pribor",
          "description": "",
          "handle": "elektricni-brijaci-pribor",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK46YG69NRB3HQKNMNZ0C",
          "rank": 0,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK5H8GFDPH0Z1TTQXAYJQ",
          "created_at": "2024-01-03T14:45:44.101Z",
          "updated_at": "2024-01-03T14:45:44.101Z",
          "name": "Električni brijači za muškarce",
          "description": "",
          "handle": "elektricni-brijaci-elektricni-brijaci-za-muskarce",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK46YG69NRB3HQKNMNZ0C",
          "rank": 1,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK5HVMCFSE258T24TNAYS",
          "created_at": "2024-01-03T14:45:44.117Z",
          "updated_at": "2024-01-03T14:45:44.117Z",
          "name": "Električni brijači za žene",
          "description": "",
          "handle": "elektricni-brijaci-elektricni-brijaci-za-zene",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK46YG69NRB3HQKNMNZ0C",
          "rank": 2,
          "metadata": null
        }
      ],
      "parent_category": {
        "id": "pcat_01HK7WK41MNZ3C795B2JN5JW0G",
        "created_at": "2024-01-03T14:45:42.579Z",
        "updated_at": "2024-01-03T14:45:42.579Z",
        "name": "Brijanje i depilacija",
        "description": "",
        "handle": "ljepota-brijanje-i-depilacija",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": null,
        "rank": 0,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKB3HY3RTXZFKQMFDSP7T",
      "created_at": "2024-01-03T14:45:49.806Z",
      "updated_at": "2024-01-03T14:45:49.806Z",
      "name": "Kistovi za usne",
      "description": "",
      "handle": "cetke-i-pribor-za-sminkanje-kistovi-za-usne",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK5D0544YXJQT9G60XVED",
      "rank": 1,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK5D0544YXJQT9G60XVED",
        "created_at": "2024-01-03T14:45:43.966Z",
        "updated_at": "2024-01-03T14:45:43.966Z",
        "name": "Četke i pribor za šminkanje",
        "description": "",
        "handle": "pribor-i-dodaci-cetke-i-pribor-za-sminkanje",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK45W0F9X0VMBBCAVDPWD",
        "rank": 3,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKCS54NGMD5M9TQBEP8JN",
      "created_at": "2024-01-03T14:45:51.522Z",
      "updated_at": "2024-01-03T14:45:51.522Z",
      "name": "Setovi kistova",
      "description": "",
      "handle": "cetke-setovi-kistova",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK9VTHAJTS8VVR8H2VYBE",
      "rank": 1,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK9VTHAJTS8VVR8H2VYBE",
        "created_at": "2024-01-03T14:45:48.534Z",
        "updated_at": "2024-01-03T14:45:48.534Z",
        "name": "Četke",
        "description": "",
        "handle": "alati-i-pribor-za-sminkanje-cetke",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK57XS2NQS0EGT358BW0Q",
        "rank": 0,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKDV7N6X21E623DR9Q493",
      "created_at": "2024-01-03T14:45:52.607Z",
      "updated_at": "2024-01-03T14:45:52.607Z",
      "name": "Četke",
      "description": "",
      "handle": "ciscenje-cetke_1040",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WKBECX73T18N8C8Z8EJZZ",
      "rank": 1,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WKBECX73T18N8C8Z8EJZZ",
        "created_at": "2024-01-03T14:45:50.154Z",
        "updated_at": "2024-01-03T14:45:50.154Z",
        "name": "Čišćenje",
        "description": "",
        "handle": "pribor-ciscenje_1038",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK5GP4MZXQ69EPS0WG1AR",
        "rank": 3,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKD01JBPNDMDRXB6V683Q",
      "created_at": "2024-01-03T14:45:51.742Z",
      "updated_at": "2024-01-03T14:45:51.742Z",
      "name": "Kreme i mlijeka",
      "description": "",
      "handle": "cistaci-kreme-i-mlijeka",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WKA2274BVZPY0TZ6SN7Z9",
      "rank": 1,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WKA2274BVZPY0TZ6SN7Z9",
        "created_at": "2024-01-03T14:45:48.735Z",
        "updated_at": "2024-01-03T14:45:48.735Z",
        "name": "Čistači",
        "description": "",
        "handle": "lice-cistaci_20934",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK4CQ8JZ3NZ6QJC2MQW11",
        "rank": 5,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKBHZ6W52WZMZQJ2BY30G",
      "created_at": "2024-01-03T14:45:50.264Z",
      "updated_at": "2024-01-03T14:45:50.264Z",
      "name": "Grijači za vosak",
      "description": "",
      "handle": "depilacija-voskom-grijaci-za-vosak",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK649EWGYVF36CJ6071RM",
      "rank": 1,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK649EWGYVF36CJ6071RM",
        "created_at": "2024-01-03T14:45:44.706Z",
        "updated_at": "2024-01-03T14:45:44.706Z",
        "name": "Depilacija voskom",
        "description": "",
        "handle": "uklanjanje-dlaka-depilacija-voskom",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK493C3GNJNECHNPJ0K2N",
        "rank": 1,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKBRKMPVAA7GVTB52WGYX",
      "created_at": "2024-01-03T14:45:50.481Z",
      "updated_at": "2024-01-03T14:45:50.481Z",
      "name": "Depilatori",
      "description": "",
      "handle": "depilatori-i-dodaci-depilatori",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK68D07TWPZME6GB2Q1K0",
      "rank": 1,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK68D07TWPZME6GB2Q1K0",
        "created_at": "2024-01-03T14:45:44.839Z",
        "updated_at": "2024-01-03T14:45:44.839Z",
        "name": "Depilatori i dodaci",
        "description": "",
        "handle": "uklanjanje-dlaka-depilatori-i-dodaci",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK493C3GNJNECHNPJ0K2N",
        "rank": 4,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKBQRSS3YT16BXKZ2VX4C",
      "created_at": "2024-01-03T14:45:50.450Z",
      "updated_at": "2024-01-03T14:45:50.450Z",
      "name": "Impulsni depilacijski sustavi",
      "description": "",
      "handle": "depilatori-s-impulsnim-svjetlom-impulsni-depilacijski-sustavi",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK65RQ00RY9H3YFT5TEA6",
      "rank": 1,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK65RQ00RY9H3YFT5TEA6",
        "created_at": "2024-01-03T14:45:44.751Z",
        "updated_at": "2024-01-03T14:45:44.751Z",
        "name": "Depilatori s impulsnim svjetlom",
        "description": "",
        "handle": "uklanjanje-dlaka-depilatori-s-impulsnim-svjetlom",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK493C3GNJNECHNPJ0K2N",
        "rank": 2,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK8R73K7EVCDXG17G66GZ",
      "created_at": "2024-01-03T14:45:47.397Z",
      "updated_at": "2024-01-03T14:45:47.397Z",
      "name": "Gornji sloj",
      "description": "",
      "handle": "dizajni-za-nokte-gornji-sloj",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4PDYPCE7H5D1FAFMHNK",
      "rank": 1,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4PDYPCE7H5D1FAFMHNK",
        "created_at": "2024-01-03T14:45:43.244Z",
        "updated_at": "2024-01-03T14:45:43.244Z",
        "name": "Dizajni za nokte",
        "description": "",
        "handle": "manikura-i-pedikura-dizajni-za-nokte",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK43VEK8SG6M4T81C3W37",
        "rank": 1,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKDY8GYJ4SYZ8XEV46K7C",
      "created_at": "2024-01-03T14:45:52.710Z",
      "updated_at": "2024-01-03T14:45:52.710Z",
      "name": "Gelovi",
      "description": "",
      "handle": "dnevna-njega-gelovi",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WKBS3M1Y3H3YRT1SGTGNX",
      "rank": 1,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WKBS3M1Y3H3YRT1SGTGNX",
        "created_at": "2024-01-03T14:45:50.496Z",
        "updated_at": "2024-01-03T14:45:50.496Z",
        "name": "Dnevna njega",
        "description": "",
        "handle": "za-hidrataciju-dnevna-njega",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK6SZXFJCA60BBDC8S5W7",
        "rank": 0,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK6JWXWVGJQNVKVRDH5RP",
      "created_at": "2024-01-03T14:45:45.178Z",
      "updated_at": "2024-01-03T14:45:45.178Z",
      "name": "Kupka",
      "description": "",
      "handle": "dodaci-za-kupke-kupka",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4AMW72YGS9N8YSBWJ37",
      "rank": 1,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4AMW72YGS9N8YSBWJ37",
        "created_at": "2024-01-03T14:45:42.866Z",
        "updated_at": "2024-01-03T14:45:42.866Z",
        "name": "Dodaci za kupke",
        "description": "",
        "handle": "kupanje-i-osobna-higijena-dodaci-za-kupke",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK42BQ7XFW617X5XP4BAR",
        "rank": 1,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK5H8GFDPH0Z1TTQXAYJQ",
      "created_at": "2024-01-03T14:45:44.101Z",
      "updated_at": "2024-01-03T14:45:44.101Z",
      "name": "Električni brijači za muškarce",
      "description": "",
      "handle": "elektricni-brijaci-elektricni-brijaci-za-muskarce",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK46YG69NRB3HQKNMNZ0C",
      "rank": 1,
      "metadata": null,
      "category_children": [
        {
          "id": "pcat_01HK7WKBF7YG39DFNYPWYMZRJV",
          "created_at": "2024-01-03T14:45:50.181Z",
          "updated_at": "2024-01-03T14:45:50.181Z",
          "name": "Električni brijači s oštricama za muškarce",
          "description": "",
          "handle": "elektricni-brijaci-za-muskarce-elektricni-brijaci-s-ostricama-za-muskarce",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK5H8GFDPH0Z1TTQXAYJQ",
          "rank": 0,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKBFM39QWT667Y6VD4JYA",
          "created_at": "2024-01-03T14:45:50.194Z",
          "updated_at": "2024-01-03T14:45:50.194Z",
          "name": "Rotacijski električni brijači za muškarce",
          "description": "",
          "handle": "elektricni-brijaci-za-muskarce-rotacijski-elektricni-brijaci-za-muskarce",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK5H8GFDPH0Z1TTQXAYJQ",
          "rank": 1,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKCXKZRSRCC9S5W3NJD2M",
          "created_at": "2024-01-03T14:45:51.665Z",
          "updated_at": "2024-01-03T14:45:51.665Z",
          "name": "Zamjenske glave za električne brijače",
          "description": "",
          "handle": "elektricni-brijaci-za-muskarce-zamjenske-glave-za-elektricne-brijace",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK5H8GFDPH0Z1TTQXAYJQ",
          "rank": 2,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKCY2QKJVW65BK9CCQK8R",
          "created_at": "2024-01-03T14:45:51.679Z",
          "updated_at": "2024-01-03T14:45:51.679Z",
          "name": "Zamjenske oštrice",
          "description": "",
          "handle": "elektricni-brijaci-za-muskarce-zamjenske-ostrice",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK5H8GFDPH0Z1TTQXAYJQ",
          "rank": 3,
          "metadata": null
        }
      ],
      "parent_category": {
        "id": "pcat_01HK7WK46YG69NRB3HQKNMNZ0C",
        "created_at": "2024-01-03T14:45:42.747Z",
        "updated_at": "2024-01-03T14:45:42.747Z",
        "name": "Električni brijači",
        "description": "",
        "handle": "brijanje-i-depilacija-elektricni-brijaci",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK41MNZ3C795B2JN5JW0G",
        "rank": 1,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKBFM39QWT667Y6VD4JYA",
      "created_at": "2024-01-03T14:45:50.194Z",
      "updated_at": "2024-01-03T14:45:50.194Z",
      "name": "Rotacijski električni brijači za muškarce",
      "description": "",
      "handle": "elektricni-brijaci-za-muskarce-rotacijski-elektricni-brijaci-za-muskarce",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK5H8GFDPH0Z1TTQXAYJQ",
      "rank": 1,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK5H8GFDPH0Z1TTQXAYJQ",
        "created_at": "2024-01-03T14:45:44.101Z",
        "updated_at": "2024-01-03T14:45:44.101Z",
        "name": "Električni brijači za muškarce",
        "description": "",
        "handle": "elektricni-brijaci-elektricni-brijaci-za-muskarce",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK46YG69NRB3HQKNMNZ0C",
        "rank": 1,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKD5RQFCXXH4JJ2XC036K",
      "created_at": "2024-01-03T14:45:51.926Z",
      "updated_at": "2024-01-03T14:45:51.926Z",
      "name": "Češljevi",
      "description": "",
      "handle": "fenovi-i-difuzori-cesljevi",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WKAA0GJVNVKHFMBVWE9GZ",
      "rank": 1,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WKAA0GJVNVKHFMBVWE9GZ",
        "created_at": "2024-01-03T14:45:48.986Z",
        "updated_at": "2024-01-03T14:45:48.986Z",
        "name": "Fenovi i difuzori",
        "description": "",
        "handle": "frizerski-uredaji-i-pribor-fenovi-i-difuzori",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK4K84XGGV7XG3TFC6W69",
        "rank": 13,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKCCWBAJFDF76EV39BD0R",
      "created_at": "2024-01-03T14:45:51.130Z",
      "updated_at": "2024-01-03T14:45:51.130Z",
      "name": "Češljevi",
      "description": "",
      "handle": "fenovi-za-kosu-cesljevi",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK7H0CCVR0D8RJQJFTVCE",
      "rank": 1,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK7H0CCVR0D8RJQJFTVCE",
        "created_at": "2024-01-03T14:45:46.142Z",
        "updated_at": "2024-01-03T14:45:46.142Z",
        "name": "Fenovi za kosu",
        "description": "",
        "handle": "frizerski-uredaji-i-pribor-fenovi-za-kosu",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK4K84XGGV7XG3TFC6W69",
        "rank": 11,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK7VNMNJ5117D2P2ZD7BR",
      "created_at": "2024-01-03T14:45:46.478Z",
      "updated_at": "2024-01-03T14:45:46.478Z",
      "name": "Frizerski ogrtači",
      "description": "",
      "handle": "frizerski-namjestaj-i-potrepstine-frizerski-ogrtaci",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4M7JE8S0KGQCMWF47WJ",
      "rank": 1,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4M7JE8S0KGQCMWF47WJ",
        "created_at": "2024-01-03T14:45:43.173Z",
        "updated_at": "2024-01-03T14:45:43.173Z",
        "name": "Frizerski namještaj i potrepštine",
        "description": "",
        "handle": "njega-kose-frizerski-namjestaj-i-potrepstine",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK43BQJST1XZ18R4BST01",
        "rank": 3,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK7BZPW5NHM7KGFCZHKC9",
      "created_at": "2024-01-03T14:45:45.981Z",
      "updated_at": "2024-01-03T14:45:45.981Z",
      "name": "Četke za kosu",
      "description": "",
      "handle": "frizerski-uredaji-i-pribor-cetke-za-kosu",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4K84XGGV7XG3TFC6W69",
      "rank": 1,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4K84XGGV7XG3TFC6W69",
        "created_at": "2024-01-03T14:45:43.141Z",
        "updated_at": "2024-01-03T14:45:43.141Z",
        "name": "Frizerski uređaji i pribor",
        "description": "",
        "handle": "njega-kose-frizerski-uredaji-i-pribor",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK43BQJST1XZ18R4BST01",
        "rank": 1,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKCR83GH6GREEVBV51G70",
      "created_at": "2024-01-03T14:45:51.494Z",
      "updated_at": "2024-01-03T14:45:51.494Z",
      "name": "Torbice za šminku",
      "description": "",
      "handle": "futrole-za-sminku-torbice-za-sminku",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK9V466Q4VJEZJ17KXJAP",
      "rank": 1,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK9V466Q4VJEZJ17KXJAP",
        "created_at": "2024-01-03T14:45:48.513Z",
        "updated_at": "2024-01-03T14:45:48.513Z",
        "name": "Futrole za šminku",
        "description": "",
        "handle": "torbe-i-futrole-futrole-za-sminku",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK571RGN4X4BE7Q8XRZ7N",
        "rank": 0,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKA7ZGXQYRBZ9BAADZQKB",
      "created_at": "2024-01-03T14:45:48.924Z",
      "updated_at": "2024-01-03T14:45:48.924Z",
      "name": "Ubrzivači i optimizatori tamnjenja",
      "description": "",
      "handle": "kreme-za-suncanje-i-tamnjenje-ubrzivaci-i-optimizatori-tamnjenja",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK59M5WTCRNSKRG8JBN56",
      "rank": 1,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK59M5WTCRNSKRG8JBN56",
        "created_at": "2024-01-03T14:45:43.857Z",
        "updated_at": "2024-01-03T14:45:43.857Z",
        "name": "Kreme za sunčanje i tamnjenje",
        "description": "",
        "handle": "njega-koze-kreme-za-suncanje-i-tamnjenje",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK42VB8WYE6GKG3FEJ41V",
        "rank": 8,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK4AMW72YGS9N8YSBWJ37",
      "created_at": "2024-01-03T14:45:42.866Z",
      "updated_at": "2024-01-03T14:45:42.866Z",
      "name": "Dodaci za kupke",
      "description": "",
      "handle": "kupanje-i-osobna-higijena-dodaci-za-kupke",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK42BQ7XFW617X5XP4BAR",
      "rank": 1,
      "metadata": null,
      "category_children": [
        {
          "id": "pcat_01HK7WK6JCH3GRER3MFHB8V6HT",
          "created_at": "2024-01-03T14:45:45.161Z",
          "updated_at": "2024-01-03T14:45:45.161Z",
          "name": "Ulja",
          "description": "",
          "handle": "dodaci-za-kupke-ulja",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4AMW72YGS9N8YSBWJ37",
          "rank": 0,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK6JWXWVGJQNVKVRDH5RP",
          "created_at": "2024-01-03T14:45:45.178Z",
          "updated_at": "2024-01-03T14:45:45.178Z",
          "name": "Kupka",
          "description": "",
          "handle": "dodaci-za-kupke-kupka",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4AMW72YGS9N8YSBWJ37",
          "rank": 1,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK6KF0BK04Z1P6QB2V34G",
          "created_at": "2024-01-03T14:45:45.196Z",
          "updated_at": "2024-01-03T14:45:45.196Z",
          "name": "Bombice za kupku",
          "description": "",
          "handle": "dodaci-za-kupke-bombice-za-kupku",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4AMW72YGS9N8YSBWJ37",
          "rank": 2,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK6M13H9R1A08CBJS555M",
          "created_at": "2024-01-03T14:45:45.214Z",
          "updated_at": "2024-01-03T14:45:45.214Z",
          "name": "Biseri i pahuljice za kupku",
          "description": "",
          "handle": "dodaci-za-kupke-biseri-i-pahuljice-za-kupku",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4AMW72YGS9N8YSBWJ37",
          "rank": 3,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK6MJ4JYZ8HZSQB1T0JA8",
          "created_at": "2024-01-03T14:45:45.232Z",
          "updated_at": "2024-01-03T14:45:45.232Z",
          "name": "Soli i minerali",
          "description": "",
          "handle": "dodaci-za-kupke-soli-i-minerali",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4AMW72YGS9N8YSBWJ37",
          "rank": 4,
          "metadata": null
        }
      ],
      "parent_category": {
        "id": "pcat_01HK7WK42BQ7XFW617X5XP4BAR",
        "created_at": "2024-01-03T14:45:42.602Z",
        "updated_at": "2024-01-03T14:45:42.602Z",
        "name": "Kupanje i osobna higijena",
        "description": "",
        "handle": "ljepota-kupanje-i-osobna-higijena",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": null,
        "rank": 1,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK6FF5A6ZVMAYJF47RXSE",
      "created_at": "2024-01-03T14:45:45.066Z",
      "updated_at": "2024-01-03T14:45:45.066Z",
      "name": "Spužve",
      "description": "",
      "handle": "kupaonski-pribor-spuzve",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4A2XE81CJFBQCJE5EC2",
      "rank": 1,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4A2XE81CJFBQCJE5EC2",
        "created_at": "2024-01-03T14:45:42.848Z",
        "updated_at": "2024-01-03T14:45:42.848Z",
        "name": "Kupaonski pribor",
        "description": "",
        "handle": "kupanje-i-osobna-higijena-kupaonski-pribor",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK42BQ7XFW617X5XP4BAR",
        "rank": 0,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK91YZ2GP018FD1FQBH06",
      "created_at": "2024-01-03T14:45:47.708Z",
      "updated_at": "2024-01-03T14:45:47.708Z",
      "name": "Baze",
      "description": "",
      "handle": "lice-baze",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4QT18DTEY1R94HB0RRX",
      "rank": 1,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4QT18DTEY1R94HB0RRX",
        "created_at": "2024-01-03T14:45:43.284Z",
        "updated_at": "2024-01-03T14:45:43.284Z",
        "name": "Lice",
        "description": "",
        "handle": "sminka-lice_11703",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK4495D212GWWBGHX6D9H",
        "rank": 0,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKCT0REQ5YJGNGGVMHEE2",
      "created_at": "2024-01-03T14:45:51.550Z",
      "updated_at": "2024-01-03T14:45:51.550Z",
      "name": "Četke",
      "description": "",
      "handle": "lice-cetke",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK9WEZEVWDMWDPK8W460Y",
      "rank": 1,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK9WEZEVWDMWDPK8W460Y",
        "created_at": "2024-01-03T14:45:48.552Z",
        "updated_at": "2024-01-03T14:45:48.552Z",
        "name": "Lice",
        "description": "",
        "handle": "alati-i-pribor-za-sminkanje-lice",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK57XS2NQS0EGT358BW0Q",
        "rank": 1,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKDD44H0EWGSA2WEADCGS",
      "created_at": "2024-01-03T14:45:52.161Z",
      "updated_at": "2024-01-03T14:45:52.161Z",
      "name": "Četke",
      "description": "",
      "handle": "lice-cetke_25081",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WKB4GBXA5NY5NKJJMS67V",
      "rank": 1,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WKB4GBXA5NY5NKJJMS67V",
        "created_at": "2024-01-03T14:45:49.837Z",
        "updated_at": "2024-01-03T14:45:49.837Z",
        "name": "Lice",
        "description": "",
        "handle": "cetke-i-pribor-za-sminkanje-lice",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK5D0544YXJQT9G60XVED",
        "rank": 3,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK6SZXFJCA60BBDC8S5W7",
      "created_at": "2024-01-03T14:45:45.388Z",
      "updated_at": "2024-01-03T14:45:45.388Z",
      "name": "Za hidrataciju",
      "description": "",
      "handle": "lice-za-hidrataciju",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4CQ8JZ3NZ6QJC2MQW11",
      "rank": 1,
      "metadata": null,
      "category_children": [
        {
          "id": "pcat_01HK7WKBS3M1Y3H3YRT1SGTGNX",
          "created_at": "2024-01-03T14:45:50.496Z",
          "updated_at": "2024-01-03T14:45:50.496Z",
          "name": "Dnevna njega",
          "description": "",
          "handle": "za-hidrataciju-dnevna-njega",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK6SZXFJCA60BBDC8S5W7",
          "rank": 0,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKBSGXTDPPGA4ZZRT7ZNX",
          "created_at": "2024-01-03T14:45:50.510Z",
          "updated_at": "2024-01-03T14:45:50.510Z",
          "name": "Noćna njega",
          "description": "",
          "handle": "za-hidrataciju-nocna-njega",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK6SZXFJCA60BBDC8S5W7",
          "rank": 1,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKBT02X37RFAWVR4X3017",
          "created_at": "2024-01-03T14:45:50.525Z",
          "updated_at": "2024-01-03T14:45:50.525Z",
          "name": "Sprejevi i maglice",
          "description": "",
          "handle": "za-hidrataciju-sprejevi-i-maglice",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK6SZXFJCA60BBDC8S5W7",
          "rank": 2,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKCZ0ZM3KVR5C7QK001S7",
          "created_at": "2024-01-03T14:45:51.710Z",
          "updated_at": "2024-01-03T14:45:51.710Z",
          "name": "Korektivne i kreme protiv nesavršenosti",
          "description": "",
          "handle": "za-hidrataciju-korektivne-i-kreme-protiv-nesavrsenosti",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK6SZXFJCA60BBDC8S5W7",
          "rank": 3,
          "metadata": null
        }
      ],
      "parent_category": {
        "id": "pcat_01HK7WK4CQ8JZ3NZ6QJC2MQW11",
        "created_at": "2024-01-03T14:45:42.933Z",
        "updated_at": "2024-01-03T14:45:42.933Z",
        "name": "Lice",
        "description": "",
        "handle": "njega-koze-lice_5325",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK42VB8WYE6GKG3FEJ41V",
        "rank": 0,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK42BQ7XFW617X5XP4BAR",
      "created_at": "2024-01-03T14:45:42.602Z",
      "updated_at": "2024-01-03T14:45:42.602Z",
      "name": "Kupanje i osobna higijena",
      "description": "",
      "handle": "ljepota-kupanje-i-osobna-higijena",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": null,
      "rank": 1,
      "metadata": null,
      "category_children": [
        {
          "id": "pcat_01HK7WK4A2XE81CJFBQCJE5EC2",
          "created_at": "2024-01-03T14:45:42.848Z",
          "updated_at": "2024-01-03T14:45:42.848Z",
          "name": "Kupaonski pribor",
          "description": "",
          "handle": "kupanje-i-osobna-higijena-kupaonski-pribor",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK42BQ7XFW617X5XP4BAR",
          "rank": 0,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK4AMW72YGS9N8YSBWJ37",
          "created_at": "2024-01-03T14:45:42.866Z",
          "updated_at": "2024-01-03T14:45:42.866Z",
          "name": "Dodaci za kupke",
          "description": "",
          "handle": "kupanje-i-osobna-higijena-dodaci-za-kupke",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK42BQ7XFW617X5XP4BAR",
          "rank": 1,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK4B4MMEP1TFJR5T1JGPH",
          "created_at": "2024-01-03T14:45:42.881Z",
          "updated_at": "2024-01-03T14:45:42.881Z",
          "name": "Dezodoransi",
          "description": "",
          "handle": "kupanje-i-osobna-higijena-dezodoransi",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK42BQ7XFW617X5XP4BAR",
          "rank": 2,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK4BPJ14F2XFATFDQBNTJ",
          "created_at": "2024-01-03T14:45:42.900Z",
          "updated_at": "2024-01-03T14:45:42.900Z",
          "name": "Kompleti",
          "description": "",
          "handle": "kupanje-i-osobna-higijena-kompleti",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK42BQ7XFW617X5XP4BAR",
          "rank": 3,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK4C6VG3TJWR51GB1ZQJW",
          "created_at": "2024-01-03T14:45:42.916Z",
          "updated_at": "2024-01-03T14:45:42.916Z",
          "name": "Osobna čistoća",
          "description": "",
          "handle": "kupanje-i-osobna-higijena-osobna-cistoca",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK42BQ7XFW617X5XP4BAR",
          "rank": 4,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK58KGH06FH7809X0WYY9",
          "created_at": "2024-01-03T14:45:43.824Z",
          "updated_at": "2024-01-03T14:45:43.824Z",
          "name": "Pilinzi i tretmani za tijelo",
          "description": "",
          "handle": "kupanje-i-osobna-higijena-pilinzi-i-tretmani-za-tijelo",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK42BQ7XFW617X5XP4BAR",
          "rank": 5,
          "metadata": null
        }
      ],
      "parent_category": null
    },
    {
      "id": "pcat_01HK7WK4PDYPCE7H5D1FAFMHNK",
      "created_at": "2024-01-03T14:45:43.244Z",
      "updated_at": "2024-01-03T14:45:43.244Z",
      "name": "Dizajni za nokte",
      "description": "",
      "handle": "manikura-i-pedikura-dizajni-za-nokte",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK43VEK8SG6M4T81C3W37",
      "rank": 1,
      "metadata": null,
      "category_children": [
        {
          "id": "pcat_01HK7WK8QT38HTXECJJZ3XSY34",
          "created_at": "2024-01-03T14:45:47.384Z",
          "updated_at": "2024-01-03T14:45:47.384Z",
          "name": "Pribor za ukrašavanje noktiju",
          "description": "",
          "handle": "dizajni-za-nokte-pribor-za-ukrasavanje-noktiju",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4PDYPCE7H5D1FAFMHNK",
          "rank": 0,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK8R73K7EVCDXG17G66GZ",
          "created_at": "2024-01-03T14:45:47.397Z",
          "updated_at": "2024-01-03T14:45:47.397Z",
          "name": "Gornji sloj",
          "description": "",
          "handle": "dizajni-za-nokte-gornji-sloj",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4PDYPCE7H5D1FAFMHNK",
          "rank": 1,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK8RM57YBDESB6PQT81TG",
          "created_at": "2024-01-03T14:45:47.410Z",
          "updated_at": "2024-01-03T14:45:47.410Z",
          "name": "Osnovni slojevi",
          "description": "",
          "handle": "dizajni-za-nokte-osnovni-slojevi",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4PDYPCE7H5D1FAFMHNK",
          "rank": 2,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK8S16QZ3Y71X9HD1TV7C",
          "created_at": "2024-01-03T14:45:47.423Z",
          "updated_at": "2024-01-03T14:45:47.423Z",
          "name": "Lakovi",
          "description": "",
          "handle": "dizajni-za-nokte-lakovi",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4PDYPCE7H5D1FAFMHNK",
          "rank": 3,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK8SECJX3MM3D1K1C5SNS",
          "created_at": "2024-01-03T14:45:47.436Z",
          "updated_at": "2024-01-03T14:45:47.436Z",
          "name": "Gel lakovi",
          "description": "",
          "handle": "dizajni-za-nokte-gel-lakovi",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4PDYPCE7H5D1FAFMHNK",
          "rank": 4,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK8SVF49V7SYBDPB6DEPE",
          "created_at": "2024-01-03T14:45:47.449Z",
          "updated_at": "2024-01-03T14:45:47.449Z",
          "name": "Odstranjivač laka za nokte",
          "description": "",
          "handle": "dizajni-za-nokte-odstranjivac-laka-za-nokte",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4PDYPCE7H5D1FAFMHNK",
          "rank": 5,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK8T8G4NJ1CDZJA4Z968J",
          "created_at": "2024-01-03T14:45:47.462Z",
          "updated_at": "2024-01-03T14:45:47.462Z",
          "name": "Setovi nokata",
          "description": "",
          "handle": "dizajni-za-nokte-setovi-nokata",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4PDYPCE7H5D1FAFMHNK",
          "rank": 6,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK8TNZ6RWPNFYABFMKJ6W",
          "created_at": "2024-01-03T14:45:47.475Z",
          "updated_at": "2024-01-03T14:45:47.475Z",
          "name": "Pribor",
          "description": "",
          "handle": "dizajni-za-nokte-pribor",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4PDYPCE7H5D1FAFMHNK",
          "rank": 7,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK8V224HR9CV9810ZE5S6",
          "created_at": "2024-01-03T14:45:47.487Z",
          "updated_at": "2024-01-03T14:45:47.487Z",
          "name": "Umjetni nokti i pribor",
          "description": "",
          "handle": "dizajni-za-nokte-umjetni-nokti-i-pribor",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4PDYPCE7H5D1FAFMHNK",
          "rank": 8,
          "metadata": null
        }
      ],
      "parent_category": {
        "id": "pcat_01HK7WK43VEK8SG6M4T81C3W37",
        "created_at": "2024-01-03T14:45:42.650Z",
        "updated_at": "2024-01-03T14:45:42.650Z",
        "name": "Manikura i pedikura",
        "description": "",
        "handle": "ljepota-manikura-i-pedikura",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": null,
        "rank": 4,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKBZQ31SBEMQP7AA65H05",
      "created_at": "2024-01-03T14:45:50.704Z",
      "updated_at": "2024-01-03T14:45:50.704Z",
      "name": "Maske za piling i čišćenje",
      "description": "",
      "handle": "maske-maske-za-piling-i-ciscenje",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK6V1EVEFGH0FNVJKPN1D",
      "rank": 1,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK6V1EVEFGH0FNVJKPN1D",
        "created_at": "2024-01-03T14:45:45.439Z",
        "updated_at": "2024-01-03T14:45:45.439Z",
        "name": "Maske",
        "description": "",
        "handle": "lice-maske",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK4CQ8JZ3NZ6QJC2MQW11",
        "rank": 3,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK4K84XGGV7XG3TFC6W69",
      "created_at": "2024-01-03T14:45:43.141Z",
      "updated_at": "2024-01-03T14:45:43.141Z",
      "name": "Frizerski uređaji i pribor",
      "description": "",
      "handle": "njega-kose-frizerski-uredaji-i-pribor",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK43BQJST1XZ18R4BST01",
      "rank": 1,
      "metadata": null,
      "category_children": [
        {
          "id": "pcat_01HK7WK7BJZ1ZZXEBW846YVEA6",
          "created_at": "2024-01-03T14:45:45.968Z",
          "updated_at": "2024-01-03T14:45:45.968Z",
          "name": "Četke na vrući zrak",
          "description": "",
          "handle": "frizerski-uredaji-i-pribor-cetke-na-vruci-zrak",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4K84XGGV7XG3TFC6W69",
          "rank": 0,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK7BZPW5NHM7KGFCZHKC9",
          "created_at": "2024-01-03T14:45:45.981Z",
          "updated_at": "2024-01-03T14:45:45.981Z",
          "name": "Četke za kosu",
          "description": "",
          "handle": "frizerski-uredaji-i-pribor-cetke-za-kosu",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4K84XGGV7XG3TFC6W69",
          "rank": 1,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK7CDBMMHH40CXP04GGC3",
          "created_at": "2024-01-03T14:45:45.995Z",
          "updated_at": "2024-01-03T14:45:45.995Z",
          "name": "Toplinske rukavice za oblikovanje",
          "description": "",
          "handle": "frizerski-uredaji-i-pribor-toplinske-rukavice-za-oblikovanje",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4K84XGGV7XG3TFC6W69",
          "rank": 2,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK7CX65MT0BE6ES2N35XX",
          "created_at": "2024-01-03T14:45:46.011Z",
          "updated_at": "2024-01-03T14:45:46.011Z",
          "name": "Višenamjenski kalupi",
          "description": "",
          "handle": "frizerski-uredaji-i-pribor-visenamjenski-kalupi",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4K84XGGV7XG3TFC6W69",
          "rank": 3,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK7DCDZARF7ESDZHZ3VP1",
          "created_at": "2024-01-03T14:45:46.026Z",
          "updated_at": "2024-01-03T14:45:46.026Z",
          "name": "Uvijači za kosu",
          "description": "",
          "handle": "frizerski-uredaji-i-pribor-uvijaci-za-kosu",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4K84XGGV7XG3TFC6W69",
          "rank": 4,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK7DVKAX1WPRXXVAF05GR",
          "created_at": "2024-01-03T14:45:46.041Z",
          "updated_at": "2024-01-03T14:45:46.041Z",
          "name": "Češljevi",
          "description": "",
          "handle": "frizerski-uredaji-i-pribor-cesljevi",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4K84XGGV7XG3TFC6W69",
          "rank": 5,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK7EHQ5XFRJMD8VKW3THX",
          "created_at": "2024-01-03T14:45:46.063Z",
          "updated_at": "2024-01-03T14:45:46.063Z",
          "name": "Figari",
          "description": "",
          "handle": "frizerski-uredaji-i-pribor-figari",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4K84XGGV7XG3TFC6W69",
          "rank": 6,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK7F2GZA0Y35SQZQZZKW4",
          "created_at": "2024-01-03T14:45:46.080Z",
          "updated_at": "2024-01-03T14:45:46.080Z",
          "name": "Pegle za kosu",
          "description": "",
          "handle": "frizerski-uredaji-i-pribor-pegle-za-kosu",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4K84XGGV7XG3TFC6W69",
          "rank": 7,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK7FMA2XHSD9CT3FNPC0Y",
          "created_at": "2024-01-03T14:45:46.098Z",
          "updated_at": "2024-01-03T14:45:46.098Z",
          "name": "Pegle za kovrčanje",
          "description": "",
          "handle": "frizerski-uredaji-i-pribor-pegle-za-kovrcanje",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4K84XGGV7XG3TFC6W69",
          "rank": 8,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK7G49108C023ACQJBQXK",
          "created_at": "2024-01-03T14:45:46.114Z",
          "updated_at": "2024-01-03T14:45:46.114Z",
          "name": "Vikleri",
          "description": "",
          "handle": "frizerski-uredaji-i-pribor-vikleri",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4K84XGGV7XG3TFC6W69",
          "rank": 9,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK7GJ8HGJNY1PB3BBQQFT",
          "created_at": "2024-01-03T14:45:46.127Z",
          "updated_at": "2024-01-03T14:45:46.127Z",
          "name": "Haube za kosu",
          "description": "",
          "handle": "frizerski-uredaji-i-pribor-haube-za-kosu",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4K84XGGV7XG3TFC6W69",
          "rank": 10,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK7H0CCVR0D8RJQJFTVCE",
          "created_at": "2024-01-03T14:45:46.142Z",
          "updated_at": "2024-01-03T14:45:46.142Z",
          "name": "Fenovi za kosu",
          "description": "",
          "handle": "frizerski-uredaji-i-pribor-fenovi-za-kosu",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4K84XGGV7XG3TFC6W69",
          "rank": 11,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKA9AGTDM7Q21X9A0VCKZ",
          "created_at": "2024-01-03T14:45:48.966Z",
          "updated_at": "2024-01-03T14:45:48.966Z",
          "name": "Škare za kosu i bradu",
          "description": "",
          "handle": "frizerski-uredaji-i-pribor-skare-za-kosu-i-bradu",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4K84XGGV7XG3TFC6W69",
          "rank": 12,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKAA0GJVNVKHFMBVWE9GZ",
          "created_at": "2024-01-03T14:45:48.986Z",
          "updated_at": "2024-01-03T14:45:48.986Z",
          "name": "Fenovi i difuzori",
          "description": "",
          "handle": "frizerski-uredaji-i-pribor-fenovi-i-difuzori",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4K84XGGV7XG3TFC6W69",
          "rank": 13,
          "metadata": null
        }
      ],
      "parent_category": {
        "id": "pcat_01HK7WK43BQJST1XZ18R4BST01",
        "created_at": "2024-01-03T14:45:42.635Z",
        "updated_at": "2024-01-03T14:45:42.635Z",
        "name": "Njega kose",
        "description": "",
        "handle": "ljepota-njega-kose",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": null,
        "rank": 3,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK6YP8P6FJEVY6EPN5M8D",
      "created_at": "2024-01-03T14:45:45.554Z",
      "updated_at": "2024-01-03T14:45:45.554Z",
      "name": "Poslije sunčanja",
      "description": "",
      "handle": "njega-koze-suncem-i-tamnjenjem-poslije-suncanja",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4E2GJJTAKSG9K2YD30C",
      "rank": 1,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4E2GJJTAKSG9K2YD30C",
        "created_at": "2024-01-03T14:45:42.976Z",
        "updated_at": "2024-01-03T14:45:42.976Z",
        "name": "Njega kože suncem i tamnjenjem",
        "description": "",
        "handle": "njega-koze-njega-koze-suncem-i-tamnjenjem",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK42VB8WYE6GKG3FEJ41V",
        "rank": 3,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK4D5FYH1WKJS7EB2FNRN",
      "created_at": "2024-01-03T14:45:42.947Z",
      "updated_at": "2024-01-03T14:45:42.947Z",
      "name": "Vrat i dekolte",
      "description": "",
      "handle": "njega-koze-vrat-i-dekolte",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK42VB8WYE6GKG3FEJ41V",
      "rank": 1,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK42VB8WYE6GKG3FEJ41V",
        "created_at": "2024-01-03T14:45:42.618Z",
        "updated_at": "2024-01-03T14:45:42.618Z",
        "name": "Njega kože",
        "description": "",
        "handle": "ljepota-njega-koze",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": null,
        "rank": 2,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKD775R1GVQAMG107HCFA",
      "created_at": "2024-01-03T14:45:51.973Z",
      "updated_at": "2024-01-03T14:45:51.973Z",
      "name": "Ulja",
      "description": "",
      "handle": "njega-kutikula-ulja",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WKAMBXQ4F92A7N7VK9M86",
      "rank": 1,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WKAMBXQ4F92A7N7VK9M86",
        "created_at": "2024-01-03T14:45:49.314Z",
        "updated_at": "2024-01-03T14:45:49.314Z",
        "name": "Njega kutikula",
        "description": "",
        "handle": "tretmani-za-nokte-njega-kutikula",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK4PWXATZSWB566TB87WB",
        "rank": 4,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK5PN4ENAM693RZNNY6AM",
      "created_at": "2024-01-03T14:45:44.270Z",
      "updated_at": "2024-01-03T14:45:44.270Z",
      "name": "Adstringenti",
      "description": "",
      "handle": "njega-nakon-depilacije-i-brijanja-adstringenti",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK481HCTWDV2XH5M4VNWK",
      "rank": 1,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK481HCTWDV2XH5M4VNWK",
        "created_at": "2024-01-03T14:45:42.782Z",
        "updated_at": "2024-01-03T14:45:42.782Z",
        "name": "Njega nakon depilacije i brijanja",
        "description": "",
        "handle": "brijanje-i-depilacija-njega-nakon-depilacije-i-brijanja",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK41MNZ3C795B2JN5JW0G",
        "rank": 3,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK5Z16GX3J028B42P9A9S",
      "created_at": "2024-01-03T14:45:44.542Z",
      "updated_at": "2024-01-03T14:45:44.542Z",
      "name": "Kreme",
      "description": "",
      "handle": "njega-prije-depilacije-i-brijanja-kreme",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK48MV187B5ZHKEDJWPEG",
      "rank": 1,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK48MV187B5ZHKEDJWPEG",
        "created_at": "2024-01-03T14:45:42.801Z",
        "updated_at": "2024-01-03T14:45:42.801Z",
        "name": "Njega prije depilacije i brijanja",
        "description": "",
        "handle": "brijanje-i-depilacija-njega-prije-depilacije-i-brijanja",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK41MNZ3C795B2JN5JW0G",
        "rank": 4,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKDZEDYWYHTA4MJAPYCD4",
      "created_at": "2024-01-03T14:45:52.748Z",
      "updated_at": "2024-01-03T14:45:52.748Z",
      "name": "Gelovi",
      "description": "",
      "handle": "nocna-njega-gelovi",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WKBSGXTDPPGA4ZZRT7ZNX",
      "rank": 1,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WKBSGXTDPPGA4ZZRT7ZNX",
        "created_at": "2024-01-03T14:45:50.510Z",
        "updated_at": "2024-01-03T14:45:50.510Z",
        "name": "Noćna njega",
        "description": "",
        "handle": "za-hidrataciju-nocna-njega",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK6SZXFJCA60BBDC8S5W7",
        "rank": 1,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK9C3HT3DJYFQF5D0EQ8H",
      "created_at": "2024-01-03T14:45:48.032Z",
      "updated_at": "2024-01-03T14:45:48.032Z",
      "name": "Baze",
      "description": "",
      "handle": "oci-baze",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4T4JAQRP4VBRRAJR36J",
      "rank": 1,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4T4JAQRP4VBRRAJR36J",
        "created_at": "2024-01-03T14:45:43.362Z",
        "updated_at": "2024-01-03T14:45:43.362Z",
        "name": "Oči",
        "description": "",
        "handle": "sminka-oci_11745",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK4495D212GWWBGHX6D9H",
        "rank": 4,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKDFF88AQSR9AH8X24NW5",
      "created_at": "2024-01-03T14:45:52.232Z",
      "updated_at": "2024-01-03T14:45:52.232Z",
      "name": "Češljevi za trepavice i obrve",
      "description": "",
      "handle": "oci-cesljevi-za-trepavice-i-obrve_25085",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WKB41CSS4HCT9G53TPNK8",
      "rank": 1,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WKB41CSS4HCT9G53TPNK8",
        "created_at": "2024-01-03T14:45:49.823Z",
        "updated_at": "2024-01-03T14:45:49.823Z",
        "name": "Oči",
        "description": "",
        "handle": "cetke-i-pribor-za-sminkanje-oci",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK5D0544YXJQT9G60XVED",
        "rank": 2,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK75R744R9XMBMGRD6ZF0",
      "created_at": "2024-01-03T14:45:45.782Z",
      "updated_at": "2024-01-03T14:45:45.782Z",
      "name": "Sredstva za uklanjanje šminke",
      "description": "",
      "handle": "oci-sredstva-za-uklanjanje-sminke",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4J1NW5Q3B0PAC2JEQ2N",
      "rank": 1,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4J1NW5Q3B0PAC2JEQ2N",
        "created_at": "2024-01-03T14:45:43.101Z",
        "updated_at": "2024-01-03T14:45:43.101Z",
        "name": "Oči",
        "description": "",
        "handle": "njega-koze-oci_5391",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK42VB8WYE6GKG3FEJ41V",
        "rank": 6,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKCVT8485V2DSHZMRFDW7",
      "created_at": "2024-01-03T14:45:51.608Z",
      "updated_at": "2024-01-03T14:45:51.608Z",
      "name": "Trake za kapke",
      "description": "",
      "handle": "oci-trake-za-kapke",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK9X10701060SA690EKN6",
      "rank": 1,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK9X10701060SA690EKN6",
        "created_at": "2024-01-03T14:45:48.574Z",
        "updated_at": "2024-01-03T14:45:48.574Z",
        "name": "Oči",
        "description": "",
        "handle": "alati-i-pribor-za-sminkanje-oci",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK57XS2NQS0EGT358BW0Q",
        "rank": 2,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKB69K9PTQR4NH8217425",
      "created_at": "2024-01-03T14:45:49.895Z",
      "updated_at": "2024-01-03T14:45:49.895Z",
      "name": "Igle",
      "description": "",
      "handle": "oprema-za-tetoviranje-igle_20992",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK5E7TWKXSDKE1B6B7FCQ",
      "rank": 1,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK5E7TWKXSDKE1B6B7FCQ",
        "created_at": "2024-01-03T14:45:44.005Z",
        "updated_at": "2024-01-03T14:45:44.005Z",
        "name": "Oprema za tetoviranje",
        "description": "",
        "handle": "pribor-i-dodaci-oprema-za-tetoviranje",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK45W0F9X0VMBBCAVDPWD",
        "rank": 5,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK6NS7QGH7TYT5Z5R0YN5",
      "created_at": "2024-01-03T14:45:45.271Z",
      "updated_at": "2024-01-03T14:45:45.271Z",
      "name": "Gelovi i sapuni",
      "description": "",
      "handle": "osobna-cistoca-gelovi-i-sapuni",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4C6VG3TJWR51GB1ZQJW",
      "rank": 1,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4C6VG3TJWR51GB1ZQJW",
        "created_at": "2024-01-03T14:45:42.916Z",
        "updated_at": "2024-01-03T14:45:42.916Z",
        "name": "Osobna čistoća",
        "description": "",
        "handle": "kupanje-i-osobna-higijena-osobna-cistoca",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK42BQ7XFW617X5XP4BAR",
        "rank": 4,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKCPPXF9W79F824GJ00GH",
      "created_at": "2024-01-03T14:45:51.444Z",
      "updated_at": "2024-01-03T14:45:51.444Z",
      "name": "Papiri",
      "description": "",
      "handle": "papiri_11726",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK97BH9JQSNRK7NH98XRD",
      "rank": 1,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK97BH9JQSNRK7NH98XRD",
        "created_at": "2024-01-03T14:45:47.879Z",
        "updated_at": "2024-01-03T14:45:47.879Z",
        "name": "Oprema za tetoviranje",
        "description": "",
        "handle": "tijelo-oprema-za-tetoviranje",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK4RBTHMFSNA47WTF4D6J",
        "rank": 2,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK4VK9T55YRNH96BBHEDM",
      "created_at": "2024-01-03T14:45:43.408Z",
      "updated_at": "2024-01-03T14:45:43.408Z",
      "name": "Svježa voda",
      "description": "",
      "handle": "parfemi-i-mirisi-svjeza-voda",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK45CQPDHWCYPEW3W862G",
      "rank": 1,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK45CQPDHWCYPEW3W862G",
        "created_at": "2024-01-03T14:45:42.699Z",
        "updated_at": "2024-01-03T14:45:42.699Z",
        "name": "Parfemi i mirisi",
        "description": "",
        "handle": "ljepota-parfemi-i-mirisi",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": null,
        "rank": 6,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK8XN5741PFD0K122VF9A",
      "created_at": "2024-01-03T14:45:47.570Z",
      "updated_at": "2024-01-03T14:45:47.570Z",
      "name": "Električni uređaji i pribor za manikuru i pedikuru",
      "description": "",
      "handle": "pribor-i-dodaci-elektricni-uredaji-i-pribor-za-manikuru-i-pedikuru",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4Q9J4VMNM7KX6F1K8RQ",
      "rank": 1,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4Q9J4VMNM7KX6F1K8RQ",
        "created_at": "2024-01-03T14:45:43.271Z",
        "updated_at": "2024-01-03T14:45:43.271Z",
        "name": "Pribor i dodaci",
        "description": "",
        "handle": "manikura-i-pedikura-pribor-i-dodaci",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK43VEK8SG6M4T81C3W37",
        "rank": 3,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK571RGN4X4BE7Q8XRZ7N",
      "created_at": "2024-01-03T14:45:43.774Z",
      "updated_at": "2024-01-03T14:45:43.774Z",
      "name": "Torbe i futrole",
      "description": "",
      "handle": "pribor-i-dodaci-torbe-i-futrole",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK45W0F9X0VMBBCAVDPWD",
      "rank": 1,
      "metadata": null,
      "category_children": [
        {
          "id": "pcat_01HK7WK9V466Q4VJEZJ17KXJAP",
          "created_at": "2024-01-03T14:45:48.513Z",
          "updated_at": "2024-01-03T14:45:48.513Z",
          "name": "Futrole za šminku",
          "description": "",
          "handle": "torbe-i-futrole-futrole-za-sminku",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK571RGN4X4BE7Q8XRZ7N",
          "rank": 0,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKB1G2MB78EY2D98CQ800",
          "created_at": "2024-01-03T14:45:49.736Z",
          "updated_at": "2024-01-03T14:45:49.736Z",
          "name": "Toaletne torbe",
          "description": "",
          "handle": "torbe-i-futrole-toaletne-torbe",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK571RGN4X4BE7Q8XRZ7N",
          "rank": 1,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKB2382WX727PJZFC2ND0",
          "created_at": "2024-01-03T14:45:49.759Z",
          "updated_at": "2024-01-03T14:45:49.759Z",
          "name": "Putne boce i spremnici",
          "description": "",
          "handle": "torbe-i-futrole-putne-boce-i-spremnici",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK571RGN4X4BE7Q8XRZ7N",
          "rank": 2,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKB2MJAKJW7AYR00YG975",
          "created_at": "2024-01-03T14:45:49.778Z",
          "updated_at": "2024-01-03T14:45:49.778Z",
          "name": "Putni neseseri",
          "description": "",
          "handle": "torbe-i-futrole-putni-neseseri",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK571RGN4X4BE7Q8XRZ7N",
          "rank": 3,
          "metadata": null
        }
      ],
      "parent_category": {
        "id": "pcat_01HK7WK45W0F9X0VMBBCAVDPWD",
        "created_at": "2024-01-03T14:45:42.715Z",
        "updated_at": "2024-01-03T14:45:42.715Z",
        "name": "Pribor i dodaci",
        "description": "",
        "handle": "ljepota-pribor-i-dodaci",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": null,
        "rank": 7,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKCGF25K35C5YWEAE89GY",
      "created_at": "2024-01-03T14:45:51.245Z",
      "updated_at": "2024-01-03T14:45:51.245Z",
      "name": "Korektori za lak",
      "description": "",
      "handle": "pribor-korektori-za-lak",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK8TNZ6RWPNFYABFMKJ6W",
      "rank": 1,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK8TNZ6RWPNFYABFMKJ6W",
        "created_at": "2024-01-03T14:45:47.475Z",
        "updated_at": "2024-01-03T14:45:47.475Z",
        "name": "Pribor",
        "description": "",
        "handle": "dizajni-za-nokte-pribor",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK4PDYPCE7H5D1FAFMHNK",
        "rank": 7,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKBDJ9G2DBHYDZCHR9CCC",
      "created_at": "2024-01-03T14:45:50.128Z",
      "updated_at": "2024-01-03T14:45:50.128Z",
      "name": "Punjači i napajanja za električne brijače",
      "description": "",
      "handle": "pribor-punjaci-i-napajanja-za-elektricne-brijace",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK5GP4MZXQ69EPS0WG1AR",
      "rank": 1,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK5GP4MZXQ69EPS0WG1AR",
        "created_at": "2024-01-03T14:45:44.084Z",
        "updated_at": "2024-01-03T14:45:44.084Z",
        "name": "Pribor",
        "description": "",
        "handle": "elektricni-brijaci-pribor",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK46YG69NRB3HQKNMNZ0C",
        "rank": 0,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK78AEKY7AFS5R2QMW62J",
      "created_at": "2024-01-03T14:45:45.864Z",
      "updated_at": "2024-01-03T14:45:45.864Z",
      "name": "Trake za kosu",
      "description": "",
      "handle": "pribor-za-friziranje-trake-za-kosu",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4JRZ92REX0CX4M9JWEM",
      "rank": 1,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4JRZ92REX0CX4M9JWEM",
        "created_at": "2024-01-03T14:45:43.125Z",
        "updated_at": "2024-01-03T14:45:43.125Z",
        "name": "Pribor za friziranje",
        "description": "",
        "handle": "njega-kose-pribor-za-friziranje",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK43BQJST1XZ18R4BST01",
        "rank": 0,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK8PHKW0XASQ6QJSA0GP3",
      "created_at": "2024-01-03T14:45:47.343Z",
      "updated_at": "2024-01-03T14:45:47.343Z",
      "name": "Kompleti za šišanje",
      "description": "",
      "handle": "pribor-za-sisanje-kompleti-za-sisanje",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4NHTG89E1DN79HZD0W5",
      "rank": 1,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4NHTG89E1DN79HZD0W5",
        "created_at": "2024-01-03T14:45:43.215Z",
        "updated_at": "2024-01-03T14:45:43.215Z",
        "name": "Pribor za šišanje",
        "description": "",
        "handle": "njega-kose-pribor-za-sisanje",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK43BQJST1XZ18R4BST01",
        "rank": 6,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKCE9XX8TTKT117V92S0F",
      "created_at": "2024-01-03T14:45:51.175Z",
      "updated_at": "2024-01-03T14:45:51.175Z",
      "name": "Šljokice",
      "description": "",
      "handle": "pribor-za-ukrasavanje-noktiju-sljokice_11502",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK8QT38HTXECJJZ3XSY34",
      "rank": 1,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK8QT38HTXECJJZ3XSY34",
        "created_at": "2024-01-03T14:45:47.384Z",
        "updated_at": "2024-01-03T14:45:47.384Z",
        "name": "Pribor za ukrašavanje noktiju",
        "description": "",
        "handle": "dizajni-za-nokte-pribor-za-ukrasavanje-noktiju",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK4PDYPCE7H5D1FAFMHNK",
        "rank": 0,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKB79NHNXKS41RJQRYVCD",
      "created_at": "2024-01-03T14:45:49.927Z",
      "updated_at": "2024-01-03T14:45:49.927Z",
      "name": "Zdjelice za sapun za brijanje",
      "description": "",
      "handle": "pribor-zdjelice-za-sapun-za-brijanje",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK5EWA9P9TTPVVM3FFT1X",
      "rank": 1,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK5EWA9P9TTPVVM3FFT1X",
        "created_at": "2024-01-03T14:45:44.026Z",
        "updated_at": "2024-01-03T14:45:44.026Z",
        "name": "Pribor",
        "description": "",
        "handle": "rucno-brijanje-pribor",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK46D6H08A70HPE8S7SCQ",
        "rank": 0,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK83W4M0844RZX8TCYK43",
      "created_at": "2024-01-03T14:45:46.746Z",
      "updated_at": "2024-01-03T14:45:46.746Z",
      "name": "Mirisi za kosu",
      "description": "",
      "handle": "proizvodi-za-friziranje-mirisi-za-kosu",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4MPDDG329H66PT1KDYF",
      "rank": 1,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4MPDDG329H66PT1KDYF",
        "created_at": "2024-01-03T14:45:43.187Z",
        "updated_at": "2024-01-03T14:45:43.187Z",
        "name": "Proizvodi za friziranje",
        "description": "",
        "handle": "njega-kose-proizvodi-za-friziranje",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK43BQJST1XZ18R4BST01",
        "rank": 4,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK892R5ZJCFZAJMW9MNXW",
      "created_at": "2024-01-03T14:45:46.911Z",
      "updated_at": "2024-01-03T14:45:46.911Z",
      "name": "Pribor za trajnu",
      "description": "",
      "handle": "proizvodi-za-njegu-kose-pribor-za-trajnu",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4N31HG0EQ98AA4ZHV8C",
      "rank": 1,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4N31HG0EQ98AA4ZHV8C",
        "created_at": "2024-01-03T14:45:43.201Z",
        "updated_at": "2024-01-03T14:45:43.201Z",
        "name": "Proizvodi za njegu kose",
        "description": "",
        "handle": "njega-kose-proizvodi-za-njegu-kose",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK43BQJST1XZ18R4BST01",
        "rank": 5,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK5FHHQR6TNHWK6MYMBRV",
      "created_at": "2024-01-03T14:45:44.044Z",
      "updated_at": "2024-01-03T14:45:44.044Z",
      "name": "Za muškarce",
      "description": "",
      "handle": "rucno-brijanje-za-muskarce",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK46D6H08A70HPE8S7SCQ",
      "rank": 1,
      "metadata": null,
      "category_children": [
        {
          "id": "pcat_01HK7WKB8VGMYF9B990VK0MC17",
          "created_at": "2024-01-03T14:45:49.977Z",
          "updated_at": "2024-01-03T14:45:49.977Z",
          "name": "Muške oštrice za brijanje ",
          "description": "",
          "handle": "za-muskarce-muske-ostrice-za-brijanje",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK5FHHQR6TNHWK6MYMBRV",
          "rank": 0,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKB9ANK6JRDDR6FYMXM3V",
          "created_at": "2024-01-03T14:45:49.992Z",
          "updated_at": "2024-01-03T14:45:49.992Z",
          "name": "Setovi",
          "description": "",
          "handle": "za-muskarce-setovi",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK5FHHQR6TNHWK6MYMBRV",
          "rank": 1,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKB9WAPVFWJM7ZX13CCKT",
          "created_at": "2024-01-03T14:45:50.009Z",
          "updated_at": "2024-01-03T14:45:50.009Z",
          "name": "Aparati za brijanje za muškarce",
          "description": "",
          "handle": "za-muskarce-aparati-za-brijanje-za-muskarce",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK5FHHQR6TNHWK6MYMBRV",
          "rank": 2,
          "metadata": null
        }
      ],
      "parent_category": {
        "id": "pcat_01HK7WK46D6H08A70HPE8S7SCQ",
        "created_at": "2024-01-03T14:45:42.731Z",
        "updated_at": "2024-01-03T14:45:42.731Z",
        "name": "Ručno brijanje",
        "description": "",
        "handle": "brijanje-i-depilacija-rucno-brijanje",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK41MNZ3C795B2JN5JW0G",
        "rank": 0,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKAHGYQWMJQF69T934WNM",
      "created_at": "2024-01-03T14:45:49.223Z",
      "updated_at": "2024-01-03T14:45:49.223Z",
      "name": "Kreme za ruke i nokte",
      "description": "",
      "handle": "ruke-i-stopala-kreme-za-ruke-i-nokte_20960",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK5ARJD927HV0B1E6F91V",
      "rank": 1,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK5ARJD927HV0B1E6F91V",
        "created_at": "2024-01-03T14:45:43.893Z",
        "updated_at": "2024-01-03T14:45:43.893Z",
        "name": "Ruke i stopala",
        "description": "",
        "handle": "manikura-i-pedikura-ruke-i-stopala",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK43VEK8SG6M4T81C3W37",
        "rank": 4,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK71B3W0J7Q19BDKT8Y1Z",
      "created_at": "2024-01-03T14:45:45.641Z",
      "updated_at": "2024-01-03T14:45:45.641Z",
      "name": "Parafinske kupke",
      "description": "",
      "handle": "ruke-i-stopala-parafinske-kupke",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4G7CJB2WFRCRPS0131Q",
      "rank": 1,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4G7CJB2WFRCRPS0131Q",
        "created_at": "2024-01-03T14:45:43.032Z",
        "updated_at": "2024-01-03T14:45:43.032Z",
        "name": "Ruke i stopala",
        "description": "",
        "handle": "njega-koze-ruke-i-stopala",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK42VB8WYE6GKG3FEJ41V",
        "rank": 5,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK6DE7RE05NJ12T9VVGW0",
      "created_at": "2024-01-03T14:45:45.002Z",
      "updated_at": "2024-01-03T14:45:45.002Z",
      "name": "Škare za nos i uho",
      "description": "",
      "handle": "skare-skare-za-nos-i-uho",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK49KK07E1DMRZYHFY1HD",
      "rank": 1,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK49KK07E1DMRZYHFY1HD",
        "created_at": "2024-01-03T14:45:42.833Z",
        "updated_at": "2024-01-03T14:45:42.833Z",
        "name": "Škare",
        "description": "",
        "handle": "brijanje-i-depilacija-skare",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK41MNZ3C795B2JN5JW0G",
        "rank": 6,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK5JPXVJPF1V58XZM4A32",
      "created_at": "2024-01-03T14:45:44.147Z",
      "updated_at": "2024-01-03T14:45:44.147Z",
      "name": "Aparati za brijanje tijela",
      "description": "",
      "handle": "skare-za-kosu-brijaci-i-aparati-za-brijanje-tijela-aparati-za-brijanje-tijela",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK47G3STXHFMNMZ4MMQ7G",
      "rank": 1,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK47G3STXHFMNMZ4MMQ7G",
        "created_at": "2024-01-03T14:45:42.766Z",
        "updated_at": "2024-01-03T14:45:42.766Z",
        "name": "Škare za kosu, brijači i aparati za brijanje tijela",
        "description": "",
        "handle": "brijanje-i-depilacija-skare-za-kosu-brijaci-i-aparati-za-brijanje-tijela",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK41MNZ3C795B2JN5JW0G",
        "rank": 2,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK4RBTHMFSNA47WTF4D6J",
      "created_at": "2024-01-03T14:45:43.304Z",
      "updated_at": "2024-01-03T14:45:43.304Z",
      "name": "Tijelo",
      "description": "",
      "handle": "sminka-tijelo_11713",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4495D212GWWBGHX6D9H",
      "rank": 1,
      "metadata": null,
      "category_children": [
        {
          "id": "pcat_01HK7WK95GEZ1A1RP0TF0TF5WZ",
          "created_at": "2024-01-03T14:45:47.822Z",
          "updated_at": "2024-01-03T14:45:47.822Z",
          "name": "Korektori",
          "description": "",
          "handle": "tijelo-korektori",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4RBTHMFSNA47WTF4D6J",
          "rank": 0,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK96H1CEHK3NBNRRZD9YH",
          "created_at": "2024-01-03T14:45:47.850Z",
          "updated_at": "2024-01-03T14:45:47.850Z",
          "name": "Boje",
          "description": "",
          "handle": "tijelo-boje",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4RBTHMFSNA47WTF4D6J",
          "rank": 1,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK97BH9JQSNRK7NH98XRD",
          "created_at": "2024-01-03T14:45:47.879Z",
          "updated_at": "2024-01-03T14:45:47.879Z",
          "name": "Oprema za tetoviranje",
          "description": "",
          "handle": "tijelo-oprema-za-tetoviranje",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4RBTHMFSNA47WTF4D6J",
          "rank": 2,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK9800PGYS8HAXQXYZKZA",
          "created_at": "2024-01-03T14:45:47.901Z",
          "updated_at": "2024-01-03T14:45:47.901Z",
          "name": "Privremene tetovaže",
          "description": "",
          "handle": "tijelo-privremene-tetovaze",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4RBTHMFSNA47WTF4D6J",
          "rank": 3,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKAVZJBY2Q2C480K3P722",
          "created_at": "2024-01-03T14:45:49.561Z",
          "updated_at": "2024-01-03T14:45:49.561Z",
          "name": "Bronzeri za tijelo",
          "description": "",
          "handle": "tijelo-bronzeri-za-tijelo",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4RBTHMFSNA47WTF4D6J",
          "rank": 4,
          "metadata": null
        }
      ],
      "parent_category": {
        "id": "pcat_01HK7WK4495D212GWWBGHX6D9H",
        "created_at": "2024-01-03T14:45:42.665Z",
        "updated_at": "2024-01-03T14:45:42.665Z",
        "name": "Šminka",
        "description": "",
        "handle": "ljepota-sminka",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": null,
        "rank": 5,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKCAAVVZQQX0T3WQ4XGHM",
      "created_at": "2024-01-03T14:45:51.047Z",
      "updated_at": "2024-01-03T14:45:51.047Z",
      "name": "Solarni filtri za lice",
      "description": "",
      "handle": "solarni-filtri-solarni-filtri-za-lice",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK6ZJ0V95JGS57SVR77VS",
      "rank": 1,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK6ZJ0V95JGS57SVR77VS",
        "created_at": "2024-01-03T14:45:45.584Z",
        "updated_at": "2024-01-03T14:45:45.584Z",
        "name": "Solarni filtri",
        "description": "",
        "handle": "njega-koze-suncem-i-tamnjenjem-solarni-filtri",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK4E2GJJTAKSG9K2YD30C",
        "rank": 3,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKD4F45QHV8F5QGKA1GCC",
      "created_at": "2024-01-03T14:45:51.885Z",
      "updated_at": "2024-01-03T14:45:51.885Z",
      "name": "Solarni filtri za lice",
      "description": "",
      "handle": "solarni-filtri-solarni-filtri-za-lice_25062",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WKA8MEFNXMV7G5QH0DGE2",
      "rank": 1,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WKA8MEFNXMV7G5QH0DGE2",
        "created_at": "2024-01-03T14:45:48.945Z",
        "updated_at": "2024-01-03T14:45:48.945Z",
        "name": "Solarni filtri",
        "description": "",
        "handle": "kreme-za-suncanje-i-tamnjenje-solarni-filtri",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK59M5WTCRNSKRG8JBN56",
        "rank": 2,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKBWY632YGW1JS713YBR7",
      "created_at": "2024-01-03T14:45:50.615Z",
      "updated_at": "2024-01-03T14:45:50.615Z",
      "name": "Sredstva za uklanjanje šminke",
      "description": "",
      "handle": "sredstva-za-ciscenje-i-piling-sredstva-za-uklanjanje-sminke",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK6TKWN0PFYN9S0Y0JEG0",
      "rank": 1,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK6TKWN0PFYN9S0Y0JEG0",
        "created_at": "2024-01-03T14:45:45.425Z",
        "updated_at": "2024-01-03T14:45:45.425Z",
        "name": "Sredstva za čišćenje i piling",
        "description": "",
        "handle": "lice-sredstva-za-ciscenje-i-piling",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK4CQ8JZ3NZ6QJC2MQW11",
        "rank": 2,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKC8XNQRW0DCFN59P6P73",
      "created_at": "2024-01-03T14:45:51.002Z",
      "updated_at": "2024-01-03T14:45:51.002Z",
      "name": "Sredstva za samotamnjenje za lice",
      "description": "",
      "handle": "sredstva-za-samotamnjenje-sredstva-za-samotamnjenje-za-lice_5372",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK6Z40CPVFT96RJA3DA69",
      "rank": 1,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK6Z40CPVFT96RJA3DA69",
        "created_at": "2024-01-03T14:45:45.569Z",
        "updated_at": "2024-01-03T14:45:45.569Z",
        "name": "Sredstva za samotamnjenje",
        "description": "",
        "handle": "njega-koze-suncem-i-tamnjenjem-sredstva-za-samotamnjenje",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK4E2GJJTAKSG9K2YD30C",
        "rank": 2,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKAZ0XM1CAM1E1JV2C2F4",
      "created_at": "2024-01-03T14:45:49.659Z",
      "updated_at": "2024-01-03T14:45:49.659Z",
      "name": "Sredstva za uklanjanje šminke s lica",
      "description": "",
      "handle": "sredstva-za-uklanjanje-sminke-sredstva-za-uklanjanje-sminke-s-lica",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK5B70957TDHQA8K7KARN",
      "rank": 1,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK5B70957TDHQA8K7KARN",
        "created_at": "2024-01-03T14:45:43.909Z",
        "updated_at": "2024-01-03T14:45:43.909Z",
        "name": "Sredstva za uklanjanje šminke",
        "description": "",
        "handle": "sminka-sredstva-za-uklanjanje-sminke",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK4495D212GWWBGHX6D9H",
        "rank": 6,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK9TDB7RQ9HQCY7ATJVEG",
      "created_at": "2024-01-03T14:45:48.490Z",
      "updated_at": "2024-01-03T14:45:48.490Z",
      "name": "Svijeće",
      "description": "",
      "handle": "svijece-i-esencije-za-dom-svijece",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK5549S5WEVDCDA22RP2N",
      "rank": 1,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK5549S5WEVDCDA22RP2N",
        "created_at": "2024-01-03T14:45:43.707Z",
        "updated_at": "2024-01-03T14:45:43.707Z",
        "name": "Svijeće i esencije za dom",
        "description": "",
        "handle": "parfemi-i-mirisi-svijece-i-esencije-za-dom",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK45CQPDHWCYPEW3W862G",
        "rank": 7,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK96H1CEHK3NBNRRZD9YH",
      "created_at": "2024-01-03T14:45:47.850Z",
      "updated_at": "2024-01-03T14:45:47.850Z",
      "name": "Boje",
      "description": "",
      "handle": "tijelo-boje",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4RBTHMFSNA47WTF4D6J",
      "rank": 1,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4RBTHMFSNA47WTF4D6J",
        "created_at": "2024-01-03T14:45:43.304Z",
        "updated_at": "2024-01-03T14:45:43.304Z",
        "name": "Tijelo",
        "description": "",
        "handle": "sminka-tijelo_11713",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK4495D212GWWBGHX6D9H",
        "rank": 1,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK6WA7CDBMWAWAHDXNKA8",
      "created_at": "2024-01-03T14:45:45.480Z",
      "updated_at": "2024-01-03T14:45:45.480Z",
      "name": "Za hidrataciju",
      "description": "",
      "handle": "tijelo-za-hidrataciju",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4DMG2Q4J3RMF32RA7Y1",
      "rank": 1,
      "metadata": null,
      "category_children": [
        {
          "id": "pcat_01HK7WKC2E9KQTERQ290SGNPVF",
          "created_at": "2024-01-03T14:45:50.791Z",
          "updated_at": "2024-01-03T14:45:50.791Z",
          "name": "Ulja",
          "description": "",
          "handle": "za-hidrataciju-ulja",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK6WA7CDBMWAWAHDXNKA8",
          "rank": 0,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKC3PSWXMSAYDNT36C1G6",
          "created_at": "2024-01-03T14:45:50.831Z",
          "updated_at": "2024-01-03T14:45:50.831Z",
          "name": "Kreme",
          "description": "",
          "handle": "za-hidrataciju-kreme",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK6WA7CDBMWAWAHDXNKA8",
          "rank": 1,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKC51H2YS26A8NTWHZ7SZ",
          "created_at": "2024-01-03T14:45:50.874Z",
          "updated_at": "2024-01-03T14:45:50.874Z",
          "name": "Losioni za tijelo",
          "description": "",
          "handle": "za-hidrataciju-losioni-za-tijelo",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK6WA7CDBMWAWAHDXNKA8",
          "rank": 2,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKC6HZFVPGHBCEKKHJN43",
          "created_at": "2024-01-03T14:45:50.918Z",
          "updated_at": "2024-01-03T14:45:50.918Z",
          "name": "Maslaci za tijelo",
          "description": "",
          "handle": "za-hidrataciju-maslaci-za-tijelo",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK6WA7CDBMWAWAHDXNKA8",
          "rank": 3,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKC7HNQ1VB947PA3HXFHT",
          "created_at": "2024-01-03T14:45:50.956Z",
          "updated_at": "2024-01-03T14:45:50.956Z",
          "name": "Moussevi",
          "description": "",
          "handle": "za-hidrataciju-moussevi",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK6WA7CDBMWAWAHDXNKA8",
          "rank": 4,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKD36EA2V4KEB6J4ZNXGN",
          "created_at": "2024-01-03T14:45:51.844Z",
          "updated_at": "2024-01-03T14:45:51.844Z",
          "name": "Poslije sunčanja",
          "description": "",
          "handle": "za-hidrataciju-poslije-suncanja",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK6WA7CDBMWAWAHDXNKA8",
          "rank": 5,
          "metadata": null
        }
      ],
      "parent_category": {
        "id": "pcat_01HK7WK4DMG2Q4J3RMF32RA7Y1",
        "created_at": "2024-01-03T14:45:42.962Z",
        "updated_at": "2024-01-03T14:45:42.962Z",
        "name": "Tijelo",
        "description": "",
        "handle": "njega-koze-tijelo_5355",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK42VB8WYE6GKG3FEJ41V",
        "rank": 2,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKB1G2MB78EY2D98CQ800",
      "created_at": "2024-01-03T14:45:49.736Z",
      "updated_at": "2024-01-03T14:45:49.736Z",
      "name": "Toaletne torbe",
      "description": "",
      "handle": "torbe-i-futrole-toaletne-torbe",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK571RGN4X4BE7Q8XRZ7N",
      "rank": 1,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK571RGN4X4BE7Q8XRZ7N",
        "created_at": "2024-01-03T14:45:43.774Z",
        "updated_at": "2024-01-03T14:45:43.774Z",
        "name": "Torbe i futrole",
        "description": "",
        "handle": "pribor-i-dodaci-torbe-i-futrole",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK45W0F9X0VMBBCAVDPWD",
        "rank": 1,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK8W1W272XZ92X17YWRY8",
      "created_at": "2024-01-03T14:45:47.518Z",
      "updated_at": "2024-01-03T14:45:47.518Z",
      "name": "Učvršćivači",
      "description": "",
      "handle": "tretmani-za-nokte-ucvrscivaci",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4PWXATZSWB566TB87WB",
      "rank": 1,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4PWXATZSWB566TB87WB",
        "created_at": "2024-01-03T14:45:43.258Z",
        "updated_at": "2024-01-03T14:45:43.258Z",
        "name": "Tretmani za nokte",
        "description": "",
        "handle": "manikura-i-pedikura-tretmani-za-nokte",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK43VEK8SG6M4T81C3W37",
        "rank": 2,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK649EWGYVF36CJ6071RM",
      "created_at": "2024-01-03T14:45:44.706Z",
      "updated_at": "2024-01-03T14:45:44.706Z",
      "name": "Depilacija voskom",
      "description": "",
      "handle": "uklanjanje-dlaka-depilacija-voskom",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK493C3GNJNECHNPJ0K2N",
      "rank": 1,
      "metadata": null,
      "category_children": [
        {
          "id": "pcat_01HK7WKBGP5ESN8QMWKSJS2N7S",
          "created_at": "2024-01-03T14:45:50.222Z",
          "updated_at": "2024-01-03T14:45:50.222Z",
          "name": "Trake za depilaciju voskom",
          "description": "",
          "handle": "depilacija-voskom-trake-za-depilaciju-voskom",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK649EWGYVF36CJ6071RM",
          "rank": 0,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKBHZ6W52WZMZQJ2BY30G",
          "created_at": "2024-01-03T14:45:50.264Z",
          "updated_at": "2024-01-03T14:45:50.264Z",
          "name": "Grijači za vosak",
          "description": "",
          "handle": "depilacija-voskom-grijaci-za-vosak",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK649EWGYVF36CJ6071RM",
          "rank": 1,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKBKEJG81CACY9HWRF39G",
          "created_at": "2024-01-03T14:45:50.311Z",
          "updated_at": "2024-01-03T14:45:50.311Z",
          "name": "Vosak",
          "description": "",
          "handle": "depilacija-voskom-vosak",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK649EWGYVF36CJ6071RM",
          "rank": 2,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKBN25F933TR64F1VERPV",
          "created_at": "2024-01-03T14:45:50.365Z",
          "updated_at": "2024-01-03T14:45:50.365Z",
          "name": "Lopatice",
          "description": "",
          "handle": "depilacija-voskom-lopatice",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK649EWGYVF36CJ6071RM",
          "rank": 3,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKBNV6BY68J9P5SGMQTF1",
          "created_at": "2024-01-03T14:45:50.389Z",
          "updated_at": "2024-01-03T14:45:50.389Z",
          "name": "Kompleti",
          "description": "",
          "handle": "depilacija-voskom-kompleti",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK649EWGYVF36CJ6071RM",
          "rank": 4,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKBP9JSSE7NB6FZYCTPHP",
          "created_at": "2024-01-03T14:45:50.407Z",
          "updated_at": "2024-01-03T14:45:50.407Z",
          "name": "Šećerna pasta",
          "description": "",
          "handle": "depilacija-voskom-secerna-pasta",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK649EWGYVF36CJ6071RM",
          "rank": 5,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKBPTTMNZKSZJ9943EKFC",
          "created_at": "2024-01-03T14:45:50.424Z",
          "updated_at": "2024-01-03T14:45:50.424Z",
          "name": "Roll-oni ",
          "description": "",
          "handle": "depilacija-voskom-roll-oni",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK649EWGYVF36CJ6071RM",
          "rank": 6,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKCYJNE95HEFDRR8R20S9",
          "created_at": "2024-01-03T14:45:51.696Z",
          "updated_at": "2024-01-03T14:45:51.696Z",
          "name": "Puderi od talka",
          "description": "",
          "handle": "depilacija-voskom-puderi-od-talka",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK649EWGYVF36CJ6071RM",
          "rank": 7,
          "metadata": null
        }
      ],
      "parent_category": {
        "id": "pcat_01HK7WK493C3GNJNECHNPJ0K2N",
        "created_at": "2024-01-03T14:45:42.817Z",
        "updated_at": "2024-01-03T14:45:42.817Z",
        "name": "Uklanjanje dlaka",
        "description": "",
        "handle": "brijanje-i-depilacija-uklanjanje-dlaka",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK41MNZ3C795B2JN5JW0G",
        "rank": 5,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKABANM1AAD55XWDKH51X",
      "created_at": "2024-01-03T14:45:49.031Z",
      "updated_at": "2024-01-03T14:45:49.031Z",
      "name": "Kape za perike",
      "description": "",
      "handle": "umeci-za-kosu-perike-i-dodaci-kape-za-perike_20950",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK5A5XWEMK8CFBX8HWCSH",
      "rank": 1,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK5A5XWEMK8CFBX8HWCSH",
        "created_at": "2024-01-03T14:45:43.874Z",
        "updated_at": "2024-01-03T14:45:43.874Z",
        "name": "Umeci za kosu, perike i dodaci",
        "description": "",
        "handle": "njega-kose-umeci-za-kosu-perike-i-dodaci",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK43BQJST1XZ18R4BST01",
        "rank": 7,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKCM4BVDT5DNWY78KG9S9",
      "created_at": "2024-01-03T14:45:51.362Z",
      "updated_at": "2024-01-03T14:45:51.362Z",
      "name": "Akrilne tekućine i prašci",
      "description": "",
      "handle": "umjetni-nokti-i-pribor-akrilne-tekucine-i-prasci",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK8V224HR9CV9810ZE5S6",
      "rank": 1,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK8V224HR9CV9810ZE5S6",
        "created_at": "2024-01-03T14:45:47.487Z",
        "updated_at": "2024-01-03T14:45:47.487Z",
        "name": "Umjetni nokti i pribor",
        "description": "",
        "handle": "dizajni-za-nokte-umjetni-nokti-i-pribor",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK4PDYPCE7H5D1FAFMHNK",
        "rank": 8,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK70F3RVETQTBZKPWS37R",
      "created_at": "2024-01-03T14:45:45.614Z",
      "updated_at": "2024-01-03T14:45:45.614Z",
      "name": "Pilinzi",
      "description": "",
      "handle": "usne-pilinzi",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4EKHB2R4FXMDWSTMP8X",
      "rank": 1,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4EKHB2R4FXMDWSTMP8X",
        "created_at": "2024-01-03T14:45:42.991Z",
        "updated_at": "2024-01-03T14:45:42.991Z",
        "name": "Usne",
        "description": "",
        "handle": "njega-koze-usne",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK42VB8WYE6GKG3FEJ41V",
        "rank": 4,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK9928NREJQAV2NRNX3QF",
      "created_at": "2024-01-03T14:45:47.935Z",
      "updated_at": "2024-01-03T14:45:47.935Z",
      "name": "Šljokice",
      "description": "",
      "handle": "usne-sljokice",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4SPEA6NYM5SZ5DDC5A8",
      "rank": 1,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4SPEA6NYM5SZ5DDC5A8",
        "created_at": "2024-01-03T14:45:43.348Z",
        "updated_at": "2024-01-03T14:45:43.348Z",
        "name": "Usne",
        "description": "",
        "handle": "sminka-usne_11739",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK4495D212GWWBGHX6D9H",
        "rank": 3,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKC3PSWXMSAYDNT36C1G6",
      "created_at": "2024-01-03T14:45:50.831Z",
      "updated_at": "2024-01-03T14:45:50.831Z",
      "name": "Kreme",
      "description": "",
      "handle": "za-hidrataciju-kreme",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK6WA7CDBMWAWAHDXNKA8",
      "rank": 1,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK6WA7CDBMWAWAHDXNKA8",
        "created_at": "2024-01-03T14:45:45.480Z",
        "updated_at": "2024-01-03T14:45:45.480Z",
        "name": "Za hidrataciju",
        "description": "",
        "handle": "tijelo-za-hidrataciju",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK4DMG2Q4J3RMF32RA7Y1",
        "rank": 1,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKBSGXTDPPGA4ZZRT7ZNX",
      "created_at": "2024-01-03T14:45:50.510Z",
      "updated_at": "2024-01-03T14:45:50.510Z",
      "name": "Noćna njega",
      "description": "",
      "handle": "za-hidrataciju-nocna-njega",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK6SZXFJCA60BBDC8S5W7",
      "rank": 1,
      "metadata": null,
      "category_children": [
        {
          "id": "pcat_01HK7WKDZ2A1A1PSMY46N4JR2W",
          "created_at": "2024-01-03T14:45:52.735Z",
          "updated_at": "2024-01-03T14:45:52.735Z",
          "name": "Kreme",
          "description": "",
          "handle": "nocna-njega-kreme",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WKBSGXTDPPGA4ZZRT7ZNX",
          "rank": 0,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKDZEDYWYHTA4MJAPYCD4",
          "created_at": "2024-01-03T14:45:52.748Z",
          "updated_at": "2024-01-03T14:45:52.748Z",
          "name": "Gelovi",
          "description": "",
          "handle": "nocna-njega-gelovi",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WKBSGXTDPPGA4ZZRT7ZNX",
          "rank": 1,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKDZV9FF3053TTQ69JVGD",
          "created_at": "2024-01-03T14:45:52.761Z",
          "updated_at": "2024-01-03T14:45:52.761Z",
          "name": "Serumi i tekućine",
          "description": "",
          "handle": "nocna-njega-serumi-i-tekucine",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WKBSGXTDPPGA4ZZRT7ZNX",
          "rank": 2,
          "metadata": null
        }
      ],
      "parent_category": {
        "id": "pcat_01HK7WK6SZXFJCA60BBDC8S5W7",
        "created_at": "2024-01-03T14:45:45.388Z",
        "updated_at": "2024-01-03T14:45:45.388Z",
        "name": "Za hidrataciju",
        "description": "",
        "handle": "lice-za-hidrataciju",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK4CQ8JZ3NZ6QJC2MQW11",
        "rank": 1,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK9HCGW48V1ZX8CX0X3M1",
      "created_at": "2024-01-03T14:45:48.202Z",
      "updated_at": "2024-01-03T14:45:48.202Z",
      "name": "Parfemska voda",
      "description": "",
      "handle": "za-muskarce-parfemska-voda",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4WXV52RYH206J4P8TD0",
      "rank": 1,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4WXV52RYH206J4P8TD0",
        "created_at": "2024-01-03T14:45:43.446Z",
        "updated_at": "2024-01-03T14:45:43.446Z",
        "name": "Za muškarce",
        "description": "",
        "handle": "parfemi-i-mirisi-za-muskarce_14094",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK45CQPDHWCYPEW3W862G",
        "rank": 2,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKB9ANK6JRDDR6FYMXM3V",
      "created_at": "2024-01-03T14:45:49.992Z",
      "updated_at": "2024-01-03T14:45:49.992Z",
      "name": "Setovi",
      "description": "",
      "handle": "za-muskarce-setovi",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK5FHHQR6TNHWK6MYMBRV",
      "rank": 1,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK5FHHQR6TNHWK6MYMBRV",
        "created_at": "2024-01-03T14:45:44.044Z",
        "updated_at": "2024-01-03T14:45:44.044Z",
        "name": "Za muškarce",
        "description": "",
        "handle": "rucno-brijanje-za-muskarce",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK46D6H08A70HPE8S7SCQ",
        "rank": 1,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKBCC7WWVFJYCAX70VV1N",
      "created_at": "2024-01-03T14:45:50.088Z",
      "updated_at": "2024-01-03T14:45:50.088Z",
      "name": "Aparati za brijanje za žene",
      "description": "",
      "handle": "za-zene-aparati-za-brijanje-za-zene",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK5G6G274F8G2WFZ816SN",
      "rank": 1,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK5G6G274F8G2WFZ816SN",
        "created_at": "2024-01-03T14:45:44.067Z",
        "updated_at": "2024-01-03T14:45:44.067Z",
        "name": "Za žene",
        "description": "",
        "handle": "rucno-brijanje-za-zene",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK46D6H08A70HPE8S7SCQ",
        "rank": 2,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK9JX9P29DZZ37WECSK08",
      "created_at": "2024-01-03T14:45:48.251Z",
      "updated_at": "2024-01-03T14:45:48.251Z",
      "name": "Kolonjska voda",
      "description": "",
      "handle": "za-zene-kolonjska-voda",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4YNQSS786T0PHYXE7FP",
      "rank": 1,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4YNQSS786T0PHYXE7FP",
        "created_at": "2024-01-03T14:45:43.492Z",
        "updated_at": "2024-01-03T14:45:43.492Z",
        "name": "Za žene",
        "description": "",
        "handle": "parfemi-i-mirisi-za-zene_14100",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK45CQPDHWCYPEW3W862G",
        "rank": 3,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKDBR1SC4CRD0P5EGR04W",
      "created_at": "2024-01-03T14:45:52.111Z",
      "updated_at": "2024-01-03T14:45:52.111Z",
      "name": "Losioni i tekućine",
      "description": "",
      "handle": "aftershave-losioni-i-tekucine",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WKAZX7KTPB76GCBMFEHGP",
      "rank": 2,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WKAZX7KTPB76GCBMFEHGP",
        "created_at": "2024-01-03T14:45:49.684Z",
        "updated_at": "2024-01-03T14:45:49.684Z",
        "name": "Aftershave",
        "description": "",
        "handle": "za-muskarce-aftershave",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK4WXV52RYH206J4P8TD0",
        "rank": 4,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK9X10701060SA690EKN6",
      "created_at": "2024-01-03T14:45:48.574Z",
      "updated_at": "2024-01-03T14:45:48.574Z",
      "name": "Oči",
      "description": "",
      "handle": "alati-i-pribor-za-sminkanje-oci",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK57XS2NQS0EGT358BW0Q",
      "rank": 2,
      "metadata": null,
      "category_children": [
        {
          "id": "pcat_01HK7WKCVDSTPC75MGVA0DG7GZ",
          "created_at": "2024-01-03T14:45:51.595Z",
          "updated_at": "2024-01-03T14:45:51.595Z",
          "name": "Aplikatori",
          "description": "",
          "handle": "oci-aplikatori",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK9X10701060SA690EKN6",
          "rank": 0,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKCVT8485V2DSHZMRFDW7",
          "created_at": "2024-01-03T14:45:51.608Z",
          "updated_at": "2024-01-03T14:45:51.608Z",
          "name": "Trake za kapke",
          "description": "",
          "handle": "oci-trake-za-kapke",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK9X10701060SA690EKN6",
          "rank": 1,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKCW9QXNZQ97Q6AVR6FWP",
          "created_at": "2024-01-03T14:45:51.623Z",
          "updated_at": "2024-01-03T14:45:51.623Z",
          "name": "Češljevi za trepavice i obrve",
          "description": "",
          "handle": "oci-cesljevi-za-trepavice-i-obrve",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK9X10701060SA690EKN6",
          "rank": 2,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKCWQC9HE1G9FRHMW11Q3",
          "created_at": "2024-01-03T14:45:51.637Z",
          "updated_at": "2024-01-03T14:45:51.637Z",
          "name": "Umjetne trepavice",
          "description": "",
          "handle": "oci-umjetne-trepavice",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK9X10701060SA690EKN6",
          "rank": 3,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKCX4VR023GHVY6WQ81ZE",
          "created_at": "2024-01-03T14:45:51.650Z",
          "updated_at": "2024-01-03T14:45:51.650Z",
          "name": "Uvijači za trepavice",
          "description": "",
          "handle": "oci-uvijaci-za-trepavice",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK9X10701060SA690EKN6",
          "rank": 4,
          "metadata": null
        }
      ],
      "parent_category": {
        "id": "pcat_01HK7WK57XS2NQS0EGT358BW0Q",
        "created_at": "2024-01-03T14:45:43.802Z",
        "updated_at": "2024-01-03T14:45:43.802Z",
        "name": "Alati i pribor za šminkanje",
        "description": "",
        "handle": "pribor-i-dodaci-alati-i-pribor-za-sminkanje",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK45W0F9X0VMBBCAVDPWD",
        "rank": 2,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK7J6HVDGPHNJG2T8BHTX",
      "created_at": "2024-01-03T14:45:46.181Z",
      "updated_at": "2024-01-03T14:45:46.181Z",
      "name": "Trajno bojanje",
      "description": "",
      "handle": "bojanje-kose-trajno-bojanje",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4KQVZQK6XPGZJEJ50QZ",
      "rank": 2,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4KQVZQK6XPGZJEJ50QZ",
        "created_at": "2024-01-03T14:45:43.157Z",
        "updated_at": "2024-01-03T14:45:43.157Z",
        "name": "Bojanje kose",
        "description": "",
        "handle": "njega-kose-bojanje-kose",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK43BQJST1XZ18R4BST01",
        "rank": 2,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK47G3STXHFMNMZ4MMQ7G",
      "created_at": "2024-01-03T14:45:42.766Z",
      "updated_at": "2024-01-03T14:45:42.766Z",
      "name": "Škare za kosu, brijači i aparati za brijanje tijela",
      "description": "",
      "handle": "brijanje-i-depilacija-skare-za-kosu-brijaci-i-aparati-za-brijanje-tijela",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK41MNZ3C795B2JN5JW0G",
      "rank": 2,
      "metadata": null,
      "category_children": [
        {
          "id": "pcat_01HK7WK5J88H7Q94FFN2RD1WZG",
          "created_at": "2024-01-03T14:45:44.134Z",
          "updated_at": "2024-01-03T14:45:44.134Z",
          "name": "Ulja za čišćenje",
          "description": "",
          "handle": "skare-za-kosu-brijaci-i-aparati-za-brijanje-tijela-ulja-za-ciscenje",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK47G3STXHFMNMZ4MMQ7G",
          "rank": 0,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK5JPXVJPF1V58XZM4A32",
          "created_at": "2024-01-03T14:45:44.147Z",
          "updated_at": "2024-01-03T14:45:44.147Z",
          "name": "Aparati za brijanje tijela",
          "description": "",
          "handle": "skare-za-kosu-brijaci-i-aparati-za-brijanje-tijela-aparati-za-brijanje-tijela",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK47G3STXHFMNMZ4MMQ7G",
          "rank": 1,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK5K56WX2G7FYJSMVB9G6",
          "created_at": "2024-01-03T14:45:44.163Z",
          "updated_at": "2024-01-03T14:45:44.163Z",
          "name": "Setovi i kompleti",
          "description": "",
          "handle": "skare-za-kosu-brijaci-i-aparati-za-brijanje-tijela-setovi-i-kompleti",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK47G3STXHFMNMZ4MMQ7G",
          "rank": 2,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK5KNGTNHYD6QSAG97YJ2",
          "created_at": "2024-01-03T14:45:44.179Z",
          "updated_at": "2024-01-03T14:45:44.179Z",
          "name": "Trimeri za dlake na licu",
          "description": "",
          "handle": "skare-za-kosu-brijaci-i-aparati-za-brijanje-tijela-trimeri-za-dlake-na-licu",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK47G3STXHFMNMZ4MMQ7G",
          "rank": 3,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK5M442PQ4QMSQ7K41BEY",
          "created_at": "2024-01-03T14:45:44.194Z",
          "updated_at": "2024-01-03T14:45:44.194Z",
          "name": "Škare za kosu i bradu",
          "description": "",
          "handle": "skare-za-kosu-brijaci-i-aparati-za-brijanje-tijela-skare-za-kosu-i-bradu",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK47G3STXHFMNMZ4MMQ7G",
          "rank": 4,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK5MK02W8KETBZB8XTVJX",
          "created_at": "2024-01-03T14:45:44.209Z",
          "updated_at": "2024-01-03T14:45:44.209Z",
          "name": "Češljevi vodiči",
          "description": "",
          "handle": "skare-za-kosu-brijaci-i-aparati-za-brijanje-tijela-cesljevi-vodici",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK47G3STXHFMNMZ4MMQ7G",
          "rank": 5,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK5N1KRF3G83RNQF2Z77T",
          "created_at": "2024-01-03T14:45:44.223Z",
          "updated_at": "2024-01-03T14:45:44.223Z",
          "name": "Ženski precizni trimeri",
          "description": "",
          "handle": "skare-za-kosu-brijaci-i-aparati-za-brijanje-tijela-zenski-precizni-trimeri",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK47G3STXHFMNMZ4MMQ7G",
          "rank": 6,
          "metadata": null
        }
      ],
      "parent_category": {
        "id": "pcat_01HK7WK41MNZ3C795B2JN5JW0G",
        "created_at": "2024-01-03T14:45:42.579Z",
        "updated_at": "2024-01-03T14:45:42.579Z",
        "name": "Brijanje i depilacija",
        "description": "",
        "handle": "ljepota-brijanje-i-depilacija",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": null,
        "rank": 0,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKB41CSS4HCT9G53TPNK8",
      "created_at": "2024-01-03T14:45:49.823Z",
      "updated_at": "2024-01-03T14:45:49.823Z",
      "name": "Oči",
      "description": "",
      "handle": "cetke-i-pribor-za-sminkanje-oci",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK5D0544YXJQT9G60XVED",
      "rank": 2,
      "metadata": null,
      "category_children": [
        {
          "id": "pcat_01HK7WKDEDDZ809FD0XJS3G80K",
          "created_at": "2024-01-03T14:45:52.203Z",
          "updated_at": "2024-01-03T14:45:52.203Z",
          "name": "Aplikatori",
          "description": "",
          "handle": "oci-aplikatori_25084",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WKB41CSS4HCT9G53TPNK8",
          "rank": 0,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKDFF88AQSR9AH8X24NW5",
          "created_at": "2024-01-03T14:45:52.232Z",
          "updated_at": "2024-01-03T14:45:52.232Z",
          "name": "Češljevi za trepavice i obrve",
          "description": "",
          "handle": "oci-cesljevi-za-trepavice-i-obrve_25085",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WKB41CSS4HCT9G53TPNK8",
          "rank": 1,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKDGQC1MXY7TCAJXA5D4G",
          "created_at": "2024-01-03T14:45:52.272Z",
          "updated_at": "2024-01-03T14:45:52.272Z",
          "name": "Četke",
          "description": "",
          "handle": "oci-cetke_25086",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WKB41CSS4HCT9G53TPNK8",
          "rank": 2,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKDJ0ZJ6JSFDJQ5C6N29C",
          "created_at": "2024-01-03T14:45:52.313Z",
          "updated_at": "2024-01-03T14:45:52.313Z",
          "name": "Umjetne trepavice",
          "description": "",
          "handle": "oci-umjetne-trepavice_25088",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WKB41CSS4HCT9G53TPNK8",
          "rank": 3,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKDKE9ZG8EJ52S1F8T52R",
          "created_at": "2024-01-03T14:45:52.357Z",
          "updated_at": "2024-01-03T14:45:52.357Z",
          "name": "Uvijači za trepavice",
          "description": "",
          "handle": "oci-uvijaci-za-trepavice_25089",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WKB41CSS4HCT9G53TPNK8",
          "rank": 4,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKDN0QZ4DHQ2DWJEQK0VG",
          "created_at": "2024-01-03T14:45:52.408Z",
          "updated_at": "2024-01-03T14:45:52.408Z",
          "name": "Četke za obrve",
          "description": "",
          "handle": "oci-cetke-za-obrve",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WKB41CSS4HCT9G53TPNK8",
          "rank": 5,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKDPJRK8NFBT083HY1P7B",
          "created_at": "2024-01-03T14:45:52.457Z",
          "updated_at": "2024-01-03T14:45:52.457Z",
          "name": "Četke za trepavice",
          "description": "",
          "handle": "oci-cetke-za-trepavice",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WKB41CSS4HCT9G53TPNK8",
          "rank": 6,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKDR8T6C0W257T6YB9MBH",
          "created_at": "2024-01-03T14:45:52.505Z",
          "updated_at": "2024-01-03T14:45:52.505Z",
          "name": "Kistovi za tuš",
          "description": "",
          "handle": "oci-kistovi-za-tus",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WKB41CSS4HCT9G53TPNK8",
          "rank": 7,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKDS30VWAW0CJB83X6570",
          "created_at": "2024-01-03T14:45:52.543Z",
          "updated_at": "2024-01-03T14:45:52.543Z",
          "name": "Četkice za sjenila",
          "description": "",
          "handle": "oci-cetkice-za-sjenila",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WKB41CSS4HCT9G53TPNK8",
          "rank": 8,
          "metadata": null
        }
      ],
      "parent_category": {
        "id": "pcat_01HK7WK5D0544YXJQT9G60XVED",
        "created_at": "2024-01-03T14:45:43.966Z",
        "updated_at": "2024-01-03T14:45:43.966Z",
        "name": "Četke i pribor za šminkanje",
        "description": "",
        "handle": "pribor-i-dodaci-cetke-i-pribor-za-sminkanje",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK45W0F9X0VMBBCAVDPWD",
        "rank": 3,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKDX3AZN40HRN26KM7F36",
      "created_at": "2024-01-03T14:45:52.660Z",
      "updated_at": "2024-01-03T14:45:52.660Z",
      "name": "Tekućine i sprejevi",
      "description": "",
      "handle": "ciscenje-tekucine-i-sprejevi",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WKBECX73T18N8C8Z8EJZZ",
      "rank": 2,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WKBECX73T18N8C8Z8EJZZ",
        "created_at": "2024-01-03T14:45:50.154Z",
        "updated_at": "2024-01-03T14:45:50.154Z",
        "name": "Čišćenje",
        "description": "",
        "handle": "pribor-ciscenje_1038",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK5GP4MZXQ69EPS0WG1AR",
        "rank": 3,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKD0FXRAFFJ1YVT5G9802",
      "created_at": "2024-01-03T14:45:51.757Z",
      "updated_at": "2024-01-03T14:45:51.757Z",
      "name": "Gelovi i pjene",
      "description": "",
      "handle": "cistaci-gelovi-i-pjene",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WKA2274BVZPY0TZ6SN7Z9",
      "rank": 2,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WKA2274BVZPY0TZ6SN7Z9",
        "created_at": "2024-01-03T14:45:48.735Z",
        "updated_at": "2024-01-03T14:45:48.735Z",
        "name": "Čistači",
        "description": "",
        "handle": "lice-cistaci_20934",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK4CQ8JZ3NZ6QJC2MQW11",
        "rank": 5,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKBKEJG81CACY9HWRF39G",
      "created_at": "2024-01-03T14:45:50.311Z",
      "updated_at": "2024-01-03T14:45:50.311Z",
      "name": "Vosak",
      "description": "",
      "handle": "depilacija-voskom-vosak",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK649EWGYVF36CJ6071RM",
      "rank": 2,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK649EWGYVF36CJ6071RM",
        "created_at": "2024-01-03T14:45:44.706Z",
        "updated_at": "2024-01-03T14:45:44.706Z",
        "name": "Depilacija voskom",
        "description": "",
        "handle": "uklanjanje-dlaka-depilacija-voskom",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK493C3GNJNECHNPJ0K2N",
        "rank": 1,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK8RM57YBDESB6PQT81TG",
      "created_at": "2024-01-03T14:45:47.410Z",
      "updated_at": "2024-01-03T14:45:47.410Z",
      "name": "Osnovni slojevi",
      "description": "",
      "handle": "dizajni-za-nokte-osnovni-slojevi",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4PDYPCE7H5D1FAFMHNK",
      "rank": 2,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4PDYPCE7H5D1FAFMHNK",
        "created_at": "2024-01-03T14:45:43.244Z",
        "updated_at": "2024-01-03T14:45:43.244Z",
        "name": "Dizajni za nokte",
        "description": "",
        "handle": "manikura-i-pedikura-dizajni-za-nokte",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK43VEK8SG6M4T81C3W37",
        "rank": 1,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKDYM1WJ8C37HSQRWSSMV",
      "created_at": "2024-01-03T14:45:52.723Z",
      "updated_at": "2024-01-03T14:45:52.723Z",
      "name": "Serumi i tekućine",
      "description": "",
      "handle": "dnevna-njega-serumi-i-tekucine",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WKBS3M1Y3H3YRT1SGTGNX",
      "rank": 2,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WKBS3M1Y3H3YRT1SGTGNX",
        "created_at": "2024-01-03T14:45:50.496Z",
        "updated_at": "2024-01-03T14:45:50.496Z",
        "name": "Dnevna njega",
        "description": "",
        "handle": "za-hidrataciju-dnevna-njega",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK6SZXFJCA60BBDC8S5W7",
        "rank": 0,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK6KF0BK04Z1P6QB2V34G",
      "created_at": "2024-01-03T14:45:45.196Z",
      "updated_at": "2024-01-03T14:45:45.196Z",
      "name": "Bombice za kupku",
      "description": "",
      "handle": "dodaci-za-kupke-bombice-za-kupku",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4AMW72YGS9N8YSBWJ37",
      "rank": 2,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4AMW72YGS9N8YSBWJ37",
        "created_at": "2024-01-03T14:45:42.866Z",
        "updated_at": "2024-01-03T14:45:42.866Z",
        "name": "Dodaci za kupke",
        "description": "",
        "handle": "kupanje-i-osobna-higijena-dodaci-za-kupke",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK42BQ7XFW617X5XP4BAR",
        "rank": 1,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK5HVMCFSE258T24TNAYS",
      "created_at": "2024-01-03T14:45:44.117Z",
      "updated_at": "2024-01-03T14:45:44.117Z",
      "name": "Električni brijači za žene",
      "description": "",
      "handle": "elektricni-brijaci-elektricni-brijaci-za-zene",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK46YG69NRB3HQKNMNZ0C",
      "rank": 2,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK46YG69NRB3HQKNMNZ0C",
        "created_at": "2024-01-03T14:45:42.747Z",
        "updated_at": "2024-01-03T14:45:42.747Z",
        "name": "Električni brijači",
        "description": "",
        "handle": "brijanje-i-depilacija-elektricni-brijaci",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK41MNZ3C795B2JN5JW0G",
        "rank": 1,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKCXKZRSRCC9S5W3NJD2M",
      "created_at": "2024-01-03T14:45:51.665Z",
      "updated_at": "2024-01-03T14:45:51.665Z",
      "name": "Zamjenske glave za električne brijače",
      "description": "",
      "handle": "elektricni-brijaci-za-muskarce-zamjenske-glave-za-elektricne-brijace",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK5H8GFDPH0Z1TTQXAYJQ",
      "rank": 2,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK5H8GFDPH0Z1TTQXAYJQ",
        "created_at": "2024-01-03T14:45:44.101Z",
        "updated_at": "2024-01-03T14:45:44.101Z",
        "name": "Električni brijači za muškarce",
        "description": "",
        "handle": "elektricni-brijaci-elektricni-brijaci-za-muskarce",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK46YG69NRB3HQKNMNZ0C",
        "rank": 1,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKD6C1W8AEG3WGXS781FD",
      "created_at": "2024-01-03T14:45:51.945Z",
      "updated_at": "2024-01-03T14:45:51.945Z",
      "name": "Fenovi za kosu",
      "description": "",
      "handle": "fenovi-i-difuzori-fenovi-za-kosu",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WKAA0GJVNVKHFMBVWE9GZ",
      "rank": 2,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WKAA0GJVNVKHFMBVWE9GZ",
        "created_at": "2024-01-03T14:45:48.986Z",
        "updated_at": "2024-01-03T14:45:48.986Z",
        "name": "Fenovi i difuzori",
        "description": "",
        "handle": "frizerski-uredaji-i-pribor-fenovi-i-difuzori",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK4K84XGGV7XG3TFC6W69",
        "rank": 13,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKCDA24B2FNVMVT43ZQBB",
      "created_at": "2024-01-03T14:45:51.143Z",
      "updated_at": "2024-01-03T14:45:51.143Z",
      "name": "Fenovi za kosu",
      "description": "",
      "handle": "fenovi-za-kosu-fenovi-za-kosu",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK7H0CCVR0D8RJQJFTVCE",
      "rank": 2,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK7H0CCVR0D8RJQJFTVCE",
        "created_at": "2024-01-03T14:45:46.142Z",
        "updated_at": "2024-01-03T14:45:46.142Z",
        "name": "Fenovi za kosu",
        "description": "",
        "handle": "frizerski-uredaji-i-pribor-fenovi-za-kosu",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK4K84XGGV7XG3TFC6W69",
        "rank": 11,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK7CDBMMHH40CXP04GGC3",
      "created_at": "2024-01-03T14:45:45.995Z",
      "updated_at": "2024-01-03T14:45:45.995Z",
      "name": "Toplinske rukavice za oblikovanje",
      "description": "",
      "handle": "frizerski-uredaji-i-pribor-toplinske-rukavice-za-oblikovanje",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4K84XGGV7XG3TFC6W69",
      "rank": 2,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4K84XGGV7XG3TFC6W69",
        "created_at": "2024-01-03T14:45:43.141Z",
        "updated_at": "2024-01-03T14:45:43.141Z",
        "name": "Frizerski uređaji i pribor",
        "description": "",
        "handle": "njega-kose-frizerski-uredaji-i-pribor",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK43BQJST1XZ18R4BST01",
        "rank": 1,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK7X7WGP1CYG47B60VCHQ",
      "created_at": "2024-01-03T14:45:46.528Z",
      "updated_at": "2024-01-03T14:45:46.528Z",
      "name": "Kolica za održavanje",
      "description": "",
      "handle": "kolica-za-odr-avanje",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4M7JE8S0KGQCMWF47WJ",
      "rank": 2,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4M7JE8S0KGQCMWF47WJ",
        "created_at": "2024-01-03T14:45:43.173Z",
        "updated_at": "2024-01-03T14:45:43.173Z",
        "name": "Frizerski namještaj i potrepštine",
        "description": "",
        "handle": "njega-kose-frizerski-namjestaj-i-potrepstine",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK43BQJST1XZ18R4BST01",
        "rank": 3,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKA8MEFNXMV7G5QH0DGE2",
      "created_at": "2024-01-03T14:45:48.945Z",
      "updated_at": "2024-01-03T14:45:48.945Z",
      "name": "Solarni filtri",
      "description": "",
      "handle": "kreme-za-suncanje-i-tamnjenje-solarni-filtri",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK59M5WTCRNSKRG8JBN56",
      "rank": 2,
      "metadata": null,
      "category_children": [
        {
          "id": "pcat_01HK7WKD43FCE3QZMAH1TC1V21",
          "created_at": "2024-01-03T14:45:51.871Z",
          "updated_at": "2024-01-03T14:45:51.871Z",
          "name": "Solarni filtri za tijelo",
          "description": "",
          "handle": "solarni-filtri-solarni-filtri-za-tijelo_25061",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WKA8MEFNXMV7G5QH0DGE2",
          "rank": 0,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKD4F45QHV8F5QGKA1GCC",
          "created_at": "2024-01-03T14:45:51.885Z",
          "updated_at": "2024-01-03T14:45:51.885Z",
          "name": "Solarni filtri za lice",
          "description": "",
          "handle": "solarni-filtri-solarni-filtri-za-lice_25062",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WKA8MEFNXMV7G5QH0DGE2",
          "rank": 1,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKD4X79FPVNW40K5YWR9K",
          "created_at": "2024-01-03T14:45:51.899Z",
          "updated_at": "2024-01-03T14:45:51.899Z",
          "name": "Solarni filtri za usne",
          "description": "",
          "handle": "solarni-filtri-solarni-filtri-za-usne_25063",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WKA8MEFNXMV7G5QH0DGE2",
          "rank": 2,
          "metadata": null
        }
      ],
      "parent_category": {
        "id": "pcat_01HK7WK59M5WTCRNSKRG8JBN56",
        "created_at": "2024-01-03T14:45:43.857Z",
        "updated_at": "2024-01-03T14:45:43.857Z",
        "name": "Kreme za sunčanje i tamnjenje",
        "description": "",
        "handle": "njega-koze-kreme-za-suncanje-i-tamnjenje",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK42VB8WYE6GKG3FEJ41V",
        "rank": 8,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK4B4MMEP1TFJR5T1JGPH",
      "created_at": "2024-01-03T14:45:42.881Z",
      "updated_at": "2024-01-03T14:45:42.881Z",
      "name": "Dezodoransi",
      "description": "",
      "handle": "kupanje-i-osobna-higijena-dezodoransi",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK42BQ7XFW617X5XP4BAR",
      "rank": 2,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK42BQ7XFW617X5XP4BAR",
        "created_at": "2024-01-03T14:45:42.602Z",
        "updated_at": "2024-01-03T14:45:42.602Z",
        "name": "Kupanje i osobna higijena",
        "description": "",
        "handle": "ljepota-kupanje-i-osobna-higijena",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": null,
        "rank": 1,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK6GJXN0CE59ZS6ENV401",
      "created_at": "2024-01-03T14:45:45.101Z",
      "updated_at": "2024-01-03T14:45:45.101Z",
      "name": "Cvjetne spužve",
      "description": "",
      "handle": "kupaonski-pribor-cvjetne-spuzve",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4A2XE81CJFBQCJE5EC2",
      "rank": 2,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4A2XE81CJFBQCJE5EC2",
        "created_at": "2024-01-03T14:45:42.848Z",
        "updated_at": "2024-01-03T14:45:42.848Z",
        "name": "Kupaonski pribor",
        "description": "",
        "handle": "kupanje-i-osobna-higijena-kupaonski-pribor",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK42BQ7XFW617X5XP4BAR",
        "rank": 0,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK92ECJB7S3BF325WHGPN",
      "created_at": "2024-01-03T14:45:47.724Z",
      "updated_at": "2024-01-03T14:45:47.724Z",
      "name": "Bronzeri i highlighteri",
      "description": "",
      "handle": "lice-bronzeri-i-highlighteri",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4QT18DTEY1R94HB0RRX",
      "rank": 2,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4QT18DTEY1R94HB0RRX",
        "created_at": "2024-01-03T14:45:43.284Z",
        "updated_at": "2024-01-03T14:45:43.284Z",
        "name": "Lice",
        "description": "",
        "handle": "sminka-lice_11703",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK4495D212GWWBGHX6D9H",
        "rank": 0,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKCTFBMZT6A2CQY1JZ06R",
      "created_at": "2024-01-03T14:45:51.565Z",
      "updated_at": "2024-01-03T14:45:51.565Z",
      "name": "Spužve",
      "description": "",
      "handle": "lice-spuzve",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK9WEZEVWDMWDPK8W460Y",
      "rank": 2,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK9WEZEVWDMWDPK8W460Y",
        "created_at": "2024-01-03T14:45:48.552Z",
        "updated_at": "2024-01-03T14:45:48.552Z",
        "name": "Lice",
        "description": "",
        "handle": "alati-i-pribor-za-sminkanje-lice",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK57XS2NQS0EGT358BW0Q",
        "rank": 1,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKDDJ67061TG9MG1BT2R8",
      "created_at": "2024-01-03T14:45:52.176Z",
      "updated_at": "2024-01-03T14:45:52.176Z",
      "name": "Spužve",
      "description": "",
      "handle": "lice-spuzve_25082",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WKB4GBXA5NY5NKJJMS67V",
      "rank": 2,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WKB4GBXA5NY5NKJJMS67V",
        "created_at": "2024-01-03T14:45:49.837Z",
        "updated_at": "2024-01-03T14:45:49.837Z",
        "name": "Lice",
        "description": "",
        "handle": "cetke-i-pribor-za-sminkanje-lice",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK5D0544YXJQT9G60XVED",
        "rank": 3,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK6TKWN0PFYN9S0Y0JEG0",
      "created_at": "2024-01-03T14:45:45.425Z",
      "updated_at": "2024-01-03T14:45:45.425Z",
      "name": "Sredstva za čišćenje i piling",
      "description": "",
      "handle": "lice-sredstva-za-ciscenje-i-piling",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4CQ8JZ3NZ6QJC2MQW11",
      "rank": 2,
      "metadata": null,
      "category_children": [
        {
          "id": "pcat_01HK7WKBVP6GSWEWFFT08YC04C",
          "created_at": "2024-01-03T14:45:50.576Z",
          "updated_at": "2024-01-03T14:45:50.576Z",
          "name": "Četke i uređaji za čišćenje",
          "description": "",
          "handle": "sredstva-za-ciscenje-i-piling-cetke-i-uredaji-za-ciscenje",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK6TKWN0PFYN9S0Y0JEG0",
          "rank": 0,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKBWY632YGW1JS713YBR7",
          "created_at": "2024-01-03T14:45:50.615Z",
          "updated_at": "2024-01-03T14:45:50.615Z",
          "name": "Sredstva za uklanjanje šminke",
          "description": "",
          "handle": "sredstva-za-ciscenje-i-piling-sredstva-za-uklanjanje-sminke",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK6TKWN0PFYN9S0Y0JEG0",
          "rank": 1,
          "metadata": null
        }
      ],
      "parent_category": {
        "id": "pcat_01HK7WK4CQ8JZ3NZ6QJC2MQW11",
        "created_at": "2024-01-03T14:45:42.933Z",
        "updated_at": "2024-01-03T14:45:42.933Z",
        "name": "Lice",
        "description": "",
        "handle": "njega-koze-lice_5325",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK42VB8WYE6GKG3FEJ41V",
        "rank": 0,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK42VB8WYE6GKG3FEJ41V",
      "created_at": "2024-01-03T14:45:42.618Z",
      "updated_at": "2024-01-03T14:45:42.618Z",
      "name": "Njega kože",
      "description": "",
      "handle": "ljepota-njega-koze",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": null,
      "rank": 2,
      "metadata": null,
      "category_children": [
        {
          "id": "pcat_01HK7WK4CQ8JZ3NZ6QJC2MQW11",
          "created_at": "2024-01-03T14:45:42.933Z",
          "updated_at": "2024-01-03T14:45:42.933Z",
          "name": "Lice",
          "description": "",
          "handle": "njega-koze-lice_5325",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK42VB8WYE6GKG3FEJ41V",
          "rank": 0,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK4D5FYH1WKJS7EB2FNRN",
          "created_at": "2024-01-03T14:45:42.947Z",
          "updated_at": "2024-01-03T14:45:42.947Z",
          "name": "Vrat i dekolte",
          "description": "",
          "handle": "njega-koze-vrat-i-dekolte",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK42VB8WYE6GKG3FEJ41V",
          "rank": 1,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK4DMG2Q4J3RMF32RA7Y1",
          "created_at": "2024-01-03T14:45:42.962Z",
          "updated_at": "2024-01-03T14:45:42.962Z",
          "name": "Tijelo",
          "description": "",
          "handle": "njega-koze-tijelo_5355",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK42VB8WYE6GKG3FEJ41V",
          "rank": 2,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK4E2GJJTAKSG9K2YD30C",
          "created_at": "2024-01-03T14:45:42.976Z",
          "updated_at": "2024-01-03T14:45:42.976Z",
          "name": "Njega kože suncem i tamnjenjem",
          "description": "",
          "handle": "njega-koze-njega-koze-suncem-i-tamnjenjem",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK42VB8WYE6GKG3FEJ41V",
          "rank": 3,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK4EKHB2R4FXMDWSTMP8X",
          "created_at": "2024-01-03T14:45:42.991Z",
          "updated_at": "2024-01-03T14:45:42.991Z",
          "name": "Usne",
          "description": "",
          "handle": "njega-koze-usne",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK42VB8WYE6GKG3FEJ41V",
          "rank": 4,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK4G7CJB2WFRCRPS0131Q",
          "created_at": "2024-01-03T14:45:43.032Z",
          "updated_at": "2024-01-03T14:45:43.032Z",
          "name": "Ruke i stopala",
          "description": "",
          "handle": "njega-koze-ruke-i-stopala",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK42VB8WYE6GKG3FEJ41V",
          "rank": 5,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK4J1NW5Q3B0PAC2JEQ2N",
          "created_at": "2024-01-03T14:45:43.101Z",
          "updated_at": "2024-01-03T14:45:43.101Z",
          "name": "Oči",
          "description": "",
          "handle": "njega-koze-oci_5391",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK42VB8WYE6GKG3FEJ41V",
          "rank": 6,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK593052WTZY7CMZ9J7TZ",
          "created_at": "2024-01-03T14:45:43.841Z",
          "updated_at": "2024-01-03T14:45:43.841Z",
          "name": "Kompleti i setovi za njegu kože",
          "description": "",
          "handle": "njega-koze-kompleti-i-setovi-za-njegu-koze",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK42VB8WYE6GKG3FEJ41V",
          "rank": 7,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK59M5WTCRNSKRG8JBN56",
          "created_at": "2024-01-03T14:45:43.857Z",
          "updated_at": "2024-01-03T14:45:43.857Z",
          "name": "Kreme za sunčanje i tamnjenje",
          "description": "",
          "handle": "njega-koze-kreme-za-suncanje-i-tamnjenje",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK42VB8WYE6GKG3FEJ41V",
          "rank": 8,
          "metadata": null
        }
      ],
      "parent_category": null
    },
    {
      "id": "pcat_01HK7WK4PWXATZSWB566TB87WB",
      "created_at": "2024-01-03T14:45:43.258Z",
      "updated_at": "2024-01-03T14:45:43.258Z",
      "name": "Tretmani za nokte",
      "description": "",
      "handle": "manikura-i-pedikura-tretmani-za-nokte",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK43VEK8SG6M4T81C3W37",
      "rank": 2,
      "metadata": null,
      "category_children": [
        {
          "id": "pcat_01HK7WK8VM7KP958E9108V388R",
          "created_at": "2024-01-03T14:45:47.502Z",
          "updated_at": "2024-01-03T14:45:47.502Z",
          "name": "Izbjeljivači",
          "description": "",
          "handle": "tretmani-za-nokte-izbjeljivaci",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4PWXATZSWB566TB87WB",
          "rank": 0,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK8W1W272XZ92X17YWRY8",
          "created_at": "2024-01-03T14:45:47.518Z",
          "updated_at": "2024-01-03T14:45:47.518Z",
          "name": "Učvršćivači",
          "description": "",
          "handle": "tretmani-za-nokte-ucvrscivaci",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4PWXATZSWB566TB87WB",
          "rank": 1,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK8WE6D6F0X72MCV40EQ7",
          "created_at": "2024-01-03T14:45:47.532Z",
          "updated_at": "2024-01-03T14:45:47.532Z",
          "name": "Sredstva za popravak noktiju",
          "description": "",
          "handle": "tretmani-za-nokte-sredstva-za-popravak-noktiju",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4PWXATZSWB566TB87WB",
          "rank": 2,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK8WT3RZCQ3WHYKBAN43Q",
          "created_at": "2024-01-03T14:45:47.544Z",
          "updated_at": "2024-01-03T14:45:47.544Z",
          "name": "Tretman za rast noktiju",
          "description": "",
          "handle": "tretmani-za-nokte-tretman-za-rast-noktiju",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4PWXATZSWB566TB87WB",
          "rank": 3,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKAMBXQ4F92A7N7VK9M86",
          "created_at": "2024-01-03T14:45:49.314Z",
          "updated_at": "2024-01-03T14:45:49.314Z",
          "name": "Njega kutikula",
          "description": "",
          "handle": "tretmani-za-nokte-njega-kutikula",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4PWXATZSWB566TB87WB",
          "rank": 4,
          "metadata": null
        }
      ],
      "parent_category": {
        "id": "pcat_01HK7WK43VEK8SG6M4T81C3W37",
        "created_at": "2024-01-03T14:45:42.650Z",
        "updated_at": "2024-01-03T14:45:42.650Z",
        "name": "Manikura i pedikura",
        "description": "",
        "handle": "ljepota-manikura-i-pedikura",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": null,
        "rank": 4,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKC11373AASRD5VMNA8X7",
      "created_at": "2024-01-03T14:45:50.747Z",
      "updated_at": "2024-01-03T14:45:50.747Z",
      "name": "Hidratantne i pomlađujuće maske",
      "description": "",
      "handle": "maske-hidratantne-i-pomladujuce-maske",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK6V1EVEFGH0FNVJKPN1D",
      "rank": 2,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK6V1EVEFGH0FNVJKPN1D",
        "created_at": "2024-01-03T14:45:45.439Z",
        "updated_at": "2024-01-03T14:45:45.439Z",
        "name": "Maske",
        "description": "",
        "handle": "lice-maske",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK4CQ8JZ3NZ6QJC2MQW11",
        "rank": 3,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK4KQVZQK6XPGZJEJ50QZ",
      "created_at": "2024-01-03T14:45:43.157Z",
      "updated_at": "2024-01-03T14:45:43.157Z",
      "name": "Bojanje kose",
      "description": "",
      "handle": "njega-kose-bojanje-kose",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK43BQJST1XZ18R4BST01",
      "rank": 2,
      "metadata": null,
      "category_children": [
        {
          "id": "pcat_01HK7WK7HD6DRSG3HTFZX6CZQG",
          "created_at": "2024-01-03T14:45:46.155Z",
          "updated_at": "2024-01-03T14:45:46.155Z",
          "name": "Pribor za bojanje",
          "description": "",
          "handle": "bojanje-kose-pribor-za-bojanje",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4KQVZQK6XPGZJEJ50QZ",
          "rank": 0,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK7HS3A8G7QRX198A256W",
          "created_at": "2024-01-03T14:45:46.167Z",
          "updated_at": "2024-01-03T14:45:46.167Z",
          "name": "Aditivi i punjenja za boje",
          "description": "",
          "handle": "bojanje-kose-aditivi-i-punjenja-za-boje",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4KQVZQK6XPGZJEJ50QZ",
          "rank": 1,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK7J6HVDGPHNJG2T8BHTX",
          "created_at": "2024-01-03T14:45:46.181Z",
          "updated_at": "2024-01-03T14:45:46.181Z",
          "name": "Trajno bojanje",
          "description": "",
          "handle": "bojanje-kose-trajno-bojanje",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4KQVZQK6XPGZJEJ50QZ",
          "rank": 2,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK7JKEMZVYXR89QH3DFGB",
          "created_at": "2024-01-03T14:45:46.193Z",
          "updated_at": "2024-01-03T14:45:46.193Z",
          "name": "Polutrajno bojanje",
          "description": "",
          "handle": "bojanje-kose-polutrajno-bojanje",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4KQVZQK6XPGZJEJ50QZ",
          "rank": 3,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK7K0S9ZDCJNS3MVGMKKT",
          "created_at": "2024-01-03T14:45:46.206Z",
          "updated_at": "2024-01-03T14:45:46.206Z",
          "name": "Korektori boje",
          "description": "",
          "handle": "bojanje-kose-korektori-boje",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4KQVZQK6XPGZJEJ50QZ",
          "rank": 4,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK7KK5NMP8FCK5JTVY33D",
          "created_at": "2024-01-03T14:45:46.224Z",
          "updated_at": "2024-01-03T14:45:46.224Z",
          "name": "Izbjeljivači ",
          "description": "",
          "handle": "bojanje-kose-izbjeljivaci",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4KQVZQK6XPGZJEJ50QZ",
          "rank": 5,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK7M374FQP6XFVB7QDH77",
          "created_at": "2024-01-03T14:45:46.240Z",
          "updated_at": "2024-01-03T14:45:46.240Z",
          "name": "Izbjeljivači za kosu",
          "description": "",
          "handle": "bojanje-kose-izbjeljivaci-za-kosu",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4KQVZQK6XPGZJEJ50QZ",
          "rank": 6,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK7MJTS98WVVPDYFR58EP",
          "created_at": "2024-01-03T14:45:46.254Z",
          "updated_at": "2024-01-03T14:45:46.254Z",
          "name": "Kana",
          "description": "",
          "handle": "bojanje-kose-kana",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4KQVZQK6XPGZJEJ50QZ",
          "rank": 7,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK7N0WE9XEEGQMCEF0D3J",
          "created_at": "2024-01-03T14:45:46.270Z",
          "updated_at": "2024-01-03T14:45:46.270Z",
          "name": "Pojačivači sjaja",
          "description": "",
          "handle": "bojanje-kose-pojacivaci-sjaja",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4KQVZQK6XPGZJEJ50QZ",
          "rank": 8,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK7NER2J5N522EZH55B2M",
          "created_at": "2024-01-03T14:45:46.284Z",
          "updated_at": "2024-01-03T14:45:46.284Z",
          "name": "Razvijači",
          "description": "",
          "handle": "bojanje-kose-razvijaci",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4KQVZQK6XPGZJEJ50QZ",
          "rank": 9,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK7NY0HSTC9CBHTVK2HB6",
          "created_at": "2024-01-03T14:45:46.299Z",
          "updated_at": "2024-01-03T14:45:46.299Z",
          "name": "Krede za kosu",
          "description": "",
          "handle": "bojanje-kose-krede-za-kosu",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4KQVZQK6XPGZJEJ50QZ",
          "rank": 10,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK7PEMR10FJBSZDJRZYKT",
          "created_at": "2024-01-03T14:45:46.316Z",
          "updated_at": "2024-01-03T14:45:46.316Z",
          "name": "Boje za brkove i bradu",
          "description": "",
          "handle": "bojanje-kose-boje-za-brkove-i-bradu",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4KQVZQK6XPGZJEJ50QZ",
          "rank": 11,
          "metadata": null
        }
      ],
      "parent_category": {
        "id": "pcat_01HK7WK43BQJST1XZ18R4BST01",
        "created_at": "2024-01-03T14:45:42.635Z",
        "updated_at": "2024-01-03T14:45:42.635Z",
        "name": "Njega kose",
        "description": "",
        "handle": "ljepota-njega-kose",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": null,
        "rank": 3,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK6Z40CPVFT96RJA3DA69",
      "created_at": "2024-01-03T14:45:45.569Z",
      "updated_at": "2024-01-03T14:45:45.569Z",
      "name": "Sredstva za samotamnjenje",
      "description": "",
      "handle": "njega-koze-suncem-i-tamnjenjem-sredstva-za-samotamnjenje",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4E2GJJTAKSG9K2YD30C",
      "rank": 2,
      "metadata": null,
      "category_children": [
        {
          "id": "pcat_01HK7WKC878KCPFEWN40Q5B8MY",
          "created_at": "2024-01-03T14:45:50.979Z",
          "updated_at": "2024-01-03T14:45:50.979Z",
          "name": "Sredstva za samotamnjenje za tijelo",
          "description": "",
          "handle": "sredstva-za-samotamnjenje-sredstva-za-samotamnjenje-za-tijelo",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK6Z40CPVFT96RJA3DA69",
          "rank": 0,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKC8XNQRW0DCFN59P6P73",
          "created_at": "2024-01-03T14:45:51.002Z",
          "updated_at": "2024-01-03T14:45:51.002Z",
          "name": "Sredstva za samotamnjenje za lice",
          "description": "",
          "handle": "sredstva-za-samotamnjenje-sredstva-za-samotamnjenje-za-lice_5372",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK6Z40CPVFT96RJA3DA69",
          "rank": 1,
          "metadata": null
        }
      ],
      "parent_category": {
        "id": "pcat_01HK7WK4E2GJJTAKSG9K2YD30C",
        "created_at": "2024-01-03T14:45:42.976Z",
        "updated_at": "2024-01-03T14:45:42.976Z",
        "name": "Njega kože suncem i tamnjenjem",
        "description": "",
        "handle": "njega-koze-njega-koze-suncem-i-tamnjenjem",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK42VB8WYE6GKG3FEJ41V",
        "rank": 3,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK4DMG2Q4J3RMF32RA7Y1",
      "created_at": "2024-01-03T14:45:42.962Z",
      "updated_at": "2024-01-03T14:45:42.962Z",
      "name": "Tijelo",
      "description": "",
      "handle": "njega-koze-tijelo_5355",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK42VB8WYE6GKG3FEJ41V",
      "rank": 2,
      "metadata": null,
      "category_children": [
        {
          "id": "pcat_01HK7WK6VXG6KXMQ9X38KYNYX4",
          "created_at": "2024-01-03T14:45:45.467Z",
          "updated_at": "2024-01-03T14:45:45.467Z",
          "name": "Pilinzi",
          "description": "",
          "handle": "tijelo-pilinzi",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4DMG2Q4J3RMF32RA7Y1",
          "rank": 0,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK6WA7CDBMWAWAHDXNKA8",
          "created_at": "2024-01-03T14:45:45.480Z",
          "updated_at": "2024-01-03T14:45:45.480Z",
          "name": "Za hidrataciju",
          "description": "",
          "handle": "tijelo-za-hidrataciju",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4DMG2Q4J3RMF32RA7Y1",
          "rank": 1,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK6WTY0BZWKZWG5BK92W2",
          "created_at": "2024-01-03T14:45:45.496Z",
          "updated_at": "2024-01-03T14:45:45.496Z",
          "name": "Puderi od talka",
          "description": "",
          "handle": "tijelo-puderi-od-talka",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4DMG2Q4J3RMF32RA7Y1",
          "rank": 2,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK6XB3VQJ2SYTFT2S91MJ",
          "created_at": "2024-01-03T14:45:45.513Z",
          "updated_at": "2024-01-03T14:45:45.513Z",
          "name": "Za toniranje i oblikovanje",
          "description": "",
          "handle": "tijelo-za-toniranje-i-oblikovanje",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4DMG2Q4J3RMF32RA7Y1",
          "rank": 3,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK6XS2PQQ74NJ8FFA3Z56",
          "created_at": "2024-01-03T14:45:45.527Z",
          "updated_at": "2024-01-03T14:45:45.527Z",
          "name": "Tonici",
          "description": "",
          "handle": "tijelo-tonici",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4DMG2Q4J3RMF32RA7Y1",
          "rank": 4,
          "metadata": null
        }
      ],
      "parent_category": {
        "id": "pcat_01HK7WK42VB8WYE6GKG3FEJ41V",
        "created_at": "2024-01-03T14:45:42.618Z",
        "updated_at": "2024-01-03T14:45:42.618Z",
        "name": "Njega kože",
        "description": "",
        "handle": "ljepota-njega-koze",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": null,
        "rank": 2,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKD7P9N9AAGANWGJJTATP",
      "created_at": "2024-01-03T14:45:51.987Z",
      "updated_at": "2024-01-03T14:45:51.987Z",
      "name": "Sredstvo za uklanjanje kutikula",
      "description": "",
      "handle": "njega-kutikula-sredstvo-za-uklanjanje-kutikula",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WKAMBXQ4F92A7N7VK9M86",
      "rank": 2,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WKAMBXQ4F92A7N7VK9M86",
        "created_at": "2024-01-03T14:45:49.314Z",
        "updated_at": "2024-01-03T14:45:49.314Z",
        "name": "Njega kutikula",
        "description": "",
        "handle": "tretmani-za-nokte-njega-kutikula",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK4PWXATZSWB566TB87WB",
        "rank": 4,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK5R1C9F1FFN0DMBHGWNN",
      "created_at": "2024-01-03T14:45:44.313Z",
      "updated_at": "2024-01-03T14:45:44.313Z",
      "name": "Balzami",
      "description": "",
      "handle": "njega-nakon-depilacije-i-brijanja-balzami",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK481HCTWDV2XH5M4VNWK",
      "rank": 2,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK481HCTWDV2XH5M4VNWK",
        "created_at": "2024-01-03T14:45:42.782Z",
        "updated_at": "2024-01-03T14:45:42.782Z",
        "name": "Njega nakon depilacije i brijanja",
        "description": "",
        "handle": "brijanje-i-depilacija-njega-nakon-depilacije-i-brijanja",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK41MNZ3C795B2JN5JW0G",
        "rank": 3,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK5ZPMPP6PXD4Z1JBFSJJ",
      "created_at": "2024-01-03T14:45:44.564Z",
      "updated_at": "2024-01-03T14:45:44.564Z",
      "name": "Pjene",
      "description": "",
      "handle": "njega-prije-depilacije-i-brijanja-pjene",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK48MV187B5ZHKEDJWPEG",
      "rank": 2,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK48MV187B5ZHKEDJWPEG",
        "created_at": "2024-01-03T14:45:42.801Z",
        "updated_at": "2024-01-03T14:45:42.801Z",
        "name": "Njega prije depilacije i brijanja",
        "description": "",
        "handle": "brijanje-i-depilacija-njega-prije-depilacije-i-brijanja",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK41MNZ3C795B2JN5JW0G",
        "rank": 4,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKDZV9FF3053TTQ69JVGD",
      "created_at": "2024-01-03T14:45:52.761Z",
      "updated_at": "2024-01-03T14:45:52.761Z",
      "name": "Serumi i tekućine",
      "description": "",
      "handle": "nocna-njega-serumi-i-tekucine",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WKBSGXTDPPGA4ZZRT7ZNX",
      "rank": 2,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WKBSGXTDPPGA4ZZRT7ZNX",
        "created_at": "2024-01-03T14:45:50.510Z",
        "updated_at": "2024-01-03T14:45:50.510Z",
        "name": "Noćna njega",
        "description": "",
        "handle": "za-hidrataciju-nocna-njega",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK6SZXFJCA60BBDC8S5W7",
        "rank": 1,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKCW9QXNZQ97Q6AVR6FWP",
      "created_at": "2024-01-03T14:45:51.623Z",
      "updated_at": "2024-01-03T14:45:51.623Z",
      "name": "Češljevi za trepavice i obrve",
      "description": "",
      "handle": "oci-cesljevi-za-trepavice-i-obrve",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK9X10701060SA690EKN6",
      "rank": 2,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK9X10701060SA690EKN6",
        "created_at": "2024-01-03T14:45:48.574Z",
        "updated_at": "2024-01-03T14:45:48.574Z",
        "name": "Oči",
        "description": "",
        "handle": "alati-i-pribor-za-sminkanje-oci",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK57XS2NQS0EGT358BW0Q",
        "rank": 2,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKDGQC1MXY7TCAJXA5D4G",
      "created_at": "2024-01-03T14:45:52.272Z",
      "updated_at": "2024-01-03T14:45:52.272Z",
      "name": "Četke",
      "description": "",
      "handle": "oci-cetke_25086",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WKB41CSS4HCT9G53TPNK8",
      "rank": 2,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WKB41CSS4HCT9G53TPNK8",
        "created_at": "2024-01-03T14:45:49.823Z",
        "updated_at": "2024-01-03T14:45:49.823Z",
        "name": "Oči",
        "description": "",
        "handle": "cetke-i-pribor-za-sminkanje-oci",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK5D0544YXJQT9G60XVED",
        "rank": 2,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK76789GCDGD0F5Z8JR3A",
      "created_at": "2024-01-03T14:45:45.797Z",
      "updated_at": "2024-01-03T14:45:45.797Z",
      "name": "Gelovi",
      "description": "",
      "handle": "oci-gelovi",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4J1NW5Q3B0PAC2JEQ2N",
      "rank": 2,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4J1NW5Q3B0PAC2JEQ2N",
        "created_at": "2024-01-03T14:45:43.101Z",
        "updated_at": "2024-01-03T14:45:43.101Z",
        "name": "Oči",
        "description": "",
        "handle": "njega-koze-oci_5391",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK42VB8WYE6GKG3FEJ41V",
        "rank": 6,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK9CVWX3TR9SYFDVTVG6Z",
      "created_at": "2024-01-03T14:45:48.056Z",
      "updated_at": "2024-01-03T14:45:48.056Z",
      "name": "Šljokice",
      "description": "",
      "handle": "oci-sljokice",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4T4JAQRP4VBRRAJR36J",
      "rank": 2,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4T4JAQRP4VBRRAJR36J",
        "created_at": "2024-01-03T14:45:43.362Z",
        "updated_at": "2024-01-03T14:45:43.362Z",
        "name": "Oči",
        "description": "",
        "handle": "sminka-oci_11745",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK4495D212GWWBGHX6D9H",
        "rank": 4,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKCQ6P3AB7MR7NPKAT3NA",
      "created_at": "2024-01-03T14:45:51.459Z",
      "updated_at": "2024-01-03T14:45:51.459Z",
      "name": "Post-tretmani za kožu",
      "description": "",
      "handle": "oprema-za-tetoviranje-post-tretmani-za-kozu",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK97BH9JQSNRK7NH98XRD",
      "rank": 2,
      "metadata": null,
      "category_children": [
        {
          "id": "pcat_01HK7WKE07WSQKSZAKYXAXPDJP",
          "created_at": "2024-01-03T14:45:52.774Z",
          "updated_at": "2024-01-03T14:45:52.774Z",
          "name": "Kreme",
          "description": "",
          "handle": "post-tretmani-za-kozu-kreme_11732",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WKCQ6P3AB7MR7NPKAT3NA",
          "rank": 0,
          "metadata": null
        }
      ],
      "parent_category": {
        "id": "pcat_01HK7WK97BH9JQSNRK7NH98XRD",
        "created_at": "2024-01-03T14:45:47.879Z",
        "updated_at": "2024-01-03T14:45:47.879Z",
        "name": "Oprema za tetoviranje",
        "description": "",
        "handle": "tijelo-oprema-za-tetoviranje",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK4RBTHMFSNA47WTF4D6J",
        "rank": 2,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK6PB5GMXY65DHG0SF05X",
      "created_at": "2024-01-03T14:45:45.288Z",
      "updated_at": "2024-01-03T14:45:45.288Z",
      "name": "Kruti i tekući sapuni za ruke",
      "description": "",
      "handle": "osobna-cistoca-kruti-i-tekuci-sapuni-za-ruke",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4C6VG3TJWR51GB1ZQJW",
      "rank": 2,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4C6VG3TJWR51GB1ZQJW",
        "created_at": "2024-01-03T14:45:42.916Z",
        "updated_at": "2024-01-03T14:45:42.916Z",
        "name": "Osobna čistoća",
        "description": "",
        "handle": "kupanje-i-osobna-higijena-osobna-cistoca",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK42BQ7XFW617X5XP4BAR",
        "rank": 4,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK4WXV52RYH206J4P8TD0",
      "created_at": "2024-01-03T14:45:43.446Z",
      "updated_at": "2024-01-03T14:45:43.446Z",
      "name": "Za muškarce",
      "description": "",
      "handle": "parfemi-i-mirisi-za-muskarce_14094",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK45CQPDHWCYPEW3W862G",
      "rank": 2,
      "metadata": null,
      "category_children": [
        {
          "id": "pcat_01HK7WK9H0P481ZJ4J1B6Z0TJ7",
          "created_at": "2024-01-03T14:45:48.190Z",
          "updated_at": "2024-01-03T14:45:48.190Z",
          "name": "Kolonjska voda",
          "description": "",
          "handle": "za-muskarce-kolonjska-voda",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4WXV52RYH206J4P8TD0",
          "rank": 0,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK9HCGW48V1ZX8CX0X3M1",
          "created_at": "2024-01-03T14:45:48.202Z",
          "updated_at": "2024-01-03T14:45:48.202Z",
          "name": "Parfemska voda",
          "description": "",
          "handle": "za-muskarce-parfemska-voda",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4WXV52RYH206J4P8TD0",
          "rank": 1,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK9HQXNW9ZQY35MB9ZY3B",
          "created_at": "2024-01-03T14:45:48.214Z",
          "updated_at": "2024-01-03T14:45:48.214Z",
          "name": "Toaletna voda",
          "description": "",
          "handle": "za-muskarce-toaletna-voda",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4WXV52RYH206J4P8TD0",
          "rank": 2,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK9J40YXPNW9HBHB880VP",
          "created_at": "2024-01-03T14:45:48.227Z",
          "updated_at": "2024-01-03T14:45:48.227Z",
          "name": "Kompleti",
          "description": "",
          "handle": "za-muskarce-kompleti_14099",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4WXV52RYH206J4P8TD0",
          "rank": 3,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKAZX7KTPB76GCBMFEHGP",
          "created_at": "2024-01-03T14:45:49.684Z",
          "updated_at": "2024-01-03T14:45:49.684Z",
          "name": "Aftershave",
          "description": "",
          "handle": "za-muskarce-aftershave",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4WXV52RYH206J4P8TD0",
          "rank": 4,
          "metadata": null
        }
      ],
      "parent_category": {
        "id": "pcat_01HK7WK45CQPDHWCYPEW3W862G",
        "created_at": "2024-01-03T14:45:42.699Z",
        "updated_at": "2024-01-03T14:45:42.699Z",
        "name": "Parfemi i mirisi",
        "description": "",
        "handle": "ljepota-parfemi-i-mirisi",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": null,
        "rank": 6,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKB7TFAVCEGPW7M29E5FC",
      "created_at": "2024-01-03T14:45:49.944Z",
      "updated_at": "2024-01-03T14:45:49.944Z",
      "name": "Futrole",
      "description": "",
      "handle": "pribor-futrole_1024",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK5EWA9P9TTPVVM3FFT1X",
      "rank": 2,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK5EWA9P9TTPVVM3FFT1X",
        "created_at": "2024-01-03T14:45:44.026Z",
        "updated_at": "2024-01-03T14:45:44.026Z",
        "name": "Pribor",
        "description": "",
        "handle": "rucno-brijanje-pribor",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK46D6H08A70HPE8S7SCQ",
        "rank": 0,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKBDZ19NMKFVE43ZZ5J44",
      "created_at": "2024-01-03T14:45:50.141Z",
      "updated_at": "2024-01-03T14:45:50.141Z",
      "name": "Futrole za električne brijače aparate",
      "description": "",
      "handle": "pribor-futrole-za-elektricne-brijace-aparate",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK5GP4MZXQ69EPS0WG1AR",
      "rank": 2,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK5GP4MZXQ69EPS0WG1AR",
        "created_at": "2024-01-03T14:45:44.084Z",
        "updated_at": "2024-01-03T14:45:44.084Z",
        "name": "Pribor",
        "description": "",
        "handle": "elektricni-brijaci-pribor",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK46YG69NRB3HQKNMNZ0C",
        "rank": 0,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK57XS2NQS0EGT358BW0Q",
      "created_at": "2024-01-03T14:45:43.802Z",
      "updated_at": "2024-01-03T14:45:43.802Z",
      "name": "Alati i pribor za šminkanje",
      "description": "",
      "handle": "pribor-i-dodaci-alati-i-pribor-za-sminkanje",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK45W0F9X0VMBBCAVDPWD",
      "rank": 2,
      "metadata": null,
      "category_children": [
        {
          "id": "pcat_01HK7WK9VTHAJTS8VVR8H2VYBE",
          "created_at": "2024-01-03T14:45:48.534Z",
          "updated_at": "2024-01-03T14:45:48.534Z",
          "name": "Četke",
          "description": "",
          "handle": "alati-i-pribor-za-sminkanje-cetke",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK57XS2NQS0EGT358BW0Q",
          "rank": 0,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK9WEZEVWDMWDPK8W460Y",
          "created_at": "2024-01-03T14:45:48.552Z",
          "updated_at": "2024-01-03T14:45:48.552Z",
          "name": "Lice",
          "description": "",
          "handle": "alati-i-pribor-za-sminkanje-lice",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK57XS2NQS0EGT358BW0Q",
          "rank": 1,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK9X10701060SA690EKN6",
          "created_at": "2024-01-03T14:45:48.574Z",
          "updated_at": "2024-01-03T14:45:48.574Z",
          "name": "Oči",
          "description": "",
          "handle": "alati-i-pribor-za-sminkanje-oci",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK57XS2NQS0EGT358BW0Q",
          "rank": 2,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK9XN1FCXSMJJ94EZHJKX",
          "created_at": "2024-01-03T14:45:48.594Z",
          "updated_at": "2024-01-03T14:45:48.594Z",
          "name": "Šiljilo",
          "description": "",
          "handle": "alati-i-pribor-za-sminkanje-siljilo",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK57XS2NQS0EGT358BW0Q",
          "rank": 3,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK9Y73ZGXWQYWY7Z1YXA7",
          "created_at": "2024-01-03T14:45:48.612Z",
          "updated_at": "2024-01-03T14:45:48.612Z",
          "name": "Setovi i kompleti",
          "description": "",
          "handle": "alati-i-pribor-za-sminkanje-setovi-i-kompleti",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK57XS2NQS0EGT358BW0Q",
          "rank": 4,
          "metadata": null
        }
      ],
      "parent_category": {
        "id": "pcat_01HK7WK45W0F9X0VMBBCAVDPWD",
        "created_at": "2024-01-03T14:45:42.715Z",
        "updated_at": "2024-01-03T14:45:42.715Z",
        "name": "Pribor i dodaci",
        "description": "",
        "handle": "ljepota-pribor-i-dodaci",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": null,
        "rank": 7,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK8Y0ARX160AXR6V3VYXW",
      "created_at": "2024-01-03T14:45:47.583Z",
      "updated_at": "2024-01-03T14:45:47.583Z",
      "name": "Grickalica za nokte",
      "description": "",
      "handle": "pribor-i-dodaci-grickalica-za-nokte",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4Q9J4VMNM7KX6F1K8RQ",
      "rank": 2,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4Q9J4VMNM7KX6F1K8RQ",
        "created_at": "2024-01-03T14:45:43.271Z",
        "updated_at": "2024-01-03T14:45:43.271Z",
        "name": "Pribor i dodaci",
        "description": "",
        "handle": "manikura-i-pedikura-pribor-i-dodaci",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK43VEK8SG6M4T81C3W37",
        "rank": 3,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKCGYG037A88V70DA0PTK",
      "created_at": "2024-01-03T14:45:51.260Z",
      "updated_at": "2024-01-03T14:45:51.260Z",
      "name": "Olovke za nokte",
      "description": "",
      "handle": "pribor-olovke-za-nokte",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK8TNZ6RWPNFYABFMKJ6W",
      "rank": 2,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK8TNZ6RWPNFYABFMKJ6W",
        "created_at": "2024-01-03T14:45:47.475Z",
        "updated_at": "2024-01-03T14:45:47.475Z",
        "name": "Pribor",
        "description": "",
        "handle": "dizajni-za-nokte-pribor",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK4PDYPCE7H5D1FAFMHNK",
        "rank": 7,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK78QJ6WPTT3VV6C3V55Y",
      "created_at": "2024-01-03T14:45:45.877Z",
      "updated_at": "2024-01-03T14:45:45.877Z",
      "name": "Gumice za kosu",
      "description": "",
      "handle": "pribor-za-friziranje-gumice-za-kosu",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4JRZ92REX0CX4M9JWEM",
      "rank": 2,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4JRZ92REX0CX4M9JWEM",
        "created_at": "2024-01-03T14:45:43.125Z",
        "updated_at": "2024-01-03T14:45:43.125Z",
        "name": "Pribor za friziranje",
        "description": "",
        "handle": "njega-kose-pribor-za-friziranje",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK43BQJST1XZ18R4BST01",
        "rank": 0,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK8PZVVDJHX69MTF1P8YP",
      "created_at": "2024-01-03T14:45:47.357Z",
      "updated_at": "2024-01-03T14:45:47.357Z",
      "name": "Britvice",
      "description": "",
      "handle": "pribor-za-sisanje-britvice",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4NHTG89E1DN79HZD0W5",
      "rank": 2,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4NHTG89E1DN79HZD0W5",
        "created_at": "2024-01-03T14:45:43.215Z",
        "updated_at": "2024-01-03T14:45:43.215Z",
        "name": "Pribor za šišanje",
        "description": "",
        "handle": "njega-kose-pribor-za-sisanje",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK43BQJST1XZ18R4BST01",
        "rank": 6,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKCESF4BQW27NQ6GV472A",
      "created_at": "2024-01-03T14:45:51.191Z",
      "updated_at": "2024-01-03T14:45:51.191Z",
      "name": "Naljepnice i samoljepljivi artikli",
      "description": "",
      "handle": "pribor-za-ukrasavanje-noktiju-naljepnice-i-samoljepljivi-artikli",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK8QT38HTXECJJZ3XSY34",
      "rank": 2,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK8QT38HTXECJJZ3XSY34",
        "created_at": "2024-01-03T14:45:47.384Z",
        "updated_at": "2024-01-03T14:45:47.384Z",
        "name": "Pribor za ukrašavanje noktiju",
        "description": "",
        "handle": "dizajni-za-nokte-pribor-za-ukrasavanje-noktiju",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK4PDYPCE7H5D1FAFMHNK",
        "rank": 0,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK84D4F00TWPCJT71W43V",
      "created_at": "2024-01-03T14:45:46.763Z",
      "updated_at": "2024-01-03T14:45:46.763Z",
      "name": "Gelovi",
      "description": "",
      "handle": "proizvodi-za-friziranje-gelovi",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4MPDDG329H66PT1KDYF",
      "rank": 2,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4MPDDG329H66PT1KDYF",
        "created_at": "2024-01-03T14:45:43.187Z",
        "updated_at": "2024-01-03T14:45:43.187Z",
        "name": "Proizvodi za friziranje",
        "description": "",
        "handle": "njega-kose-proizvodi-za-friziranje",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK43BQJST1XZ18R4BST01",
        "rank": 4,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK89WEG4QS2Y36N8HX9HG",
      "created_at": "2024-01-03T14:45:46.937Z",
      "updated_at": "2024-01-03T14:45:46.937Z",
      "name": "Ulja za kosu",
      "description": "",
      "handle": "proizvodi-za-njegu-kose-ulja-za-kosu",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4N31HG0EQ98AA4ZHV8C",
      "rank": 2,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4N31HG0EQ98AA4ZHV8C",
        "created_at": "2024-01-03T14:45:43.201Z",
        "updated_at": "2024-01-03T14:45:43.201Z",
        "name": "Proizvodi za njegu kose",
        "description": "",
        "handle": "njega-kose-proizvodi-za-njegu-kose",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK43BQJST1XZ18R4BST01",
        "rank": 5,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK5G6G274F8G2WFZ816SN",
      "created_at": "2024-01-03T14:45:44.067Z",
      "updated_at": "2024-01-03T14:45:44.067Z",
      "name": "Za žene",
      "description": "",
      "handle": "rucno-brijanje-za-zene",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK46D6H08A70HPE8S7SCQ",
      "rank": 2,
      "metadata": null,
      "category_children": [
        {
          "id": "pcat_01HK7WKBAFT89YDBHRXCD8E2Y0",
          "created_at": "2024-01-03T14:45:50.028Z",
          "updated_at": "2024-01-03T14:45:50.028Z",
          "name": "Britvice za brijanje za žene",
          "description": "",
          "handle": "za-zene-britvice-za-brijanje-za-zene",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK5G6G274F8G2WFZ816SN",
          "rank": 0,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKBCC7WWVFJYCAX70VV1N",
          "created_at": "2024-01-03T14:45:50.088Z",
          "updated_at": "2024-01-03T14:45:50.088Z",
          "name": "Aparati za brijanje za žene",
          "description": "",
          "handle": "za-zene-aparati-za-brijanje-za-zene",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK5G6G274F8G2WFZ816SN",
          "rank": 1,
          "metadata": null
        }
      ],
      "parent_category": {
        "id": "pcat_01HK7WK46D6H08A70HPE8S7SCQ",
        "created_at": "2024-01-03T14:45:42.731Z",
        "updated_at": "2024-01-03T14:45:42.731Z",
        "name": "Ručno brijanje",
        "description": "",
        "handle": "brijanje-i-depilacija-rucno-brijanje",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK41MNZ3C795B2JN5JW0G",
        "rank": 0,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK71TRQVYXWGWWQ1MNY7C",
      "created_at": "2024-01-03T14:45:45.656Z",
      "updated_at": "2024-01-03T14:45:45.656Z",
      "name": "Kupke za stopala",
      "description": "",
      "handle": "ruke-i-stopala-kupke-za-stopala",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4G7CJB2WFRCRPS0131Q",
      "rank": 2,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4G7CJB2WFRCRPS0131Q",
        "created_at": "2024-01-03T14:45:43.032Z",
        "updated_at": "2024-01-03T14:45:43.032Z",
        "name": "Ruke i stopala",
        "description": "",
        "handle": "njega-koze-ruke-i-stopala",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK42VB8WYE6GKG3FEJ41V",
        "rank": 5,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKAJZDYY5FJZ78DDXRH4P",
      "created_at": "2024-01-03T14:45:49.272Z",
      "updated_at": "2024-01-03T14:45:49.272Z",
      "name": "Pilinzi",
      "description": "",
      "handle": "ruke-i-stopala-pilinzi_20964",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK5ARJD927HV0B1E6F91V",
      "rank": 2,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK5ARJD927HV0B1E6F91V",
        "created_at": "2024-01-03T14:45:43.893Z",
        "updated_at": "2024-01-03T14:45:43.893Z",
        "name": "Ruke i stopala",
        "description": "",
        "handle": "manikura-i-pedikura-ruke-i-stopala",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK43VEK8SG6M4T81C3W37",
        "rank": 4,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK5K56WX2G7FYJSMVB9G6",
      "created_at": "2024-01-03T14:45:44.163Z",
      "updated_at": "2024-01-03T14:45:44.163Z",
      "name": "Setovi i kompleti",
      "description": "",
      "handle": "skare-za-kosu-brijaci-i-aparati-za-brijanje-tijela-setovi-i-kompleti",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK47G3STXHFMNMZ4MMQ7G",
      "rank": 2,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK47G3STXHFMNMZ4MMQ7G",
        "created_at": "2024-01-03T14:45:42.766Z",
        "updated_at": "2024-01-03T14:45:42.766Z",
        "name": "Škare za kosu, brijači i aparati za brijanje tijela",
        "description": "",
        "handle": "brijanje-i-depilacija-skare-za-kosu-brijaci-i-aparati-za-brijanje-tijela",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK41MNZ3C795B2JN5JW0G",
        "rank": 2,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK4S01M9B9XAM3VHQQC3N",
      "created_at": "2024-01-03T14:45:43.324Z",
      "updated_at": "2024-01-03T14:45:43.324Z",
      "name": "Setovi za šminkanje",
      "description": "",
      "handle": "sminka-setovi-za-sminkanje",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4495D212GWWBGHX6D9H",
      "rank": 2,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4495D212GWWBGHX6D9H",
        "created_at": "2024-01-03T14:45:42.665Z",
        "updated_at": "2024-01-03T14:45:42.665Z",
        "name": "Šminka",
        "description": "",
        "handle": "ljepota-sminka",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": null,
        "rank": 5,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKCB2999ANAF94BD7ZQYC",
      "created_at": "2024-01-03T14:45:51.070Z",
      "updated_at": "2024-01-03T14:45:51.070Z",
      "name": "Solarni filtri za usne",
      "description": "",
      "handle": "solarni-filtri-solarni-filtri-za-usne",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK6ZJ0V95JGS57SVR77VS",
      "rank": 2,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK6ZJ0V95JGS57SVR77VS",
        "created_at": "2024-01-03T14:45:45.584Z",
        "updated_at": "2024-01-03T14:45:45.584Z",
        "name": "Solarni filtri",
        "description": "",
        "handle": "njega-koze-suncem-i-tamnjenjem-solarni-filtri",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK4E2GJJTAKSG9K2YD30C",
        "rank": 3,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKD4X79FPVNW40K5YWR9K",
      "created_at": "2024-01-03T14:45:51.899Z",
      "updated_at": "2024-01-03T14:45:51.899Z",
      "name": "Solarni filtri za usne",
      "description": "",
      "handle": "solarni-filtri-solarni-filtri-za-usne_25063",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WKA8MEFNXMV7G5QH0DGE2",
      "rank": 2,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WKA8MEFNXMV7G5QH0DGE2",
        "created_at": "2024-01-03T14:45:48.945Z",
        "updated_at": "2024-01-03T14:45:48.945Z",
        "name": "Solarni filtri",
        "description": "",
        "handle": "kreme-za-suncanje-i-tamnjenje-solarni-filtri",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK59M5WTCRNSKRG8JBN56",
        "rank": 2,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK97BH9JQSNRK7NH98XRD",
      "created_at": "2024-01-03T14:45:47.879Z",
      "updated_at": "2024-01-03T14:45:47.879Z",
      "name": "Oprema za tetoviranje",
      "description": "",
      "handle": "tijelo-oprema-za-tetoviranje",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4RBTHMFSNA47WTF4D6J",
      "rank": 2,
      "metadata": null,
      "category_children": [
        {
          "id": "pcat_01HK7WKCNYWC66PZGHBF0589MS",
          "created_at": "2024-01-03T14:45:51.418Z",
          "updated_at": "2024-01-03T14:45:51.418Z",
          "name": "Igle",
          "description": "",
          "handle": "oprema-za-tetoviranje-igle",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK97BH9JQSNRK7NH98XRD",
          "rank": 0,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKCPPXF9W79F824GJ00GH",
          "created_at": "2024-01-03T14:45:51.444Z",
          "updated_at": "2024-01-03T14:45:51.444Z",
          "name": "Papiri",
          "description": "",
          "handle": "papiri_11726",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK97BH9JQSNRK7NH98XRD",
          "rank": 1,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKCQ6P3AB7MR7NPKAT3NA",
          "created_at": "2024-01-03T14:45:51.459Z",
          "updated_at": "2024-01-03T14:45:51.459Z",
          "name": "Post-tretmani za kožu",
          "description": "",
          "handle": "oprema-za-tetoviranje-post-tretmani-za-kozu",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK97BH9JQSNRK7NH98XRD",
          "rank": 2,
          "metadata": null
        }
      ],
      "parent_category": {
        "id": "pcat_01HK7WK4RBTHMFSNA47WTF4D6J",
        "created_at": "2024-01-03T14:45:43.304Z",
        "updated_at": "2024-01-03T14:45:43.304Z",
        "name": "Tijelo",
        "description": "",
        "handle": "sminka-tijelo_11713",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK4495D212GWWBGHX6D9H",
        "rank": 1,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK6WTY0BZWKZWG5BK92W2",
      "created_at": "2024-01-03T14:45:45.496Z",
      "updated_at": "2024-01-03T14:45:45.496Z",
      "name": "Puderi od talka",
      "description": "",
      "handle": "tijelo-puderi-od-talka",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4DMG2Q4J3RMF32RA7Y1",
      "rank": 2,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4DMG2Q4J3RMF32RA7Y1",
        "created_at": "2024-01-03T14:45:42.962Z",
        "updated_at": "2024-01-03T14:45:42.962Z",
        "name": "Tijelo",
        "description": "",
        "handle": "njega-koze-tijelo_5355",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK42VB8WYE6GKG3FEJ41V",
        "rank": 2,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKB2382WX727PJZFC2ND0",
      "created_at": "2024-01-03T14:45:49.759Z",
      "updated_at": "2024-01-03T14:45:49.759Z",
      "name": "Putne boce i spremnici",
      "description": "",
      "handle": "torbe-i-futrole-putne-boce-i-spremnici",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK571RGN4X4BE7Q8XRZ7N",
      "rank": 2,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK571RGN4X4BE7Q8XRZ7N",
        "created_at": "2024-01-03T14:45:43.774Z",
        "updated_at": "2024-01-03T14:45:43.774Z",
        "name": "Torbe i futrole",
        "description": "",
        "handle": "pribor-i-dodaci-torbe-i-futrole",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK45W0F9X0VMBBCAVDPWD",
        "rank": 1,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK8WE6D6F0X72MCV40EQ7",
      "created_at": "2024-01-03T14:45:47.532Z",
      "updated_at": "2024-01-03T14:45:47.532Z",
      "name": "Sredstva za popravak noktiju",
      "description": "",
      "handle": "tretmani-za-nokte-sredstva-za-popravak-noktiju",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4PWXATZSWB566TB87WB",
      "rank": 2,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4PWXATZSWB566TB87WB",
        "created_at": "2024-01-03T14:45:43.258Z",
        "updated_at": "2024-01-03T14:45:43.258Z",
        "name": "Tretmani za nokte",
        "description": "",
        "handle": "manikura-i-pedikura-tretmani-za-nokte",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK43VEK8SG6M4T81C3W37",
        "rank": 2,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK65RQ00RY9H3YFT5TEA6",
      "created_at": "2024-01-03T14:45:44.751Z",
      "updated_at": "2024-01-03T14:45:44.751Z",
      "name": "Depilatori s impulsnim svjetlom",
      "description": "",
      "handle": "uklanjanje-dlaka-depilatori-s-impulsnim-svjetlom",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK493C3GNJNECHNPJ0K2N",
      "rank": 2,
      "metadata": null,
      "category_children": [
        {
          "id": "pcat_01HK7WKBQ7QX0P63QRMDM7VXYW",
          "created_at": "2024-01-03T14:45:50.437Z",
          "updated_at": "2024-01-03T14:45:50.437Z",
          "name": "Zamjenski ulošci za epilatore s impulsnim svjetlom",
          "description": "",
          "handle": "depilatori-s-impulsnim-svjetlom-zamjenski-ulosci-za-epilatore-s-impulsnim-svjetlom",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK65RQ00RY9H3YFT5TEA6",
          "rank": 0,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKBQRSS3YT16BXKZ2VX4C",
          "created_at": "2024-01-03T14:45:50.450Z",
          "updated_at": "2024-01-03T14:45:50.450Z",
          "name": "Impulsni depilacijski sustavi",
          "description": "",
          "handle": "depilatori-s-impulsnim-svjetlom-impulsni-depilacijski-sustavi",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK65RQ00RY9H3YFT5TEA6",
          "rank": 1,
          "metadata": null
        }
      ],
      "parent_category": {
        "id": "pcat_01HK7WK493C3GNJNECHNPJ0K2N",
        "created_at": "2024-01-03T14:45:42.817Z",
        "updated_at": "2024-01-03T14:45:42.817Z",
        "name": "Uklanjanje dlaka",
        "description": "",
        "handle": "brijanje-i-depilacija-uklanjanje-dlaka",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK41MNZ3C795B2JN5JW0G",
        "rank": 5,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKABW287XCMZ1EYHB8F13",
      "created_at": "2024-01-03T14:45:49.049Z",
      "updated_at": "2024-01-03T14:45:49.049Z",
      "name": "Ekstenzije za kosu",
      "description": "",
      "handle": "umeci-za-kosu-perike-i-dodaci-ekstenzije-za-kosu_20952",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK5A5XWEMK8CFBX8HWCSH",
      "rank": 2,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK5A5XWEMK8CFBX8HWCSH",
        "created_at": "2024-01-03T14:45:43.874Z",
        "updated_at": "2024-01-03T14:45:43.874Z",
        "name": "Umeci za kosu, perike i dodaci",
        "description": "",
        "handle": "njega-kose-umeci-za-kosu-perike-i-dodaci",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK43BQJST1XZ18R4BST01",
        "rank": 7,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKCMJS79KBV0S8Q9BGYNQ",
      "created_at": "2024-01-03T14:45:51.376Z",
      "updated_at": "2024-01-03T14:45:51.376Z",
      "name": "Šablone za nokte",
      "description": "",
      "handle": "umjetni-nokti-i-pribor-sablone-za-nokte",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK8V224HR9CV9810ZE5S6",
      "rank": 2,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK8V224HR9CV9810ZE5S6",
        "created_at": "2024-01-03T14:45:47.487Z",
        "updated_at": "2024-01-03T14:45:47.487Z",
        "name": "Umjetni nokti i pribor",
        "description": "",
        "handle": "dizajni-za-nokte-umjetni-nokti-i-pribor",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK4PDYPCE7H5D1FAFMHNK",
        "rank": 8,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK9A205JTD2EGFB0N574X",
      "created_at": "2024-01-03T14:45:47.968Z",
      "updated_at": "2024-01-03T14:45:47.968Z",
      "name": "Olovke za usne",
      "description": "",
      "handle": "usne-olovke-za-usne",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4SPEA6NYM5SZ5DDC5A8",
      "rank": 2,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4SPEA6NYM5SZ5DDC5A8",
        "created_at": "2024-01-03T14:45:43.348Z",
        "updated_at": "2024-01-03T14:45:43.348Z",
        "name": "Usne",
        "description": "",
        "handle": "sminka-usne_11739",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK4495D212GWWBGHX6D9H",
        "rank": 3,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKC51H2YS26A8NTWHZ7SZ",
      "created_at": "2024-01-03T14:45:50.874Z",
      "updated_at": "2024-01-03T14:45:50.874Z",
      "name": "Losioni za tijelo",
      "description": "",
      "handle": "za-hidrataciju-losioni-za-tijelo",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK6WA7CDBMWAWAHDXNKA8",
      "rank": 2,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK6WA7CDBMWAWAHDXNKA8",
        "created_at": "2024-01-03T14:45:45.480Z",
        "updated_at": "2024-01-03T14:45:45.480Z",
        "name": "Za hidrataciju",
        "description": "",
        "handle": "tijelo-za-hidrataciju",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK4DMG2Q4J3RMF32RA7Y1",
        "rank": 1,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKBT02X37RFAWVR4X3017",
      "created_at": "2024-01-03T14:45:50.525Z",
      "updated_at": "2024-01-03T14:45:50.525Z",
      "name": "Sprejevi i maglice",
      "description": "",
      "handle": "za-hidrataciju-sprejevi-i-maglice",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK6SZXFJCA60BBDC8S5W7",
      "rank": 2,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK6SZXFJCA60BBDC8S5W7",
        "created_at": "2024-01-03T14:45:45.388Z",
        "updated_at": "2024-01-03T14:45:45.388Z",
        "name": "Za hidrataciju",
        "description": "",
        "handle": "lice-za-hidrataciju",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK4CQ8JZ3NZ6QJC2MQW11",
        "rank": 1,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKB9WAPVFWJM7ZX13CCKT",
      "created_at": "2024-01-03T14:45:50.009Z",
      "updated_at": "2024-01-03T14:45:50.009Z",
      "name": "Aparati za brijanje za muškarce",
      "description": "",
      "handle": "za-muskarce-aparati-za-brijanje-za-muskarce",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK5FHHQR6TNHWK6MYMBRV",
      "rank": 2,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK5FHHQR6TNHWK6MYMBRV",
        "created_at": "2024-01-03T14:45:44.044Z",
        "updated_at": "2024-01-03T14:45:44.044Z",
        "name": "Za muškarce",
        "description": "",
        "handle": "rucno-brijanje-za-muskarce",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK46D6H08A70HPE8S7SCQ",
        "rank": 1,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK9HQXNW9ZQY35MB9ZY3B",
      "created_at": "2024-01-03T14:45:48.214Z",
      "updated_at": "2024-01-03T14:45:48.214Z",
      "name": "Toaletna voda",
      "description": "",
      "handle": "za-muskarce-toaletna-voda",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4WXV52RYH206J4P8TD0",
      "rank": 2,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4WXV52RYH206J4P8TD0",
        "created_at": "2024-01-03T14:45:43.446Z",
        "updated_at": "2024-01-03T14:45:43.446Z",
        "name": "Za muškarce",
        "description": "",
        "handle": "parfemi-i-mirisi-za-muskarce_14094",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK45CQPDHWCYPEW3W862G",
        "rank": 2,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK9KC5DQVH6DCRFJEW3H9",
      "created_at": "2024-01-03T14:45:48.265Z",
      "updated_at": "2024-01-03T14:45:48.265Z",
      "name": "Parfemska voda",
      "description": "",
      "handle": "za-zene-parfemska-voda",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4YNQSS786T0PHYXE7FP",
      "rank": 2,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4YNQSS786T0PHYXE7FP",
        "created_at": "2024-01-03T14:45:43.492Z",
        "updated_at": "2024-01-03T14:45:43.492Z",
        "name": "Za žene",
        "description": "",
        "handle": "parfemi-i-mirisi-za-zene_14100",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK45CQPDHWCYPEW3W862G",
        "rank": 3,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK9XN1FCXSMJJ94EZHJKX",
      "created_at": "2024-01-03T14:45:48.594Z",
      "updated_at": "2024-01-03T14:45:48.594Z",
      "name": "Šiljilo",
      "description": "",
      "handle": "alati-i-pribor-za-sminkanje-siljilo",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK57XS2NQS0EGT358BW0Q",
      "rank": 3,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK57XS2NQS0EGT358BW0Q",
        "created_at": "2024-01-03T14:45:43.802Z",
        "updated_at": "2024-01-03T14:45:43.802Z",
        "name": "Alati i pribor za šminkanje",
        "description": "",
        "handle": "pribor-i-dodaci-alati-i-pribor-za-sminkanje",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK45W0F9X0VMBBCAVDPWD",
        "rank": 2,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK7JKEMZVYXR89QH3DFGB",
      "created_at": "2024-01-03T14:45:46.193Z",
      "updated_at": "2024-01-03T14:45:46.193Z",
      "name": "Polutrajno bojanje",
      "description": "",
      "handle": "bojanje-kose-polutrajno-bojanje",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4KQVZQK6XPGZJEJ50QZ",
      "rank": 3,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4KQVZQK6XPGZJEJ50QZ",
        "created_at": "2024-01-03T14:45:43.157Z",
        "updated_at": "2024-01-03T14:45:43.157Z",
        "name": "Bojanje kose",
        "description": "",
        "handle": "njega-kose-bojanje-kose",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK43BQJST1XZ18R4BST01",
        "rank": 2,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK481HCTWDV2XH5M4VNWK",
      "created_at": "2024-01-03T14:45:42.782Z",
      "updated_at": "2024-01-03T14:45:42.782Z",
      "name": "Njega nakon depilacije i brijanja",
      "description": "",
      "handle": "brijanje-i-depilacija-njega-nakon-depilacije-i-brijanja",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK41MNZ3C795B2JN5JW0G",
      "rank": 3,
      "metadata": null,
      "category_children": [
        {
          "id": "pcat_01HK7WK5NFMQG8XVM3BKEG546T",
          "created_at": "2024-01-03T14:45:44.237Z",
          "updated_at": "2024-01-03T14:45:44.237Z",
          "name": "Aftershave",
          "description": "",
          "handle": "njega-nakon-depilacije-i-brijanja-aftershave",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK481HCTWDV2XH5M4VNWK",
          "rank": 0,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK5PN4ENAM693RZNNY6AM",
          "created_at": "2024-01-03T14:45:44.270Z",
          "updated_at": "2024-01-03T14:45:44.270Z",
          "name": "Adstringenti",
          "description": "",
          "handle": "njega-nakon-depilacije-i-brijanja-adstringenti",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK481HCTWDV2XH5M4VNWK",
          "rank": 1,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK5R1C9F1FFN0DMBHGWNN",
          "created_at": "2024-01-03T14:45:44.313Z",
          "updated_at": "2024-01-03T14:45:44.313Z",
          "name": "Balzami",
          "description": "",
          "handle": "njega-nakon-depilacije-i-brijanja-balzami",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK481HCTWDV2XH5M4VNWK",
          "rank": 2,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK5SKZ0FM7YPWSBXYKW3W",
          "created_at": "2024-01-03T14:45:44.361Z",
          "updated_at": "2024-01-03T14:45:44.361Z",
          "name": "Gelovi",
          "description": "",
          "handle": "njega-nakon-depilacije-i-brijanja-gelovi",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK481HCTWDV2XH5M4VNWK",
          "rank": 3,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK5TZKHSQEEQKD1SY69AP",
          "created_at": "2024-01-03T14:45:44.408Z",
          "updated_at": "2024-01-03T14:45:44.408Z",
          "name": "Kompleti",
          "description": "",
          "handle": "njega-nakon-depilacije-i-brijanja-kompleti",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK481HCTWDV2XH5M4VNWK",
          "rank": 4,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK5WNGRG0H1EG4EMKCCQN",
          "created_at": "2024-01-03T14:45:44.454Z",
          "updated_at": "2024-01-03T14:45:44.454Z",
          "name": "Losioni i tekućine",
          "description": "",
          "handle": "njega-nakon-depilacije-i-brijanja-losioni-i-tekucine",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK481HCTWDV2XH5M4VNWK",
          "rank": 5,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK5XS1KQFQF6QTSCGX6KZ",
          "created_at": "2024-01-03T14:45:44.500Z",
          "updated_at": "2024-01-03T14:45:44.500Z",
          "name": "Tretmani za urasle dlake",
          "description": "",
          "handle": "njega-nakon-depilacije-i-brijanja-tretmani-za-urasle-dlake",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK481HCTWDV2XH5M4VNWK",
          "rank": 6,
          "metadata": null
        }
      ],
      "parent_category": {
        "id": "pcat_01HK7WK41MNZ3C795B2JN5JW0G",
        "created_at": "2024-01-03T14:45:42.579Z",
        "updated_at": "2024-01-03T14:45:42.579Z",
        "name": "Brijanje i depilacija",
        "description": "",
        "handle": "ljepota-brijanje-i-depilacija",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": null,
        "rank": 0,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKB4GBXA5NY5NKJJMS67V",
      "created_at": "2024-01-03T14:45:49.837Z",
      "updated_at": "2024-01-03T14:45:49.837Z",
      "name": "Lice",
      "description": "",
      "handle": "cetke-i-pribor-za-sminkanje-lice",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK5D0544YXJQT9G60XVED",
      "rank": 3,
      "metadata": null,
      "category_children": [
        {
          "id": "pcat_01HK7WKDCJZ7CJN6M5Z7JA4V0X",
          "created_at": "2024-01-03T14:45:52.144Z",
          "updated_at": "2024-01-03T14:45:52.144Z",
          "name": "Apsorpcijski papir",
          "description": "",
          "handle": "lice-apsorpcijski-papir_25080",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WKB4GBXA5NY5NKJJMS67V",
          "rank": 0,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKDD44H0EWGSA2WEADCGS",
          "created_at": "2024-01-03T14:45:52.161Z",
          "updated_at": "2024-01-03T14:45:52.161Z",
          "name": "Četke",
          "description": "",
          "handle": "lice-cetke_25081",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WKB4GBXA5NY5NKJJMS67V",
          "rank": 1,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKDDJ67061TG9MG1BT2R8",
          "created_at": "2024-01-03T14:45:52.176Z",
          "updated_at": "2024-01-03T14:45:52.176Z",
          "name": "Spužve",
          "description": "",
          "handle": "lice-spuzve_25082",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WKB4GBXA5NY5NKJJMS67V",
          "rank": 2,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKDE127GBD664CYVSHCR8",
          "created_at": "2024-01-03T14:45:52.191Z",
          "updated_at": "2024-01-03T14:45:52.191Z",
          "name": "Aplikatori za prah",
          "description": "",
          "handle": "lice-aplikatori-za-prah_25083",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WKB4GBXA5NY5NKJJMS67V",
          "rank": 3,
          "metadata": null
        }
      ],
      "parent_category": {
        "id": "pcat_01HK7WK5D0544YXJQT9G60XVED",
        "created_at": "2024-01-03T14:45:43.966Z",
        "updated_at": "2024-01-03T14:45:43.966Z",
        "name": "Četke i pribor za šminkanje",
        "description": "",
        "handle": "pribor-i-dodaci-cetke-i-pribor-za-sminkanje",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK45W0F9X0VMBBCAVDPWD",
        "rank": 3,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKD0TXJET8M1N2Q3P1F2S",
      "created_at": "2024-01-03T14:45:51.769Z",
      "updated_at": "2024-01-03T14:45:51.769Z",
      "name": "Jastučići",
      "description": "",
      "handle": "cistaci-jastucici",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WKA2274BVZPY0TZ6SN7Z9",
      "rank": 3,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WKA2274BVZPY0TZ6SN7Z9",
        "created_at": "2024-01-03T14:45:48.735Z",
        "updated_at": "2024-01-03T14:45:48.735Z",
        "name": "Čistači",
        "description": "",
        "handle": "lice-cistaci_20934",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK4CQ8JZ3NZ6QJC2MQW11",
        "rank": 5,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKBN25F933TR64F1VERPV",
      "created_at": "2024-01-03T14:45:50.365Z",
      "updated_at": "2024-01-03T14:45:50.365Z",
      "name": "Lopatice",
      "description": "",
      "handle": "depilacija-voskom-lopatice",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK649EWGYVF36CJ6071RM",
      "rank": 3,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK649EWGYVF36CJ6071RM",
        "created_at": "2024-01-03T14:45:44.706Z",
        "updated_at": "2024-01-03T14:45:44.706Z",
        "name": "Depilacija voskom",
        "description": "",
        "handle": "uklanjanje-dlaka-depilacija-voskom",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK493C3GNJNECHNPJ0K2N",
        "rank": 1,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK8S16QZ3Y71X9HD1TV7C",
      "created_at": "2024-01-03T14:45:47.423Z",
      "updated_at": "2024-01-03T14:45:47.423Z",
      "name": "Lakovi",
      "description": "",
      "handle": "dizajni-za-nokte-lakovi",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4PDYPCE7H5D1FAFMHNK",
      "rank": 3,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4PDYPCE7H5D1FAFMHNK",
        "created_at": "2024-01-03T14:45:43.244Z",
        "updated_at": "2024-01-03T14:45:43.244Z",
        "name": "Dizajni za nokte",
        "description": "",
        "handle": "manikura-i-pedikura-dizajni-za-nokte",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK43VEK8SG6M4T81C3W37",
        "rank": 1,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK6M13H9R1A08CBJS555M",
      "created_at": "2024-01-03T14:45:45.214Z",
      "updated_at": "2024-01-03T14:45:45.214Z",
      "name": "Biseri i pahuljice za kupku",
      "description": "",
      "handle": "dodaci-za-kupke-biseri-i-pahuljice-za-kupku",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4AMW72YGS9N8YSBWJ37",
      "rank": 3,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4AMW72YGS9N8YSBWJ37",
        "created_at": "2024-01-03T14:45:42.866Z",
        "updated_at": "2024-01-03T14:45:42.866Z",
        "name": "Dodaci za kupke",
        "description": "",
        "handle": "kupanje-i-osobna-higijena-dodaci-za-kupke",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK42BQ7XFW617X5XP4BAR",
        "rank": 1,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKCY2QKJVW65BK9CCQK8R",
      "created_at": "2024-01-03T14:45:51.679Z",
      "updated_at": "2024-01-03T14:45:51.679Z",
      "name": "Zamjenske oštrice",
      "description": "",
      "handle": "elektricni-brijaci-za-muskarce-zamjenske-ostrice",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK5H8GFDPH0Z1TTQXAYJQ",
      "rank": 3,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK5H8GFDPH0Z1TTQXAYJQ",
        "created_at": "2024-01-03T14:45:44.101Z",
        "updated_at": "2024-01-03T14:45:44.101Z",
        "name": "Električni brijači za muškarce",
        "description": "",
        "handle": "elektricni-brijaci-elektricni-brijaci-za-muskarce",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK46YG69NRB3HQKNMNZ0C",
        "rank": 1,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK7YHFCNSXK55Q1NATYXT",
      "created_at": "2024-01-03T14:45:46.570Z",
      "updated_at": "2024-01-03T14:45:46.570Z",
      "name": "Metle i četke",
      "description": "",
      "handle": "frizerski-namjestaj-i-potrepstine-metle-i-cetke",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4M7JE8S0KGQCMWF47WJ",
      "rank": 3,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4M7JE8S0KGQCMWF47WJ",
        "created_at": "2024-01-03T14:45:43.173Z",
        "updated_at": "2024-01-03T14:45:43.173Z",
        "name": "Frizerski namještaj i potrepštine",
        "description": "",
        "handle": "njega-kose-frizerski-namjestaj-i-potrepstine",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK43BQJST1XZ18R4BST01",
        "rank": 3,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK7CX65MT0BE6ES2N35XX",
      "created_at": "2024-01-03T14:45:46.011Z",
      "updated_at": "2024-01-03T14:45:46.011Z",
      "name": "Višenamjenski kalupi",
      "description": "",
      "handle": "frizerski-uredaji-i-pribor-visenamjenski-kalupi",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4K84XGGV7XG3TFC6W69",
      "rank": 3,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4K84XGGV7XG3TFC6W69",
        "created_at": "2024-01-03T14:45:43.141Z",
        "updated_at": "2024-01-03T14:45:43.141Z",
        "name": "Frizerski uređaji i pribor",
        "description": "",
        "handle": "njega-kose-frizerski-uredaji-i-pribor",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK43BQJST1XZ18R4BST01",
        "rank": 1,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK4BPJ14F2XFATFDQBNTJ",
      "created_at": "2024-01-03T14:45:42.900Z",
      "updated_at": "2024-01-03T14:45:42.900Z",
      "name": "Kompleti",
      "description": "",
      "handle": "kupanje-i-osobna-higijena-kompleti",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK42BQ7XFW617X5XP4BAR",
      "rank": 3,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK42BQ7XFW617X5XP4BAR",
        "created_at": "2024-01-03T14:45:42.602Z",
        "updated_at": "2024-01-03T14:45:42.602Z",
        "name": "Kupanje i osobna higijena",
        "description": "",
        "handle": "ljepota-kupanje-i-osobna-higijena",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": null,
        "rank": 1,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK6H8PGVT1S2QMK2XZRAG",
      "created_at": "2024-01-03T14:45:45.124Z",
      "updated_at": "2024-01-03T14:45:45.124Z",
      "name": "Kape za tuširanje",
      "description": "",
      "handle": "kupaonski-pribor-kape-za-tusiranje_2169",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4A2XE81CJFBQCJE5EC2",
      "rank": 3,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4A2XE81CJFBQCJE5EC2",
        "created_at": "2024-01-03T14:45:42.848Z",
        "updated_at": "2024-01-03T14:45:42.848Z",
        "name": "Kupaonski pribor",
        "description": "",
        "handle": "kupanje-i-osobna-higijena-kupaonski-pribor",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK42BQ7XFW617X5XP4BAR",
        "rank": 0,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKDE127GBD664CYVSHCR8",
      "created_at": "2024-01-03T14:45:52.191Z",
      "updated_at": "2024-01-03T14:45:52.191Z",
      "name": "Aplikatori za prah",
      "description": "",
      "handle": "lice-aplikatori-za-prah_25083",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WKB4GBXA5NY5NKJJMS67V",
      "rank": 3,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WKB4GBXA5NY5NKJJMS67V",
        "created_at": "2024-01-03T14:45:49.837Z",
        "updated_at": "2024-01-03T14:45:49.837Z",
        "name": "Lice",
        "description": "",
        "handle": "cetke-i-pribor-za-sminkanje-lice",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK5D0544YXJQT9G60XVED",
        "rank": 3,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKCTZQFKC9GDQ5Z161NWM",
      "created_at": "2024-01-03T14:45:51.581Z",
      "updated_at": "2024-01-03T14:45:51.581Z",
      "name": "Apsorpcijski papir",
      "description": "",
      "handle": "lice-apsorpcijski-papir",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK9WEZEVWDMWDPK8W460Y",
      "rank": 3,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK9WEZEVWDMWDPK8W460Y",
        "created_at": "2024-01-03T14:45:48.552Z",
        "updated_at": "2024-01-03T14:45:48.552Z",
        "name": "Lice",
        "description": "",
        "handle": "alati-i-pribor-za-sminkanje-lice",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK57XS2NQS0EGT358BW0Q",
        "rank": 1,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK6V1EVEFGH0FNVJKPN1D",
      "created_at": "2024-01-03T14:45:45.439Z",
      "updated_at": "2024-01-03T14:45:45.439Z",
      "name": "Maske",
      "description": "",
      "handle": "lice-maske",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4CQ8JZ3NZ6QJC2MQW11",
      "rank": 3,
      "metadata": null,
      "category_children": [
        {
          "id": "pcat_01HK7WKBYCZ8V7TPP4XFGMJF0G",
          "created_at": "2024-01-03T14:45:50.661Z",
          "updated_at": "2024-01-03T14:45:50.661Z",
          "name": "Opuštajuće antistres maske",
          "description": "",
          "handle": "maske-opustajuce-antistres-maske",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK6V1EVEFGH0FNVJKPN1D",
          "rank": 0,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKBZQ31SBEMQP7AA65H05",
          "created_at": "2024-01-03T14:45:50.704Z",
          "updated_at": "2024-01-03T14:45:50.704Z",
          "name": "Maske za piling i čišćenje",
          "description": "",
          "handle": "maske-maske-za-piling-i-ciscenje",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK6V1EVEFGH0FNVJKPN1D",
          "rank": 1,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKC11373AASRD5VMNA8X7",
          "created_at": "2024-01-03T14:45:50.747Z",
          "updated_at": "2024-01-03T14:45:50.747Z",
          "name": "Hidratantne i pomlađujuće maske",
          "description": "",
          "handle": "maske-hidratantne-i-pomladujuce-maske",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK6V1EVEFGH0FNVJKPN1D",
          "rank": 2,
          "metadata": null
        }
      ],
      "parent_category": {
        "id": "pcat_01HK7WK4CQ8JZ3NZ6QJC2MQW11",
        "created_at": "2024-01-03T14:45:42.933Z",
        "updated_at": "2024-01-03T14:45:42.933Z",
        "name": "Lice",
        "description": "",
        "handle": "njega-koze-lice_5325",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK42VB8WYE6GKG3FEJ41V",
        "rank": 0,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK92W85MN7B2C5P25M51Q",
      "created_at": "2024-01-03T14:45:47.738Z",
      "updated_at": "2024-01-03T14:45:47.738Z",
      "name": "Rumenila",
      "description": "",
      "handle": "lice-rumenila",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4QT18DTEY1R94HB0RRX",
      "rank": 3,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4QT18DTEY1R94HB0RRX",
        "created_at": "2024-01-03T14:45:43.284Z",
        "updated_at": "2024-01-03T14:45:43.284Z",
        "name": "Lice",
        "description": "",
        "handle": "sminka-lice_11703",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK4495D212GWWBGHX6D9H",
        "rank": 0,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK43BQJST1XZ18R4BST01",
      "created_at": "2024-01-03T14:45:42.635Z",
      "updated_at": "2024-01-03T14:45:42.635Z",
      "name": "Njega kose",
      "description": "",
      "handle": "ljepota-njega-kose",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": null,
      "rank": 3,
      "metadata": null,
      "category_children": [
        {
          "id": "pcat_01HK7WK4JRZ92REX0CX4M9JWEM",
          "created_at": "2024-01-03T14:45:43.125Z",
          "updated_at": "2024-01-03T14:45:43.125Z",
          "name": "Pribor za friziranje",
          "description": "",
          "handle": "njega-kose-pribor-za-friziranje",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK43BQJST1XZ18R4BST01",
          "rank": 0,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK4K84XGGV7XG3TFC6W69",
          "created_at": "2024-01-03T14:45:43.141Z",
          "updated_at": "2024-01-03T14:45:43.141Z",
          "name": "Frizerski uređaji i pribor",
          "description": "",
          "handle": "njega-kose-frizerski-uredaji-i-pribor",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK43BQJST1XZ18R4BST01",
          "rank": 1,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK4KQVZQK6XPGZJEJ50QZ",
          "created_at": "2024-01-03T14:45:43.157Z",
          "updated_at": "2024-01-03T14:45:43.157Z",
          "name": "Bojanje kose",
          "description": "",
          "handle": "njega-kose-bojanje-kose",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK43BQJST1XZ18R4BST01",
          "rank": 2,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK4M7JE8S0KGQCMWF47WJ",
          "created_at": "2024-01-03T14:45:43.173Z",
          "updated_at": "2024-01-03T14:45:43.173Z",
          "name": "Frizerski namještaj i potrepštine",
          "description": "",
          "handle": "njega-kose-frizerski-namjestaj-i-potrepstine",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK43BQJST1XZ18R4BST01",
          "rank": 3,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK4MPDDG329H66PT1KDYF",
          "created_at": "2024-01-03T14:45:43.187Z",
          "updated_at": "2024-01-03T14:45:43.187Z",
          "name": "Proizvodi za friziranje",
          "description": "",
          "handle": "njega-kose-proizvodi-za-friziranje",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK43BQJST1XZ18R4BST01",
          "rank": 4,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK4N31HG0EQ98AA4ZHV8C",
          "created_at": "2024-01-03T14:45:43.201Z",
          "updated_at": "2024-01-03T14:45:43.201Z",
          "name": "Proizvodi za njegu kose",
          "description": "",
          "handle": "njega-kose-proizvodi-za-njegu-kose",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK43BQJST1XZ18R4BST01",
          "rank": 5,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK4NHTG89E1DN79HZD0W5",
          "created_at": "2024-01-03T14:45:43.215Z",
          "updated_at": "2024-01-03T14:45:43.215Z",
          "name": "Pribor za šišanje",
          "description": "",
          "handle": "njega-kose-pribor-za-sisanje",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK43BQJST1XZ18R4BST01",
          "rank": 6,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK5A5XWEMK8CFBX8HWCSH",
          "created_at": "2024-01-03T14:45:43.874Z",
          "updated_at": "2024-01-03T14:45:43.874Z",
          "name": "Umeci za kosu, perike i dodaci",
          "description": "",
          "handle": "njega-kose-umeci-za-kosu-perike-i-dodaci",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK43BQJST1XZ18R4BST01",
          "rank": 7,
          "metadata": null
        }
      ],
      "parent_category": null
    },
    {
      "id": "pcat_01HK7WK4Q9J4VMNM7KX6F1K8RQ",
      "created_at": "2024-01-03T14:45:43.271Z",
      "updated_at": "2024-01-03T14:45:43.271Z",
      "name": "Pribor i dodaci",
      "description": "",
      "handle": "manikura-i-pedikura-pribor-i-dodaci",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK43VEK8SG6M4T81C3W37",
      "rank": 3,
      "metadata": null,
      "category_children": [
        {
          "id": "pcat_01HK7WK8X7JG89BQEYJQ7V3NKF",
          "created_at": "2024-01-03T14:45:47.558Z",
          "updated_at": "2024-01-03T14:45:47.558Z",
          "name": "Kliješta za nokte",
          "description": "",
          "handle": "pribor-i-dodaci-klijesta-za-nokte",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4Q9J4VMNM7KX6F1K8RQ",
          "rank": 0,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK8XN5741PFD0K122VF9A",
          "created_at": "2024-01-03T14:45:47.570Z",
          "updated_at": "2024-01-03T14:45:47.570Z",
          "name": "Električni uređaji i pribor za manikuru i pedikuru",
          "description": "",
          "handle": "pribor-i-dodaci-elektricni-uredaji-i-pribor-za-manikuru-i-pedikuru",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4Q9J4VMNM7KX6F1K8RQ",
          "rank": 1,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK8Y0ARX160AXR6V3VYXW",
          "created_at": "2024-01-03T14:45:47.583Z",
          "updated_at": "2024-01-03T14:45:47.583Z",
          "name": "Grickalica za nokte",
          "description": "",
          "handle": "pribor-i-dodaci-grickalica-za-nokte",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4Q9J4VMNM7KX6F1K8RQ",
          "rank": 2,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK8YESAFVYF2K1X59FMJG",
          "created_at": "2024-01-03T14:45:47.596Z",
          "updated_at": "2024-01-03T14:45:47.596Z",
          "name": "Podizači kutikula",
          "description": "",
          "handle": "pribor-i-dodaci-podizaci-kutikula",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4Q9J4VMNM7KX6F1K8RQ",
          "rank": 3,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK8YW9M2BNG96M2719KAT",
          "created_at": "2024-01-03T14:45:47.610Z",
          "updated_at": "2024-01-03T14:45:47.610Z",
          "name": "Rašpice za poliranje",
          "description": "",
          "handle": "pribor-i-dodaci-raspice-za-poliranje",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4Q9J4VMNM7KX6F1K8RQ",
          "rank": 4,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK8Z80QT7MGWR1TJKFQ0E",
          "created_at": "2024-01-03T14:45:47.623Z",
          "updated_at": "2024-01-03T14:45:47.623Z",
          "name": "Rašpice za nokte",
          "description": "",
          "handle": "pribor-i-dodaci-raspice-za-nokte",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4Q9J4VMNM7KX6F1K8RQ",
          "rank": 5,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK8ZN3NYEXYZT4D3JKNNK",
          "created_at": "2024-01-03T14:45:47.635Z",
          "updated_at": "2024-01-03T14:45:47.635Z",
          "name": "Setovi i kompleti",
          "description": "",
          "handle": "pribor-i-dodaci-setovi-i-kompleti",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4Q9J4VMNM7KX6F1K8RQ",
          "rank": 6,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK90JG69AKJ74H3079HVH",
          "created_at": "2024-01-03T14:45:47.663Z",
          "updated_at": "2024-01-03T14:45:47.663Z",
          "name": "Škarice za kutikule",
          "description": "",
          "handle": "pribor-i-dodaci-skarice-za-kutikule",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4Q9J4VMNM7KX6F1K8RQ",
          "rank": 7,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK911XT0QAQ98QPMW77BN",
          "created_at": "2024-01-03T14:45:47.679Z",
          "updated_at": "2024-01-03T14:45:47.679Z",
          "name": "Škarice za nokte",
          "description": "",
          "handle": "pribor-i-dodaci-skarice-za-nokte",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4Q9J4VMNM7KX6F1K8RQ",
          "rank": 8,
          "metadata": null
        }
      ],
      "parent_category": {
        "id": "pcat_01HK7WK43VEK8SG6M4T81C3W37",
        "created_at": "2024-01-03T14:45:42.650Z",
        "updated_at": "2024-01-03T14:45:42.650Z",
        "name": "Manikura i pedikura",
        "description": "",
        "handle": "ljepota-manikura-i-pedikura",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": null,
        "rank": 4,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKCF63J4RPKF4CRRZWHSS",
      "created_at": "2024-01-03T14:45:51.204Z",
      "updated_at": "2024-01-03T14:45:51.204Z",
      "name": "Nakit",
      "description": "",
      "handle": "nakit_11505",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK8QT38HTXECJJZ3XSY34",
      "rank": 3,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK8QT38HTXECJJZ3XSY34",
        "created_at": "2024-01-03T14:45:47.384Z",
        "updated_at": "2024-01-03T14:45:47.384Z",
        "name": "Pribor za ukrašavanje noktiju",
        "description": "",
        "handle": "dizajni-za-nokte-pribor-za-ukrasavanje-noktiju",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK4PDYPCE7H5D1FAFMHNK",
        "rank": 0,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK4M7JE8S0KGQCMWF47WJ",
      "created_at": "2024-01-03T14:45:43.173Z",
      "updated_at": "2024-01-03T14:45:43.173Z",
      "name": "Frizerski namještaj i potrepštine",
      "description": "",
      "handle": "njega-kose-frizerski-namjestaj-i-potrepstine",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK43BQJST1XZ18R4BST01",
      "rank": 3,
      "metadata": null,
      "category_children": [
        {
          "id": "pcat_01HK7WK7TA7H0FK4DBGPW6WKP6",
          "created_at": "2024-01-03T14:45:46.434Z",
          "updated_at": "2024-01-03T14:45:46.434Z",
          "name": "Vreće i kutije za alate",
          "description": "",
          "handle": "frizerski-namjestaj-i-potrepstine-vrece-i-kutije-za-alate",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4M7JE8S0KGQCMWF47WJ",
          "rank": 0,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK7VNMNJ5117D2P2ZD7BR",
          "created_at": "2024-01-03T14:45:46.478Z",
          "updated_at": "2024-01-03T14:45:46.478Z",
          "name": "Frizerski ogrtači",
          "description": "",
          "handle": "frizerski-namjestaj-i-potrepstine-frizerski-ogrtaci",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4M7JE8S0KGQCMWF47WJ",
          "rank": 1,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK7X7WGP1CYG47B60VCHQ",
          "created_at": "2024-01-03T14:45:46.528Z",
          "updated_at": "2024-01-03T14:45:46.528Z",
          "name": "Kolica za održavanje",
          "description": "",
          "handle": "kolica-za-odr-avanje",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4M7JE8S0KGQCMWF47WJ",
          "rank": 2,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK7YHFCNSXK55Q1NATYXT",
          "created_at": "2024-01-03T14:45:46.570Z",
          "updated_at": "2024-01-03T14:45:46.570Z",
          "name": "Metle i četke",
          "description": "",
          "handle": "frizerski-namjestaj-i-potrepstine-metle-i-cetke",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4M7JE8S0KGQCMWF47WJ",
          "rank": 3,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK80A720BF7AXX64YS0R0",
          "created_at": "2024-01-03T14:45:46.625Z",
          "updated_at": "2024-01-03T14:45:46.625Z",
          "name": "Ručna ogledala",
          "description": "",
          "handle": "frizerski-namjestaj-i-potrepstine-rucna-ogledala",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4M7JE8S0KGQCMWF47WJ",
          "rank": 4,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK81M2QBVRFXVNA1JE0VY",
          "created_at": "2024-01-03T14:45:46.671Z",
          "updated_at": "2024-01-03T14:45:46.671Z",
          "name": "Boce s raspršivačem ",
          "description": "",
          "handle": "frizerski-namjestaj-i-potrepstine-boce-s-rasprsivacem",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4M7JE8S0KGQCMWF47WJ",
          "rank": 5,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK82AW43SNS1TEE8BPXDQ",
          "created_at": "2024-01-03T14:45:46.695Z",
          "updated_at": "2024-01-03T14:45:46.695Z",
          "name": "Frizerski lavaboi",
          "description": "",
          "handle": "frizerski-namjestaj-i-potrepstine-frizerski-lavaboi",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4M7JE8S0KGQCMWF47WJ",
          "rank": 6,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK82TW7QZDDN77E663KZ7",
          "created_at": "2024-01-03T14:45:46.712Z",
          "updated_at": "2024-01-03T14:45:46.712Z",
          "name": "Lutke za učenje",
          "description": "",
          "handle": "frizerski-namjestaj-i-potrepstine-lutke-za-ucenje",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4M7JE8S0KGQCMWF47WJ",
          "rank": 7,
          "metadata": null
        }
      ],
      "parent_category": {
        "id": "pcat_01HK7WK43BQJST1XZ18R4BST01",
        "created_at": "2024-01-03T14:45:42.635Z",
        "updated_at": "2024-01-03T14:45:42.635Z",
        "name": "Njega kose",
        "description": "",
        "handle": "ljepota-njega-kose",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": null,
        "rank": 3,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK4E2GJJTAKSG9K2YD30C",
      "created_at": "2024-01-03T14:45:42.976Z",
      "updated_at": "2024-01-03T14:45:42.976Z",
      "name": "Njega kože suncem i tamnjenjem",
      "description": "",
      "handle": "njega-koze-njega-koze-suncem-i-tamnjenjem",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK42VB8WYE6GKG3FEJ41V",
      "rank": 3,
      "metadata": null,
      "category_children": [
        {
          "id": "pcat_01HK7WK6Y7XG165YC5CEFQ3X9Q",
          "created_at": "2024-01-03T14:45:45.541Z",
          "updated_at": "2024-01-03T14:45:45.541Z",
          "name": "Ubrzivači i optimizatori tamnjenja",
          "description": "",
          "handle": "njega-koze-suncem-i-tamnjenjem-ubrzivaci-i-optimizatori-tamnjenja",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4E2GJJTAKSG9K2YD30C",
          "rank": 0,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK6YP8P6FJEVY6EPN5M8D",
          "created_at": "2024-01-03T14:45:45.554Z",
          "updated_at": "2024-01-03T14:45:45.554Z",
          "name": "Poslije sunčanja",
          "description": "",
          "handle": "njega-koze-suncem-i-tamnjenjem-poslije-suncanja",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4E2GJJTAKSG9K2YD30C",
          "rank": 1,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK6Z40CPVFT96RJA3DA69",
          "created_at": "2024-01-03T14:45:45.569Z",
          "updated_at": "2024-01-03T14:45:45.569Z",
          "name": "Sredstva za samotamnjenje",
          "description": "",
          "handle": "njega-koze-suncem-i-tamnjenjem-sredstva-za-samotamnjenje",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4E2GJJTAKSG9K2YD30C",
          "rank": 2,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK6ZJ0V95JGS57SVR77VS",
          "created_at": "2024-01-03T14:45:45.584Z",
          "updated_at": "2024-01-03T14:45:45.584Z",
          "name": "Solarni filtri",
          "description": "",
          "handle": "njega-koze-suncem-i-tamnjenjem-solarni-filtri",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4E2GJJTAKSG9K2YD30C",
          "rank": 3,
          "metadata": null
        }
      ],
      "parent_category": {
        "id": "pcat_01HK7WK42VB8WYE6GKG3FEJ41V",
        "created_at": "2024-01-03T14:45:42.618Z",
        "updated_at": "2024-01-03T14:45:42.618Z",
        "name": "Njega kože",
        "description": "",
        "handle": "ljepota-njega-koze",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": null,
        "rank": 2,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK6ZJ0V95JGS57SVR77VS",
      "created_at": "2024-01-03T14:45:45.584Z",
      "updated_at": "2024-01-03T14:45:45.584Z",
      "name": "Solarni filtri",
      "description": "",
      "handle": "njega-koze-suncem-i-tamnjenjem-solarni-filtri",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4E2GJJTAKSG9K2YD30C",
      "rank": 3,
      "metadata": null,
      "category_children": [
        {
          "id": "pcat_01HK7WKC9ME9V0BFDKK0S3RNTD",
          "created_at": "2024-01-03T14:45:51.025Z",
          "updated_at": "2024-01-03T14:45:51.025Z",
          "name": "Solarni filtri za tijelo",
          "description": "",
          "handle": "solarni-filtri-solarni-filtri-za-tijelo",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK6ZJ0V95JGS57SVR77VS",
          "rank": 0,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKCAAVVZQQX0T3WQ4XGHM",
          "created_at": "2024-01-03T14:45:51.047Z",
          "updated_at": "2024-01-03T14:45:51.047Z",
          "name": "Solarni filtri za lice",
          "description": "",
          "handle": "solarni-filtri-solarni-filtri-za-lice",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK6ZJ0V95JGS57SVR77VS",
          "rank": 1,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKCB2999ANAF94BD7ZQYC",
          "created_at": "2024-01-03T14:45:51.070Z",
          "updated_at": "2024-01-03T14:45:51.070Z",
          "name": "Solarni filtri za usne",
          "description": "",
          "handle": "solarni-filtri-solarni-filtri-za-usne",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK6ZJ0V95JGS57SVR77VS",
          "rank": 2,
          "metadata": null
        }
      ],
      "parent_category": {
        "id": "pcat_01HK7WK4E2GJJTAKSG9K2YD30C",
        "created_at": "2024-01-03T14:45:42.976Z",
        "updated_at": "2024-01-03T14:45:42.976Z",
        "name": "Njega kože suncem i tamnjenjem",
        "description": "",
        "handle": "njega-koze-njega-koze-suncem-i-tamnjenjem",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK42VB8WYE6GKG3FEJ41V",
        "rank": 3,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK5SKZ0FM7YPWSBXYKW3W",
      "created_at": "2024-01-03T14:45:44.361Z",
      "updated_at": "2024-01-03T14:45:44.361Z",
      "name": "Gelovi",
      "description": "",
      "handle": "njega-nakon-depilacije-i-brijanja-gelovi",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK481HCTWDV2XH5M4VNWK",
      "rank": 3,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK481HCTWDV2XH5M4VNWK",
        "created_at": "2024-01-03T14:45:42.782Z",
        "updated_at": "2024-01-03T14:45:42.782Z",
        "name": "Njega nakon depilacije i brijanja",
        "description": "",
        "handle": "brijanje-i-depilacija-njega-nakon-depilacije-i-brijanja",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK41MNZ3C795B2JN5JW0G",
        "rank": 3,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK60A4X3S46T4F512M604",
      "created_at": "2024-01-03T14:45:44.583Z",
      "updated_at": "2024-01-03T14:45:44.583Z",
      "name": "Učvršćivači i gelovi",
      "description": "",
      "handle": "njega-prije-depilacije-i-brijanja-ucvrscivaci-i-gelovi",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK48MV187B5ZHKEDJWPEG",
      "rank": 3,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK48MV187B5ZHKEDJWPEG",
        "created_at": "2024-01-03T14:45:42.801Z",
        "updated_at": "2024-01-03T14:45:42.801Z",
        "name": "Njega prije depilacije i brijanja",
        "description": "",
        "handle": "brijanje-i-depilacija-njega-prije-depilacije-i-brijanja",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK41MNZ3C795B2JN5JW0G",
        "rank": 4,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK9E02QDSEW3NE9SF8N4Y",
      "created_at": "2024-01-03T14:45:48.094Z",
      "updated_at": "2024-01-03T14:45:48.094Z",
      "name": "Boje za obrve",
      "description": "",
      "handle": "oci-boje-za-obrve",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4T4JAQRP4VBRRAJR36J",
      "rank": 3,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4T4JAQRP4VBRRAJR36J",
        "created_at": "2024-01-03T14:45:43.362Z",
        "updated_at": "2024-01-03T14:45:43.362Z",
        "name": "Oči",
        "description": "",
        "handle": "sminka-oci_11745",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK4495D212GWWBGHX6D9H",
        "rank": 4,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK76M8E6HMG1ZF6TZHQDN",
      "created_at": "2024-01-03T14:45:45.810Z",
      "updated_at": "2024-01-03T14:45:45.810Z",
      "name": "Osvježavajuće maske",
      "description": "",
      "handle": "oci-osvjezavajuce-maske",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4J1NW5Q3B0PAC2JEQ2N",
      "rank": 3,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4J1NW5Q3B0PAC2JEQ2N",
        "created_at": "2024-01-03T14:45:43.101Z",
        "updated_at": "2024-01-03T14:45:43.101Z",
        "name": "Oči",
        "description": "",
        "handle": "njega-koze-oci_5391",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK42VB8WYE6GKG3FEJ41V",
        "rank": 6,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKCWQC9HE1G9FRHMW11Q3",
      "created_at": "2024-01-03T14:45:51.637Z",
      "updated_at": "2024-01-03T14:45:51.637Z",
      "name": "Umjetne trepavice",
      "description": "",
      "handle": "oci-umjetne-trepavice",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK9X10701060SA690EKN6",
      "rank": 3,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK9X10701060SA690EKN6",
        "created_at": "2024-01-03T14:45:48.574Z",
        "updated_at": "2024-01-03T14:45:48.574Z",
        "name": "Oči",
        "description": "",
        "handle": "alati-i-pribor-za-sminkanje-oci",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK57XS2NQS0EGT358BW0Q",
        "rank": 2,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKDJ0ZJ6JSFDJQ5C6N29C",
      "created_at": "2024-01-03T14:45:52.313Z",
      "updated_at": "2024-01-03T14:45:52.313Z",
      "name": "Umjetne trepavice",
      "description": "",
      "handle": "oci-umjetne-trepavice_25088",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WKB41CSS4HCT9G53TPNK8",
      "rank": 3,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WKB41CSS4HCT9G53TPNK8",
        "created_at": "2024-01-03T14:45:49.823Z",
        "updated_at": "2024-01-03T14:45:49.823Z",
        "name": "Oči",
        "description": "",
        "handle": "cetke-i-pribor-za-sminkanje-oci",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK5D0544YXJQT9G60XVED",
        "rank": 2,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK9ZZ8H02QRXQ0AFVM4NC",
      "created_at": "2024-01-03T14:45:48.668Z",
      "updated_at": "2024-01-03T14:45:48.668Z",
      "name": "Gelovi za tuširanje",
      "description": "",
      "handle": "osobna-cistoca-gelovi-za-tusiranje",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4C6VG3TJWR51GB1ZQJW",
      "rank": 3,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4C6VG3TJWR51GB1ZQJW",
        "created_at": "2024-01-03T14:45:42.916Z",
        "updated_at": "2024-01-03T14:45:42.916Z",
        "name": "Osobna čistoća",
        "description": "",
        "handle": "kupanje-i-osobna-higijena-osobna-cistoca",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK42BQ7XFW617X5XP4BAR",
        "rank": 4,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK4YNQSS786T0PHYXE7FP",
      "created_at": "2024-01-03T14:45:43.492Z",
      "updated_at": "2024-01-03T14:45:43.492Z",
      "name": "Za žene",
      "description": "",
      "handle": "parfemi-i-mirisi-za-zene_14100",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK45CQPDHWCYPEW3W862G",
      "rank": 3,
      "metadata": null,
      "category_children": [
        {
          "id": "pcat_01HK7WK9JHDR43FGH963119W2Y",
          "created_at": "2024-01-03T14:45:48.240Z",
          "updated_at": "2024-01-03T14:45:48.240Z",
          "name": "Mirisna ulja",
          "description": "",
          "handle": "za-zene-mirisna-ulja",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4YNQSS786T0PHYXE7FP",
          "rank": 0,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK9JX9P29DZZ37WECSK08",
          "created_at": "2024-01-03T14:45:48.251Z",
          "updated_at": "2024-01-03T14:45:48.251Z",
          "name": "Kolonjska voda",
          "description": "",
          "handle": "za-zene-kolonjska-voda",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4YNQSS786T0PHYXE7FP",
          "rank": 1,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK9KC5DQVH6DCRFJEW3H9",
          "created_at": "2024-01-03T14:45:48.265Z",
          "updated_at": "2024-01-03T14:45:48.265Z",
          "name": "Parfemska voda",
          "description": "",
          "handle": "za-zene-parfemska-voda",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4YNQSS786T0PHYXE7FP",
          "rank": 2,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK9MKMCF5MCSN5S2470NY",
          "created_at": "2024-01-03T14:45:48.295Z",
          "updated_at": "2024-01-03T14:45:48.295Z",
          "name": "Toaletna voda",
          "description": "",
          "handle": "za-zene-toaletna-voda",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4YNQSS786T0PHYXE7FP",
          "rank": 3,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK9PFK70M91JAQ3ZQQSYQ",
          "created_at": "2024-01-03T14:45:48.362Z",
          "updated_at": "2024-01-03T14:45:48.362Z",
          "name": "Setovi",
          "description": "",
          "handle": "za-zene-setovi",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4YNQSS786T0PHYXE7FP",
          "rank": 4,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK9QNNHT6VRYMM4W20PTG",
          "created_at": "2024-01-03T14:45:48.398Z",
          "updated_at": "2024-01-03T14:45:48.398Z",
          "name": "Sprejevi za tijelo",
          "description": "",
          "handle": "za-zene-sprejevi-za-tijelo_14107",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4YNQSS786T0PHYXE7FP",
          "rank": 5,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKB0EXW3NJR8RK4GQCJXC",
          "created_at": "2024-01-03T14:45:49.707Z",
          "updated_at": "2024-01-03T14:45:49.707Z",
          "name": "Svježa voda",
          "description": "",
          "handle": "za-zene-svjeza-voda",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4YNQSS786T0PHYXE7FP",
          "rank": 6,
          "metadata": null
        }
      ],
      "parent_category": {
        "id": "pcat_01HK7WK45CQPDHWCYPEW3W862G",
        "created_at": "2024-01-03T14:45:42.699Z",
        "updated_at": "2024-01-03T14:45:42.699Z",
        "name": "Parfemi i mirisi",
        "description": "",
        "handle": "ljepota-parfemi-i-mirisi",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": null,
        "rank": 6,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKBECX73T18N8C8Z8EJZZ",
      "created_at": "2024-01-03T14:45:50.154Z",
      "updated_at": "2024-01-03T14:45:50.154Z",
      "name": "Čišćenje",
      "description": "",
      "handle": "pribor-ciscenje_1038",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK5GP4MZXQ69EPS0WG1AR",
      "rank": 3,
      "metadata": null,
      "category_children": [
        {
          "id": "pcat_01HK7WKDSWN0SP7XZH515KDKD9",
          "created_at": "2024-01-03T14:45:52.569Z",
          "updated_at": "2024-01-03T14:45:52.569Z",
          "name": "Patrone",
          "description": "",
          "handle": "ciscenje-patrone",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WKBECX73T18N8C8Z8EJZZ",
          "rank": 0,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKDV7N6X21E623DR9Q493",
          "created_at": "2024-01-03T14:45:52.607Z",
          "updated_at": "2024-01-03T14:45:52.607Z",
          "name": "Četke",
          "description": "",
          "handle": "ciscenje-cetke_1040",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WKBECX73T18N8C8Z8EJZZ",
          "rank": 1,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKDX3AZN40HRN26KM7F36",
          "created_at": "2024-01-03T14:45:52.660Z",
          "updated_at": "2024-01-03T14:45:52.660Z",
          "name": "Tekućine i sprejevi",
          "description": "",
          "handle": "ciscenje-tekucine-i-sprejevi",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WKBECX73T18N8C8Z8EJZZ",
          "rank": 2,
          "metadata": null
        }
      ],
      "parent_category": {
        "id": "pcat_01HK7WK5GP4MZXQ69EPS0WG1AR",
        "created_at": "2024-01-03T14:45:44.084Z",
        "updated_at": "2024-01-03T14:45:44.084Z",
        "name": "Pribor",
        "description": "",
        "handle": "elektricni-brijaci-pribor",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK46YG69NRB3HQKNMNZ0C",
        "rank": 0,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKB8BPXMAMPPN0M89MBZD",
      "created_at": "2024-01-03T14:45:49.961Z",
      "updated_at": "2024-01-03T14:45:49.961Z",
      "name": "Držači za pribor za ručno brijanje",
      "description": "",
      "handle": "pribor-drzaci-za-pribor-za-rucno-brijanje",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK5EWA9P9TTPVVM3FFT1X",
      "rank": 3,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK5EWA9P9TTPVVM3FFT1X",
        "created_at": "2024-01-03T14:45:44.026Z",
        "updated_at": "2024-01-03T14:45:44.026Z",
        "name": "Pribor",
        "description": "",
        "handle": "rucno-brijanje-pribor",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK46D6H08A70HPE8S7SCQ",
        "rank": 0,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK5D0544YXJQT9G60XVED",
      "created_at": "2024-01-03T14:45:43.966Z",
      "updated_at": "2024-01-03T14:45:43.966Z",
      "name": "Četke i pribor za šminkanje",
      "description": "",
      "handle": "pribor-i-dodaci-cetke-i-pribor-za-sminkanje",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK45W0F9X0VMBBCAVDPWD",
      "rank": 3,
      "metadata": null,
      "category_children": [
        {
          "id": "pcat_01HK7WKB32TXGJCE8WVBT87HR0",
          "created_at": "2024-01-03T14:45:49.792Z",
          "updated_at": "2024-01-03T14:45:49.792Z",
          "name": "Setovi kistova",
          "description": "",
          "handle": "cetke-i-pribor-za-sminkanje-setovi-kistova",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK5D0544YXJQT9G60XVED",
          "rank": 0,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKB3HY3RTXZFKQMFDSP7T",
          "created_at": "2024-01-03T14:45:49.806Z",
          "updated_at": "2024-01-03T14:45:49.806Z",
          "name": "Kistovi za usne",
          "description": "",
          "handle": "cetke-i-pribor-za-sminkanje-kistovi-za-usne",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK5D0544YXJQT9G60XVED",
          "rank": 1,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKB41CSS4HCT9G53TPNK8",
          "created_at": "2024-01-03T14:45:49.823Z",
          "updated_at": "2024-01-03T14:45:49.823Z",
          "name": "Oči",
          "description": "",
          "handle": "cetke-i-pribor-za-sminkanje-oci",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK5D0544YXJQT9G60XVED",
          "rank": 2,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKB4GBXA5NY5NKJJMS67V",
          "created_at": "2024-01-03T14:45:49.837Z",
          "updated_at": "2024-01-03T14:45:49.837Z",
          "name": "Lice",
          "description": "",
          "handle": "cetke-i-pribor-za-sminkanje-lice",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK5D0544YXJQT9G60XVED",
          "rank": 3,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKB4XZ8VQZKJFGWXHPW27",
          "created_at": "2024-01-03T14:45:49.850Z",
          "updated_at": "2024-01-03T14:45:49.850Z",
          "name": "Setovi i kompleti",
          "description": "",
          "handle": "cetke-i-pribor-za-sminkanje-setovi-i-kompleti",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK5D0544YXJQT9G60XVED",
          "rank": 4,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKB5DZ8TD3E1ETT62YDWN",
          "created_at": "2024-01-03T14:45:49.867Z",
          "updated_at": "2024-01-03T14:45:49.867Z",
          "name": "Šiljilo",
          "description": "",
          "handle": "cetke-i-pribor-za-sminkanje-siljilo",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK5D0544YXJQT9G60XVED",
          "rank": 5,
          "metadata": null
        }
      ],
      "parent_category": {
        "id": "pcat_01HK7WK45W0F9X0VMBBCAVDPWD",
        "created_at": "2024-01-03T14:45:42.715Z",
        "updated_at": "2024-01-03T14:45:42.715Z",
        "name": "Pribor i dodaci",
        "description": "",
        "handle": "ljepota-pribor-i-dodaci",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": null,
        "rank": 7,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK8YESAFVYF2K1X59FMJG",
      "created_at": "2024-01-03T14:45:47.596Z",
      "updated_at": "2024-01-03T14:45:47.596Z",
      "name": "Podizači kutikula",
      "description": "",
      "handle": "pribor-i-dodaci-podizaci-kutikula",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4Q9J4VMNM7KX6F1K8RQ",
      "rank": 3,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4Q9J4VMNM7KX6F1K8RQ",
        "created_at": "2024-01-03T14:45:43.271Z",
        "updated_at": "2024-01-03T14:45:43.271Z",
        "name": "Pribor i dodaci",
        "description": "",
        "handle": "manikura-i-pedikura-pribor-i-dodaci",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK43VEK8SG6M4T81C3W37",
        "rank": 3,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKCHBX15Q3C9W3XXNA6NK",
      "created_at": "2024-01-03T14:45:51.273Z",
      "updated_at": "2024-01-03T14:45:51.273Z",
      "name": "Ruke i prsti za vježbanje",
      "description": "",
      "handle": "pribor-ruke-i-prsti-za-vjezbanje",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK8TNZ6RWPNFYABFMKJ6W",
      "rank": 3,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK8TNZ6RWPNFYABFMKJ6W",
        "created_at": "2024-01-03T14:45:47.475Z",
        "updated_at": "2024-01-03T14:45:47.475Z",
        "name": "Pribor",
        "description": "",
        "handle": "dizajni-za-nokte-pribor",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK4PDYPCE7H5D1FAFMHNK",
        "rank": 7,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK794ETJK0B56S2SCAB7Q",
      "created_at": "2024-01-03T14:45:45.890Z",
      "updated_at": "2024-01-03T14:45:45.890Z",
      "name": "Gumice za friziranje",
      "description": "",
      "handle": "pribor-za-friziranje-gumice-za-friziranje",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4JRZ92REX0CX4M9JWEM",
      "rank": 3,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4JRZ92REX0CX4M9JWEM",
        "created_at": "2024-01-03T14:45:43.125Z",
        "updated_at": "2024-01-03T14:45:43.125Z",
        "name": "Pribor za friziranje",
        "description": "",
        "handle": "njega-kose-pribor-za-friziranje",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK43BQJST1XZ18R4BST01",
        "rank": 0,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK8QC3X1JJ8KPTPR8WS9B",
      "created_at": "2024-01-03T14:45:47.370Z",
      "updated_at": "2024-01-03T14:45:47.370Z",
      "name": "Škare za kosu",
      "description": "",
      "handle": "pribor-za-sisanje-skare-za-kosu",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4NHTG89E1DN79HZD0W5",
      "rank": 3,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4NHTG89E1DN79HZD0W5",
        "created_at": "2024-01-03T14:45:43.215Z",
        "updated_at": "2024-01-03T14:45:43.215Z",
        "name": "Pribor za šišanje",
        "description": "",
        "handle": "njega-kose-pribor-za-sisanje",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK43BQJST1XZ18R4BST01",
        "rank": 6,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK84XN13FCF2JRNRH4BKZ",
      "created_at": "2024-01-03T14:45:46.779Z",
      "updated_at": "2024-01-03T14:45:46.779Z",
      "name": "Lakovi",
      "description": "",
      "handle": "proizvodi-za-friziranje-lakovi",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4MPDDG329H66PT1KDYF",
      "rank": 3,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4MPDDG329H66PT1KDYF",
        "created_at": "2024-01-03T14:45:43.187Z",
        "updated_at": "2024-01-03T14:45:43.187Z",
        "name": "Proizvodi za friziranje",
        "description": "",
        "handle": "njega-kose-proizvodi-za-friziranje",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK43BQJST1XZ18R4BST01",
        "rank": 4,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK8ACR52ST9TRTPHNMP9M",
      "created_at": "2024-01-03T14:45:46.953Z",
      "updated_at": "2024-01-03T14:45:46.953Z",
      "name": "Regeneratori",
      "description": "",
      "handle": "proizvodi-za-njegu-kose-regeneratori",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4N31HG0EQ98AA4ZHV8C",
      "rank": 3,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4N31HG0EQ98AA4ZHV8C",
        "created_at": "2024-01-03T14:45:43.201Z",
        "updated_at": "2024-01-03T14:45:43.201Z",
        "name": "Proizvodi za njegu kose",
        "description": "",
        "handle": "njega-kose-proizvodi-za-njegu-kose",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK43BQJST1XZ18R4BST01",
        "rank": 5,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK727067M9J5DS5KCEF8P",
      "created_at": "2024-01-03T14:45:45.669Z",
      "updated_at": "2024-01-03T14:45:45.669Z",
      "name": "Hidratantne čarape",
      "description": "",
      "handle": "ruke-i-stopala-hidratantne-carape",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4G7CJB2WFRCRPS0131Q",
      "rank": 3,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4G7CJB2WFRCRPS0131Q",
        "created_at": "2024-01-03T14:45:43.032Z",
        "updated_at": "2024-01-03T14:45:43.032Z",
        "name": "Ruke i stopala",
        "description": "",
        "handle": "njega-koze-ruke-i-stopala",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK42VB8WYE6GKG3FEJ41V",
        "rank": 5,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK5KNGTNHYD6QSAG97YJ2",
      "created_at": "2024-01-03T14:45:44.179Z",
      "updated_at": "2024-01-03T14:45:44.179Z",
      "name": "Trimeri za dlake na licu",
      "description": "",
      "handle": "skare-za-kosu-brijaci-i-aparati-za-brijanje-tijela-trimeri-za-dlake-na-licu",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK47G3STXHFMNMZ4MMQ7G",
      "rank": 3,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK47G3STXHFMNMZ4MMQ7G",
        "created_at": "2024-01-03T14:45:42.766Z",
        "updated_at": "2024-01-03T14:45:42.766Z",
        "name": "Škare za kosu, brijači i aparati za brijanje tijela",
        "description": "",
        "handle": "brijanje-i-depilacija-skare-za-kosu-brijaci-i-aparati-za-brijanje-tijela",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK41MNZ3C795B2JN5JW0G",
        "rank": 2,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK4SPEA6NYM5SZ5DDC5A8",
      "created_at": "2024-01-03T14:45:43.348Z",
      "updated_at": "2024-01-03T14:45:43.348Z",
      "name": "Usne",
      "description": "",
      "handle": "sminka-usne_11739",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4495D212GWWBGHX6D9H",
      "rank": 3,
      "metadata": null,
      "category_children": [
        {
          "id": "pcat_01HK7WK98JPE14XZWYMHW5KK7N",
          "created_at": "2024-01-03T14:45:47.919Z",
          "updated_at": "2024-01-03T14:45:47.919Z",
          "name": "Baze",
          "description": "",
          "handle": "usne-baze",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4SPEA6NYM5SZ5DDC5A8",
          "rank": 0,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK9928NREJQAV2NRNX3QF",
          "created_at": "2024-01-03T14:45:47.935Z",
          "updated_at": "2024-01-03T14:45:47.935Z",
          "name": "Šljokice",
          "description": "",
          "handle": "usne-sljokice",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4SPEA6NYM5SZ5DDC5A8",
          "rank": 1,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK9A205JTD2EGFB0N574X",
          "created_at": "2024-01-03T14:45:47.968Z",
          "updated_at": "2024-01-03T14:45:47.968Z",
          "name": "Olovke za usne",
          "description": "",
          "handle": "usne-olovke-za-usne",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4SPEA6NYM5SZ5DDC5A8",
          "rank": 2,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK9AGRZXBE1ER1M129E4K",
          "created_at": "2024-01-03T14:45:47.982Z",
          "updated_at": "2024-01-03T14:45:47.982Z",
          "name": "Ruževi",
          "description": "",
          "handle": "usne-ruzevi",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4SPEA6NYM5SZ5DDC5A8",
          "rank": 3,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK9B0B3KBQR19D0JS7WCB",
          "created_at": "2024-01-03T14:45:47.997Z",
          "updated_at": "2024-01-03T14:45:47.997Z",
          "name": "Boje i bojila",
          "description": "",
          "handle": "usne-boje-i-bojila",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4SPEA6NYM5SZ5DDC5A8",
          "rank": 4,
          "metadata": null
        }
      ],
      "parent_category": {
        "id": "pcat_01HK7WK4495D212GWWBGHX6D9H",
        "created_at": "2024-01-03T14:45:42.665Z",
        "updated_at": "2024-01-03T14:45:42.665Z",
        "name": "Šminka",
        "description": "",
        "handle": "ljepota-sminka",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": null,
        "rank": 5,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK9800PGYS8HAXQXYZKZA",
      "created_at": "2024-01-03T14:45:47.901Z",
      "updated_at": "2024-01-03T14:45:47.901Z",
      "name": "Privremene tetovaže",
      "description": "",
      "handle": "tijelo-privremene-tetovaze",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4RBTHMFSNA47WTF4D6J",
      "rank": 3,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4RBTHMFSNA47WTF4D6J",
        "created_at": "2024-01-03T14:45:43.304Z",
        "updated_at": "2024-01-03T14:45:43.304Z",
        "name": "Tijelo",
        "description": "",
        "handle": "sminka-tijelo_11713",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK4495D212GWWBGHX6D9H",
        "rank": 1,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK6XB3VQJ2SYTFT2S91MJ",
      "created_at": "2024-01-03T14:45:45.513Z",
      "updated_at": "2024-01-03T14:45:45.513Z",
      "name": "Za toniranje i oblikovanje",
      "description": "",
      "handle": "tijelo-za-toniranje-i-oblikovanje",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4DMG2Q4J3RMF32RA7Y1",
      "rank": 3,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4DMG2Q4J3RMF32RA7Y1",
        "created_at": "2024-01-03T14:45:42.962Z",
        "updated_at": "2024-01-03T14:45:42.962Z",
        "name": "Tijelo",
        "description": "",
        "handle": "njega-koze-tijelo_5355",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK42VB8WYE6GKG3FEJ41V",
        "rank": 2,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKB2MJAKJW7AYR00YG975",
      "created_at": "2024-01-03T14:45:49.778Z",
      "updated_at": "2024-01-03T14:45:49.778Z",
      "name": "Putni neseseri",
      "description": "",
      "handle": "torbe-i-futrole-putni-neseseri",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK571RGN4X4BE7Q8XRZ7N",
      "rank": 3,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK571RGN4X4BE7Q8XRZ7N",
        "created_at": "2024-01-03T14:45:43.774Z",
        "updated_at": "2024-01-03T14:45:43.774Z",
        "name": "Torbe i futrole",
        "description": "",
        "handle": "pribor-i-dodaci-torbe-i-futrole",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK45W0F9X0VMBBCAVDPWD",
        "rank": 1,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK8WT3RZCQ3WHYKBAN43Q",
      "created_at": "2024-01-03T14:45:47.544Z",
      "updated_at": "2024-01-03T14:45:47.544Z",
      "name": "Tretman za rast noktiju",
      "description": "",
      "handle": "tretmani-za-nokte-tretman-za-rast-noktiju",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4PWXATZSWB566TB87WB",
      "rank": 3,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4PWXATZSWB566TB87WB",
        "created_at": "2024-01-03T14:45:43.258Z",
        "updated_at": "2024-01-03T14:45:43.258Z",
        "name": "Tretmani za nokte",
        "description": "",
        "handle": "manikura-i-pedikura-tretmani-za-nokte",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK43VEK8SG6M4T81C3W37",
        "rank": 2,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK67B1T43G9Z84TVHAD7Q",
      "created_at": "2024-01-03T14:45:44.804Z",
      "updated_at": "2024-01-03T14:45:44.804Z",
      "name": "Laserski depilatori",
      "description": "",
      "handle": "uklanjanje-dlaka-laserski-depilatori",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK493C3GNJNECHNPJ0K2N",
      "rank": 3,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK493C3GNJNECHNPJ0K2N",
        "created_at": "2024-01-03T14:45:42.817Z",
        "updated_at": "2024-01-03T14:45:42.817Z",
        "name": "Uklanjanje dlaka",
        "description": "",
        "handle": "brijanje-i-depilacija-uklanjanje-dlaka",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK41MNZ3C795B2JN5JW0G",
        "rank": 5,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKACVM8XRD0AS8HD8R40R",
      "created_at": "2024-01-03T14:45:49.079Z",
      "updated_at": "2024-01-03T14:45:49.079Z",
      "name": "Umeci",
      "description": "",
      "handle": "umeci-za-kosu-perike-i-dodaci-umeci_20953",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK5A5XWEMK8CFBX8HWCSH",
      "rank": 3,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK5A5XWEMK8CFBX8HWCSH",
        "created_at": "2024-01-03T14:45:43.874Z",
        "updated_at": "2024-01-03T14:45:43.874Z",
        "name": "Umeci za kosu, perike i dodaci",
        "description": "",
        "handle": "njega-kose-umeci-za-kosu-perike-i-dodaci",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK43BQJST1XZ18R4BST01",
        "rank": 7,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKCN199616QD8G5GJ069D",
      "created_at": "2024-01-03T14:45:51.390Z",
      "updated_at": "2024-01-03T14:45:51.390Z",
      "name": "Ljepila za nokte",
      "description": "",
      "handle": "umjetni-nokti-i-pribor-ljepila-za-nokte",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK8V224HR9CV9810ZE5S6",
      "rank": 3,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK8V224HR9CV9810ZE5S6",
        "created_at": "2024-01-03T14:45:47.487Z",
        "updated_at": "2024-01-03T14:45:47.487Z",
        "name": "Umjetni nokti i pribor",
        "description": "",
        "handle": "dizajni-za-nokte-umjetni-nokti-i-pribor",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK4PDYPCE7H5D1FAFMHNK",
        "rank": 8,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK9AGRZXBE1ER1M129E4K",
      "created_at": "2024-01-03T14:45:47.982Z",
      "updated_at": "2024-01-03T14:45:47.982Z",
      "name": "Ruževi",
      "description": "",
      "handle": "usne-ruzevi",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4SPEA6NYM5SZ5DDC5A8",
      "rank": 3,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4SPEA6NYM5SZ5DDC5A8",
        "created_at": "2024-01-03T14:45:43.348Z",
        "updated_at": "2024-01-03T14:45:43.348Z",
        "name": "Usne",
        "description": "",
        "handle": "sminka-usne_11739",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK4495D212GWWBGHX6D9H",
        "rank": 3,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKCZ0ZM3KVR5C7QK001S7",
      "created_at": "2024-01-03T14:45:51.710Z",
      "updated_at": "2024-01-03T14:45:51.710Z",
      "name": "Korektivne i kreme protiv nesavršenosti",
      "description": "",
      "handle": "za-hidrataciju-korektivne-i-kreme-protiv-nesavrsenosti",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK6SZXFJCA60BBDC8S5W7",
      "rank": 3,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK6SZXFJCA60BBDC8S5W7",
        "created_at": "2024-01-03T14:45:45.388Z",
        "updated_at": "2024-01-03T14:45:45.388Z",
        "name": "Za hidrataciju",
        "description": "",
        "handle": "lice-za-hidrataciju",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK4CQ8JZ3NZ6QJC2MQW11",
        "rank": 1,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKC6HZFVPGHBCEKKHJN43",
      "created_at": "2024-01-03T14:45:50.918Z",
      "updated_at": "2024-01-03T14:45:50.918Z",
      "name": "Maslaci za tijelo",
      "description": "",
      "handle": "za-hidrataciju-maslaci-za-tijelo",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK6WA7CDBMWAWAHDXNKA8",
      "rank": 3,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK6WA7CDBMWAWAHDXNKA8",
        "created_at": "2024-01-03T14:45:45.480Z",
        "updated_at": "2024-01-03T14:45:45.480Z",
        "name": "Za hidrataciju",
        "description": "",
        "handle": "tijelo-za-hidrataciju",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK4DMG2Q4J3RMF32RA7Y1",
        "rank": 1,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK9J40YXPNW9HBHB880VP",
      "created_at": "2024-01-03T14:45:48.227Z",
      "updated_at": "2024-01-03T14:45:48.227Z",
      "name": "Kompleti",
      "description": "",
      "handle": "za-muskarce-kompleti_14099",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4WXV52RYH206J4P8TD0",
      "rank": 3,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4WXV52RYH206J4P8TD0",
        "created_at": "2024-01-03T14:45:43.446Z",
        "updated_at": "2024-01-03T14:45:43.446Z",
        "name": "Za muškarce",
        "description": "",
        "handle": "parfemi-i-mirisi-za-muskarce_14094",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK45CQPDHWCYPEW3W862G",
        "rank": 2,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK9MKMCF5MCSN5S2470NY",
      "created_at": "2024-01-03T14:45:48.295Z",
      "updated_at": "2024-01-03T14:45:48.295Z",
      "name": "Toaletna voda",
      "description": "",
      "handle": "za-zene-toaletna-voda",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4YNQSS786T0PHYXE7FP",
      "rank": 3,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4YNQSS786T0PHYXE7FP",
        "created_at": "2024-01-03T14:45:43.492Z",
        "updated_at": "2024-01-03T14:45:43.492Z",
        "name": "Za žene",
        "description": "",
        "handle": "parfemi-i-mirisi-za-zene_14100",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK45CQPDHWCYPEW3W862G",
        "rank": 3,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK9Y73ZGXWQYWY7Z1YXA7",
      "created_at": "2024-01-03T14:45:48.612Z",
      "updated_at": "2024-01-03T14:45:48.612Z",
      "name": "Setovi i kompleti",
      "description": "",
      "handle": "alati-i-pribor-za-sminkanje-setovi-i-kompleti",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK57XS2NQS0EGT358BW0Q",
      "rank": 4,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK57XS2NQS0EGT358BW0Q",
        "created_at": "2024-01-03T14:45:43.802Z",
        "updated_at": "2024-01-03T14:45:43.802Z",
        "name": "Alati i pribor za šminkanje",
        "description": "",
        "handle": "pribor-i-dodaci-alati-i-pribor-za-sminkanje",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK45W0F9X0VMBBCAVDPWD",
        "rank": 2,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK7K0S9ZDCJNS3MVGMKKT",
      "created_at": "2024-01-03T14:45:46.206Z",
      "updated_at": "2024-01-03T14:45:46.206Z",
      "name": "Korektori boje",
      "description": "",
      "handle": "bojanje-kose-korektori-boje",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4KQVZQK6XPGZJEJ50QZ",
      "rank": 4,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4KQVZQK6XPGZJEJ50QZ",
        "created_at": "2024-01-03T14:45:43.157Z",
        "updated_at": "2024-01-03T14:45:43.157Z",
        "name": "Bojanje kose",
        "description": "",
        "handle": "njega-kose-bojanje-kose",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK43BQJST1XZ18R4BST01",
        "rank": 2,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK48MV187B5ZHKEDJWPEG",
      "created_at": "2024-01-03T14:45:42.801Z",
      "updated_at": "2024-01-03T14:45:42.801Z",
      "name": "Njega prije depilacije i brijanja",
      "description": "",
      "handle": "brijanje-i-depilacija-njega-prije-depilacije-i-brijanja",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK41MNZ3C795B2JN5JW0G",
      "rank": 4,
      "metadata": null,
      "category_children": [
        {
          "id": "pcat_01HK7WK5YE2AVK0FAHS3JSZCH9",
          "created_at": "2024-01-03T14:45:44.523Z",
          "updated_at": "2024-01-03T14:45:44.523Z",
          "name": "Ulja",
          "description": "",
          "handle": "njega-prije-depilacije-i-brijanja-ulja",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK48MV187B5ZHKEDJWPEG",
          "rank": 0,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK5Z16GX3J028B42P9A9S",
          "created_at": "2024-01-03T14:45:44.542Z",
          "updated_at": "2024-01-03T14:45:44.542Z",
          "name": "Kreme",
          "description": "",
          "handle": "njega-prije-depilacije-i-brijanja-kreme",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK48MV187B5ZHKEDJWPEG",
          "rank": 1,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK5ZPMPP6PXD4Z1JBFSJJ",
          "created_at": "2024-01-03T14:45:44.564Z",
          "updated_at": "2024-01-03T14:45:44.564Z",
          "name": "Pjene",
          "description": "",
          "handle": "njega-prije-depilacije-i-brijanja-pjene",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK48MV187B5ZHKEDJWPEG",
          "rank": 2,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK60A4X3S46T4F512M604",
          "created_at": "2024-01-03T14:45:44.583Z",
          "updated_at": "2024-01-03T14:45:44.583Z",
          "name": "Učvršćivači i gelovi",
          "description": "",
          "handle": "njega-prije-depilacije-i-brijanja-ucvrscivaci-i-gelovi",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK48MV187B5ZHKEDJWPEG",
          "rank": 3,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK60YMT7XET4GMGENVBD5",
          "created_at": "2024-01-03T14:45:44.603Z",
          "updated_at": "2024-01-03T14:45:44.603Z",
          "name": "Sapuni",
          "description": "",
          "handle": "njega-prije-depilacije-i-brijanja-sapuni",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK48MV187B5ZHKEDJWPEG",
          "rank": 4,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK61J243W1H9V19RR1B41",
          "created_at": "2024-01-03T14:45:44.622Z",
          "updated_at": "2024-01-03T14:45:44.622Z",
          "name": "Kompleti",
          "description": "",
          "handle": "njega-prije-depilacije-i-brijanja-kompleti",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK48MV187B5ZHKEDJWPEG",
          "rank": 5,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK626G8V6ENRRR2SQWXXT",
          "created_at": "2024-01-03T14:45:44.643Z",
          "updated_at": "2024-01-03T14:45:44.643Z",
          "name": "Losioni",
          "description": "",
          "handle": "njega-prije-depilacije-i-brijanja-losioni",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK48MV187B5ZHKEDJWPEG",
          "rank": 6,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK9YTTXSZP0E49ZC32R5R",
          "created_at": "2024-01-03T14:45:48.631Z",
          "updated_at": "2024-01-03T14:45:48.631Z",
          "name": "Rukavice za piling",
          "description": "",
          "handle": "njega-prije-depilacije-i-brijanja-rukavice-za-piling",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK48MV187B5ZHKEDJWPEG",
          "rank": 7,
          "metadata": null
        }
      ],
      "parent_category": {
        "id": "pcat_01HK7WK41MNZ3C795B2JN5JW0G",
        "created_at": "2024-01-03T14:45:42.579Z",
        "updated_at": "2024-01-03T14:45:42.579Z",
        "name": "Brijanje i depilacija",
        "description": "",
        "handle": "ljepota-brijanje-i-depilacija",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": null,
        "rank": 0,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKB4XZ8VQZKJFGWXHPW27",
      "created_at": "2024-01-03T14:45:49.850Z",
      "updated_at": "2024-01-03T14:45:49.850Z",
      "name": "Setovi i kompleti",
      "description": "",
      "handle": "cetke-i-pribor-za-sminkanje-setovi-i-kompleti",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK5D0544YXJQT9G60XVED",
      "rank": 4,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK5D0544YXJQT9G60XVED",
        "created_at": "2024-01-03T14:45:43.966Z",
        "updated_at": "2024-01-03T14:45:43.966Z",
        "name": "Četke i pribor za šminkanje",
        "description": "",
        "handle": "pribor-i-dodaci-cetke-i-pribor-za-sminkanje",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK45W0F9X0VMBBCAVDPWD",
        "rank": 3,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKD1TMD8W2DN1YV054JNW",
      "created_at": "2024-01-03T14:45:51.798Z",
      "updated_at": "2024-01-03T14:45:51.798Z",
      "name": "Sapuni",
      "description": "",
      "handle": "cistaci-sapuni_25055",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WKA2274BVZPY0TZ6SN7Z9",
      "rank": 4,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WKA2274BVZPY0TZ6SN7Z9",
        "created_at": "2024-01-03T14:45:48.735Z",
        "updated_at": "2024-01-03T14:45:48.735Z",
        "name": "Čistači",
        "description": "",
        "handle": "lice-cistaci_20934",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK4CQ8JZ3NZ6QJC2MQW11",
        "rank": 5,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKBNV6BY68J9P5SGMQTF1",
      "created_at": "2024-01-03T14:45:50.389Z",
      "updated_at": "2024-01-03T14:45:50.389Z",
      "name": "Kompleti",
      "description": "",
      "handle": "depilacija-voskom-kompleti",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK649EWGYVF36CJ6071RM",
      "rank": 4,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK649EWGYVF36CJ6071RM",
        "created_at": "2024-01-03T14:45:44.706Z",
        "updated_at": "2024-01-03T14:45:44.706Z",
        "name": "Depilacija voskom",
        "description": "",
        "handle": "uklanjanje-dlaka-depilacija-voskom",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK493C3GNJNECHNPJ0K2N",
        "rank": 1,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK8SECJX3MM3D1K1C5SNS",
      "created_at": "2024-01-03T14:45:47.436Z",
      "updated_at": "2024-01-03T14:45:47.436Z",
      "name": "Gel lakovi",
      "description": "",
      "handle": "dizajni-za-nokte-gel-lakovi",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4PDYPCE7H5D1FAFMHNK",
      "rank": 4,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4PDYPCE7H5D1FAFMHNK",
        "created_at": "2024-01-03T14:45:43.244Z",
        "updated_at": "2024-01-03T14:45:43.244Z",
        "name": "Dizajni za nokte",
        "description": "",
        "handle": "manikura-i-pedikura-dizajni-za-nokte",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK43VEK8SG6M4T81C3W37",
        "rank": 1,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK6MJ4JYZ8HZSQB1T0JA8",
      "created_at": "2024-01-03T14:45:45.232Z",
      "updated_at": "2024-01-03T14:45:45.232Z",
      "name": "Soli i minerali",
      "description": "",
      "handle": "dodaci-za-kupke-soli-i-minerali",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4AMW72YGS9N8YSBWJ37",
      "rank": 4,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4AMW72YGS9N8YSBWJ37",
        "created_at": "2024-01-03T14:45:42.866Z",
        "updated_at": "2024-01-03T14:45:42.866Z",
        "name": "Dodaci za kupke",
        "description": "",
        "handle": "kupanje-i-osobna-higijena-dodaci-za-kupke",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK42BQ7XFW617X5XP4BAR",
        "rank": 1,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK80A720BF7AXX64YS0R0",
      "created_at": "2024-01-03T14:45:46.625Z",
      "updated_at": "2024-01-03T14:45:46.625Z",
      "name": "Ručna ogledala",
      "description": "",
      "handle": "frizerski-namjestaj-i-potrepstine-rucna-ogledala",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4M7JE8S0KGQCMWF47WJ",
      "rank": 4,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4M7JE8S0KGQCMWF47WJ",
        "created_at": "2024-01-03T14:45:43.173Z",
        "updated_at": "2024-01-03T14:45:43.173Z",
        "name": "Frizerski namještaj i potrepštine",
        "description": "",
        "handle": "njega-kose-frizerski-namjestaj-i-potrepstine",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK43BQJST1XZ18R4BST01",
        "rank": 3,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK7DCDZARF7ESDZHZ3VP1",
      "created_at": "2024-01-03T14:45:46.026Z",
      "updated_at": "2024-01-03T14:45:46.026Z",
      "name": "Uvijači za kosu",
      "description": "",
      "handle": "frizerski-uredaji-i-pribor-uvijaci-za-kosu",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4K84XGGV7XG3TFC6W69",
      "rank": 4,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4K84XGGV7XG3TFC6W69",
        "created_at": "2024-01-03T14:45:43.141Z",
        "updated_at": "2024-01-03T14:45:43.141Z",
        "name": "Frizerski uređaji i pribor",
        "description": "",
        "handle": "njega-kose-frizerski-uredaji-i-pribor",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK43BQJST1XZ18R4BST01",
        "rank": 1,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK4C6VG3TJWR51GB1ZQJW",
      "created_at": "2024-01-03T14:45:42.916Z",
      "updated_at": "2024-01-03T14:45:42.916Z",
      "name": "Osobna čistoća",
      "description": "",
      "handle": "kupanje-i-osobna-higijena-osobna-cistoca",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK42BQ7XFW617X5XP4BAR",
      "rank": 4,
      "metadata": null,
      "category_children": [
        {
          "id": "pcat_01HK7WK6N5WSRHATDWQ8AHA2MG",
          "created_at": "2024-01-03T14:45:45.250Z",
          "updated_at": "2024-01-03T14:45:45.250Z",
          "name": "Ulja za tuširanje",
          "description": "",
          "handle": "osobna-cistoca-ulja-za-tusiranje",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4C6VG3TJWR51GB1ZQJW",
          "rank": 0,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK6NS7QGH7TYT5Z5R0YN5",
          "created_at": "2024-01-03T14:45:45.271Z",
          "updated_at": "2024-01-03T14:45:45.271Z",
          "name": "Gelovi i sapuni",
          "description": "",
          "handle": "osobna-cistoca-gelovi-i-sapuni",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4C6VG3TJWR51GB1ZQJW",
          "rank": 1,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK6PB5GMXY65DHG0SF05X",
          "created_at": "2024-01-03T14:45:45.288Z",
          "updated_at": "2024-01-03T14:45:45.288Z",
          "name": "Kruti i tekući sapuni za ruke",
          "description": "",
          "handle": "osobna-cistoca-kruti-i-tekuci-sapuni-za-ruke",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4C6VG3TJWR51GB1ZQJW",
          "rank": 2,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK9ZZ8H02QRXQ0AFVM4NC",
          "created_at": "2024-01-03T14:45:48.668Z",
          "updated_at": "2024-01-03T14:45:48.668Z",
          "name": "Gelovi za tuširanje",
          "description": "",
          "handle": "osobna-cistoca-gelovi-za-tusiranje",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4C6VG3TJWR51GB1ZQJW",
          "rank": 3,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKA0JKQFRYM82V76BW6P8",
          "created_at": "2024-01-03T14:45:48.685Z",
          "updated_at": "2024-01-03T14:45:48.685Z",
          "name": "Kruti sapuni",
          "description": "",
          "handle": "osobna-cistoca-kruti-sapuni",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4C6VG3TJWR51GB1ZQJW",
          "rank": 4,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKA17D8NH56JB1XNRRGYV",
          "created_at": "2024-01-03T14:45:48.708Z",
          "updated_at": "2024-01-03T14:45:48.708Z",
          "name": "Sapuni za ruke",
          "description": "",
          "handle": "osobna-cistoca-sapuni-za-ruke",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4C6VG3TJWR51GB1ZQJW",
          "rank": 5,
          "metadata": null
        }
      ],
      "parent_category": {
        "id": "pcat_01HK7WK42BQ7XFW617X5XP4BAR",
        "created_at": "2024-01-03T14:45:42.602Z",
        "updated_at": "2024-01-03T14:45:42.602Z",
        "name": "Kupanje i osobna higijena",
        "description": "",
        "handle": "ljepota-kupanje-i-osobna-higijena",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": null,
        "rank": 1,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK6HT888PX65C1S7EMMPN",
      "created_at": "2024-01-03T14:45:45.144Z",
      "updated_at": "2024-01-03T14:45:45.144Z",
      "name": "Rukavice za piling",
      "description": "",
      "handle": "kupaonski-pribor-rukavice-za-piling",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4A2XE81CJFBQCJE5EC2",
      "rank": 4,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4A2XE81CJFBQCJE5EC2",
        "created_at": "2024-01-03T14:45:42.848Z",
        "updated_at": "2024-01-03T14:45:42.848Z",
        "name": "Kupaonski pribor",
        "description": "",
        "handle": "kupanje-i-osobna-higijena-kupaonski-pribor",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK42BQ7XFW617X5XP4BAR",
        "rank": 0,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK939NYQVC03WYH7K74JR",
      "created_at": "2024-01-03T14:45:47.751Z",
      "updated_at": "2024-01-03T14:45:47.751Z",
      "name": "Korektivne i kreme protiv nesavršenosti",
      "description": "",
      "handle": "lice-korektivne-i-kreme-protiv-nesavrsenosti",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4QT18DTEY1R94HB0RRX",
      "rank": 4,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4QT18DTEY1R94HB0RRX",
        "created_at": "2024-01-03T14:45:43.284Z",
        "updated_at": "2024-01-03T14:45:43.284Z",
        "name": "Lice",
        "description": "",
        "handle": "sminka-lice_11703",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK4495D212GWWBGHX6D9H",
        "rank": 0,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK6VGVXCDR5PH9GWTZJWJ",
      "created_at": "2024-01-03T14:45:45.453Z",
      "updated_at": "2024-01-03T14:45:45.453Z",
      "name": "Toneri",
      "description": "",
      "handle": "lice-toneri",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4CQ8JZ3NZ6QJC2MQW11",
      "rank": 4,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4CQ8JZ3NZ6QJC2MQW11",
        "created_at": "2024-01-03T14:45:42.933Z",
        "updated_at": "2024-01-03T14:45:42.933Z",
        "name": "Lice",
        "description": "",
        "handle": "njega-koze-lice_5325",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK42VB8WYE6GKG3FEJ41V",
        "rank": 0,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK43VEK8SG6M4T81C3W37",
      "created_at": "2024-01-03T14:45:42.650Z",
      "updated_at": "2024-01-03T14:45:42.650Z",
      "name": "Manikura i pedikura",
      "description": "",
      "handle": "ljepota-manikura-i-pedikura",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": null,
      "rank": 4,
      "metadata": null,
      "category_children": [
        {
          "id": "pcat_01HK7WK4P0YJC78YM99J3AQX3F",
          "created_at": "2024-01-03T14:45:43.230Z",
          "updated_at": "2024-01-03T14:45:43.230Z",
          "name": "Njega ruku i stopala",
          "description": "",
          "handle": "manikura-i-pedikura-njega-ruku-i-stopala",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK43VEK8SG6M4T81C3W37",
          "rank": 0,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK4PDYPCE7H5D1FAFMHNK",
          "created_at": "2024-01-03T14:45:43.244Z",
          "updated_at": "2024-01-03T14:45:43.244Z",
          "name": "Dizajni za nokte",
          "description": "",
          "handle": "manikura-i-pedikura-dizajni-za-nokte",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK43VEK8SG6M4T81C3W37",
          "rank": 1,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK4PWXATZSWB566TB87WB",
          "created_at": "2024-01-03T14:45:43.258Z",
          "updated_at": "2024-01-03T14:45:43.258Z",
          "name": "Tretmani za nokte",
          "description": "",
          "handle": "manikura-i-pedikura-tretmani-za-nokte",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK43VEK8SG6M4T81C3W37",
          "rank": 2,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK4Q9J4VMNM7KX6F1K8RQ",
          "created_at": "2024-01-03T14:45:43.271Z",
          "updated_at": "2024-01-03T14:45:43.271Z",
          "name": "Pribor i dodaci",
          "description": "",
          "handle": "manikura-i-pedikura-pribor-i-dodaci",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK43VEK8SG6M4T81C3W37",
          "rank": 3,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK5ARJD927HV0B1E6F91V",
          "created_at": "2024-01-03T14:45:43.893Z",
          "updated_at": "2024-01-03T14:45:43.893Z",
          "name": "Ruke i stopala",
          "description": "",
          "handle": "manikura-i-pedikura-ruke-i-stopala",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK43VEK8SG6M4T81C3W37",
          "rank": 4,
          "metadata": null
        }
      ],
      "parent_category": null
    },
    {
      "id": "pcat_01HK7WK5ARJD927HV0B1E6F91V",
      "created_at": "2024-01-03T14:45:43.893Z",
      "updated_at": "2024-01-03T14:45:43.893Z",
      "name": "Ruke i stopala",
      "description": "",
      "handle": "manikura-i-pedikura-ruke-i-stopala",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK43VEK8SG6M4T81C3W37",
      "rank": 4,
      "metadata": null,
      "category_children": [
        {
          "id": "pcat_01HK7WKAGBAQ5GGNNW409FPQ0D",
          "created_at": "2024-01-03T14:45:49.192Z",
          "updated_at": "2024-01-03T14:45:49.192Z",
          "name": "Kreme za stopala",
          "description": "",
          "handle": "ruke-i-stopala-kreme-za-stopala_20958",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK5ARJD927HV0B1E6F91V",
          "rank": 0,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKAHGYQWMJQF69T934WNM",
          "created_at": "2024-01-03T14:45:49.223Z",
          "updated_at": "2024-01-03T14:45:49.223Z",
          "name": "Kreme za ruke i nokte",
          "description": "",
          "handle": "ruke-i-stopala-kreme-za-ruke-i-nokte_20960",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK5ARJD927HV0B1E6F91V",
          "rank": 1,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKAJZDYY5FJZ78DDXRH4P",
          "created_at": "2024-01-03T14:45:49.272Z",
          "updated_at": "2024-01-03T14:45:49.272Z",
          "name": "Pilinzi",
          "description": "",
          "handle": "ruke-i-stopala-pilinzi_20964",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK5ARJD927HV0B1E6F91V",
          "rank": 2,
          "metadata": null
        }
      ],
      "parent_category": {
        "id": "pcat_01HK7WK43VEK8SG6M4T81C3W37",
        "created_at": "2024-01-03T14:45:42.650Z",
        "updated_at": "2024-01-03T14:45:42.650Z",
        "name": "Manikura i pedikura",
        "description": "",
        "handle": "ljepota-manikura-i-pedikura",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": null,
        "rank": 4,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK4MPDDG329H66PT1KDYF",
      "created_at": "2024-01-03T14:45:43.187Z",
      "updated_at": "2024-01-03T14:45:43.187Z",
      "name": "Proizvodi za friziranje",
      "description": "",
      "handle": "njega-kose-proizvodi-za-friziranje",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK43BQJST1XZ18R4BST01",
      "rank": 4,
      "metadata": null,
      "category_children": [
        {
          "id": "pcat_01HK7WK83BS0K28E3C66X8GJJ7",
          "created_at": "2024-01-03T14:45:46.729Z",
          "updated_at": "2024-01-03T14:45:46.729Z",
          "name": "Za raščešljavanje",
          "description": "",
          "handle": "proizvodi-za-friziranje-za-rascesljavanje",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4MPDDG329H66PT1KDYF",
          "rank": 0,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK83W4M0844RZX8TCYK43",
          "created_at": "2024-01-03T14:45:46.746Z",
          "updated_at": "2024-01-03T14:45:46.746Z",
          "name": "Mirisi za kosu",
          "description": "",
          "handle": "proizvodi-za-friziranje-mirisi-za-kosu",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4MPDDG329H66PT1KDYF",
          "rank": 1,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK84D4F00TWPCJT71W43V",
          "created_at": "2024-01-03T14:45:46.763Z",
          "updated_at": "2024-01-03T14:45:46.763Z",
          "name": "Gelovi",
          "description": "",
          "handle": "proizvodi-za-friziranje-gelovi",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4MPDDG329H66PT1KDYF",
          "rank": 2,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK84XN13FCF2JRNRH4BKZ",
          "created_at": "2024-01-03T14:45:46.779Z",
          "updated_at": "2024-01-03T14:45:46.779Z",
          "name": "Lakovi",
          "description": "",
          "handle": "proizvodi-za-friziranje-lakovi",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4MPDDG329H66PT1KDYF",
          "rank": 3,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK85EXQ86TH3BARB9TY22",
          "created_at": "2024-01-03T14:45:46.796Z",
          "updated_at": "2024-01-03T14:45:46.796Z",
          "name": "Krema za oblikovanje, glina i vosak",
          "description": "",
          "handle": "proizvodi-za-friziranje-krema-za-oblikovanje-glina-i-vosak",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4MPDDG329H66PT1KDYF",
          "rank": 4,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK85YWG2B27DWPB15ECDB",
          "created_at": "2024-01-03T14:45:46.812Z",
          "updated_at": "2024-01-03T14:45:46.812Z",
          "name": "Moussevi i pjene",
          "description": "",
          "handle": "proizvodi-za-friziranje-moussevi-i-pjene",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4MPDDG329H66PT1KDYF",
          "rank": 5,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK86H0GRHTD1KFW7Y426S",
          "created_at": "2024-01-03T14:45:46.831Z",
          "updated_at": "2024-01-03T14:45:46.831Z",
          "name": "Prašci za podizanje korijena",
          "description": "",
          "handle": "proizvodi-za-friziranje-prasci-za-podizanje-korijena",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4MPDDG329H66PT1KDYF",
          "rank": 6,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK871STBS1B8MV5H0VZFS",
          "created_at": "2024-01-03T14:45:46.847Z",
          "updated_at": "2024-01-03T14:45:46.847Z",
          "name": "Sprejevi sa slanom vodom",
          "description": "",
          "handle": "proizvodi-za-friziranje-sprejevi-sa-slanom-vodom",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4MPDDG329H66PT1KDYF",
          "rank": 7,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK87J8PH9MPSMCVC6E27V",
          "created_at": "2024-01-03T14:45:46.863Z",
          "updated_at": "2024-01-03T14:45:46.863Z",
          "name": "Termo zaštitni sprejevi",
          "description": "",
          "handle": "proizvodi-za-friziranje-termo-zastitni-sprejevi",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4MPDDG329H66PT1KDYF",
          "rank": 8,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK88200NPA35NZNE6YKYT",
          "created_at": "2024-01-03T14:45:46.879Z",
          "updated_at": "2024-01-03T14:45:46.879Z",
          "name": "Serumi",
          "description": "",
          "handle": "proizvodi-za-friziranje-serumi",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4MPDDG329H66PT1KDYF",
          "rank": 9,
          "metadata": null
        }
      ],
      "parent_category": {
        "id": "pcat_01HK7WK43BQJST1XZ18R4BST01",
        "created_at": "2024-01-03T14:45:42.635Z",
        "updated_at": "2024-01-03T14:45:42.635Z",
        "name": "Njega kose",
        "description": "",
        "handle": "ljepota-njega-kose",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": null,
        "rank": 3,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK4EKHB2R4FXMDWSTMP8X",
      "created_at": "2024-01-03T14:45:42.991Z",
      "updated_at": "2024-01-03T14:45:42.991Z",
      "name": "Usne",
      "description": "",
      "handle": "njega-koze-usne",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK42VB8WYE6GKG3FEJ41V",
      "rank": 4,
      "metadata": null,
      "category_children": [
        {
          "id": "pcat_01HK7WK701S9B9XXQVGG7NGVZJ",
          "created_at": "2024-01-03T14:45:45.599Z",
          "updated_at": "2024-01-03T14:45:45.599Z",
          "name": "Balzami",
          "description": "",
          "handle": "usne-balzami",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4EKHB2R4FXMDWSTMP8X",
          "rank": 0,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK70F3RVETQTBZKPWS37R",
          "created_at": "2024-01-03T14:45:45.614Z",
          "updated_at": "2024-01-03T14:45:45.614Z",
          "name": "Pilinzi",
          "description": "",
          "handle": "usne-pilinzi",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4EKHB2R4FXMDWSTMP8X",
          "rank": 1,
          "metadata": null
        }
      ],
      "parent_category": {
        "id": "pcat_01HK7WK42VB8WYE6GKG3FEJ41V",
        "created_at": "2024-01-03T14:45:42.618Z",
        "updated_at": "2024-01-03T14:45:42.618Z",
        "name": "Njega kože",
        "description": "",
        "handle": "ljepota-njega-koze",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": null,
        "rank": 2,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK5TZKHSQEEQKD1SY69AP",
      "created_at": "2024-01-03T14:45:44.408Z",
      "updated_at": "2024-01-03T14:45:44.408Z",
      "name": "Kompleti",
      "description": "",
      "handle": "njega-nakon-depilacije-i-brijanja-kompleti",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK481HCTWDV2XH5M4VNWK",
      "rank": 4,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK481HCTWDV2XH5M4VNWK",
        "created_at": "2024-01-03T14:45:42.782Z",
        "updated_at": "2024-01-03T14:45:42.782Z",
        "name": "Njega nakon depilacije i brijanja",
        "description": "",
        "handle": "brijanje-i-depilacija-njega-nakon-depilacije-i-brijanja",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK41MNZ3C795B2JN5JW0G",
        "rank": 3,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK60YMT7XET4GMGENVBD5",
      "created_at": "2024-01-03T14:45:44.603Z",
      "updated_at": "2024-01-03T14:45:44.603Z",
      "name": "Sapuni",
      "description": "",
      "handle": "njega-prije-depilacije-i-brijanja-sapuni",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK48MV187B5ZHKEDJWPEG",
      "rank": 4,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK48MV187B5ZHKEDJWPEG",
        "created_at": "2024-01-03T14:45:42.801Z",
        "updated_at": "2024-01-03T14:45:42.801Z",
        "name": "Njega prije depilacije i brijanja",
        "description": "",
        "handle": "brijanje-i-depilacija-njega-prije-depilacije-i-brijanja",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK41MNZ3C795B2JN5JW0G",
        "rank": 4,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK7713H8NCY8QPQHXS4C7",
      "created_at": "2024-01-03T14:45:45.823Z",
      "updated_at": "2024-01-03T14:45:45.823Z",
      "name": "Flasteri",
      "description": "",
      "handle": "oci-flasteri",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4J1NW5Q3B0PAC2JEQ2N",
      "rank": 4,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4J1NW5Q3B0PAC2JEQ2N",
        "created_at": "2024-01-03T14:45:43.101Z",
        "updated_at": "2024-01-03T14:45:43.101Z",
        "name": "Oči",
        "description": "",
        "handle": "njega-koze-oci_5391",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK42VB8WYE6GKG3FEJ41V",
        "rank": 6,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK9EF0NX5DZ6SRH5VGJMP",
      "created_at": "2024-01-03T14:45:48.108Z",
      "updated_at": "2024-01-03T14:45:48.108Z",
      "name": "Kombinacije sjenila i tuševa",
      "description": "",
      "handle": "oci-kombinacije-sjenila-i-tuseva",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4T4JAQRP4VBRRAJR36J",
      "rank": 4,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4T4JAQRP4VBRRAJR36J",
        "created_at": "2024-01-03T14:45:43.362Z",
        "updated_at": "2024-01-03T14:45:43.362Z",
        "name": "Oči",
        "description": "",
        "handle": "sminka-oci_11745",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK4495D212GWWBGHX6D9H",
        "rank": 4,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKCX4VR023GHVY6WQ81ZE",
      "created_at": "2024-01-03T14:45:51.650Z",
      "updated_at": "2024-01-03T14:45:51.650Z",
      "name": "Uvijači za trepavice",
      "description": "",
      "handle": "oci-uvijaci-za-trepavice",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK9X10701060SA690EKN6",
      "rank": 4,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK9X10701060SA690EKN6",
        "created_at": "2024-01-03T14:45:48.574Z",
        "updated_at": "2024-01-03T14:45:48.574Z",
        "name": "Oči",
        "description": "",
        "handle": "alati-i-pribor-za-sminkanje-oci",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK57XS2NQS0EGT358BW0Q",
        "rank": 2,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKDKE9ZG8EJ52S1F8T52R",
      "created_at": "2024-01-03T14:45:52.357Z",
      "updated_at": "2024-01-03T14:45:52.357Z",
      "name": "Uvijači za trepavice",
      "description": "",
      "handle": "oci-uvijaci-za-trepavice_25089",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WKB41CSS4HCT9G53TPNK8",
      "rank": 4,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WKB41CSS4HCT9G53TPNK8",
        "created_at": "2024-01-03T14:45:49.823Z",
        "updated_at": "2024-01-03T14:45:49.823Z",
        "name": "Oči",
        "description": "",
        "handle": "cetke-i-pribor-za-sminkanje-oci",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK5D0544YXJQT9G60XVED",
        "rank": 2,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKA0JKQFRYM82V76BW6P8",
      "created_at": "2024-01-03T14:45:48.685Z",
      "updated_at": "2024-01-03T14:45:48.685Z",
      "name": "Kruti sapuni",
      "description": "",
      "handle": "osobna-cistoca-kruti-sapuni",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4C6VG3TJWR51GB1ZQJW",
      "rank": 4,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4C6VG3TJWR51GB1ZQJW",
        "created_at": "2024-01-03T14:45:42.916Z",
        "updated_at": "2024-01-03T14:45:42.916Z",
        "name": "Osobna čistoća",
        "description": "",
        "handle": "kupanje-i-osobna-higijena-osobna-cistoca",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK42BQ7XFW617X5XP4BAR",
        "rank": 4,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK507QGAWMAS16QF11D2F",
      "created_at": "2024-01-03T14:45:43.550Z",
      "updated_at": "2024-01-03T14:45:43.550Z",
      "name": "Za djecu",
      "description": "",
      "handle": "parfemi-i-mirisi-za-djecu",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK45CQPDHWCYPEW3W862G",
      "rank": 4,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK45CQPDHWCYPEW3W862G",
        "created_at": "2024-01-03T14:45:42.699Z",
        "updated_at": "2024-01-03T14:45:42.699Z",
        "name": "Parfemi i mirisi",
        "description": "",
        "handle": "ljepota-parfemi-i-mirisi",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": null,
        "rank": 6,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK5DQTS5KH6Q63G306Q59",
      "created_at": "2024-01-03T14:45:43.984Z",
      "updated_at": "2024-01-03T14:45:43.984Z",
      "name": "Kompaktna ogledala",
      "description": "",
      "handle": "pribor-i-dodaci-kompaktna-ogledala",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK45W0F9X0VMBBCAVDPWD",
      "rank": 4,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK45W0F9X0VMBBCAVDPWD",
        "created_at": "2024-01-03T14:45:42.715Z",
        "updated_at": "2024-01-03T14:45:42.715Z",
        "name": "Pribor i dodaci",
        "description": "",
        "handle": "ljepota-pribor-i-dodaci",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": null,
        "rank": 7,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK8YW9M2BNG96M2719KAT",
      "created_at": "2024-01-03T14:45:47.610Z",
      "updated_at": "2024-01-03T14:45:47.610Z",
      "name": "Rašpice za poliranje",
      "description": "",
      "handle": "pribor-i-dodaci-raspice-za-poliranje",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4Q9J4VMNM7KX6F1K8RQ",
      "rank": 4,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4Q9J4VMNM7KX6F1K8RQ",
        "created_at": "2024-01-03T14:45:43.271Z",
        "updated_at": "2024-01-03T14:45:43.271Z",
        "name": "Pribor i dodaci",
        "description": "",
        "handle": "manikura-i-pedikura-pribor-i-dodaci",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK43VEK8SG6M4T81C3W37",
        "rank": 3,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKCHVK1KHFJHB8G26RKYE",
      "created_at": "2024-01-03T14:45:51.289Z",
      "updated_at": "2024-01-03T14:45:51.289Z",
      "name": "Sušila za nokte",
      "description": "",
      "handle": "pribor-susila-za-nokte",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK8TNZ6RWPNFYABFMKJ6W",
      "rank": 4,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK8TNZ6RWPNFYABFMKJ6W",
        "created_at": "2024-01-03T14:45:47.475Z",
        "updated_at": "2024-01-03T14:45:47.475Z",
        "name": "Pribor",
        "description": "",
        "handle": "dizajni-za-nokte-pribor",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK4PDYPCE7H5D1FAFMHNK",
        "rank": 7,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK79HGWWQVTTSXYPCBEBX",
      "created_at": "2024-01-03T14:45:45.903Z",
      "updated_at": "2024-01-03T14:45:45.903Z",
      "name": "Kopče",
      "description": "",
      "handle": "pribor-za-friziranje-kopce",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4JRZ92REX0CX4M9JWEM",
      "rank": 4,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4JRZ92REX0CX4M9JWEM",
        "created_at": "2024-01-03T14:45:43.125Z",
        "updated_at": "2024-01-03T14:45:43.125Z",
        "name": "Pribor za friziranje",
        "description": "",
        "handle": "njega-kose-pribor-za-friziranje",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK43BQJST1XZ18R4BST01",
        "rank": 0,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKCFKXJ6TBDPBDPBY2AHJ",
      "created_at": "2024-01-03T14:45:51.216Z",
      "updated_at": "2024-01-03T14:45:51.216Z",
      "name": "Kompleti za ukrašavanje",
      "description": "",
      "handle": "pribor-za-ukrasavanje-noktiju-kompleti-za-ukrasavanje",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK8QT38HTXECJJZ3XSY34",
      "rank": 4,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK8QT38HTXECJJZ3XSY34",
        "created_at": "2024-01-03T14:45:47.384Z",
        "updated_at": "2024-01-03T14:45:47.384Z",
        "name": "Pribor za ukrašavanje noktiju",
        "description": "",
        "handle": "dizajni-za-nokte-pribor-za-ukrasavanje-noktiju",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK4PDYPCE7H5D1FAFMHNK",
        "rank": 0,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK85EXQ86TH3BARB9TY22",
      "created_at": "2024-01-03T14:45:46.796Z",
      "updated_at": "2024-01-03T14:45:46.796Z",
      "name": "Krema za oblikovanje, glina i vosak",
      "description": "",
      "handle": "proizvodi-za-friziranje-krema-za-oblikovanje-glina-i-vosak",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4MPDDG329H66PT1KDYF",
      "rank": 4,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4MPDDG329H66PT1KDYF",
        "created_at": "2024-01-03T14:45:43.187Z",
        "updated_at": "2024-01-03T14:45:43.187Z",
        "name": "Proizvodi za friziranje",
        "description": "",
        "handle": "njega-kose-proizvodi-za-friziranje",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK43BQJST1XZ18R4BST01",
        "rank": 4,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK8AVPAD6VV1V7T9W67TT",
      "created_at": "2024-01-03T14:45:46.969Z",
      "updated_at": "2024-01-03T14:45:46.969Z",
      "name": "Šamponi",
      "description": "",
      "handle": "proizvodi-za-njegu-kose-samponi",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4N31HG0EQ98AA4ZHV8C",
      "rank": 4,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4N31HG0EQ98AA4ZHV8C",
        "created_at": "2024-01-03T14:45:43.201Z",
        "updated_at": "2024-01-03T14:45:43.201Z",
        "name": "Proizvodi za njegu kose",
        "description": "",
        "handle": "njega-kose-proizvodi-za-njegu-kose",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK43BQJST1XZ18R4BST01",
        "rank": 5,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK72PYNP409F6Y6C17NSJ",
      "created_at": "2024-01-03T14:45:45.683Z",
      "updated_at": "2024-01-03T14:45:45.683Z",
      "name": "Kreme za ruke i nokte",
      "description": "",
      "handle": "ruke-i-stopala-kreme-za-ruke-i-nokte",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4G7CJB2WFRCRPS0131Q",
      "rank": 4,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4G7CJB2WFRCRPS0131Q",
        "created_at": "2024-01-03T14:45:43.032Z",
        "updated_at": "2024-01-03T14:45:43.032Z",
        "name": "Ruke i stopala",
        "description": "",
        "handle": "njega-koze-ruke-i-stopala",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK42VB8WYE6GKG3FEJ41V",
        "rank": 5,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK5M442PQ4QMSQ7K41BEY",
      "created_at": "2024-01-03T14:45:44.194Z",
      "updated_at": "2024-01-03T14:45:44.194Z",
      "name": "Škare za kosu i bradu",
      "description": "",
      "handle": "skare-za-kosu-brijaci-i-aparati-za-brijanje-tijela-skare-za-kosu-i-bradu",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK47G3STXHFMNMZ4MMQ7G",
      "rank": 4,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK47G3STXHFMNMZ4MMQ7G",
        "created_at": "2024-01-03T14:45:42.766Z",
        "updated_at": "2024-01-03T14:45:42.766Z",
        "name": "Škare za kosu, brijači i aparati za brijanje tijela",
        "description": "",
        "handle": "brijanje-i-depilacija-skare-za-kosu-brijaci-i-aparati-za-brijanje-tijela",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK41MNZ3C795B2JN5JW0G",
        "rank": 2,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK4T4JAQRP4VBRRAJR36J",
      "created_at": "2024-01-03T14:45:43.362Z",
      "updated_at": "2024-01-03T14:45:43.362Z",
      "name": "Oči",
      "description": "",
      "handle": "sminka-oci_11745",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4495D212GWWBGHX6D9H",
      "rank": 4,
      "metadata": null,
      "category_children": [
        {
          "id": "pcat_01HK7WK9BHTCNMT54TDR5D4AXX",
          "created_at": "2024-01-03T14:45:48.011Z",
          "updated_at": "2024-01-03T14:45:48.011Z",
          "name": "Protiv podočnjaka",
          "description": "",
          "handle": "oci-protiv-podocnjaka",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4T4JAQRP4VBRRAJR36J",
          "rank": 0,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK9C3HT3DJYFQF5D0EQ8H",
          "created_at": "2024-01-03T14:45:48.032Z",
          "updated_at": "2024-01-03T14:45:48.032Z",
          "name": "Baze",
          "description": "",
          "handle": "oci-baze",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4T4JAQRP4VBRRAJR36J",
          "rank": 1,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK9CVWX3TR9SYFDVTVG6Z",
          "created_at": "2024-01-03T14:45:48.056Z",
          "updated_at": "2024-01-03T14:45:48.056Z",
          "name": "Šljokice",
          "description": "",
          "handle": "oci-sljokice",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4T4JAQRP4VBRRAJR36J",
          "rank": 2,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK9E02QDSEW3NE9SF8N4Y",
          "created_at": "2024-01-03T14:45:48.094Z",
          "updated_at": "2024-01-03T14:45:48.094Z",
          "name": "Boje za obrve",
          "description": "",
          "handle": "oci-boje-za-obrve",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4T4JAQRP4VBRRAJR36J",
          "rank": 3,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK9EF0NX5DZ6SRH5VGJMP",
          "created_at": "2024-01-03T14:45:48.108Z",
          "updated_at": "2024-01-03T14:45:48.108Z",
          "name": "Kombinacije sjenila i tuševa",
          "description": "",
          "handle": "oci-kombinacije-sjenila-i-tuseva",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4T4JAQRP4VBRRAJR36J",
          "rank": 4,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK9EYQ06P2C20N9C60G50",
          "created_at": "2024-01-03T14:45:48.124Z",
          "updated_at": "2024-01-03T14:45:48.124Z",
          "name": "Tuševi za oči",
          "description": "",
          "handle": "oci-tusevi-za-oci",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4T4JAQRP4VBRRAJR36J",
          "rank": 5,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK9FAKX44P3VY1K0H93Q9",
          "created_at": "2024-01-03T14:45:48.136Z",
          "updated_at": "2024-01-03T14:45:48.136Z",
          "name": "Olovke za oči",
          "description": "",
          "handle": "oci-olovke-za-oci",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4T4JAQRP4VBRRAJR36J",
          "rank": 6,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK9FPHAQ2N3K83Y0MH8N4",
          "created_at": "2024-01-03T14:45:48.148Z",
          "updated_at": "2024-01-03T14:45:48.148Z",
          "name": "Maskare",
          "description": "",
          "handle": "oci-maskare",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4T4JAQRP4VBRRAJR36J",
          "rank": 7,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK9G5BQV456SXH5ZVJ75J",
          "created_at": "2024-01-03T14:45:48.163Z",
          "updated_at": "2024-01-03T14:45:48.163Z",
          "name": "Sjenila za oči",
          "description": "",
          "handle": "oci-sjenila-za-oci",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4T4JAQRP4VBRRAJR36J",
          "rank": 8,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK9GJGGVJZK3DYK9KC2PX",
          "created_at": "2024-01-03T14:45:48.176Z",
          "updated_at": "2024-01-03T14:45:48.176Z",
          "name": "Tretmani za trepavice",
          "description": "",
          "handle": "oci-tretmani-za-trepavice",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4T4JAQRP4VBRRAJR36J",
          "rank": 9,
          "metadata": null
        }
      ],
      "parent_category": {
        "id": "pcat_01HK7WK4495D212GWWBGHX6D9H",
        "created_at": "2024-01-03T14:45:42.665Z",
        "updated_at": "2024-01-03T14:45:42.665Z",
        "name": "Šminka",
        "description": "",
        "handle": "ljepota-sminka",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": null,
        "rank": 5,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKAVZJBY2Q2C480K3P722",
      "created_at": "2024-01-03T14:45:49.561Z",
      "updated_at": "2024-01-03T14:45:49.561Z",
      "name": "Bronzeri za tijelo",
      "description": "",
      "handle": "tijelo-bronzeri-za-tijelo",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4RBTHMFSNA47WTF4D6J",
      "rank": 4,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4RBTHMFSNA47WTF4D6J",
        "created_at": "2024-01-03T14:45:43.304Z",
        "updated_at": "2024-01-03T14:45:43.304Z",
        "name": "Tijelo",
        "description": "",
        "handle": "sminka-tijelo_11713",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK4495D212GWWBGHX6D9H",
        "rank": 1,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK6XS2PQQ74NJ8FFA3Z56",
      "created_at": "2024-01-03T14:45:45.527Z",
      "updated_at": "2024-01-03T14:45:45.527Z",
      "name": "Tonici",
      "description": "",
      "handle": "tijelo-tonici",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4DMG2Q4J3RMF32RA7Y1",
      "rank": 4,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4DMG2Q4J3RMF32RA7Y1",
        "created_at": "2024-01-03T14:45:42.962Z",
        "updated_at": "2024-01-03T14:45:42.962Z",
        "name": "Tijelo",
        "description": "",
        "handle": "njega-koze-tijelo_5355",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK42VB8WYE6GKG3FEJ41V",
        "rank": 2,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKAMBXQ4F92A7N7VK9M86",
      "created_at": "2024-01-03T14:45:49.314Z",
      "updated_at": "2024-01-03T14:45:49.314Z",
      "name": "Njega kutikula",
      "description": "",
      "handle": "tretmani-za-nokte-njega-kutikula",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4PWXATZSWB566TB87WB",
      "rank": 4,
      "metadata": null,
      "category_children": [
        {
          "id": "pcat_01HK7WKD6VP1PKDY9MWS0RQFAF",
          "created_at": "2024-01-03T14:45:51.961Z",
          "updated_at": "2024-01-03T14:45:51.961Z",
          "name": "Kreme",
          "description": "",
          "handle": "njega-kutikula-kreme",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WKAMBXQ4F92A7N7VK9M86",
          "rank": 0,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKD775R1GVQAMG107HCFA",
          "created_at": "2024-01-03T14:45:51.973Z",
          "updated_at": "2024-01-03T14:45:51.973Z",
          "name": "Ulja",
          "description": "",
          "handle": "njega-kutikula-ulja",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WKAMBXQ4F92A7N7VK9M86",
          "rank": 1,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKD7P9N9AAGANWGJJTATP",
          "created_at": "2024-01-03T14:45:51.987Z",
          "updated_at": "2024-01-03T14:45:51.987Z",
          "name": "Sredstvo za uklanjanje kutikula",
          "description": "",
          "handle": "njega-kutikula-sredstvo-za-uklanjanje-kutikula",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WKAMBXQ4F92A7N7VK9M86",
          "rank": 2,
          "metadata": null
        }
      ],
      "parent_category": {
        "id": "pcat_01HK7WK4PWXATZSWB566TB87WB",
        "created_at": "2024-01-03T14:45:43.258Z",
        "updated_at": "2024-01-03T14:45:43.258Z",
        "name": "Tretmani za nokte",
        "description": "",
        "handle": "manikura-i-pedikura-tretmani-za-nokte",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK43VEK8SG6M4T81C3W37",
        "rank": 2,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK68D07TWPZME6GB2Q1K0",
      "created_at": "2024-01-03T14:45:44.839Z",
      "updated_at": "2024-01-03T14:45:44.839Z",
      "name": "Depilatori i dodaci",
      "description": "",
      "handle": "uklanjanje-dlaka-depilatori-i-dodaci",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK493C3GNJNECHNPJ0K2N",
      "rank": 4,
      "metadata": null,
      "category_children": [
        {
          "id": "pcat_01HK7WKBR598DYYEJ6Y4MF9HP7",
          "created_at": "2024-01-03T14:45:50.467Z",
          "updated_at": "2024-01-03T14:45:50.467Z",
          "name": "Pribor i rezervni dijelovi",
          "description": "",
          "handle": "depilatori-i-dodaci-pribor-i-rezervni-dijelovi",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK68D07TWPZME6GB2Q1K0",
          "rank": 0,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKBRKMPVAA7GVTB52WGYX",
          "created_at": "2024-01-03T14:45:50.481Z",
          "updated_at": "2024-01-03T14:45:50.481Z",
          "name": "Depilatori",
          "description": "",
          "handle": "depilatori-i-dodaci-depilatori",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK68D07TWPZME6GB2Q1K0",
          "rank": 1,
          "metadata": null
        }
      ],
      "parent_category": {
        "id": "pcat_01HK7WK493C3GNJNECHNPJ0K2N",
        "created_at": "2024-01-03T14:45:42.817Z",
        "updated_at": "2024-01-03T14:45:42.817Z",
        "name": "Uklanjanje dlaka",
        "description": "",
        "handle": "brijanje-i-depilacija-uklanjanje-dlaka",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK41MNZ3C795B2JN5JW0G",
        "rank": 5,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKADNP8PXDVEGQ9JYFQ4X",
      "created_at": "2024-01-03T14:45:49.106Z",
      "updated_at": "2024-01-03T14:45:49.106Z",
      "name": "Glave od stiropora",
      "description": "",
      "handle": "umeci-za-kosu-perike-i-dodaci-glave-od-stiropora_20954",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK5A5XWEMK8CFBX8HWCSH",
      "rank": 4,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK5A5XWEMK8CFBX8HWCSH",
        "created_at": "2024-01-03T14:45:43.874Z",
        "updated_at": "2024-01-03T14:45:43.874Z",
        "name": "Umeci za kosu, perike i dodaci",
        "description": "",
        "handle": "njega-kose-umeci-za-kosu-perike-i-dodaci",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK43BQJST1XZ18R4BST01",
        "rank": 7,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKCNFZR2E3ZRNREXPM3FF",
      "created_at": "2024-01-03T14:45:51.405Z",
      "updated_at": "2024-01-03T14:45:51.405Z",
      "name": "Vrhovi za nokte",
      "description": "",
      "handle": "umjetni-nokti-i-pribor-vrhovi-za-nokte",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK8V224HR9CV9810ZE5S6",
      "rank": 4,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK8V224HR9CV9810ZE5S6",
        "created_at": "2024-01-03T14:45:47.487Z",
        "updated_at": "2024-01-03T14:45:47.487Z",
        "name": "Umjetni nokti i pribor",
        "description": "",
        "handle": "dizajni-za-nokte-umjetni-nokti-i-pribor",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK4PDYPCE7H5D1FAFMHNK",
        "rank": 8,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK9B0B3KBQR19D0JS7WCB",
      "created_at": "2024-01-03T14:45:47.997Z",
      "updated_at": "2024-01-03T14:45:47.997Z",
      "name": "Boje i bojila",
      "description": "",
      "handle": "usne-boje-i-bojila",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4SPEA6NYM5SZ5DDC5A8",
      "rank": 4,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4SPEA6NYM5SZ5DDC5A8",
        "created_at": "2024-01-03T14:45:43.348Z",
        "updated_at": "2024-01-03T14:45:43.348Z",
        "name": "Usne",
        "description": "",
        "handle": "sminka-usne_11739",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK4495D212GWWBGHX6D9H",
        "rank": 3,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKC7HNQ1VB947PA3HXFHT",
      "created_at": "2024-01-03T14:45:50.956Z",
      "updated_at": "2024-01-03T14:45:50.956Z",
      "name": "Moussevi",
      "description": "",
      "handle": "za-hidrataciju-moussevi",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK6WA7CDBMWAWAHDXNKA8",
      "rank": 4,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK6WA7CDBMWAWAHDXNKA8",
        "created_at": "2024-01-03T14:45:45.480Z",
        "updated_at": "2024-01-03T14:45:45.480Z",
        "name": "Za hidrataciju",
        "description": "",
        "handle": "tijelo-za-hidrataciju",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK4DMG2Q4J3RMF32RA7Y1",
        "rank": 1,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKBESB8GYGN0HD4SRNN21",
      "created_at": "2024-01-03T14:45:50.167Z",
      "updated_at": "2024-01-03T14:45:50.167Z",
      "name": "Zamjenske oštrice",
      "description": "",
      "handle": "zamjenske-osztrice",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK5GP4MZXQ69EPS0WG1AR",
      "rank": 4,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK5GP4MZXQ69EPS0WG1AR",
        "created_at": "2024-01-03T14:45:44.084Z",
        "updated_at": "2024-01-03T14:45:44.084Z",
        "name": "Pribor",
        "description": "",
        "handle": "elektricni-brijaci-pribor",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK46YG69NRB3HQKNMNZ0C",
        "rank": 0,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKAZX7KTPB76GCBMFEHGP",
      "created_at": "2024-01-03T14:45:49.684Z",
      "updated_at": "2024-01-03T14:45:49.684Z",
      "name": "Aftershave",
      "description": "",
      "handle": "za-muskarce-aftershave",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4WXV52RYH206J4P8TD0",
      "rank": 4,
      "metadata": null,
      "category_children": [
        {
          "id": "pcat_01HK7WKD82QDK29ZG9ZA9NV18N",
          "created_at": "2024-01-03T14:45:52.001Z",
          "updated_at": "2024-01-03T14:45:52.001Z",
          "name": "Balzami",
          "description": "",
          "handle": "aftershave-balzami",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WKAZX7KTPB76GCBMFEHGP",
          "rank": 0,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKD9ESHRY3TEM1R9B598G",
          "created_at": "2024-01-03T14:45:52.038Z",
          "updated_at": "2024-01-03T14:45:52.038Z",
          "name": "Gelovi",
          "description": "",
          "handle": "aftershave-gelovi",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WKAZX7KTPB76GCBMFEHGP",
          "rank": 1,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKDBR1SC4CRD0P5EGR04W",
          "created_at": "2024-01-03T14:45:52.111Z",
          "updated_at": "2024-01-03T14:45:52.111Z",
          "name": "Losioni i tekućine",
          "description": "",
          "handle": "aftershave-losioni-i-tekucine",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WKAZX7KTPB76GCBMFEHGP",
          "rank": 2,
          "metadata": null
        }
      ],
      "parent_category": {
        "id": "pcat_01HK7WK4WXV52RYH206J4P8TD0",
        "created_at": "2024-01-03T14:45:43.446Z",
        "updated_at": "2024-01-03T14:45:43.446Z",
        "name": "Za muškarce",
        "description": "",
        "handle": "parfemi-i-mirisi-za-muskarce_14094",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK45CQPDHWCYPEW3W862G",
        "rank": 2,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK9PFK70M91JAQ3ZQQSYQ",
      "created_at": "2024-01-03T14:45:48.362Z",
      "updated_at": "2024-01-03T14:45:48.362Z",
      "name": "Setovi",
      "description": "",
      "handle": "za-zene-setovi",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4YNQSS786T0PHYXE7FP",
      "rank": 4,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4YNQSS786T0PHYXE7FP",
        "created_at": "2024-01-03T14:45:43.492Z",
        "updated_at": "2024-01-03T14:45:43.492Z",
        "name": "Za žene",
        "description": "",
        "handle": "parfemi-i-mirisi-za-zene_14100",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK45CQPDHWCYPEW3W862G",
        "rank": 3,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK7KK5NMP8FCK5JTVY33D",
      "created_at": "2024-01-03T14:45:46.224Z",
      "updated_at": "2024-01-03T14:45:46.224Z",
      "name": "Izbjeljivači ",
      "description": "",
      "handle": "bojanje-kose-izbjeljivaci",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4KQVZQK6XPGZJEJ50QZ",
      "rank": 5,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4KQVZQK6XPGZJEJ50QZ",
        "created_at": "2024-01-03T14:45:43.157Z",
        "updated_at": "2024-01-03T14:45:43.157Z",
        "name": "Bojanje kose",
        "description": "",
        "handle": "njega-kose-bojanje-kose",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK43BQJST1XZ18R4BST01",
        "rank": 2,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK493C3GNJNECHNPJ0K2N",
      "created_at": "2024-01-03T14:45:42.817Z",
      "updated_at": "2024-01-03T14:45:42.817Z",
      "name": "Uklanjanje dlaka",
      "description": "",
      "handle": "brijanje-i-depilacija-uklanjanje-dlaka",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK41MNZ3C795B2JN5JW0G",
      "rank": 5,
      "metadata": null,
      "category_children": [
        {
          "id": "pcat_01HK7WK62WN0JV5Y8QHXV1J1X3",
          "created_at": "2024-01-03T14:45:44.665Z",
          "updated_at": "2024-01-03T14:45:44.665Z",
          "name": "Kreme za depilaciju",
          "description": "",
          "handle": "uklanjanje-dlaka-kreme-za-depilaciju",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK493C3GNJNECHNPJ0K2N",
          "rank": 0,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK649EWGYVF36CJ6071RM",
          "created_at": "2024-01-03T14:45:44.706Z",
          "updated_at": "2024-01-03T14:45:44.706Z",
          "name": "Depilacija voskom",
          "description": "",
          "handle": "uklanjanje-dlaka-depilacija-voskom",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK493C3GNJNECHNPJ0K2N",
          "rank": 1,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK65RQ00RY9H3YFT5TEA6",
          "created_at": "2024-01-03T14:45:44.751Z",
          "updated_at": "2024-01-03T14:45:44.751Z",
          "name": "Depilatori s impulsnim svjetlom",
          "description": "",
          "handle": "uklanjanje-dlaka-depilatori-s-impulsnim-svjetlom",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK493C3GNJNECHNPJ0K2N",
          "rank": 2,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK67B1T43G9Z84TVHAD7Q",
          "created_at": "2024-01-03T14:45:44.804Z",
          "updated_at": "2024-01-03T14:45:44.804Z",
          "name": "Laserski depilatori",
          "description": "",
          "handle": "uklanjanje-dlaka-laserski-depilatori",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK493C3GNJNECHNPJ0K2N",
          "rank": 3,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK68D07TWPZME6GB2Q1K0",
          "created_at": "2024-01-03T14:45:44.839Z",
          "updated_at": "2024-01-03T14:45:44.839Z",
          "name": "Depilatori i dodaci",
          "description": "",
          "handle": "uklanjanje-dlaka-depilatori-i-dodaci",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK493C3GNJNECHNPJ0K2N",
          "rank": 4,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK69HMDA4WZERFFJ8FTAS",
          "created_at": "2024-01-03T14:45:44.873Z",
          "updated_at": "2024-01-03T14:45:44.873Z",
          "name": "Sredstva za depilaciju lica",
          "description": "",
          "handle": "uklanjanje-dlaka-sredstva-za-depilaciju-lica",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK493C3GNJNECHNPJ0K2N",
          "rank": 5,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK6AXSHZCY7YKHK4XSMZT",
          "created_at": "2024-01-03T14:45:44.916Z",
          "updated_at": "2024-01-03T14:45:44.916Z",
          "name": "Pincete",
          "description": "",
          "handle": "uklanjanje-dlaka-pincete",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK493C3GNJNECHNPJ0K2N",
          "rank": 6,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK6BPVGFRJYTDV9QD1W1Q",
          "created_at": "2024-01-03T14:45:44.945Z",
          "updated_at": "2024-01-03T14:45:44.945Z",
          "name": "Sustavi za depilaciju elektrolizom",
          "description": "",
          "handle": "uklanjanje-dlaka-sustavi-za-depilaciju-elektrolizom",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK493C3GNJNECHNPJ0K2N",
          "rank": 7,
          "metadata": null
        }
      ],
      "parent_category": {
        "id": "pcat_01HK7WK41MNZ3C795B2JN5JW0G",
        "created_at": "2024-01-03T14:45:42.579Z",
        "updated_at": "2024-01-03T14:45:42.579Z",
        "name": "Brijanje i depilacija",
        "description": "",
        "handle": "ljepota-brijanje-i-depilacija",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": null,
        "rank": 0,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKB5DZ8TD3E1ETT62YDWN",
      "created_at": "2024-01-03T14:45:49.867Z",
      "updated_at": "2024-01-03T14:45:49.867Z",
      "name": "Šiljilo",
      "description": "",
      "handle": "cetke-i-pribor-za-sminkanje-siljilo",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK5D0544YXJQT9G60XVED",
      "rank": 5,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK5D0544YXJQT9G60XVED",
        "created_at": "2024-01-03T14:45:43.966Z",
        "updated_at": "2024-01-03T14:45:43.966Z",
        "name": "Četke i pribor za šminkanje",
        "description": "",
        "handle": "pribor-i-dodaci-cetke-i-pribor-za-sminkanje",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK45W0F9X0VMBBCAVDPWD",
        "rank": 3,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKD2B07ZK048927Y7DDGY",
      "created_at": "2024-01-03T14:45:51.817Z",
      "updated_at": "2024-01-03T14:45:51.817Z",
      "name": "Gelovi",
      "description": "",
      "handle": "cistaci-gelovi",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WKA2274BVZPY0TZ6SN7Z9",
      "rank": 5,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WKA2274BVZPY0TZ6SN7Z9",
        "created_at": "2024-01-03T14:45:48.735Z",
        "updated_at": "2024-01-03T14:45:48.735Z",
        "name": "Čistači",
        "description": "",
        "handle": "lice-cistaci_20934",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK4CQ8JZ3NZ6QJC2MQW11",
        "rank": 5,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKBP9JSSE7NB6FZYCTPHP",
      "created_at": "2024-01-03T14:45:50.407Z",
      "updated_at": "2024-01-03T14:45:50.407Z",
      "name": "Šećerna pasta",
      "description": "",
      "handle": "depilacija-voskom-secerna-pasta",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK649EWGYVF36CJ6071RM",
      "rank": 5,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK649EWGYVF36CJ6071RM",
        "created_at": "2024-01-03T14:45:44.706Z",
        "updated_at": "2024-01-03T14:45:44.706Z",
        "name": "Depilacija voskom",
        "description": "",
        "handle": "uklanjanje-dlaka-depilacija-voskom",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK493C3GNJNECHNPJ0K2N",
        "rank": 1,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK8SVF49V7SYBDPB6DEPE",
      "created_at": "2024-01-03T14:45:47.449Z",
      "updated_at": "2024-01-03T14:45:47.449Z",
      "name": "Odstranjivač laka za nokte",
      "description": "",
      "handle": "dizajni-za-nokte-odstranjivac-laka-za-nokte",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4PDYPCE7H5D1FAFMHNK",
      "rank": 5,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4PDYPCE7H5D1FAFMHNK",
        "created_at": "2024-01-03T14:45:43.244Z",
        "updated_at": "2024-01-03T14:45:43.244Z",
        "name": "Dizajni za nokte",
        "description": "",
        "handle": "manikura-i-pedikura-dizajni-za-nokte",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK43VEK8SG6M4T81C3W37",
        "rank": 1,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK81M2QBVRFXVNA1JE0VY",
      "created_at": "2024-01-03T14:45:46.671Z",
      "updated_at": "2024-01-03T14:45:46.671Z",
      "name": "Boce s raspršivačem ",
      "description": "",
      "handle": "frizerski-namjestaj-i-potrepstine-boce-s-rasprsivacem",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4M7JE8S0KGQCMWF47WJ",
      "rank": 5,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4M7JE8S0KGQCMWF47WJ",
        "created_at": "2024-01-03T14:45:43.173Z",
        "updated_at": "2024-01-03T14:45:43.173Z",
        "name": "Frizerski namještaj i potrepštine",
        "description": "",
        "handle": "njega-kose-frizerski-namjestaj-i-potrepstine",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK43BQJST1XZ18R4BST01",
        "rank": 3,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK7DVKAX1WPRXXVAF05GR",
      "created_at": "2024-01-03T14:45:46.041Z",
      "updated_at": "2024-01-03T14:45:46.041Z",
      "name": "Češljevi",
      "description": "",
      "handle": "frizerski-uredaji-i-pribor-cesljevi",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4K84XGGV7XG3TFC6W69",
      "rank": 5,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4K84XGGV7XG3TFC6W69",
        "created_at": "2024-01-03T14:45:43.141Z",
        "updated_at": "2024-01-03T14:45:43.141Z",
        "name": "Frizerski uređaji i pribor",
        "description": "",
        "handle": "njega-kose-frizerski-uredaji-i-pribor",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK43BQJST1XZ18R4BST01",
        "rank": 1,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK58KGH06FH7809X0WYY9",
      "created_at": "2024-01-03T14:45:43.824Z",
      "updated_at": "2024-01-03T14:45:43.824Z",
      "name": "Pilinzi i tretmani za tijelo",
      "description": "",
      "handle": "kupanje-i-osobna-higijena-pilinzi-i-tretmani-za-tijelo",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK42BQ7XFW617X5XP4BAR",
      "rank": 5,
      "metadata": null,
      "category_children": [
        {
          "id": "pcat_01HK7WK9ZCEMS8FR42Y1078E5P",
          "created_at": "2024-01-03T14:45:48.649Z",
          "updated_at": "2024-01-03T14:45:48.649Z",
          "name": "Hidratantni sprejevi za tijelo",
          "description": "",
          "handle": "pilinzi-i-tretmani-za-tijelo-hidratantni-sprejevi-za-tijelo",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK58KGH06FH7809X0WYY9",
          "rank": 0,
          "metadata": null
        }
      ],
      "parent_category": {
        "id": "pcat_01HK7WK42BQ7XFW617X5XP4BAR",
        "created_at": "2024-01-03T14:45:42.602Z",
        "updated_at": "2024-01-03T14:45:42.602Z",
        "name": "Kupanje i osobna higijena",
        "description": "",
        "handle": "ljepota-kupanje-i-osobna-higijena",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": null,
        "rank": 1,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKA2274BVZPY0TZ6SN7Z9",
      "created_at": "2024-01-03T14:45:48.735Z",
      "updated_at": "2024-01-03T14:45:48.735Z",
      "name": "Čistači",
      "description": "",
      "handle": "lice-cistaci_20934",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4CQ8JZ3NZ6QJC2MQW11",
      "rank": 5,
      "metadata": null,
      "category_children": [
        {
          "id": "pcat_01HK7WKCZK3RJ0ZFS1JKA7PPVC",
          "created_at": "2024-01-03T14:45:51.729Z",
          "updated_at": "2024-01-03T14:45:51.729Z",
          "name": "Krpe i maramice",
          "description": "",
          "handle": "cistaci-krpe-i-maramice",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WKA2274BVZPY0TZ6SN7Z9",
          "rank": 0,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKD01JBPNDMDRXB6V683Q",
          "created_at": "2024-01-03T14:45:51.742Z",
          "updated_at": "2024-01-03T14:45:51.742Z",
          "name": "Kreme i mlijeka",
          "description": "",
          "handle": "cistaci-kreme-i-mlijeka",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WKA2274BVZPY0TZ6SN7Z9",
          "rank": 1,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKD0FXRAFFJ1YVT5G9802",
          "created_at": "2024-01-03T14:45:51.757Z",
          "updated_at": "2024-01-03T14:45:51.757Z",
          "name": "Gelovi i pjene",
          "description": "",
          "handle": "cistaci-gelovi-i-pjene",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WKA2274BVZPY0TZ6SN7Z9",
          "rank": 2,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKD0TXJET8M1N2Q3P1F2S",
          "created_at": "2024-01-03T14:45:51.769Z",
          "updated_at": "2024-01-03T14:45:51.769Z",
          "name": "Jastučići",
          "description": "",
          "handle": "cistaci-jastucici",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WKA2274BVZPY0TZ6SN7Z9",
          "rank": 3,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKD1TMD8W2DN1YV054JNW",
          "created_at": "2024-01-03T14:45:51.798Z",
          "updated_at": "2024-01-03T14:45:51.798Z",
          "name": "Sapuni",
          "description": "",
          "handle": "cistaci-sapuni_25055",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WKA2274BVZPY0TZ6SN7Z9",
          "rank": 4,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKD2B07ZK048927Y7DDGY",
          "created_at": "2024-01-03T14:45:51.817Z",
          "updated_at": "2024-01-03T14:45:51.817Z",
          "name": "Gelovi",
          "description": "",
          "handle": "cistaci-gelovi",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WKA2274BVZPY0TZ6SN7Z9",
          "rank": 5,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKD2T98E37CJP8WT6VGA0",
          "created_at": "2024-01-03T14:45:51.832Z",
          "updated_at": "2024-01-03T14:45:51.832Z",
          "name": "Pjene",
          "description": "",
          "handle": "cistaci-pjene",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WKA2274BVZPY0TZ6SN7Z9",
          "rank": 6,
          "metadata": null
        }
      ],
      "parent_category": {
        "id": "pcat_01HK7WK4CQ8JZ3NZ6QJC2MQW11",
        "created_at": "2024-01-03T14:45:42.933Z",
        "updated_at": "2024-01-03T14:45:42.933Z",
        "name": "Lice",
        "description": "",
        "handle": "njega-koze-lice_5325",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK42VB8WYE6GKG3FEJ41V",
        "rank": 0,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK93RFWCDBWV1PPF4R8KQ",
      "created_at": "2024-01-03T14:45:47.766Z",
      "updated_at": "2024-01-03T14:45:47.766Z",
      "name": "Iluminatori",
      "description": "",
      "handle": "lice-iluminatori",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4QT18DTEY1R94HB0RRX",
      "rank": 5,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4QT18DTEY1R94HB0RRX",
        "created_at": "2024-01-03T14:45:43.284Z",
        "updated_at": "2024-01-03T14:45:43.284Z",
        "name": "Lice",
        "description": "",
        "handle": "sminka-lice_11703",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK4495D212GWWBGHX6D9H",
        "rank": 0,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK4495D212GWWBGHX6D9H",
      "created_at": "2024-01-03T14:45:42.665Z",
      "updated_at": "2024-01-03T14:45:42.665Z",
      "name": "Šminka",
      "description": "",
      "handle": "ljepota-sminka",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": null,
      "rank": 5,
      "metadata": null,
      "category_children": [
        {
          "id": "pcat_01HK7WK4QT18DTEY1R94HB0RRX",
          "created_at": "2024-01-03T14:45:43.284Z",
          "updated_at": "2024-01-03T14:45:43.284Z",
          "name": "Lice",
          "description": "",
          "handle": "sminka-lice_11703",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4495D212GWWBGHX6D9H",
          "rank": 0,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK4RBTHMFSNA47WTF4D6J",
          "created_at": "2024-01-03T14:45:43.304Z",
          "updated_at": "2024-01-03T14:45:43.304Z",
          "name": "Tijelo",
          "description": "",
          "handle": "sminka-tijelo_11713",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4495D212GWWBGHX6D9H",
          "rank": 1,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK4S01M9B9XAM3VHQQC3N",
          "created_at": "2024-01-03T14:45:43.324Z",
          "updated_at": "2024-01-03T14:45:43.324Z",
          "name": "Setovi za šminkanje",
          "description": "",
          "handle": "sminka-setovi-za-sminkanje",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4495D212GWWBGHX6D9H",
          "rank": 2,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK4SPEA6NYM5SZ5DDC5A8",
          "created_at": "2024-01-03T14:45:43.348Z",
          "updated_at": "2024-01-03T14:45:43.348Z",
          "name": "Usne",
          "description": "",
          "handle": "sminka-usne_11739",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4495D212GWWBGHX6D9H",
          "rank": 3,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK4T4JAQRP4VBRRAJR36J",
          "created_at": "2024-01-03T14:45:43.362Z",
          "updated_at": "2024-01-03T14:45:43.362Z",
          "name": "Oči",
          "description": "",
          "handle": "sminka-oci_11745",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4495D212GWWBGHX6D9H",
          "rank": 4,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK4TMP63BG9ZEYT8MYXG4",
          "created_at": "2024-01-03T14:45:43.376Z",
          "updated_at": "2024-01-03T14:45:43.376Z",
          "name": "Palete šminke",
          "description": "",
          "handle": "sminka-palete-sminke",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4495D212GWWBGHX6D9H",
          "rank": 5,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK5B70957TDHQA8K7KARN",
          "created_at": "2024-01-03T14:45:43.909Z",
          "updated_at": "2024-01-03T14:45:43.909Z",
          "name": "Sredstva za uklanjanje šminke",
          "description": "",
          "handle": "sminka-sredstva-za-uklanjanje-sminke",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4495D212GWWBGHX6D9H",
          "rank": 6,
          "metadata": null
        }
      ],
      "parent_category": null
    },
    {
      "id": "pcat_01HK7WK4N31HG0EQ98AA4ZHV8C",
      "created_at": "2024-01-03T14:45:43.201Z",
      "updated_at": "2024-01-03T14:45:43.201Z",
      "name": "Proizvodi za njegu kose",
      "description": "",
      "handle": "njega-kose-proizvodi-za-njegu-kose",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK43BQJST1XZ18R4BST01",
      "rank": 5,
      "metadata": null,
      "category_children": [
        {
          "id": "pcat_01HK7WK88JKR3GYNG6J8Z8PP3K",
          "created_at": "2024-01-03T14:45:46.896Z",
          "updated_at": "2024-01-03T14:45:46.896Z",
          "name": "Pribor za ravnanje",
          "description": "",
          "handle": "proizvodi-za-njegu-kose-pribor-za-ravnanje",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4N31HG0EQ98AA4ZHV8C",
          "rank": 0,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK892R5ZJCFZAJMW9MNXW",
          "created_at": "2024-01-03T14:45:46.911Z",
          "updated_at": "2024-01-03T14:45:46.911Z",
          "name": "Pribor za trajnu",
          "description": "",
          "handle": "proizvodi-za-njegu-kose-pribor-za-trajnu",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4N31HG0EQ98AA4ZHV8C",
          "rank": 1,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK89WEG4QS2Y36N8HX9HG",
          "created_at": "2024-01-03T14:45:46.937Z",
          "updated_at": "2024-01-03T14:45:46.937Z",
          "name": "Ulja za kosu",
          "description": "",
          "handle": "proizvodi-za-njegu-kose-ulja-za-kosu",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4N31HG0EQ98AA4ZHV8C",
          "rank": 2,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK8ACR52ST9TRTPHNMP9M",
          "created_at": "2024-01-03T14:45:46.953Z",
          "updated_at": "2024-01-03T14:45:46.953Z",
          "name": "Regeneratori",
          "description": "",
          "handle": "proizvodi-za-njegu-kose-regeneratori",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4N31HG0EQ98AA4ZHV8C",
          "rank": 3,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK8AVPAD6VV1V7T9W67TT",
          "created_at": "2024-01-03T14:45:46.969Z",
          "updated_at": "2024-01-03T14:45:46.969Z",
          "name": "Šamponi",
          "description": "",
          "handle": "proizvodi-za-njegu-kose-samponi",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4N31HG0EQ98AA4ZHV8C",
          "rank": 4,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK8BBW1PXF9W2P29CG3CK",
          "created_at": "2024-01-03T14:45:46.984Z",
          "updated_at": "2024-01-03T14:45:46.984Z",
          "name": "3 u 1 šamponi",
          "description": "",
          "handle": "proizvodi-za-njegu-kose-3-u-1-samponi",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4N31HG0EQ98AA4ZHV8C",
          "rank": 5,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK8BZFPP6EK6PGF0N95BP",
          "created_at": "2024-01-03T14:45:47.004Z",
          "updated_at": "2024-01-03T14:45:47.004Z",
          "name": "Suhi šamponi",
          "description": "",
          "handle": "proizvodi-za-njegu-kose-suhi-samponi",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4N31HG0EQ98AA4ZHV8C",
          "rank": 6,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK8CFNNBB05HEKP4C9CEA",
          "created_at": "2024-01-03T14:45:47.020Z",
          "updated_at": "2024-01-03T14:45:47.020Z",
          "name": "Šamponi i regeneratori",
          "description": "",
          "handle": "proizvodi-za-njegu-kose-samponi-i-regeneratori",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4N31HG0EQ98AA4ZHV8C",
          "rank": 7,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK8D0258MBSFNMM9ACK2W",
          "created_at": "2024-01-03T14:45:47.037Z",
          "updated_at": "2024-01-03T14:45:47.037Z",
          "name": "Njega kose i vlasišta",
          "description": "",
          "handle": "proizvodi-za-njegu-kose-njega-kose-i-vlasista",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4N31HG0EQ98AA4ZHV8C",
          "rank": 8,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK8G2Z3AJKVQHHMP19XTT",
          "created_at": "2024-01-03T14:45:47.125Z",
          "updated_at": "2024-01-03T14:45:47.125Z",
          "name": "Setovi šampona i regeneratora",
          "description": "",
          "handle": "proizvodi-za-njegu-kose-setovi-sampona-i-regeneratora",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4N31HG0EQ98AA4ZHV8C",
          "rank": 9,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK8H4VMDHCKXWCSSNSGS8",
          "created_at": "2024-01-03T14:45:47.170Z",
          "updated_at": "2024-01-03T14:45:47.170Z",
          "name": "Maske",
          "description": "",
          "handle": "proizvodi-za-njegu-kose-maske",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4N31HG0EQ98AA4ZHV8C",
          "rank": 10,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK8HKXHNNE7D4FZG1VKR0",
          "created_at": "2024-01-03T14:45:47.184Z",
          "updated_at": "2024-01-03T14:45:47.184Z",
          "name": "Maske za kosu",
          "description": "",
          "handle": "proizvodi-za-njegu-kose-maske-za-kosu",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4N31HG0EQ98AA4ZHV8C",
          "rank": 11,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK8J1HRYV6YGWJB4E173C",
          "created_at": "2024-01-03T14:45:47.199Z",
          "updated_at": "2024-01-03T14:45:47.199Z",
          "name": "Maske za bojanje kose",
          "description": "",
          "handle": "proizvodi-za-njegu-kose-maske-za-bojanje-kose",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4N31HG0EQ98AA4ZHV8C",
          "rank": 12,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK8JFC92724VXWFTV7MP7",
          "created_at": "2024-01-03T14:45:47.212Z",
          "updated_at": "2024-01-03T14:45:47.212Z",
          "name": "Sredstva za trajnu i teksturiranje",
          "description": "",
          "handle": "proizvodi-za-njegu-kose-sredstva-za-trajnu-i-teksturiranje",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4N31HG0EQ98AA4ZHV8C",
          "rank": 13,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK8JYZJY7W2AHM17MWEXF",
          "created_at": "2024-01-03T14:45:47.227Z",
          "updated_at": "2024-01-03T14:45:47.227Z",
          "name": "Proizvodi za ravnanje kose",
          "description": "",
          "handle": "proizvodi-za-njegu-kose-proizvodi-za-ravnanje-kose",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4N31HG0EQ98AA4ZHV8C",
          "rank": 14,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK8KEFMVH9F65P6T0B99P",
          "created_at": "2024-01-03T14:45:47.242Z",
          "updated_at": "2024-01-03T14:45:47.242Z",
          "name": "Proizvodi za gubitak kose",
          "description": "",
          "handle": "proizvodi-za-njegu-kose-proizvodi-za-gubitak-kose",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4N31HG0EQ98AA4ZHV8C",
          "rank": 15,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK8KXBZWJMC3154610SV4",
          "created_at": "2024-01-03T14:45:47.258Z",
          "updated_at": "2024-01-03T14:45:47.258Z",
          "name": "Opuštači za kosu",
          "description": "",
          "handle": "proizvodi-za-njegu-kose-opustaci-za-kosu",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4N31HG0EQ98AA4ZHV8C",
          "rank": 16,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK8MB0CZ7ZXVAGYDT7P2B",
          "created_at": "2024-01-03T14:45:47.273Z",
          "updated_at": "2024-01-03T14:45:47.273Z",
          "name": "Poklon setovi",
          "description": "",
          "handle": "proizvodi-za-njegu-kose-poklon-setovi",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4N31HG0EQ98AA4ZHV8C",
          "rank": 17,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK8MTDA9QBJ5KPCNWACT7",
          "created_at": "2024-01-03T14:45:47.286Z",
          "updated_at": "2024-01-03T14:45:47.286Z",
          "name": "Putni setovi",
          "description": "",
          "handle": "proizvodi-za-njegu-kose-putni-setovi",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4N31HG0EQ98AA4ZHV8C",
          "rank": 18,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK8N7DRMEZ70Z0N698FZK",
          "created_at": "2024-01-03T14:45:47.301Z",
          "updated_at": "2024-01-03T14:45:47.301Z",
          "name": "Tretmani za rast kose",
          "description": "",
          "handle": "proizvodi-za-njegu-kose-tretmani-za-rast-kose",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4N31HG0EQ98AA4ZHV8C",
          "rank": 19,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK8NR84Y105B6G6ZK1TZT",
          "created_at": "2024-01-03T14:45:47.317Z",
          "updated_at": "2024-01-03T14:45:47.317Z",
          "name": "Tonici za kosu",
          "description": "",
          "handle": "proizvodi-za-njegu-kose-tonici-za-kosu",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4N31HG0EQ98AA4ZHV8C",
          "rank": 20,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKAEZTMPST914YJPY0TS6",
          "created_at": "2024-01-03T14:45:49.147Z",
          "updated_at": "2024-01-03T14:45:49.147Z",
          "name": "Setovi i kompleti",
          "description": "",
          "handle": "proizvodi-za-njegu-kose-setovi-i-kompleti",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4N31HG0EQ98AA4ZHV8C",
          "rank": 21,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKAFQ0N4PN73NE63QV0Y4",
          "created_at": "2024-01-03T14:45:49.171Z",
          "updated_at": "2024-01-03T14:45:49.171Z",
          "name": "Šampon, regenerator i gel 3 u 1",
          "description": "",
          "handle": "proizvodi-za-njegu-kose-sampon-regenerator-i-gel-3-u-1",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4N31HG0EQ98AA4ZHV8C",
          "rank": 22,
          "metadata": null
        }
      ],
      "parent_category": {
        "id": "pcat_01HK7WK43BQJST1XZ18R4BST01",
        "created_at": "2024-01-03T14:45:42.635Z",
        "updated_at": "2024-01-03T14:45:42.635Z",
        "name": "Njega kose",
        "description": "",
        "handle": "ljepota-njega-kose",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": null,
        "rank": 3,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK4G7CJB2WFRCRPS0131Q",
      "created_at": "2024-01-03T14:45:43.032Z",
      "updated_at": "2024-01-03T14:45:43.032Z",
      "name": "Ruke i stopala",
      "description": "",
      "handle": "njega-koze-ruke-i-stopala",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK42VB8WYE6GKG3FEJ41V",
      "rank": 5,
      "metadata": null,
      "category_children": [
        {
          "id": "pcat_01HK7WK70XHF1HJJACBCFM5286",
          "created_at": "2024-01-03T14:45:45.627Z",
          "updated_at": "2024-01-03T14:45:45.627Z",
          "name": "Ulja i kreme za zanoktice",
          "description": "",
          "handle": "ruke-i-stopala-ulja-i-kreme-za-zanoktice",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4G7CJB2WFRCRPS0131Q",
          "rank": 0,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK71B3W0J7Q19BDKT8Y1Z",
          "created_at": "2024-01-03T14:45:45.641Z",
          "updated_at": "2024-01-03T14:45:45.641Z",
          "name": "Parafinske kupke",
          "description": "",
          "handle": "ruke-i-stopala-parafinske-kupke",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4G7CJB2WFRCRPS0131Q",
          "rank": 1,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK71TRQVYXWGWWQ1MNY7C",
          "created_at": "2024-01-03T14:45:45.656Z",
          "updated_at": "2024-01-03T14:45:45.656Z",
          "name": "Kupke za stopala",
          "description": "",
          "handle": "ruke-i-stopala-kupke-za-stopala",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4G7CJB2WFRCRPS0131Q",
          "rank": 2,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK727067M9J5DS5KCEF8P",
          "created_at": "2024-01-03T14:45:45.669Z",
          "updated_at": "2024-01-03T14:45:45.669Z",
          "name": "Hidratantne čarape",
          "description": "",
          "handle": "ruke-i-stopala-hidratantne-carape",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4G7CJB2WFRCRPS0131Q",
          "rank": 3,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK72PYNP409F6Y6C17NSJ",
          "created_at": "2024-01-03T14:45:45.683Z",
          "updated_at": "2024-01-03T14:45:45.683Z",
          "name": "Kreme za ruke i nokte",
          "description": "",
          "handle": "ruke-i-stopala-kreme-za-ruke-i-nokte",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4G7CJB2WFRCRPS0131Q",
          "rank": 4,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK736QNZS7S86X85QAWC6",
          "created_at": "2024-01-03T14:45:45.700Z",
          "updated_at": "2024-01-03T14:45:45.700Z",
          "name": "Kreme za stopala",
          "description": "",
          "handle": "ruke-i-stopala-kreme-za-stopala",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4G7CJB2WFRCRPS0131Q",
          "rank": 5,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK73M6GJR5ZV9CJ24DBMQ",
          "created_at": "2024-01-03T14:45:45.714Z",
          "updated_at": "2024-01-03T14:45:45.714Z",
          "name": "Kreme za obnovu i njegu zanoktica",
          "description": "",
          "handle": "ruke-i-stopala-kreme-za-obnovu-i-njegu-zanoktica",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4G7CJB2WFRCRPS0131Q",
          "rank": 6,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK741A7KVC4DX3BGG3080",
          "created_at": "2024-01-03T14:45:45.727Z",
          "updated_at": "2024-01-03T14:45:45.727Z",
          "name": "Pilinzi",
          "description": "",
          "handle": "ruke-i-stopala-pilinzi",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4G7CJB2WFRCRPS0131Q",
          "rank": 7,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK74EHZN0P1K5BFB9M6Q8",
          "created_at": "2024-01-03T14:45:45.740Z",
          "updated_at": "2024-01-03T14:45:45.740Z",
          "name": "Hidratantne rukavice",
          "description": "",
          "handle": "ruke-i-stopala-hidratantne-rukavice",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4G7CJB2WFRCRPS0131Q",
          "rank": 8,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK74YPA81Z41X086EF098",
          "created_at": "2024-01-03T14:45:45.755Z",
          "updated_at": "2024-01-03T14:45:45.755Z",
          "name": "Tekućine za uklanjanje zanoktica",
          "description": "",
          "handle": "ruke-i-stopala-tekucine-za-uklanjanje-zanoktica",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4G7CJB2WFRCRPS0131Q",
          "rank": 9,
          "metadata": null
        }
      ],
      "parent_category": {
        "id": "pcat_01HK7WK42VB8WYE6GKG3FEJ41V",
        "created_at": "2024-01-03T14:45:42.618Z",
        "updated_at": "2024-01-03T14:45:42.618Z",
        "name": "Njega kože",
        "description": "",
        "handle": "ljepota-njega-koze",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": null,
        "rank": 2,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK5WNGRG0H1EG4EMKCCQN",
      "created_at": "2024-01-03T14:45:44.454Z",
      "updated_at": "2024-01-03T14:45:44.454Z",
      "name": "Losioni i tekućine",
      "description": "",
      "handle": "njega-nakon-depilacije-i-brijanja-losioni-i-tekucine",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK481HCTWDV2XH5M4VNWK",
      "rank": 5,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK481HCTWDV2XH5M4VNWK",
        "created_at": "2024-01-03T14:45:42.782Z",
        "updated_at": "2024-01-03T14:45:42.782Z",
        "name": "Njega nakon depilacije i brijanja",
        "description": "",
        "handle": "brijanje-i-depilacija-njega-nakon-depilacije-i-brijanja",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK41MNZ3C795B2JN5JW0G",
        "rank": 3,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK61J243W1H9V19RR1B41",
      "created_at": "2024-01-03T14:45:44.622Z",
      "updated_at": "2024-01-03T14:45:44.622Z",
      "name": "Kompleti",
      "description": "",
      "handle": "njega-prije-depilacije-i-brijanja-kompleti",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK48MV187B5ZHKEDJWPEG",
      "rank": 5,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK48MV187B5ZHKEDJWPEG",
        "created_at": "2024-01-03T14:45:42.801Z",
        "updated_at": "2024-01-03T14:45:42.801Z",
        "name": "Njega prije depilacije i brijanja",
        "description": "",
        "handle": "brijanje-i-depilacija-njega-prije-depilacije-i-brijanja",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK41MNZ3C795B2JN5JW0G",
        "rank": 4,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKDN0QZ4DHQ2DWJEQK0VG",
      "created_at": "2024-01-03T14:45:52.408Z",
      "updated_at": "2024-01-03T14:45:52.408Z",
      "name": "Četke za obrve",
      "description": "",
      "handle": "oci-cetke-za-obrve",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WKB41CSS4HCT9G53TPNK8",
      "rank": 5,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WKB41CSS4HCT9G53TPNK8",
        "created_at": "2024-01-03T14:45:49.823Z",
        "updated_at": "2024-01-03T14:45:49.823Z",
        "name": "Oči",
        "description": "",
        "handle": "cetke-i-pribor-za-sminkanje-oci",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK5D0544YXJQT9G60XVED",
        "rank": 2,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK77F8HPKH5PMAK5EPD1V",
      "created_at": "2024-01-03T14:45:45.836Z",
      "updated_at": "2024-01-03T14:45:45.836Z",
      "name": "Serumi i tekućine",
      "description": "",
      "handle": "oci-serumi-i-tekucine",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4J1NW5Q3B0PAC2JEQ2N",
      "rank": 5,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4J1NW5Q3B0PAC2JEQ2N",
        "created_at": "2024-01-03T14:45:43.101Z",
        "updated_at": "2024-01-03T14:45:43.101Z",
        "name": "Oči",
        "description": "",
        "handle": "njega-koze-oci_5391",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK42VB8WYE6GKG3FEJ41V",
        "rank": 6,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK9EYQ06P2C20N9C60G50",
      "created_at": "2024-01-03T14:45:48.124Z",
      "updated_at": "2024-01-03T14:45:48.124Z",
      "name": "Tuševi za oči",
      "description": "",
      "handle": "oci-tusevi-za-oci",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4T4JAQRP4VBRRAJR36J",
      "rank": 5,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4T4JAQRP4VBRRAJR36J",
        "created_at": "2024-01-03T14:45:43.362Z",
        "updated_at": "2024-01-03T14:45:43.362Z",
        "name": "Oči",
        "description": "",
        "handle": "sminka-oci_11745",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK4495D212GWWBGHX6D9H",
        "rank": 4,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKA17D8NH56JB1XNRRGYV",
      "created_at": "2024-01-03T14:45:48.708Z",
      "updated_at": "2024-01-03T14:45:48.708Z",
      "name": "Sapuni za ruke",
      "description": "",
      "handle": "osobna-cistoca-sapuni-za-ruke",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4C6VG3TJWR51GB1ZQJW",
      "rank": 5,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4C6VG3TJWR51GB1ZQJW",
        "created_at": "2024-01-03T14:45:42.916Z",
        "updated_at": "2024-01-03T14:45:42.916Z",
        "name": "Osobna čistoća",
        "description": "",
        "handle": "kupanje-i-osobna-higijena-osobna-cistoca",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK42BQ7XFW617X5XP4BAR",
        "rank": 4,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK51NY6TD1PFGF5DV5DHJ",
      "created_at": "2024-01-03T14:45:43.597Z",
      "updated_at": "2024-01-03T14:45:43.597Z",
      "name": "Prahovi za sušenje",
      "description": "",
      "handle": "parfemi-i-mirisi-prahovi-za-susenje",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK45CQPDHWCYPEW3W862G",
      "rank": 5,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK45CQPDHWCYPEW3W862G",
        "created_at": "2024-01-03T14:45:42.699Z",
        "updated_at": "2024-01-03T14:45:42.699Z",
        "name": "Parfemi i mirisi",
        "description": "",
        "handle": "ljepota-parfemi-i-mirisi",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": null,
        "rank": 6,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK5E7TWKXSDKE1B6B7FCQ",
      "created_at": "2024-01-03T14:45:44.005Z",
      "updated_at": "2024-01-03T14:45:44.005Z",
      "name": "Oprema za tetoviranje",
      "description": "",
      "handle": "pribor-i-dodaci-oprema-za-tetoviranje",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK45W0F9X0VMBBCAVDPWD",
      "rank": 5,
      "metadata": null,
      "category_children": [
        {
          "id": "pcat_01HK7WKB5VJMSGQTBDVWYDW3AN",
          "created_at": "2024-01-03T14:45:49.882Z",
          "updated_at": "2024-01-03T14:45:49.882Z",
          "name": "Aparati za tetoviranje",
          "description": "",
          "handle": "oprema-za-tetoviranje-aparati-za-tetoviranje",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK5E7TWKXSDKE1B6B7FCQ",
          "rank": 0,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKB69K9PTQR4NH8217425",
          "created_at": "2024-01-03T14:45:49.895Z",
          "updated_at": "2024-01-03T14:45:49.895Z",
          "name": "Igle",
          "description": "",
          "handle": "oprema-za-tetoviranje-igle_20992",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK5E7TWKXSDKE1B6B7FCQ",
          "rank": 1,
          "metadata": null
        }
      ],
      "parent_category": {
        "id": "pcat_01HK7WK45W0F9X0VMBBCAVDPWD",
        "created_at": "2024-01-03T14:45:42.715Z",
        "updated_at": "2024-01-03T14:45:42.715Z",
        "name": "Pribor i dodaci",
        "description": "",
        "handle": "ljepota-pribor-i-dodaci",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": null,
        "rank": 7,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK8Z80QT7MGWR1TJKFQ0E",
      "created_at": "2024-01-03T14:45:47.623Z",
      "updated_at": "2024-01-03T14:45:47.623Z",
      "name": "Rašpice za nokte",
      "description": "",
      "handle": "pribor-i-dodaci-raspice-za-nokte",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4Q9J4VMNM7KX6F1K8RQ",
      "rank": 5,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4Q9J4VMNM7KX6F1K8RQ",
        "created_at": "2024-01-03T14:45:43.271Z",
        "updated_at": "2024-01-03T14:45:43.271Z",
        "name": "Pribor i dodaci",
        "description": "",
        "handle": "manikura-i-pedikura-pribor-i-dodaci",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK43VEK8SG6M4T81C3W37",
        "rank": 3,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKCJ990VRDHMCNJGBB6XX",
      "created_at": "2024-01-03T14:45:51.303Z",
      "updated_at": "2024-01-03T14:45:51.303Z",
      "name": "Naljepnice i pribor za rašpanje noktiju",
      "description": "",
      "handle": "pribor-naljepnice-i-pribor-za-raspanje-noktiju",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK8TNZ6RWPNFYABFMKJ6W",
      "rank": 5,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK8TNZ6RWPNFYABFMKJ6W",
        "created_at": "2024-01-03T14:45:47.475Z",
        "updated_at": "2024-01-03T14:45:47.475Z",
        "name": "Pribor",
        "description": "",
        "handle": "dizajni-za-nokte-pribor",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK4PDYPCE7H5D1FAFMHNK",
        "rank": 7,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK7A3MV7XAN0JBY0XCSGX",
      "created_at": "2024-01-03T14:45:45.920Z",
      "updated_at": "2024-01-03T14:45:45.920Z",
      "name": "Češljevi",
      "description": "",
      "handle": "pribor-za-friziranje-cesljevi",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4JRZ92REX0CX4M9JWEM",
      "rank": 5,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4JRZ92REX0CX4M9JWEM",
        "created_at": "2024-01-03T14:45:43.125Z",
        "updated_at": "2024-01-03T14:45:43.125Z",
        "name": "Pribor za friziranje",
        "description": "",
        "handle": "njega-kose-pribor-za-friziranje",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK43BQJST1XZ18R4BST01",
        "rank": 0,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK85YWG2B27DWPB15ECDB",
      "created_at": "2024-01-03T14:45:46.812Z",
      "updated_at": "2024-01-03T14:45:46.812Z",
      "name": "Moussevi i pjene",
      "description": "",
      "handle": "proizvodi-za-friziranje-moussevi-i-pjene",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4MPDDG329H66PT1KDYF",
      "rank": 5,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4MPDDG329H66PT1KDYF",
        "created_at": "2024-01-03T14:45:43.187Z",
        "updated_at": "2024-01-03T14:45:43.187Z",
        "name": "Proizvodi za friziranje",
        "description": "",
        "handle": "njega-kose-proizvodi-za-friziranje",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK43BQJST1XZ18R4BST01",
        "rank": 4,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK8BBW1PXF9W2P29CG3CK",
      "created_at": "2024-01-03T14:45:46.984Z",
      "updated_at": "2024-01-03T14:45:46.984Z",
      "name": "3 u 1 šamponi",
      "description": "",
      "handle": "proizvodi-za-njegu-kose-3-u-1-samponi",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4N31HG0EQ98AA4ZHV8C",
      "rank": 5,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4N31HG0EQ98AA4ZHV8C",
        "created_at": "2024-01-03T14:45:43.201Z",
        "updated_at": "2024-01-03T14:45:43.201Z",
        "name": "Proizvodi za njegu kose",
        "description": "",
        "handle": "njega-kose-proizvodi-za-njegu-kose",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK43BQJST1XZ18R4BST01",
        "rank": 5,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK736QNZS7S86X85QAWC6",
      "created_at": "2024-01-03T14:45:45.700Z",
      "updated_at": "2024-01-03T14:45:45.700Z",
      "name": "Kreme za stopala",
      "description": "",
      "handle": "ruke-i-stopala-kreme-za-stopala",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4G7CJB2WFRCRPS0131Q",
      "rank": 5,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4G7CJB2WFRCRPS0131Q",
        "created_at": "2024-01-03T14:45:43.032Z",
        "updated_at": "2024-01-03T14:45:43.032Z",
        "name": "Ruke i stopala",
        "description": "",
        "handle": "njega-koze-ruke-i-stopala",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK42VB8WYE6GKG3FEJ41V",
        "rank": 5,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK5MK02W8KETBZB8XTVJX",
      "created_at": "2024-01-03T14:45:44.209Z",
      "updated_at": "2024-01-03T14:45:44.209Z",
      "name": "Češljevi vodiči",
      "description": "",
      "handle": "skare-za-kosu-brijaci-i-aparati-za-brijanje-tijela-cesljevi-vodici",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK47G3STXHFMNMZ4MMQ7G",
      "rank": 5,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK47G3STXHFMNMZ4MMQ7G",
        "created_at": "2024-01-03T14:45:42.766Z",
        "updated_at": "2024-01-03T14:45:42.766Z",
        "name": "Škare za kosu, brijači i aparati za brijanje tijela",
        "description": "",
        "handle": "brijanje-i-depilacija-skare-za-kosu-brijaci-i-aparati-za-brijanje-tijela",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK41MNZ3C795B2JN5JW0G",
        "rank": 2,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK4TMP63BG9ZEYT8MYXG4",
      "created_at": "2024-01-03T14:45:43.376Z",
      "updated_at": "2024-01-03T14:45:43.376Z",
      "name": "Palete šminke",
      "description": "",
      "handle": "sminka-palete-sminke",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4495D212GWWBGHX6D9H",
      "rank": 5,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4495D212GWWBGHX6D9H",
        "created_at": "2024-01-03T14:45:42.665Z",
        "updated_at": "2024-01-03T14:45:42.665Z",
        "name": "Šminka",
        "description": "",
        "handle": "ljepota-sminka",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": null,
        "rank": 5,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK69HMDA4WZERFFJ8FTAS",
      "created_at": "2024-01-03T14:45:44.873Z",
      "updated_at": "2024-01-03T14:45:44.873Z",
      "name": "Sredstva za depilaciju lica",
      "description": "",
      "handle": "uklanjanje-dlaka-sredstva-za-depilaciju-lica",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK493C3GNJNECHNPJ0K2N",
      "rank": 5,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK493C3GNJNECHNPJ0K2N",
        "created_at": "2024-01-03T14:45:42.817Z",
        "updated_at": "2024-01-03T14:45:42.817Z",
        "name": "Uklanjanje dlaka",
        "description": "",
        "handle": "brijanje-i-depilacija-uklanjanje-dlaka",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK41MNZ3C795B2JN5JW0G",
        "rank": 5,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKAECZRYDN3BYT3MQC3V5",
      "created_at": "2024-01-03T14:45:49.128Z",
      "updated_at": "2024-01-03T14:45:49.128Z",
      "name": "Perike",
      "description": "",
      "handle": "umeci-za-kosu-perike-i-dodaci-perike_20955",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK5A5XWEMK8CFBX8HWCSH",
      "rank": 5,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK5A5XWEMK8CFBX8HWCSH",
        "created_at": "2024-01-03T14:45:43.874Z",
        "updated_at": "2024-01-03T14:45:43.874Z",
        "name": "Umeci za kosu, perike i dodaci",
        "description": "",
        "handle": "njega-kose-umeci-za-kosu-perike-i-dodaci",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK43BQJST1XZ18R4BST01",
        "rank": 7,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKD36EA2V4KEB6J4ZNXGN",
      "created_at": "2024-01-03T14:45:51.844Z",
      "updated_at": "2024-01-03T14:45:51.844Z",
      "name": "Poslije sunčanja",
      "description": "",
      "handle": "za-hidrataciju-poslije-suncanja",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK6WA7CDBMWAWAHDXNKA8",
      "rank": 5,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK6WA7CDBMWAWAHDXNKA8",
        "created_at": "2024-01-03T14:45:45.480Z",
        "updated_at": "2024-01-03T14:45:45.480Z",
        "name": "Za hidrataciju",
        "description": "",
        "handle": "tijelo-za-hidrataciju",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK4DMG2Q4J3RMF32RA7Y1",
        "rank": 1,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK9QNNHT6VRYMM4W20PTG",
      "created_at": "2024-01-03T14:45:48.398Z",
      "updated_at": "2024-01-03T14:45:48.398Z",
      "name": "Sprejevi za tijelo",
      "description": "",
      "handle": "za-zene-sprejevi-za-tijelo_14107",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4YNQSS786T0PHYXE7FP",
      "rank": 5,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4YNQSS786T0PHYXE7FP",
        "created_at": "2024-01-03T14:45:43.492Z",
        "updated_at": "2024-01-03T14:45:43.492Z",
        "name": "Za žene",
        "description": "",
        "handle": "parfemi-i-mirisi-za-zene_14100",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK45CQPDHWCYPEW3W862G",
        "rank": 3,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK7M374FQP6XFVB7QDH77",
      "created_at": "2024-01-03T14:45:46.240Z",
      "updated_at": "2024-01-03T14:45:46.240Z",
      "name": "Izbjeljivači za kosu",
      "description": "",
      "handle": "bojanje-kose-izbjeljivaci-za-kosu",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4KQVZQK6XPGZJEJ50QZ",
      "rank": 6,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4KQVZQK6XPGZJEJ50QZ",
        "created_at": "2024-01-03T14:45:43.157Z",
        "updated_at": "2024-01-03T14:45:43.157Z",
        "name": "Bojanje kose",
        "description": "",
        "handle": "njega-kose-bojanje-kose",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK43BQJST1XZ18R4BST01",
        "rank": 2,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK49KK07E1DMRZYHFY1HD",
      "created_at": "2024-01-03T14:45:42.833Z",
      "updated_at": "2024-01-03T14:45:42.833Z",
      "name": "Škare",
      "description": "",
      "handle": "brijanje-i-depilacija-skare",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK41MNZ3C795B2JN5JW0G",
      "rank": 6,
      "metadata": null,
      "category_children": [
        {
          "id": "pcat_01HK7WK6CJ0ZAFR886W35NE5FM",
          "created_at": "2024-01-03T14:45:44.974Z",
          "updated_at": "2024-01-03T14:45:44.974Z",
          "name": "Škare za bradu i brkove",
          "description": "",
          "handle": "skare-skare-za-bradu-i-brkove",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK49KK07E1DMRZYHFY1HD",
          "rank": 0,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK6DE7RE05NJ12T9VVGW0",
          "created_at": "2024-01-03T14:45:45.002Z",
          "updated_at": "2024-01-03T14:45:45.002Z",
          "name": "Škare za nos i uho",
          "description": "",
          "handle": "skare-skare-za-nos-i-uho",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK49KK07E1DMRZYHFY1HD",
          "rank": 1,
          "metadata": null
        }
      ],
      "parent_category": {
        "id": "pcat_01HK7WK41MNZ3C795B2JN5JW0G",
        "created_at": "2024-01-03T14:45:42.579Z",
        "updated_at": "2024-01-03T14:45:42.579Z",
        "name": "Brijanje i depilacija",
        "description": "",
        "handle": "ljepota-brijanje-i-depilacija",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": null,
        "rank": 0,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKD2T98E37CJP8WT6VGA0",
      "created_at": "2024-01-03T14:45:51.832Z",
      "updated_at": "2024-01-03T14:45:51.832Z",
      "name": "Pjene",
      "description": "",
      "handle": "cistaci-pjene",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WKA2274BVZPY0TZ6SN7Z9",
      "rank": 6,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WKA2274BVZPY0TZ6SN7Z9",
        "created_at": "2024-01-03T14:45:48.735Z",
        "updated_at": "2024-01-03T14:45:48.735Z",
        "name": "Čistači",
        "description": "",
        "handle": "lice-cistaci_20934",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK4CQ8JZ3NZ6QJC2MQW11",
        "rank": 5,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKBPTTMNZKSZJ9943EKFC",
      "created_at": "2024-01-03T14:45:50.424Z",
      "updated_at": "2024-01-03T14:45:50.424Z",
      "name": "Roll-oni ",
      "description": "",
      "handle": "depilacija-voskom-roll-oni",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK649EWGYVF36CJ6071RM",
      "rank": 6,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK649EWGYVF36CJ6071RM",
        "created_at": "2024-01-03T14:45:44.706Z",
        "updated_at": "2024-01-03T14:45:44.706Z",
        "name": "Depilacija voskom",
        "description": "",
        "handle": "uklanjanje-dlaka-depilacija-voskom",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK493C3GNJNECHNPJ0K2N",
        "rank": 1,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK8T8G4NJ1CDZJA4Z968J",
      "created_at": "2024-01-03T14:45:47.462Z",
      "updated_at": "2024-01-03T14:45:47.462Z",
      "name": "Setovi nokata",
      "description": "",
      "handle": "dizajni-za-nokte-setovi-nokata",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4PDYPCE7H5D1FAFMHNK",
      "rank": 6,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4PDYPCE7H5D1FAFMHNK",
        "created_at": "2024-01-03T14:45:43.244Z",
        "updated_at": "2024-01-03T14:45:43.244Z",
        "name": "Dizajni za nokte",
        "description": "",
        "handle": "manikura-i-pedikura-dizajni-za-nokte",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK43VEK8SG6M4T81C3W37",
        "rank": 1,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK82AW43SNS1TEE8BPXDQ",
      "created_at": "2024-01-03T14:45:46.695Z",
      "updated_at": "2024-01-03T14:45:46.695Z",
      "name": "Frizerski lavaboi",
      "description": "",
      "handle": "frizerski-namjestaj-i-potrepstine-frizerski-lavaboi",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4M7JE8S0KGQCMWF47WJ",
      "rank": 6,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4M7JE8S0KGQCMWF47WJ",
        "created_at": "2024-01-03T14:45:43.173Z",
        "updated_at": "2024-01-03T14:45:43.173Z",
        "name": "Frizerski namještaj i potrepštine",
        "description": "",
        "handle": "njega-kose-frizerski-namjestaj-i-potrepstine",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK43BQJST1XZ18R4BST01",
        "rank": 3,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK7EHQ5XFRJMD8VKW3THX",
      "created_at": "2024-01-03T14:45:46.063Z",
      "updated_at": "2024-01-03T14:45:46.063Z",
      "name": "Figari",
      "description": "",
      "handle": "frizerski-uredaji-i-pribor-figari",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4K84XGGV7XG3TFC6W69",
      "rank": 6,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4K84XGGV7XG3TFC6W69",
        "created_at": "2024-01-03T14:45:43.141Z",
        "updated_at": "2024-01-03T14:45:43.141Z",
        "name": "Frizerski uređaji i pribor",
        "description": "",
        "handle": "njega-kose-frizerski-uredaji-i-pribor",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK43BQJST1XZ18R4BST01",
        "rank": 1,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK945ZMSDXSSF5A65SBJ2",
      "created_at": "2024-01-03T14:45:47.779Z",
      "updated_at": "2024-01-03T14:45:47.779Z",
      "name": "Korektivna šminka",
      "description": "",
      "handle": "lice-korektivna-sminka",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4QT18DTEY1R94HB0RRX",
      "rank": 6,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4QT18DTEY1R94HB0RRX",
        "created_at": "2024-01-03T14:45:43.284Z",
        "updated_at": "2024-01-03T14:45:43.284Z",
        "name": "Lice",
        "description": "",
        "handle": "sminka-lice_11703",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK4495D212GWWBGHX6D9H",
        "rank": 0,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKA3187RTGD687FKX5FEJ",
      "created_at": "2024-01-03T14:45:48.765Z",
      "updated_at": "2024-01-03T14:45:48.765Z",
      "name": "Polirke",
      "description": "",
      "handle": "lice-polirke",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4CQ8JZ3NZ6QJC2MQW11",
      "rank": 6,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4CQ8JZ3NZ6QJC2MQW11",
        "created_at": "2024-01-03T14:45:42.933Z",
        "updated_at": "2024-01-03T14:45:42.933Z",
        "name": "Lice",
        "description": "",
        "handle": "njega-koze-lice_5325",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK42VB8WYE6GKG3FEJ41V",
        "rank": 0,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK45CQPDHWCYPEW3W862G",
      "created_at": "2024-01-03T14:45:42.699Z",
      "updated_at": "2024-01-03T14:45:42.699Z",
      "name": "Parfemi i mirisi",
      "description": "",
      "handle": "ljepota-parfemi-i-mirisi",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": null,
      "rank": 6,
      "metadata": null,
      "category_children": [
        {
          "id": "pcat_01HK7WK4V5YV6J6PV0DFNVKD6N",
          "created_at": "2024-01-03T14:45:43.395Z",
          "updated_at": "2024-01-03T14:45:43.395Z",
          "name": "Esencijalna ulja ",
          "description": "",
          "handle": "parfemi-i-mirisi-esencijalna-ulja",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK45CQPDHWCYPEW3W862G",
          "rank": 0,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK4VK9T55YRNH96BBHEDM",
          "created_at": "2024-01-03T14:45:43.408Z",
          "updated_at": "2024-01-03T14:45:43.408Z",
          "name": "Svježa voda",
          "description": "",
          "handle": "parfemi-i-mirisi-svjeza-voda",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK45CQPDHWCYPEW3W862G",
          "rank": 1,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK4WXV52RYH206J4P8TD0",
          "created_at": "2024-01-03T14:45:43.446Z",
          "updated_at": "2024-01-03T14:45:43.446Z",
          "name": "Za muškarce",
          "description": "",
          "handle": "parfemi-i-mirisi-za-muskarce_14094",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK45CQPDHWCYPEW3W862G",
          "rank": 2,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK4YNQSS786T0PHYXE7FP",
          "created_at": "2024-01-03T14:45:43.492Z",
          "updated_at": "2024-01-03T14:45:43.492Z",
          "name": "Za žene",
          "description": "",
          "handle": "parfemi-i-mirisi-za-zene_14100",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK45CQPDHWCYPEW3W862G",
          "rank": 3,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK507QGAWMAS16QF11D2F",
          "created_at": "2024-01-03T14:45:43.550Z",
          "updated_at": "2024-01-03T14:45:43.550Z",
          "name": "Za djecu",
          "description": "",
          "handle": "parfemi-i-mirisi-za-djecu",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK45CQPDHWCYPEW3W862G",
          "rank": 4,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK51NY6TD1PFGF5DV5DHJ",
          "created_at": "2024-01-03T14:45:43.597Z",
          "updated_at": "2024-01-03T14:45:43.597Z",
          "name": "Prahovi za sušenje",
          "description": "",
          "handle": "parfemi-i-mirisi-prahovi-za-susenje",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK45CQPDHWCYPEW3W862G",
          "rank": 5,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK5374FND56KK9NRN21TB",
          "created_at": "2024-01-03T14:45:43.646Z",
          "updated_at": "2024-01-03T14:45:43.646Z",
          "name": "Sprej za tijelo",
          "description": "",
          "handle": "parfemi-i-mirisi-sprej-za-tijelo",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK45CQPDHWCYPEW3W862G",
          "rank": 6,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK5549S5WEVDCDA22RP2N",
          "created_at": "2024-01-03T14:45:43.707Z",
          "updated_at": "2024-01-03T14:45:43.707Z",
          "name": "Svijeće i esencije za dom",
          "description": "",
          "handle": "parfemi-i-mirisi-svijece-i-esencije-za-dom",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK45CQPDHWCYPEW3W862G",
          "rank": 7,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK5BT94MH3Z5NZ75JJ8VH",
          "created_at": "2024-01-03T14:45:43.928Z",
          "updated_at": "2024-01-03T14:45:43.928Z",
          "name": "Raspršivači",
          "description": "",
          "handle": "parfemi-i-mirisi-rasprsivaci",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK45CQPDHWCYPEW3W862G",
          "rank": 8,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK5CFK2YJ8SJ5H0G76M0D",
          "created_at": "2024-01-03T14:45:43.949Z",
          "updated_at": "2024-01-03T14:45:43.949Z",
          "name": "Kompleti",
          "description": "",
          "handle": "parfemi-i-mirisi-kompleti",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK45CQPDHWCYPEW3W862G",
          "rank": 9,
          "metadata": null
        }
      ],
      "parent_category": null
    },
    {
      "id": "pcat_01HK7WK4NHTG89E1DN79HZD0W5",
      "created_at": "2024-01-03T14:45:43.215Z",
      "updated_at": "2024-01-03T14:45:43.215Z",
      "name": "Pribor za šišanje",
      "description": "",
      "handle": "njega-kose-pribor-za-sisanje",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK43BQJST1XZ18R4BST01",
      "rank": 6,
      "metadata": null,
      "category_children": [
        {
          "id": "pcat_01HK7WK8P5KP8N7HD1N7H4W2EJ",
          "created_at": "2024-01-03T14:45:47.331Z",
          "updated_at": "2024-01-03T14:45:47.331Z",
          "name": "Futrole za noževe, oštrice i britvice",
          "description": "",
          "handle": "pribor-za-sisanje-futrole-za-nozeve-ostrice-i-britvice",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4NHTG89E1DN79HZD0W5",
          "rank": 0,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK8PHKW0XASQ6QJSA0GP3",
          "created_at": "2024-01-03T14:45:47.343Z",
          "updated_at": "2024-01-03T14:45:47.343Z",
          "name": "Kompleti za šišanje",
          "description": "",
          "handle": "pribor-za-sisanje-kompleti-za-sisanje",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4NHTG89E1DN79HZD0W5",
          "rank": 1,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK8PZVVDJHX69MTF1P8YP",
          "created_at": "2024-01-03T14:45:47.357Z",
          "updated_at": "2024-01-03T14:45:47.357Z",
          "name": "Britvice",
          "description": "",
          "handle": "pribor-za-sisanje-britvice",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4NHTG89E1DN79HZD0W5",
          "rank": 2,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK8QC3X1JJ8KPTPR8WS9B",
          "created_at": "2024-01-03T14:45:47.370Z",
          "updated_at": "2024-01-03T14:45:47.370Z",
          "name": "Škare za kosu",
          "description": "",
          "handle": "pribor-za-sisanje-skare-za-kosu",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4NHTG89E1DN79HZD0W5",
          "rank": 3,
          "metadata": null
        }
      ],
      "parent_category": {
        "id": "pcat_01HK7WK43BQJST1XZ18R4BST01",
        "created_at": "2024-01-03T14:45:42.635Z",
        "updated_at": "2024-01-03T14:45:42.635Z",
        "name": "Njega kose",
        "description": "",
        "handle": "ljepota-njega-kose",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": null,
        "rank": 3,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK4J1NW5Q3B0PAC2JEQ2N",
      "created_at": "2024-01-03T14:45:43.101Z",
      "updated_at": "2024-01-03T14:45:43.101Z",
      "name": "Oči",
      "description": "",
      "handle": "njega-koze-oci_5391",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK42VB8WYE6GKG3FEJ41V",
      "rank": 6,
      "metadata": null,
      "category_children": [
        {
          "id": "pcat_01HK7WK75B9R0PH5ZR1S16HTGN",
          "created_at": "2024-01-03T14:45:45.769Z",
          "updated_at": "2024-01-03T14:45:45.769Z",
          "name": "Kreme",
          "description": "",
          "handle": "oci-kreme_5392",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4J1NW5Q3B0PAC2JEQ2N",
          "rank": 0,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK75R744R9XMBMGRD6ZF0",
          "created_at": "2024-01-03T14:45:45.782Z",
          "updated_at": "2024-01-03T14:45:45.782Z",
          "name": "Sredstva za uklanjanje šminke",
          "description": "",
          "handle": "oci-sredstva-za-uklanjanje-sminke",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4J1NW5Q3B0PAC2JEQ2N",
          "rank": 1,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK76789GCDGD0F5Z8JR3A",
          "created_at": "2024-01-03T14:45:45.797Z",
          "updated_at": "2024-01-03T14:45:45.797Z",
          "name": "Gelovi",
          "description": "",
          "handle": "oci-gelovi",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4J1NW5Q3B0PAC2JEQ2N",
          "rank": 2,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK76M8E6HMG1ZF6TZHQDN",
          "created_at": "2024-01-03T14:45:45.810Z",
          "updated_at": "2024-01-03T14:45:45.810Z",
          "name": "Osvježavajuće maske",
          "description": "",
          "handle": "oci-osvjezavajuce-maske",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4J1NW5Q3B0PAC2JEQ2N",
          "rank": 3,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK7713H8NCY8QPQHXS4C7",
          "created_at": "2024-01-03T14:45:45.823Z",
          "updated_at": "2024-01-03T14:45:45.823Z",
          "name": "Flasteri",
          "description": "",
          "handle": "oci-flasteri",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4J1NW5Q3B0PAC2JEQ2N",
          "rank": 4,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK77F8HPKH5PMAK5EPD1V",
          "created_at": "2024-01-03T14:45:45.836Z",
          "updated_at": "2024-01-03T14:45:45.836Z",
          "name": "Serumi i tekućine",
          "description": "",
          "handle": "oci-serumi-i-tekucine",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4J1NW5Q3B0PAC2JEQ2N",
          "rank": 5,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKA6T9GR5AGER13VHC6G2",
          "created_at": "2024-01-03T14:45:48.885Z",
          "updated_at": "2024-01-03T14:45:48.885Z",
          "name": "Maske",
          "description": "",
          "handle": "oci-maske",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK4J1NW5Q3B0PAC2JEQ2N",
          "rank": 6,
          "metadata": null
        }
      ],
      "parent_category": {
        "id": "pcat_01HK7WK42VB8WYE6GKG3FEJ41V",
        "created_at": "2024-01-03T14:45:42.618Z",
        "updated_at": "2024-01-03T14:45:42.618Z",
        "name": "Njega kože",
        "description": "",
        "handle": "ljepota-njega-koze",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": null,
        "rank": 2,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK5XS1KQFQF6QTSCGX6KZ",
      "created_at": "2024-01-03T14:45:44.500Z",
      "updated_at": "2024-01-03T14:45:44.500Z",
      "name": "Tretmani za urasle dlake",
      "description": "",
      "handle": "njega-nakon-depilacije-i-brijanja-tretmani-za-urasle-dlake",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK481HCTWDV2XH5M4VNWK",
      "rank": 6,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK481HCTWDV2XH5M4VNWK",
        "created_at": "2024-01-03T14:45:42.782Z",
        "updated_at": "2024-01-03T14:45:42.782Z",
        "name": "Njega nakon depilacije i brijanja",
        "description": "",
        "handle": "brijanje-i-depilacija-njega-nakon-depilacije-i-brijanja",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK41MNZ3C795B2JN5JW0G",
        "rank": 3,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK626G8V6ENRRR2SQWXXT",
      "created_at": "2024-01-03T14:45:44.643Z",
      "updated_at": "2024-01-03T14:45:44.643Z",
      "name": "Losioni",
      "description": "",
      "handle": "njega-prije-depilacije-i-brijanja-losioni",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK48MV187B5ZHKEDJWPEG",
      "rank": 6,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK48MV187B5ZHKEDJWPEG",
        "created_at": "2024-01-03T14:45:42.801Z",
        "updated_at": "2024-01-03T14:45:42.801Z",
        "name": "Njega prije depilacije i brijanja",
        "description": "",
        "handle": "brijanje-i-depilacija-njega-prije-depilacije-i-brijanja",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK41MNZ3C795B2JN5JW0G",
        "rank": 4,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKDPJRK8NFBT083HY1P7B",
      "created_at": "2024-01-03T14:45:52.457Z",
      "updated_at": "2024-01-03T14:45:52.457Z",
      "name": "Četke za trepavice",
      "description": "",
      "handle": "oci-cetke-za-trepavice",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WKB41CSS4HCT9G53TPNK8",
      "rank": 6,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WKB41CSS4HCT9G53TPNK8",
        "created_at": "2024-01-03T14:45:49.823Z",
        "updated_at": "2024-01-03T14:45:49.823Z",
        "name": "Oči",
        "description": "",
        "handle": "cetke-i-pribor-za-sminkanje-oci",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK5D0544YXJQT9G60XVED",
        "rank": 2,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKA6T9GR5AGER13VHC6G2",
      "created_at": "2024-01-03T14:45:48.885Z",
      "updated_at": "2024-01-03T14:45:48.885Z",
      "name": "Maske",
      "description": "",
      "handle": "oci-maske",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4J1NW5Q3B0PAC2JEQ2N",
      "rank": 6,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4J1NW5Q3B0PAC2JEQ2N",
        "created_at": "2024-01-03T14:45:43.101Z",
        "updated_at": "2024-01-03T14:45:43.101Z",
        "name": "Oči",
        "description": "",
        "handle": "njega-koze-oci_5391",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK42VB8WYE6GKG3FEJ41V",
        "rank": 6,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK9FAKX44P3VY1K0H93Q9",
      "created_at": "2024-01-03T14:45:48.136Z",
      "updated_at": "2024-01-03T14:45:48.136Z",
      "name": "Olovke za oči",
      "description": "",
      "handle": "oci-olovke-za-oci",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4T4JAQRP4VBRRAJR36J",
      "rank": 6,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4T4JAQRP4VBRRAJR36J",
        "created_at": "2024-01-03T14:45:43.362Z",
        "updated_at": "2024-01-03T14:45:43.362Z",
        "name": "Oči",
        "description": "",
        "handle": "sminka-oci_11745",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK4495D212GWWBGHX6D9H",
        "rank": 4,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK5374FND56KK9NRN21TB",
      "created_at": "2024-01-03T14:45:43.646Z",
      "updated_at": "2024-01-03T14:45:43.646Z",
      "name": "Sprej za tijelo",
      "description": "",
      "handle": "parfemi-i-mirisi-sprej-za-tijelo",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK45CQPDHWCYPEW3W862G",
      "rank": 6,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK45CQPDHWCYPEW3W862G",
        "created_at": "2024-01-03T14:45:42.699Z",
        "updated_at": "2024-01-03T14:45:42.699Z",
        "name": "Parfemi i mirisi",
        "description": "",
        "handle": "ljepota-parfemi-i-mirisi",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": null,
        "rank": 6,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK8ZN3NYEXYZT4D3JKNNK",
      "created_at": "2024-01-03T14:45:47.635Z",
      "updated_at": "2024-01-03T14:45:47.635Z",
      "name": "Setovi i kompleti",
      "description": "",
      "handle": "pribor-i-dodaci-setovi-i-kompleti",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4Q9J4VMNM7KX6F1K8RQ",
      "rank": 6,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4Q9J4VMNM7KX6F1K8RQ",
        "created_at": "2024-01-03T14:45:43.271Z",
        "updated_at": "2024-01-03T14:45:43.271Z",
        "name": "Pribor i dodaci",
        "description": "",
        "handle": "manikura-i-pedikura-pribor-i-dodaci",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK43VEK8SG6M4T81C3W37",
        "rank": 3,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKCJQFB9YVK23B7B4BCHV",
      "created_at": "2024-01-03T14:45:51.316Z",
      "updated_at": "2024-01-03T14:45:51.316Z",
      "name": "Odvajači nožnih prstiju",
      "description": "",
      "handle": "pribor-odvajaci-noznih-prstiju",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK8TNZ6RWPNFYABFMKJ6W",
      "rank": 6,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK8TNZ6RWPNFYABFMKJ6W",
        "created_at": "2024-01-03T14:45:47.475Z",
        "updated_at": "2024-01-03T14:45:47.475Z",
        "name": "Pribor",
        "description": "",
        "handle": "dizajni-za-nokte-pribor",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK4PDYPCE7H5D1FAFMHNK",
        "rank": 7,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK7AN3CS0HG6B7VNDKXJE",
      "created_at": "2024-01-03T14:45:45.938Z",
      "updated_at": "2024-01-03T14:45:45.938Z",
      "name": "Pinceta",
      "description": "",
      "handle": "pribor-za-friziranje-pinceta",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4JRZ92REX0CX4M9JWEM",
      "rank": 6,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4JRZ92REX0CX4M9JWEM",
        "created_at": "2024-01-03T14:45:43.125Z",
        "updated_at": "2024-01-03T14:45:43.125Z",
        "name": "Pribor za friziranje",
        "description": "",
        "handle": "njega-kose-pribor-za-friziranje",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK43BQJST1XZ18R4BST01",
        "rank": 0,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK86H0GRHTD1KFW7Y426S",
      "created_at": "2024-01-03T14:45:46.831Z",
      "updated_at": "2024-01-03T14:45:46.831Z",
      "name": "Prašci za podizanje korijena",
      "description": "",
      "handle": "proizvodi-za-friziranje-prasci-za-podizanje-korijena",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4MPDDG329H66PT1KDYF",
      "rank": 6,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4MPDDG329H66PT1KDYF",
        "created_at": "2024-01-03T14:45:43.187Z",
        "updated_at": "2024-01-03T14:45:43.187Z",
        "name": "Proizvodi za friziranje",
        "description": "",
        "handle": "njega-kose-proizvodi-za-friziranje",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK43BQJST1XZ18R4BST01",
        "rank": 4,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK8BZFPP6EK6PGF0N95BP",
      "created_at": "2024-01-03T14:45:47.004Z",
      "updated_at": "2024-01-03T14:45:47.004Z",
      "name": "Suhi šamponi",
      "description": "",
      "handle": "proizvodi-za-njegu-kose-suhi-samponi",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4N31HG0EQ98AA4ZHV8C",
      "rank": 6,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4N31HG0EQ98AA4ZHV8C",
        "created_at": "2024-01-03T14:45:43.201Z",
        "updated_at": "2024-01-03T14:45:43.201Z",
        "name": "Proizvodi za njegu kose",
        "description": "",
        "handle": "njega-kose-proizvodi-za-njegu-kose",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK43BQJST1XZ18R4BST01",
        "rank": 5,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK73M6GJR5ZV9CJ24DBMQ",
      "created_at": "2024-01-03T14:45:45.714Z",
      "updated_at": "2024-01-03T14:45:45.714Z",
      "name": "Kreme za obnovu i njegu zanoktica",
      "description": "",
      "handle": "ruke-i-stopala-kreme-za-obnovu-i-njegu-zanoktica",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4G7CJB2WFRCRPS0131Q",
      "rank": 6,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4G7CJB2WFRCRPS0131Q",
        "created_at": "2024-01-03T14:45:43.032Z",
        "updated_at": "2024-01-03T14:45:43.032Z",
        "name": "Ruke i stopala",
        "description": "",
        "handle": "njega-koze-ruke-i-stopala",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK42VB8WYE6GKG3FEJ41V",
        "rank": 5,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK5N1KRF3G83RNQF2Z77T",
      "created_at": "2024-01-03T14:45:44.223Z",
      "updated_at": "2024-01-03T14:45:44.223Z",
      "name": "Ženski precizni trimeri",
      "description": "",
      "handle": "skare-za-kosu-brijaci-i-aparati-za-brijanje-tijela-zenski-precizni-trimeri",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK47G3STXHFMNMZ4MMQ7G",
      "rank": 6,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK47G3STXHFMNMZ4MMQ7G",
        "created_at": "2024-01-03T14:45:42.766Z",
        "updated_at": "2024-01-03T14:45:42.766Z",
        "name": "Škare za kosu, brijači i aparati za brijanje tijela",
        "description": "",
        "handle": "brijanje-i-depilacija-skare-za-kosu-brijaci-i-aparati-za-brijanje-tijela",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK41MNZ3C795B2JN5JW0G",
        "rank": 2,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK5B70957TDHQA8K7KARN",
      "created_at": "2024-01-03T14:45:43.909Z",
      "updated_at": "2024-01-03T14:45:43.909Z",
      "name": "Sredstva za uklanjanje šminke",
      "description": "",
      "handle": "sminka-sredstva-za-uklanjanje-sminke",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4495D212GWWBGHX6D9H",
      "rank": 6,
      "metadata": null,
      "category_children": [
        {
          "id": "pcat_01HK7WKAXCX1T94W3GASAMDBQ5",
          "created_at": "2024-01-03T14:45:49.605Z",
          "updated_at": "2024-01-03T14:45:49.605Z",
          "name": "Sredstva za uklanjanje šminke s očiju",
          "description": "",
          "handle": "sredstva-za-uklanjanje-sminke-sredstva-za-uklanjanje-sminke-s-ociju",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK5B70957TDHQA8K7KARN",
          "rank": 0,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKAZ0XM1CAM1E1JV2C2F4",
          "created_at": "2024-01-03T14:45:49.659Z",
          "updated_at": "2024-01-03T14:45:49.659Z",
          "name": "Sredstva za uklanjanje šminke s lica",
          "description": "",
          "handle": "sredstva-za-uklanjanje-sminke-sredstva-za-uklanjanje-sminke-s-lica",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK5B70957TDHQA8K7KARN",
          "rank": 1,
          "metadata": null
        }
      ],
      "parent_category": {
        "id": "pcat_01HK7WK4495D212GWWBGHX6D9H",
        "created_at": "2024-01-03T14:45:42.665Z",
        "updated_at": "2024-01-03T14:45:42.665Z",
        "name": "Šminka",
        "description": "",
        "handle": "ljepota-sminka",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": null,
        "rank": 5,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK6AXSHZCY7YKHK4XSMZT",
      "created_at": "2024-01-03T14:45:44.916Z",
      "updated_at": "2024-01-03T14:45:44.916Z",
      "name": "Pincete",
      "description": "",
      "handle": "uklanjanje-dlaka-pincete",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK493C3GNJNECHNPJ0K2N",
      "rank": 6,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK493C3GNJNECHNPJ0K2N",
        "created_at": "2024-01-03T14:45:42.817Z",
        "updated_at": "2024-01-03T14:45:42.817Z",
        "name": "Uklanjanje dlaka",
        "description": "",
        "handle": "brijanje-i-depilacija-uklanjanje-dlaka",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK41MNZ3C795B2JN5JW0G",
        "rank": 5,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKB0EXW3NJR8RK4GQCJXC",
      "created_at": "2024-01-03T14:45:49.707Z",
      "updated_at": "2024-01-03T14:45:49.707Z",
      "name": "Svježa voda",
      "description": "",
      "handle": "za-zene-svjeza-voda",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4YNQSS786T0PHYXE7FP",
      "rank": 6,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4YNQSS786T0PHYXE7FP",
        "created_at": "2024-01-03T14:45:43.492Z",
        "updated_at": "2024-01-03T14:45:43.492Z",
        "name": "Za žene",
        "description": "",
        "handle": "parfemi-i-mirisi-za-zene_14100",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK45CQPDHWCYPEW3W862G",
        "rank": 3,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK7MJTS98WVVPDYFR58EP",
      "created_at": "2024-01-03T14:45:46.254Z",
      "updated_at": "2024-01-03T14:45:46.254Z",
      "name": "Kana",
      "description": "",
      "handle": "bojanje-kose-kana",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4KQVZQK6XPGZJEJ50QZ",
      "rank": 7,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4KQVZQK6XPGZJEJ50QZ",
        "created_at": "2024-01-03T14:45:43.157Z",
        "updated_at": "2024-01-03T14:45:43.157Z",
        "name": "Bojanje kose",
        "description": "",
        "handle": "njega-kose-bojanje-kose",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK43BQJST1XZ18R4BST01",
        "rank": 2,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKCYJNE95HEFDRR8R20S9",
      "created_at": "2024-01-03T14:45:51.696Z",
      "updated_at": "2024-01-03T14:45:51.696Z",
      "name": "Puderi od talka",
      "description": "",
      "handle": "depilacija-voskom-puderi-od-talka",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK649EWGYVF36CJ6071RM",
      "rank": 7,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK649EWGYVF36CJ6071RM",
        "created_at": "2024-01-03T14:45:44.706Z",
        "updated_at": "2024-01-03T14:45:44.706Z",
        "name": "Depilacija voskom",
        "description": "",
        "handle": "uklanjanje-dlaka-depilacija-voskom",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK493C3GNJNECHNPJ0K2N",
        "rank": 1,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK8TNZ6RWPNFYABFMKJ6W",
      "created_at": "2024-01-03T14:45:47.475Z",
      "updated_at": "2024-01-03T14:45:47.475Z",
      "name": "Pribor",
      "description": "",
      "handle": "dizajni-za-nokte-pribor",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4PDYPCE7H5D1FAFMHNK",
      "rank": 7,
      "metadata": null,
      "category_children": [
        {
          "id": "pcat_01HK7WKCG154WJVKWMPJYN62MQ",
          "created_at": "2024-01-03T14:45:51.231Z",
          "updated_at": "2024-01-03T14:45:51.231Z",
          "name": "Četke za nokte",
          "description": "",
          "handle": "pribor-cetke-za-nokte",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK8TNZ6RWPNFYABFMKJ6W",
          "rank": 0,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKCGF25K35C5YWEAE89GY",
          "created_at": "2024-01-03T14:45:51.245Z",
          "updated_at": "2024-01-03T14:45:51.245Z",
          "name": "Korektori za lak",
          "description": "",
          "handle": "pribor-korektori-za-lak",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK8TNZ6RWPNFYABFMKJ6W",
          "rank": 1,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKCGYG037A88V70DA0PTK",
          "created_at": "2024-01-03T14:45:51.260Z",
          "updated_at": "2024-01-03T14:45:51.260Z",
          "name": "Olovke za nokte",
          "description": "",
          "handle": "pribor-olovke-za-nokte",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK8TNZ6RWPNFYABFMKJ6W",
          "rank": 2,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKCHBX15Q3C9W3XXNA6NK",
          "created_at": "2024-01-03T14:45:51.273Z",
          "updated_at": "2024-01-03T14:45:51.273Z",
          "name": "Ruke i prsti za vježbanje",
          "description": "",
          "handle": "pribor-ruke-i-prsti-za-vjezbanje",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK8TNZ6RWPNFYABFMKJ6W",
          "rank": 3,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKCHVK1KHFJHB8G26RKYE",
          "created_at": "2024-01-03T14:45:51.289Z",
          "updated_at": "2024-01-03T14:45:51.289Z",
          "name": "Sušila za nokte",
          "description": "",
          "handle": "pribor-susila-za-nokte",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK8TNZ6RWPNFYABFMKJ6W",
          "rank": 4,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKCJ990VRDHMCNJGBB6XX",
          "created_at": "2024-01-03T14:45:51.303Z",
          "updated_at": "2024-01-03T14:45:51.303Z",
          "name": "Naljepnice i pribor za rašpanje noktiju",
          "description": "",
          "handle": "pribor-naljepnice-i-pribor-za-raspanje-noktiju",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK8TNZ6RWPNFYABFMKJ6W",
          "rank": 5,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKCJQFB9YVK23B7B4BCHV",
          "created_at": "2024-01-03T14:45:51.316Z",
          "updated_at": "2024-01-03T14:45:51.316Z",
          "name": "Odvajači nožnih prstiju",
          "description": "",
          "handle": "pribor-odvajaci-noznih-prstiju",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK8TNZ6RWPNFYABFMKJ6W",
          "rank": 6,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKCK72A0YAE6W3NVJER0H",
          "created_at": "2024-01-03T14:45:51.332Z",
          "updated_at": "2024-01-03T14:45:51.332Z",
          "name": "Spa papuče",
          "description": "",
          "handle": "pribor-spa-papuce",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK8TNZ6RWPNFYABFMKJ6W",
          "rank": 7,
          "metadata": null
        }
      ],
      "parent_category": {
        "id": "pcat_01HK7WK4PDYPCE7H5D1FAFMHNK",
        "created_at": "2024-01-03T14:45:43.244Z",
        "updated_at": "2024-01-03T14:45:43.244Z",
        "name": "Dizajni za nokte",
        "description": "",
        "handle": "manikura-i-pedikura-dizajni-za-nokte",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK43VEK8SG6M4T81C3W37",
        "rank": 1,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK82TW7QZDDN77E663KZ7",
      "created_at": "2024-01-03T14:45:46.712Z",
      "updated_at": "2024-01-03T14:45:46.712Z",
      "name": "Lutke za učenje",
      "description": "",
      "handle": "frizerski-namjestaj-i-potrepstine-lutke-za-ucenje",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4M7JE8S0KGQCMWF47WJ",
      "rank": 7,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4M7JE8S0KGQCMWF47WJ",
        "created_at": "2024-01-03T14:45:43.173Z",
        "updated_at": "2024-01-03T14:45:43.173Z",
        "name": "Frizerski namještaj i potrepštine",
        "description": "",
        "handle": "njega-kose-frizerski-namjestaj-i-potrepstine",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK43BQJST1XZ18R4BST01",
        "rank": 3,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK7F2GZA0Y35SQZQZZKW4",
      "created_at": "2024-01-03T14:45:46.080Z",
      "updated_at": "2024-01-03T14:45:46.080Z",
      "name": "Pegle za kosu",
      "description": "",
      "handle": "frizerski-uredaji-i-pribor-pegle-za-kosu",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4K84XGGV7XG3TFC6W69",
      "rank": 7,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4K84XGGV7XG3TFC6W69",
        "created_at": "2024-01-03T14:45:43.141Z",
        "updated_at": "2024-01-03T14:45:43.141Z",
        "name": "Frizerski uređaji i pribor",
        "description": "",
        "handle": "njega-kose-frizerski-uredaji-i-pribor",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK43BQJST1XZ18R4BST01",
        "rank": 1,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKA3KYX77SWK0S59V8X08",
      "created_at": "2024-01-03T14:45:48.784Z",
      "updated_at": "2024-01-03T14:45:48.784Z",
      "name": "Pilinzi",
      "description": "",
      "handle": "lice-pilinzi",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4CQ8JZ3NZ6QJC2MQW11",
      "rank": 7,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4CQ8JZ3NZ6QJC2MQW11",
        "created_at": "2024-01-03T14:45:42.933Z",
        "updated_at": "2024-01-03T14:45:42.933Z",
        "name": "Lice",
        "description": "",
        "handle": "njega-koze-lice_5325",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK42VB8WYE6GKG3FEJ41V",
        "rank": 0,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK94NQVVCDE2JW4B4KZ5D",
      "created_at": "2024-01-03T14:45:47.794Z",
      "updated_at": "2024-01-03T14:45:47.794Z",
      "name": "Puderi",
      "description": "",
      "handle": "lice-puderi",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4QT18DTEY1R94HB0RRX",
      "rank": 7,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4QT18DTEY1R94HB0RRX",
        "created_at": "2024-01-03T14:45:43.284Z",
        "updated_at": "2024-01-03T14:45:43.284Z",
        "name": "Lice",
        "description": "",
        "handle": "sminka-lice_11703",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK4495D212GWWBGHX6D9H",
        "rank": 0,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK45W0F9X0VMBBCAVDPWD",
      "created_at": "2024-01-03T14:45:42.715Z",
      "updated_at": "2024-01-03T14:45:42.715Z",
      "name": "Pribor i dodaci",
      "description": "",
      "handle": "ljepota-pribor-i-dodaci",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": null,
      "rank": 7,
      "metadata": null,
      "category_children": [
        {
          "id": "pcat_01HK7WK567FCGG2X517C1WNA99",
          "created_at": "2024-01-03T14:45:43.747Z",
          "updated_at": "2024-01-03T14:45:43.747Z",
          "name": "Kuglice, pamučni jastučići i pamučni štapići",
          "description": "",
          "handle": "pribor-i-dodaci-kuglice-pamucni-jastucici-i-pamucni-stapici",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK45W0F9X0VMBBCAVDPWD",
          "rank": 0,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK571RGN4X4BE7Q8XRZ7N",
          "created_at": "2024-01-03T14:45:43.774Z",
          "updated_at": "2024-01-03T14:45:43.774Z",
          "name": "Torbe i futrole",
          "description": "",
          "handle": "pribor-i-dodaci-torbe-i-futrole",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK45W0F9X0VMBBCAVDPWD",
          "rank": 1,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK57XS2NQS0EGT358BW0Q",
          "created_at": "2024-01-03T14:45:43.802Z",
          "updated_at": "2024-01-03T14:45:43.802Z",
          "name": "Alati i pribor za šminkanje",
          "description": "",
          "handle": "pribor-i-dodaci-alati-i-pribor-za-sminkanje",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK45W0F9X0VMBBCAVDPWD",
          "rank": 2,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK5D0544YXJQT9G60XVED",
          "created_at": "2024-01-03T14:45:43.966Z",
          "updated_at": "2024-01-03T14:45:43.966Z",
          "name": "Četke i pribor za šminkanje",
          "description": "",
          "handle": "pribor-i-dodaci-cetke-i-pribor-za-sminkanje",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK45W0F9X0VMBBCAVDPWD",
          "rank": 3,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK5DQTS5KH6Q63G306Q59",
          "created_at": "2024-01-03T14:45:43.984Z",
          "updated_at": "2024-01-03T14:45:43.984Z",
          "name": "Kompaktna ogledala",
          "description": "",
          "handle": "pribor-i-dodaci-kompaktna-ogledala",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK45W0F9X0VMBBCAVDPWD",
          "rank": 4,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK5E7TWKXSDKE1B6B7FCQ",
          "created_at": "2024-01-03T14:45:44.005Z",
          "updated_at": "2024-01-03T14:45:44.005Z",
          "name": "Oprema za tetoviranje",
          "description": "",
          "handle": "pribor-i-dodaci-oprema-za-tetoviranje",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK45W0F9X0VMBBCAVDPWD",
          "rank": 5,
          "metadata": null
        }
      ],
      "parent_category": null
    },
    {
      "id": "pcat_01HK7WK5A5XWEMK8CFBX8HWCSH",
      "created_at": "2024-01-03T14:45:43.874Z",
      "updated_at": "2024-01-03T14:45:43.874Z",
      "name": "Umeci za kosu, perike i dodaci",
      "description": "",
      "handle": "njega-kose-umeci-za-kosu-perike-i-dodaci",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK43BQJST1XZ18R4BST01",
      "rank": 7,
      "metadata": null,
      "category_children": [
        {
          "id": "pcat_01HK7WKAAPT097NHX021CNFSTB",
          "created_at": "2024-01-03T14:45:49.011Z",
          "updated_at": "2024-01-03T14:45:49.011Z",
          "name": "Ljepila",
          "description": "",
          "handle": "umeci-za-kosu-perike-i-dodaci-ljepila_20948",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK5A5XWEMK8CFBX8HWCSH",
          "rank": 0,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKABANM1AAD55XWDKH51X",
          "created_at": "2024-01-03T14:45:49.031Z",
          "updated_at": "2024-01-03T14:45:49.031Z",
          "name": "Kape za perike",
          "description": "",
          "handle": "umeci-za-kosu-perike-i-dodaci-kape-za-perike_20950",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK5A5XWEMK8CFBX8HWCSH",
          "rank": 1,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKABW287XCMZ1EYHB8F13",
          "created_at": "2024-01-03T14:45:49.049Z",
          "updated_at": "2024-01-03T14:45:49.049Z",
          "name": "Ekstenzije za kosu",
          "description": "",
          "handle": "umeci-za-kosu-perike-i-dodaci-ekstenzije-za-kosu_20952",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK5A5XWEMK8CFBX8HWCSH",
          "rank": 2,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKACVM8XRD0AS8HD8R40R",
          "created_at": "2024-01-03T14:45:49.079Z",
          "updated_at": "2024-01-03T14:45:49.079Z",
          "name": "Umeci",
          "description": "",
          "handle": "umeci-za-kosu-perike-i-dodaci-umeci_20953",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK5A5XWEMK8CFBX8HWCSH",
          "rank": 3,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKADNP8PXDVEGQ9JYFQ4X",
          "created_at": "2024-01-03T14:45:49.106Z",
          "updated_at": "2024-01-03T14:45:49.106Z",
          "name": "Glave od stiropora",
          "description": "",
          "handle": "umeci-za-kosu-perike-i-dodaci-glave-od-stiropora_20954",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK5A5XWEMK8CFBX8HWCSH",
          "rank": 4,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKAECZRYDN3BYT3MQC3V5",
          "created_at": "2024-01-03T14:45:49.128Z",
          "updated_at": "2024-01-03T14:45:49.128Z",
          "name": "Perike",
          "description": "",
          "handle": "umeci-za-kosu-perike-i-dodaci-perike_20955",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK5A5XWEMK8CFBX8HWCSH",
          "rank": 5,
          "metadata": null
        }
      ],
      "parent_category": {
        "id": "pcat_01HK7WK43BQJST1XZ18R4BST01",
        "created_at": "2024-01-03T14:45:42.635Z",
        "updated_at": "2024-01-03T14:45:42.635Z",
        "name": "Njega kose",
        "description": "",
        "handle": "ljepota-njega-kose",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": null,
        "rank": 3,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK593052WTZY7CMZ9J7TZ",
      "created_at": "2024-01-03T14:45:43.841Z",
      "updated_at": "2024-01-03T14:45:43.841Z",
      "name": "Kompleti i setovi za njegu kože",
      "description": "",
      "handle": "njega-koze-kompleti-i-setovi-za-njegu-koze",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK42VB8WYE6GKG3FEJ41V",
      "rank": 7,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK42VB8WYE6GKG3FEJ41V",
        "created_at": "2024-01-03T14:45:42.618Z",
        "updated_at": "2024-01-03T14:45:42.618Z",
        "name": "Njega kože",
        "description": "",
        "handle": "ljepota-njega-koze",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": null,
        "rank": 2,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK9YTTXSZP0E49ZC32R5R",
      "created_at": "2024-01-03T14:45:48.631Z",
      "updated_at": "2024-01-03T14:45:48.631Z",
      "name": "Rukavice za piling",
      "description": "",
      "handle": "njega-prije-depilacije-i-brijanja-rukavice-za-piling",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK48MV187B5ZHKEDJWPEG",
      "rank": 7,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK48MV187B5ZHKEDJWPEG",
        "created_at": "2024-01-03T14:45:42.801Z",
        "updated_at": "2024-01-03T14:45:42.801Z",
        "name": "Njega prije depilacije i brijanja",
        "description": "",
        "handle": "brijanje-i-depilacija-njega-prije-depilacije-i-brijanja",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK41MNZ3C795B2JN5JW0G",
        "rank": 4,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKDR8T6C0W257T6YB9MBH",
      "created_at": "2024-01-03T14:45:52.505Z",
      "updated_at": "2024-01-03T14:45:52.505Z",
      "name": "Kistovi za tuš",
      "description": "",
      "handle": "oci-kistovi-za-tus",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WKB41CSS4HCT9G53TPNK8",
      "rank": 7,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WKB41CSS4HCT9G53TPNK8",
        "created_at": "2024-01-03T14:45:49.823Z",
        "updated_at": "2024-01-03T14:45:49.823Z",
        "name": "Oči",
        "description": "",
        "handle": "cetke-i-pribor-za-sminkanje-oci",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK5D0544YXJQT9G60XVED",
        "rank": 2,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK9FPHAQ2N3K83Y0MH8N4",
      "created_at": "2024-01-03T14:45:48.148Z",
      "updated_at": "2024-01-03T14:45:48.148Z",
      "name": "Maskare",
      "description": "",
      "handle": "oci-maskare",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4T4JAQRP4VBRRAJR36J",
      "rank": 7,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4T4JAQRP4VBRRAJR36J",
        "created_at": "2024-01-03T14:45:43.362Z",
        "updated_at": "2024-01-03T14:45:43.362Z",
        "name": "Oči",
        "description": "",
        "handle": "sminka-oci_11745",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK4495D212GWWBGHX6D9H",
        "rank": 4,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK5549S5WEVDCDA22RP2N",
      "created_at": "2024-01-03T14:45:43.707Z",
      "updated_at": "2024-01-03T14:45:43.707Z",
      "name": "Svijeće i esencije za dom",
      "description": "",
      "handle": "parfemi-i-mirisi-svijece-i-esencije-za-dom",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK45CQPDHWCYPEW3W862G",
      "rank": 7,
      "metadata": null,
      "category_children": [
        {
          "id": "pcat_01HK7WK9STN15MB61Y62XR7C5G",
          "created_at": "2024-01-03T14:45:48.471Z",
          "updated_at": "2024-01-03T14:45:48.471Z",
          "name": "Kućne esencije",
          "description": "",
          "handle": "svijece-i-esencije-za-dom-kucne-esencije",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK5549S5WEVDCDA22RP2N",
          "rank": 0,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WK9TDB7RQ9HQCY7ATJVEG",
          "created_at": "2024-01-03T14:45:48.490Z",
          "updated_at": "2024-01-03T14:45:48.490Z",
          "name": "Svijeće",
          "description": "",
          "handle": "svijece-i-esencije-za-dom-svijece",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK5549S5WEVDCDA22RP2N",
          "rank": 1,
          "metadata": null
        }
      ],
      "parent_category": {
        "id": "pcat_01HK7WK45CQPDHWCYPEW3W862G",
        "created_at": "2024-01-03T14:45:42.699Z",
        "updated_at": "2024-01-03T14:45:42.699Z",
        "name": "Parfemi i mirisi",
        "description": "",
        "handle": "ljepota-parfemi-i-mirisi",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": null,
        "rank": 6,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK90JG69AKJ74H3079HVH",
      "created_at": "2024-01-03T14:45:47.663Z",
      "updated_at": "2024-01-03T14:45:47.663Z",
      "name": "Škarice za kutikule",
      "description": "",
      "handle": "pribor-i-dodaci-skarice-za-kutikule",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4Q9J4VMNM7KX6F1K8RQ",
      "rank": 7,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4Q9J4VMNM7KX6F1K8RQ",
        "created_at": "2024-01-03T14:45:43.271Z",
        "updated_at": "2024-01-03T14:45:43.271Z",
        "name": "Pribor i dodaci",
        "description": "",
        "handle": "manikura-i-pedikura-pribor-i-dodaci",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK43VEK8SG6M4T81C3W37",
        "rank": 3,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKCK72A0YAE6W3NVJER0H",
      "created_at": "2024-01-03T14:45:51.332Z",
      "updated_at": "2024-01-03T14:45:51.332Z",
      "name": "Spa papuče",
      "description": "",
      "handle": "pribor-spa-papuce",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK8TNZ6RWPNFYABFMKJ6W",
      "rank": 7,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK8TNZ6RWPNFYABFMKJ6W",
        "created_at": "2024-01-03T14:45:47.475Z",
        "updated_at": "2024-01-03T14:45:47.475Z",
        "name": "Pribor",
        "description": "",
        "handle": "dizajni-za-nokte-pribor",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK4PDYPCE7H5D1FAFMHNK",
        "rank": 7,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK7B4ECD28K577P20QFWY",
      "created_at": "2024-01-03T14:45:45.952Z",
      "updated_at": "2024-01-03T14:45:45.952Z",
      "name": "Ručnici za sušenje kose",
      "description": "",
      "handle": "pribor-za-friziranje-rucnici-za-susenje-kose",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4JRZ92REX0CX4M9JWEM",
      "rank": 7,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4JRZ92REX0CX4M9JWEM",
        "created_at": "2024-01-03T14:45:43.125Z",
        "updated_at": "2024-01-03T14:45:43.125Z",
        "name": "Pribor za friziranje",
        "description": "",
        "handle": "njega-kose-pribor-za-friziranje",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK43BQJST1XZ18R4BST01",
        "rank": 0,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK871STBS1B8MV5H0VZFS",
      "created_at": "2024-01-03T14:45:46.847Z",
      "updated_at": "2024-01-03T14:45:46.847Z",
      "name": "Sprejevi sa slanom vodom",
      "description": "",
      "handle": "proizvodi-za-friziranje-sprejevi-sa-slanom-vodom",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4MPDDG329H66PT1KDYF",
      "rank": 7,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4MPDDG329H66PT1KDYF",
        "created_at": "2024-01-03T14:45:43.187Z",
        "updated_at": "2024-01-03T14:45:43.187Z",
        "name": "Proizvodi za friziranje",
        "description": "",
        "handle": "njega-kose-proizvodi-za-friziranje",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK43BQJST1XZ18R4BST01",
        "rank": 4,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK8CFNNBB05HEKP4C9CEA",
      "created_at": "2024-01-03T14:45:47.020Z",
      "updated_at": "2024-01-03T14:45:47.020Z",
      "name": "Šamponi i regeneratori",
      "description": "",
      "handle": "proizvodi-za-njegu-kose-samponi-i-regeneratori",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4N31HG0EQ98AA4ZHV8C",
      "rank": 7,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4N31HG0EQ98AA4ZHV8C",
        "created_at": "2024-01-03T14:45:43.201Z",
        "updated_at": "2024-01-03T14:45:43.201Z",
        "name": "Proizvodi za njegu kose",
        "description": "",
        "handle": "njega-kose-proizvodi-za-njegu-kose",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK43BQJST1XZ18R4BST01",
        "rank": 5,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK741A7KVC4DX3BGG3080",
      "created_at": "2024-01-03T14:45:45.727Z",
      "updated_at": "2024-01-03T14:45:45.727Z",
      "name": "Pilinzi",
      "description": "",
      "handle": "ruke-i-stopala-pilinzi",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4G7CJB2WFRCRPS0131Q",
      "rank": 7,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4G7CJB2WFRCRPS0131Q",
        "created_at": "2024-01-03T14:45:43.032Z",
        "updated_at": "2024-01-03T14:45:43.032Z",
        "name": "Ruke i stopala",
        "description": "",
        "handle": "njega-koze-ruke-i-stopala",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK42VB8WYE6GKG3FEJ41V",
        "rank": 5,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK6BPVGFRJYTDV9QD1W1Q",
      "created_at": "2024-01-03T14:45:44.945Z",
      "updated_at": "2024-01-03T14:45:44.945Z",
      "name": "Sustavi za depilaciju elektrolizom",
      "description": "",
      "handle": "uklanjanje-dlaka-sustavi-za-depilaciju-elektrolizom",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK493C3GNJNECHNPJ0K2N",
      "rank": 7,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK493C3GNJNECHNPJ0K2N",
        "created_at": "2024-01-03T14:45:42.817Z",
        "updated_at": "2024-01-03T14:45:42.817Z",
        "name": "Uklanjanje dlaka",
        "description": "",
        "handle": "brijanje-i-depilacija-uklanjanje-dlaka",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK41MNZ3C795B2JN5JW0G",
        "rank": 5,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK7N0WE9XEEGQMCEF0D3J",
      "created_at": "2024-01-03T14:45:46.270Z",
      "updated_at": "2024-01-03T14:45:46.270Z",
      "name": "Pojačivači sjaja",
      "description": "",
      "handle": "bojanje-kose-pojacivaci-sjaja",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4KQVZQK6XPGZJEJ50QZ",
      "rank": 8,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4KQVZQK6XPGZJEJ50QZ",
        "created_at": "2024-01-03T14:45:43.157Z",
        "updated_at": "2024-01-03T14:45:43.157Z",
        "name": "Bojanje kose",
        "description": "",
        "handle": "njega-kose-bojanje-kose",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK43BQJST1XZ18R4BST01",
        "rank": 2,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK8V224HR9CV9810ZE5S6",
      "created_at": "2024-01-03T14:45:47.487Z",
      "updated_at": "2024-01-03T14:45:47.487Z",
      "name": "Umjetni nokti i pribor",
      "description": "",
      "handle": "dizajni-za-nokte-umjetni-nokti-i-pribor",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4PDYPCE7H5D1FAFMHNK",
      "rank": 8,
      "metadata": null,
      "category_children": [
        {
          "id": "pcat_01HK7WKCKM0XSZ333TEVKYVTQJ",
          "created_at": "2024-01-03T14:45:51.346Z",
          "updated_at": "2024-01-03T14:45:51.346Z",
          "name": "Gelovi",
          "description": "",
          "handle": "umjetni-nokti-i-pribor-gelovi",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK8V224HR9CV9810ZE5S6",
          "rank": 0,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKCM4BVDT5DNWY78KG9S9",
          "created_at": "2024-01-03T14:45:51.362Z",
          "updated_at": "2024-01-03T14:45:51.362Z",
          "name": "Akrilne tekućine i prašci",
          "description": "",
          "handle": "umjetni-nokti-i-pribor-akrilne-tekucine-i-prasci",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK8V224HR9CV9810ZE5S6",
          "rank": 1,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKCMJS79KBV0S8Q9BGYNQ",
          "created_at": "2024-01-03T14:45:51.376Z",
          "updated_at": "2024-01-03T14:45:51.376Z",
          "name": "Šablone za nokte",
          "description": "",
          "handle": "umjetni-nokti-i-pribor-sablone-za-nokte",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK8V224HR9CV9810ZE5S6",
          "rank": 2,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKCN199616QD8G5GJ069D",
          "created_at": "2024-01-03T14:45:51.390Z",
          "updated_at": "2024-01-03T14:45:51.390Z",
          "name": "Ljepila za nokte",
          "description": "",
          "handle": "umjetni-nokti-i-pribor-ljepila-za-nokte",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK8V224HR9CV9810ZE5S6",
          "rank": 3,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKCNFZR2E3ZRNREXPM3FF",
          "created_at": "2024-01-03T14:45:51.405Z",
          "updated_at": "2024-01-03T14:45:51.405Z",
          "name": "Vrhovi za nokte",
          "description": "",
          "handle": "umjetni-nokti-i-pribor-vrhovi-za-nokte",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK8V224HR9CV9810ZE5S6",
          "rank": 4,
          "metadata": null
        }
      ],
      "parent_category": {
        "id": "pcat_01HK7WK4PDYPCE7H5D1FAFMHNK",
        "created_at": "2024-01-03T14:45:43.244Z",
        "updated_at": "2024-01-03T14:45:43.244Z",
        "name": "Dizajni za nokte",
        "description": "",
        "handle": "manikura-i-pedikura-dizajni-za-nokte",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK43VEK8SG6M4T81C3W37",
        "rank": 1,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK7FMA2XHSD9CT3FNPC0Y",
      "created_at": "2024-01-03T14:45:46.098Z",
      "updated_at": "2024-01-03T14:45:46.098Z",
      "name": "Pegle za kovrčanje",
      "description": "",
      "handle": "frizerski-uredaji-i-pribor-pegle-za-kovrcanje",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4K84XGGV7XG3TFC6W69",
      "rank": 8,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4K84XGGV7XG3TFC6W69",
        "created_at": "2024-01-03T14:45:43.141Z",
        "updated_at": "2024-01-03T14:45:43.141Z",
        "name": "Frizerski uređaji i pribor",
        "description": "",
        "handle": "njega-kose-frizerski-uredaji-i-pribor",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK43BQJST1XZ18R4BST01",
        "rank": 1,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK952DX31E0SKK6G6JTPS",
      "created_at": "2024-01-03T14:45:47.808Z",
      "updated_at": "2024-01-03T14:45:47.808Z",
      "name": "Primeri",
      "description": "",
      "handle": "lice-primeri",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4QT18DTEY1R94HB0RRX",
      "rank": 8,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4QT18DTEY1R94HB0RRX",
        "created_at": "2024-01-03T14:45:43.284Z",
        "updated_at": "2024-01-03T14:45:43.284Z",
        "name": "Lice",
        "description": "",
        "handle": "sminka-lice_11703",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK4495D212GWWBGHX6D9H",
        "rank": 0,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKA4A9X2P8V3NRCBSCAWK",
      "created_at": "2024-01-03T14:45:48.806Z",
      "updated_at": "2024-01-03T14:45:48.806Z",
      "name": "Tretmani za mrlje",
      "description": "",
      "handle": "lice-tretmani-za-mrlje",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4CQ8JZ3NZ6QJC2MQW11",
      "rank": 8,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4CQ8JZ3NZ6QJC2MQW11",
        "created_at": "2024-01-03T14:45:42.933Z",
        "updated_at": "2024-01-03T14:45:42.933Z",
        "name": "Lice",
        "description": "",
        "handle": "njega-koze-lice_5325",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK42VB8WYE6GKG3FEJ41V",
        "rank": 0,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK59M5WTCRNSKRG8JBN56",
      "created_at": "2024-01-03T14:45:43.857Z",
      "updated_at": "2024-01-03T14:45:43.857Z",
      "name": "Kreme za sunčanje i tamnjenje",
      "description": "",
      "handle": "njega-koze-kreme-za-suncanje-i-tamnjenje",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK42VB8WYE6GKG3FEJ41V",
      "rank": 8,
      "metadata": null,
      "category_children": [
        {
          "id": "pcat_01HK7WKA7CPSVGX00GKN7Y8S32",
          "created_at": "2024-01-03T14:45:48.905Z",
          "updated_at": "2024-01-03T14:45:48.905Z",
          "name": "Sredstva za samotamnjenje",
          "description": "",
          "handle": "kreme-za-suncanje-i-tamnjenje-sredstva-za-samotamnjenje",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK59M5WTCRNSKRG8JBN56",
          "rank": 0,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKA7ZGXQYRBZ9BAADZQKB",
          "created_at": "2024-01-03T14:45:48.924Z",
          "updated_at": "2024-01-03T14:45:48.924Z",
          "name": "Ubrzivači i optimizatori tamnjenja",
          "description": "",
          "handle": "kreme-za-suncanje-i-tamnjenje-ubrzivaci-i-optimizatori-tamnjenja",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK59M5WTCRNSKRG8JBN56",
          "rank": 1,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKA8MEFNXMV7G5QH0DGE2",
          "created_at": "2024-01-03T14:45:48.945Z",
          "updated_at": "2024-01-03T14:45:48.945Z",
          "name": "Solarni filtri",
          "description": "",
          "handle": "kreme-za-suncanje-i-tamnjenje-solarni-filtri",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK59M5WTCRNSKRG8JBN56",
          "rank": 2,
          "metadata": null
        }
      ],
      "parent_category": {
        "id": "pcat_01HK7WK42VB8WYE6GKG3FEJ41V",
        "created_at": "2024-01-03T14:45:42.618Z",
        "updated_at": "2024-01-03T14:45:42.618Z",
        "name": "Njega kože",
        "description": "",
        "handle": "ljepota-njega-koze",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": null,
        "rank": 2,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKDS30VWAW0CJB83X6570",
      "created_at": "2024-01-03T14:45:52.543Z",
      "updated_at": "2024-01-03T14:45:52.543Z",
      "name": "Četkice za sjenila",
      "description": "",
      "handle": "oci-cetkice-za-sjenila",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WKB41CSS4HCT9G53TPNK8",
      "rank": 8,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WKB41CSS4HCT9G53TPNK8",
        "created_at": "2024-01-03T14:45:49.823Z",
        "updated_at": "2024-01-03T14:45:49.823Z",
        "name": "Oči",
        "description": "",
        "handle": "cetke-i-pribor-za-sminkanje-oci",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK5D0544YXJQT9G60XVED",
        "rank": 2,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK9G5BQV456SXH5ZVJ75J",
      "created_at": "2024-01-03T14:45:48.163Z",
      "updated_at": "2024-01-03T14:45:48.163Z",
      "name": "Sjenila za oči",
      "description": "",
      "handle": "oci-sjenila-za-oci",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4T4JAQRP4VBRRAJR36J",
      "rank": 8,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4T4JAQRP4VBRRAJR36J",
        "created_at": "2024-01-03T14:45:43.362Z",
        "updated_at": "2024-01-03T14:45:43.362Z",
        "name": "Oči",
        "description": "",
        "handle": "sminka-oci_11745",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK4495D212GWWBGHX6D9H",
        "rank": 4,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK5BT94MH3Z5NZ75JJ8VH",
      "created_at": "2024-01-03T14:45:43.928Z",
      "updated_at": "2024-01-03T14:45:43.928Z",
      "name": "Raspršivači",
      "description": "",
      "handle": "parfemi-i-mirisi-rasprsivaci",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK45CQPDHWCYPEW3W862G",
      "rank": 8,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK45CQPDHWCYPEW3W862G",
        "created_at": "2024-01-03T14:45:42.699Z",
        "updated_at": "2024-01-03T14:45:42.699Z",
        "name": "Parfemi i mirisi",
        "description": "",
        "handle": "ljepota-parfemi-i-mirisi",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": null,
        "rank": 6,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK911XT0QAQ98QPMW77BN",
      "created_at": "2024-01-03T14:45:47.679Z",
      "updated_at": "2024-01-03T14:45:47.679Z",
      "name": "Škarice za nokte",
      "description": "",
      "handle": "pribor-i-dodaci-skarice-za-nokte",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4Q9J4VMNM7KX6F1K8RQ",
      "rank": 8,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4Q9J4VMNM7KX6F1K8RQ",
        "created_at": "2024-01-03T14:45:43.271Z",
        "updated_at": "2024-01-03T14:45:43.271Z",
        "name": "Pribor i dodaci",
        "description": "",
        "handle": "manikura-i-pedikura-pribor-i-dodaci",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK43VEK8SG6M4T81C3W37",
        "rank": 3,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK87J8PH9MPSMCVC6E27V",
      "created_at": "2024-01-03T14:45:46.863Z",
      "updated_at": "2024-01-03T14:45:46.863Z",
      "name": "Termo zaštitni sprejevi",
      "description": "",
      "handle": "proizvodi-za-friziranje-termo-zastitni-sprejevi",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4MPDDG329H66PT1KDYF",
      "rank": 8,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4MPDDG329H66PT1KDYF",
        "created_at": "2024-01-03T14:45:43.187Z",
        "updated_at": "2024-01-03T14:45:43.187Z",
        "name": "Proizvodi za friziranje",
        "description": "",
        "handle": "njega-kose-proizvodi-za-friziranje",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK43BQJST1XZ18R4BST01",
        "rank": 4,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK8D0258MBSFNMM9ACK2W",
      "created_at": "2024-01-03T14:45:47.037Z",
      "updated_at": "2024-01-03T14:45:47.037Z",
      "name": "Njega kose i vlasišta",
      "description": "",
      "handle": "proizvodi-za-njegu-kose-njega-kose-i-vlasista",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4N31HG0EQ98AA4ZHV8C",
      "rank": 8,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4N31HG0EQ98AA4ZHV8C",
        "created_at": "2024-01-03T14:45:43.201Z",
        "updated_at": "2024-01-03T14:45:43.201Z",
        "name": "Proizvodi za njegu kose",
        "description": "",
        "handle": "njega-kose-proizvodi-za-njegu-kose",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK43BQJST1XZ18R4BST01",
        "rank": 5,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK74EHZN0P1K5BFB9M6Q8",
      "created_at": "2024-01-03T14:45:45.740Z",
      "updated_at": "2024-01-03T14:45:45.740Z",
      "name": "Hidratantne rukavice",
      "description": "",
      "handle": "ruke-i-stopala-hidratantne-rukavice",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4G7CJB2WFRCRPS0131Q",
      "rank": 8,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4G7CJB2WFRCRPS0131Q",
        "created_at": "2024-01-03T14:45:43.032Z",
        "updated_at": "2024-01-03T14:45:43.032Z",
        "name": "Ruke i stopala",
        "description": "",
        "handle": "njega-koze-ruke-i-stopala",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK42VB8WYE6GKG3FEJ41V",
        "rank": 5,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK7NER2J5N522EZH55B2M",
      "created_at": "2024-01-03T14:45:46.284Z",
      "updated_at": "2024-01-03T14:45:46.284Z",
      "name": "Razvijači",
      "description": "",
      "handle": "bojanje-kose-razvijaci",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4KQVZQK6XPGZJEJ50QZ",
      "rank": 9,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4KQVZQK6XPGZJEJ50QZ",
        "created_at": "2024-01-03T14:45:43.157Z",
        "updated_at": "2024-01-03T14:45:43.157Z",
        "name": "Bojanje kose",
        "description": "",
        "handle": "njega-kose-bojanje-kose",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK43BQJST1XZ18R4BST01",
        "rank": 2,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK7G49108C023ACQJBQXK",
      "created_at": "2024-01-03T14:45:46.114Z",
      "updated_at": "2024-01-03T14:45:46.114Z",
      "name": "Vikleri",
      "description": "",
      "handle": "frizerski-uredaji-i-pribor-vikleri",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4K84XGGV7XG3TFC6W69",
      "rank": 9,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4K84XGGV7XG3TFC6W69",
        "created_at": "2024-01-03T14:45:43.141Z",
        "updated_at": "2024-01-03T14:45:43.141Z",
        "name": "Frizerski uređaji i pribor",
        "description": "",
        "handle": "njega-kose-frizerski-uredaji-i-pribor",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK43BQJST1XZ18R4BST01",
        "rank": 1,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKANQ83E7JVVVTGY79E5F",
      "created_at": "2024-01-03T14:45:49.359Z",
      "updated_at": "2024-01-03T14:45:49.359Z",
      "name": "BB kreme",
      "description": "",
      "handle": "lice-bb-kreme",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4QT18DTEY1R94HB0RRX",
      "rank": 9,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4QT18DTEY1R94HB0RRX",
        "created_at": "2024-01-03T14:45:43.284Z",
        "updated_at": "2024-01-03T14:45:43.284Z",
        "name": "Lice",
        "description": "",
        "handle": "sminka-lice_11703",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK4495D212GWWBGHX6D9H",
        "rank": 0,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKA4YQGBBC7EX1RK5HXNT",
      "created_at": "2024-01-03T14:45:48.827Z",
      "updated_at": "2024-01-03T14:45:48.827Z",
      "name": "Trake",
      "description": "",
      "handle": "lice-trake",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4CQ8JZ3NZ6QJC2MQW11",
      "rank": 9,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4CQ8JZ3NZ6QJC2MQW11",
        "created_at": "2024-01-03T14:45:42.933Z",
        "updated_at": "2024-01-03T14:45:42.933Z",
        "name": "Lice",
        "description": "",
        "handle": "njega-koze-lice_5325",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK42VB8WYE6GKG3FEJ41V",
        "rank": 0,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK9GJGGVJZK3DYK9KC2PX",
      "created_at": "2024-01-03T14:45:48.176Z",
      "updated_at": "2024-01-03T14:45:48.176Z",
      "name": "Tretmani za trepavice",
      "description": "",
      "handle": "oci-tretmani-za-trepavice",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4T4JAQRP4VBRRAJR36J",
      "rank": 9,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4T4JAQRP4VBRRAJR36J",
        "created_at": "2024-01-03T14:45:43.362Z",
        "updated_at": "2024-01-03T14:45:43.362Z",
        "name": "Oči",
        "description": "",
        "handle": "sminka-oci_11745",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK4495D212GWWBGHX6D9H",
        "rank": 4,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK5CFK2YJ8SJ5H0G76M0D",
      "created_at": "2024-01-03T14:45:43.949Z",
      "updated_at": "2024-01-03T14:45:43.949Z",
      "name": "Kompleti",
      "description": "",
      "handle": "parfemi-i-mirisi-kompleti",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK45CQPDHWCYPEW3W862G",
      "rank": 9,
      "metadata": null,
      "category_children": [
        {
          "id": "pcat_01HK7WKB0X3GAGPNE1SK30HRDG",
          "created_at": "2024-01-03T14:45:49.723Z",
          "updated_at": "2024-01-03T14:45:49.723Z",
          "name": "Za žene",
          "description": "",
          "handle": "kompleti-za-zene",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK5CFK2YJ8SJ5H0G76M0D",
          "rank": 0,
          "metadata": null
        }
      ],
      "parent_category": {
        "id": "pcat_01HK7WK45CQPDHWCYPEW3W862G",
        "created_at": "2024-01-03T14:45:42.699Z",
        "updated_at": "2024-01-03T14:45:42.699Z",
        "name": "Parfemi i mirisi",
        "description": "",
        "handle": "ljepota-parfemi-i-mirisi",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": null,
        "rank": 6,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK88200NPA35NZNE6YKYT",
      "created_at": "2024-01-03T14:45:46.879Z",
      "updated_at": "2024-01-03T14:45:46.879Z",
      "name": "Serumi",
      "description": "",
      "handle": "proizvodi-za-friziranje-serumi",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4MPDDG329H66PT1KDYF",
      "rank": 9,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4MPDDG329H66PT1KDYF",
        "created_at": "2024-01-03T14:45:43.187Z",
        "updated_at": "2024-01-03T14:45:43.187Z",
        "name": "Proizvodi za friziranje",
        "description": "",
        "handle": "njega-kose-proizvodi-za-friziranje",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK43BQJST1XZ18R4BST01",
        "rank": 4,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK8G2Z3AJKVQHHMP19XTT",
      "created_at": "2024-01-03T14:45:47.125Z",
      "updated_at": "2024-01-03T14:45:47.125Z",
      "name": "Setovi šampona i regeneratora",
      "description": "",
      "handle": "proizvodi-za-njegu-kose-setovi-sampona-i-regeneratora",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4N31HG0EQ98AA4ZHV8C",
      "rank": 9,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4N31HG0EQ98AA4ZHV8C",
        "created_at": "2024-01-03T14:45:43.201Z",
        "updated_at": "2024-01-03T14:45:43.201Z",
        "name": "Proizvodi za njegu kose",
        "description": "",
        "handle": "njega-kose-proizvodi-za-njegu-kose",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK43BQJST1XZ18R4BST01",
        "rank": 5,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK74YPA81Z41X086EF098",
      "created_at": "2024-01-03T14:45:45.755Z",
      "updated_at": "2024-01-03T14:45:45.755Z",
      "name": "Tekućine za uklanjanje zanoktica",
      "description": "",
      "handle": "ruke-i-stopala-tekucine-za-uklanjanje-zanoktica",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4G7CJB2WFRCRPS0131Q",
      "rank": 9,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4G7CJB2WFRCRPS0131Q",
        "created_at": "2024-01-03T14:45:43.032Z",
        "updated_at": "2024-01-03T14:45:43.032Z",
        "name": "Ruke i stopala",
        "description": "",
        "handle": "njega-koze-ruke-i-stopala",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK42VB8WYE6GKG3FEJ41V",
        "rank": 5,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK7NY0HSTC9CBHTVK2HB6",
      "created_at": "2024-01-03T14:45:46.299Z",
      "updated_at": "2024-01-03T14:45:46.299Z",
      "name": "Krede za kosu",
      "description": "",
      "handle": "bojanje-kose-krede-za-kosu",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4KQVZQK6XPGZJEJ50QZ",
      "rank": 10,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4KQVZQK6XPGZJEJ50QZ",
        "created_at": "2024-01-03T14:45:43.157Z",
        "updated_at": "2024-01-03T14:45:43.157Z",
        "name": "Bojanje kose",
        "description": "",
        "handle": "njega-kose-bojanje-kose",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK43BQJST1XZ18R4BST01",
        "rank": 2,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK7GJ8HGJNY1PB3BBQQFT",
      "created_at": "2024-01-03T14:45:46.127Z",
      "updated_at": "2024-01-03T14:45:46.127Z",
      "name": "Haube za kosu",
      "description": "",
      "handle": "frizerski-uredaji-i-pribor-haube-za-kosu",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4K84XGGV7XG3TFC6W69",
      "rank": 10,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4K84XGGV7XG3TFC6W69",
        "created_at": "2024-01-03T14:45:43.141Z",
        "updated_at": "2024-01-03T14:45:43.141Z",
        "name": "Frizerski uređaji i pribor",
        "description": "",
        "handle": "njega-kose-frizerski-uredaji-i-pribor",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK43BQJST1XZ18R4BST01",
        "rank": 1,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKAQ7PY2VM50R4VSNMDBM",
      "created_at": "2024-01-03T14:45:49.408Z",
      "updated_at": "2024-01-03T14:45:49.408Z",
      "name": "CC kreme",
      "description": "",
      "handle": "lice-cc-kreme",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4QT18DTEY1R94HB0RRX",
      "rank": 10,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4QT18DTEY1R94HB0RRX",
        "created_at": "2024-01-03T14:45:43.284Z",
        "updated_at": "2024-01-03T14:45:43.284Z",
        "name": "Lice",
        "description": "",
        "handle": "sminka-lice_11703",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK4495D212GWWBGHX6D9H",
        "rank": 0,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKA5H4XTD85V9HS4FQ1VT",
      "created_at": "2024-01-03T14:45:48.846Z",
      "updated_at": "2024-01-03T14:45:48.846Z",
      "name": "Toneri za lice i adstringenti",
      "description": "",
      "handle": "lice-toneri-za-lice-i-adstringenti",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4CQ8JZ3NZ6QJC2MQW11",
      "rank": 10,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4CQ8JZ3NZ6QJC2MQW11",
        "created_at": "2024-01-03T14:45:42.933Z",
        "updated_at": "2024-01-03T14:45:42.933Z",
        "name": "Lice",
        "description": "",
        "handle": "njega-koze-lice_5325",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK42VB8WYE6GKG3FEJ41V",
        "rank": 0,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK8H4VMDHCKXWCSSNSGS8",
      "created_at": "2024-01-03T14:45:47.170Z",
      "updated_at": "2024-01-03T14:45:47.170Z",
      "name": "Maske",
      "description": "",
      "handle": "proizvodi-za-njegu-kose-maske",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4N31HG0EQ98AA4ZHV8C",
      "rank": 10,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4N31HG0EQ98AA4ZHV8C",
        "created_at": "2024-01-03T14:45:43.201Z",
        "updated_at": "2024-01-03T14:45:43.201Z",
        "name": "Proizvodi za njegu kose",
        "description": "",
        "handle": "njega-kose-proizvodi-za-njegu-kose",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK43BQJST1XZ18R4BST01",
        "rank": 5,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK7PEMR10FJBSZDJRZYKT",
      "created_at": "2024-01-03T14:45:46.316Z",
      "updated_at": "2024-01-03T14:45:46.316Z",
      "name": "Boje za brkove i bradu",
      "description": "",
      "handle": "bojanje-kose-boje-za-brkove-i-bradu",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4KQVZQK6XPGZJEJ50QZ",
      "rank": 11,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4KQVZQK6XPGZJEJ50QZ",
        "created_at": "2024-01-03T14:45:43.157Z",
        "updated_at": "2024-01-03T14:45:43.157Z",
        "name": "Bojanje kose",
        "description": "",
        "handle": "njega-kose-bojanje-kose",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK43BQJST1XZ18R4BST01",
        "rank": 2,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK7H0CCVR0D8RJQJFTVCE",
      "created_at": "2024-01-03T14:45:46.142Z",
      "updated_at": "2024-01-03T14:45:46.142Z",
      "name": "Fenovi za kosu",
      "description": "",
      "handle": "frizerski-uredaji-i-pribor-fenovi-za-kosu",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4K84XGGV7XG3TFC6W69",
      "rank": 11,
      "metadata": null,
      "category_children": [
        {
          "id": "pcat_01HK7WKCC16Y30VKY4XX0NQEK9",
          "created_at": "2024-01-03T14:45:51.101Z",
          "updated_at": "2024-01-03T14:45:51.101Z",
          "name": "Difuzori",
          "description": "",
          "handle": "fenovi-za-kosu-difuzori",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK7H0CCVR0D8RJQJFTVCE",
          "rank": 0,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKCCWBAJFDF76EV39BD0R",
          "created_at": "2024-01-03T14:45:51.130Z",
          "updated_at": "2024-01-03T14:45:51.130Z",
          "name": "Češljevi",
          "description": "",
          "handle": "fenovi-za-kosu-cesljevi",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK7H0CCVR0D8RJQJFTVCE",
          "rank": 1,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKCDA24B2FNVMVT43ZQBB",
          "created_at": "2024-01-03T14:45:51.143Z",
          "updated_at": "2024-01-03T14:45:51.143Z",
          "name": "Fenovi za kosu",
          "description": "",
          "handle": "fenovi-za-kosu-fenovi-za-kosu",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WK7H0CCVR0D8RJQJFTVCE",
          "rank": 2,
          "metadata": null
        }
      ],
      "parent_category": {
        "id": "pcat_01HK7WK4K84XGGV7XG3TFC6W69",
        "created_at": "2024-01-03T14:45:43.141Z",
        "updated_at": "2024-01-03T14:45:43.141Z",
        "name": "Frizerski uređaji i pribor",
        "description": "",
        "handle": "njega-kose-frizerski-uredaji-i-pribor",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK43BQJST1XZ18R4BST01",
        "rank": 1,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKARMX1C6H2KE2Z9Z67WR",
      "created_at": "2024-01-03T14:45:49.449Z",
      "updated_at": "2024-01-03T14:45:49.449Z",
      "name": "Bronzeri",
      "description": "",
      "handle": "lice-bronzeri",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4QT18DTEY1R94HB0RRX",
      "rank": 11,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4QT18DTEY1R94HB0RRX",
        "created_at": "2024-01-03T14:45:43.284Z",
        "updated_at": "2024-01-03T14:45:43.284Z",
        "name": "Lice",
        "description": "",
        "handle": "sminka-lice_11703",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK4495D212GWWBGHX6D9H",
        "rank": 0,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKA65JRAC0236SKZJ0C3F",
      "created_at": "2024-01-03T14:45:48.866Z",
      "updated_at": "2024-01-03T14:45:48.866Z",
      "name": "Serumi",
      "description": "",
      "handle": "lice-serumi",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4CQ8JZ3NZ6QJC2MQW11",
      "rank": 11,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4CQ8JZ3NZ6QJC2MQW11",
        "created_at": "2024-01-03T14:45:42.933Z",
        "updated_at": "2024-01-03T14:45:42.933Z",
        "name": "Lice",
        "description": "",
        "handle": "njega-koze-lice_5325",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK42VB8WYE6GKG3FEJ41V",
        "rank": 0,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK8HKXHNNE7D4FZG1VKR0",
      "created_at": "2024-01-03T14:45:47.184Z",
      "updated_at": "2024-01-03T14:45:47.184Z",
      "name": "Maske za kosu",
      "description": "",
      "handle": "proizvodi-za-njegu-kose-maske-za-kosu",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4N31HG0EQ98AA4ZHV8C",
      "rank": 11,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4N31HG0EQ98AA4ZHV8C",
        "created_at": "2024-01-03T14:45:43.201Z",
        "updated_at": "2024-01-03T14:45:43.201Z",
        "name": "Proizvodi za njegu kose",
        "description": "",
        "handle": "njega-kose-proizvodi-za-njegu-kose",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK43BQJST1XZ18R4BST01",
        "rank": 5,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKA9AGTDM7Q21X9A0VCKZ",
      "created_at": "2024-01-03T14:45:48.966Z",
      "updated_at": "2024-01-03T14:45:48.966Z",
      "name": "Škare za kosu i bradu",
      "description": "",
      "handle": "frizerski-uredaji-i-pribor-skare-za-kosu-i-bradu",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4K84XGGV7XG3TFC6W69",
      "rank": 12,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4K84XGGV7XG3TFC6W69",
        "created_at": "2024-01-03T14:45:43.141Z",
        "updated_at": "2024-01-03T14:45:43.141Z",
        "name": "Frizerski uređaji i pribor",
        "description": "",
        "handle": "njega-kose-frizerski-uredaji-i-pribor",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK43BQJST1XZ18R4BST01",
        "rank": 1,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKATD9VZX528NQ0CM2Z8Z",
      "created_at": "2024-01-03T14:45:49.505Z",
      "updated_at": "2024-01-03T14:45:49.505Z",
      "name": "Iluminatori i luminizatori",
      "description": "",
      "handle": "lice-iluminatori-i-luminizatori",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4QT18DTEY1R94HB0RRX",
      "rank": 12,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4QT18DTEY1R94HB0RRX",
        "created_at": "2024-01-03T14:45:43.284Z",
        "updated_at": "2024-01-03T14:45:43.284Z",
        "name": "Lice",
        "description": "",
        "handle": "sminka-lice_11703",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK4495D212GWWBGHX6D9H",
        "rank": 0,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK8J1HRYV6YGWJB4E173C",
      "created_at": "2024-01-03T14:45:47.199Z",
      "updated_at": "2024-01-03T14:45:47.199Z",
      "name": "Maske za bojanje kose",
      "description": "",
      "handle": "proizvodi-za-njegu-kose-maske-za-bojanje-kose",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4N31HG0EQ98AA4ZHV8C",
      "rank": 12,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4N31HG0EQ98AA4ZHV8C",
        "created_at": "2024-01-03T14:45:43.201Z",
        "updated_at": "2024-01-03T14:45:43.201Z",
        "name": "Proizvodi za njegu kose",
        "description": "",
        "handle": "njega-kose-proizvodi-za-njegu-kose",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK43BQJST1XZ18R4BST01",
        "rank": 5,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKAA0GJVNVKHFMBVWE9GZ",
      "created_at": "2024-01-03T14:45:48.986Z",
      "updated_at": "2024-01-03T14:45:48.986Z",
      "name": "Fenovi i difuzori",
      "description": "",
      "handle": "frizerski-uredaji-i-pribor-fenovi-i-difuzori",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4K84XGGV7XG3TFC6W69",
      "rank": 13,
      "metadata": null,
      "category_children": [
        {
          "id": "pcat_01HK7WKD5BRHP1FEVTC01KN1VD",
          "created_at": "2024-01-03T14:45:51.913Z",
          "updated_at": "2024-01-03T14:45:51.913Z",
          "name": "Difuzori",
          "description": "",
          "handle": "fenovi-i-difuzori-difuzori",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WKAA0GJVNVKHFMBVWE9GZ",
          "rank": 0,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKD5RQFCXXH4JJ2XC036K",
          "created_at": "2024-01-03T14:45:51.926Z",
          "updated_at": "2024-01-03T14:45:51.926Z",
          "name": "Češljevi",
          "description": "",
          "handle": "fenovi-i-difuzori-cesljevi",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WKAA0GJVNVKHFMBVWE9GZ",
          "rank": 1,
          "metadata": null
        },
        {
          "id": "pcat_01HK7WKD6C1W8AEG3WGXS781FD",
          "created_at": "2024-01-03T14:45:51.945Z",
          "updated_at": "2024-01-03T14:45:51.945Z",
          "name": "Fenovi za kosu",
          "description": "",
          "handle": "fenovi-i-difuzori-fenovi-za-kosu",
          "is_active": true,
          "is_internal": false,
          "parent_category_id": "pcat_01HK7WKAA0GJVNVKHFMBVWE9GZ",
          "rank": 2,
          "metadata": null
        }
      ],
      "parent_category": {
        "id": "pcat_01HK7WK4K84XGGV7XG3TFC6W69",
        "created_at": "2024-01-03T14:45:43.141Z",
        "updated_at": "2024-01-03T14:45:43.141Z",
        "name": "Frizerski uređaji i pribor",
        "description": "",
        "handle": "njega-kose-frizerski-uredaji-i-pribor",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK43BQJST1XZ18R4BST01",
        "rank": 1,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK8JFC92724VXWFTV7MP7",
      "created_at": "2024-01-03T14:45:47.212Z",
      "updated_at": "2024-01-03T14:45:47.212Z",
      "name": "Sredstva za trajnu i teksturiranje",
      "description": "",
      "handle": "proizvodi-za-njegu-kose-sredstva-za-trajnu-i-teksturiranje",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4N31HG0EQ98AA4ZHV8C",
      "rank": 13,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4N31HG0EQ98AA4ZHV8C",
        "created_at": "2024-01-03T14:45:43.201Z",
        "updated_at": "2024-01-03T14:45:43.201Z",
        "name": "Proizvodi za njegu kose",
        "description": "",
        "handle": "njega-kose-proizvodi-za-njegu-kose",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK43BQJST1XZ18R4BST01",
        "rank": 5,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK8JYZJY7W2AHM17MWEXF",
      "created_at": "2024-01-03T14:45:47.227Z",
      "updated_at": "2024-01-03T14:45:47.227Z",
      "name": "Proizvodi za ravnanje kose",
      "description": "",
      "handle": "proizvodi-za-njegu-kose-proizvodi-za-ravnanje-kose",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4N31HG0EQ98AA4ZHV8C",
      "rank": 14,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4N31HG0EQ98AA4ZHV8C",
        "created_at": "2024-01-03T14:45:43.201Z",
        "updated_at": "2024-01-03T14:45:43.201Z",
        "name": "Proizvodi za njegu kose",
        "description": "",
        "handle": "njega-kose-proizvodi-za-njegu-kose",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK43BQJST1XZ18R4BST01",
        "rank": 5,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK8KEFMVH9F65P6T0B99P",
      "created_at": "2024-01-03T14:45:47.242Z",
      "updated_at": "2024-01-03T14:45:47.242Z",
      "name": "Proizvodi za gubitak kose",
      "description": "",
      "handle": "proizvodi-za-njegu-kose-proizvodi-za-gubitak-kose",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4N31HG0EQ98AA4ZHV8C",
      "rank": 15,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4N31HG0EQ98AA4ZHV8C",
        "created_at": "2024-01-03T14:45:43.201Z",
        "updated_at": "2024-01-03T14:45:43.201Z",
        "name": "Proizvodi za njegu kose",
        "description": "",
        "handle": "njega-kose-proizvodi-za-njegu-kose",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK43BQJST1XZ18R4BST01",
        "rank": 5,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK8KXBZWJMC3154610SV4",
      "created_at": "2024-01-03T14:45:47.258Z",
      "updated_at": "2024-01-03T14:45:47.258Z",
      "name": "Opuštači za kosu",
      "description": "",
      "handle": "proizvodi-za-njegu-kose-opustaci-za-kosu",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4N31HG0EQ98AA4ZHV8C",
      "rank": 16,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4N31HG0EQ98AA4ZHV8C",
        "created_at": "2024-01-03T14:45:43.201Z",
        "updated_at": "2024-01-03T14:45:43.201Z",
        "name": "Proizvodi za njegu kose",
        "description": "",
        "handle": "njega-kose-proizvodi-za-njegu-kose",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK43BQJST1XZ18R4BST01",
        "rank": 5,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK8MB0CZ7ZXVAGYDT7P2B",
      "created_at": "2024-01-03T14:45:47.273Z",
      "updated_at": "2024-01-03T14:45:47.273Z",
      "name": "Poklon setovi",
      "description": "",
      "handle": "proizvodi-za-njegu-kose-poklon-setovi",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4N31HG0EQ98AA4ZHV8C",
      "rank": 17,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4N31HG0EQ98AA4ZHV8C",
        "created_at": "2024-01-03T14:45:43.201Z",
        "updated_at": "2024-01-03T14:45:43.201Z",
        "name": "Proizvodi za njegu kose",
        "description": "",
        "handle": "njega-kose-proizvodi-za-njegu-kose",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK43BQJST1XZ18R4BST01",
        "rank": 5,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK8MTDA9QBJ5KPCNWACT7",
      "created_at": "2024-01-03T14:45:47.286Z",
      "updated_at": "2024-01-03T14:45:47.286Z",
      "name": "Putni setovi",
      "description": "",
      "handle": "proizvodi-za-njegu-kose-putni-setovi",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4N31HG0EQ98AA4ZHV8C",
      "rank": 18,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4N31HG0EQ98AA4ZHV8C",
        "created_at": "2024-01-03T14:45:43.201Z",
        "updated_at": "2024-01-03T14:45:43.201Z",
        "name": "Proizvodi za njegu kose",
        "description": "",
        "handle": "njega-kose-proizvodi-za-njegu-kose",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK43BQJST1XZ18R4BST01",
        "rank": 5,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK8N7DRMEZ70Z0N698FZK",
      "created_at": "2024-01-03T14:45:47.301Z",
      "updated_at": "2024-01-03T14:45:47.301Z",
      "name": "Tretmani za rast kose",
      "description": "",
      "handle": "proizvodi-za-njegu-kose-tretmani-za-rast-kose",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4N31HG0EQ98AA4ZHV8C",
      "rank": 19,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4N31HG0EQ98AA4ZHV8C",
        "created_at": "2024-01-03T14:45:43.201Z",
        "updated_at": "2024-01-03T14:45:43.201Z",
        "name": "Proizvodi za njegu kose",
        "description": "",
        "handle": "njega-kose-proizvodi-za-njegu-kose",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK43BQJST1XZ18R4BST01",
        "rank": 5,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WK8NR84Y105B6G6ZK1TZT",
      "created_at": "2024-01-03T14:45:47.317Z",
      "updated_at": "2024-01-03T14:45:47.317Z",
      "name": "Tonici za kosu",
      "description": "",
      "handle": "proizvodi-za-njegu-kose-tonici-za-kosu",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4N31HG0EQ98AA4ZHV8C",
      "rank": 20,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4N31HG0EQ98AA4ZHV8C",
        "created_at": "2024-01-03T14:45:43.201Z",
        "updated_at": "2024-01-03T14:45:43.201Z",
        "name": "Proizvodi za njegu kose",
        "description": "",
        "handle": "njega-kose-proizvodi-za-njegu-kose",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK43BQJST1XZ18R4BST01",
        "rank": 5,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKAEZTMPST914YJPY0TS6",
      "created_at": "2024-01-03T14:45:49.147Z",
      "updated_at": "2024-01-03T14:45:49.147Z",
      "name": "Setovi i kompleti",
      "description": "",
      "handle": "proizvodi-za-njegu-kose-setovi-i-kompleti",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4N31HG0EQ98AA4ZHV8C",
      "rank": 21,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4N31HG0EQ98AA4ZHV8C",
        "created_at": "2024-01-03T14:45:43.201Z",
        "updated_at": "2024-01-03T14:45:43.201Z",
        "name": "Proizvodi za njegu kose",
        "description": "",
        "handle": "njega-kose-proizvodi-za-njegu-kose",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK43BQJST1XZ18R4BST01",
        "rank": 5,
        "metadata": null
      }
    },
    {
      "id": "pcat_01HK7WKAFQ0N4PN73NE63QV0Y4",
      "created_at": "2024-01-03T14:45:49.171Z",
      "updated_at": "2024-01-03T14:45:49.171Z",
      "name": "Šampon, regenerator i gel 3 u 1",
      "description": "",
      "handle": "proizvodi-za-njegu-kose-sampon-regenerator-i-gel-3-u-1",
      "is_active": true,
      "is_internal": false,
      "parent_category_id": "pcat_01HK7WK4N31HG0EQ98AA4ZHV8C",
      "rank": 22,
      "metadata": null,
      "category_children": [],
      "parent_category": {
        "id": "pcat_01HK7WK4N31HG0EQ98AA4ZHV8C",
        "created_at": "2024-01-03T14:45:43.201Z",
        "updated_at": "2024-01-03T14:45:43.201Z",
        "name": "Proizvodi za njegu kose",
        "description": "",
        "handle": "njega-kose-proizvodi-za-njegu-kose",
        "is_active": true,
        "is_internal": false,
        "parent_category_id": "pcat_01HK7WK43BQJST1XZ18R4BST01",
        "rank": 5,
        "metadata": null
      }
    }
],
    "offset": 0,
    "limit": 1000
}
