import Script from 'next/script'


// TODO: Rename to chatwoot
export default function FreshWorks({children}) {
  const js = `
<script>
      window.chatwootSettings = {"position":"right","type":"standard","launcherTitle":"Razgovarajte s nama"};
      (function(d,t) {
console.log("hi2")
        var BASE_URL="https://app.chatwoot.com";
        var g=d.createElement(t),s=d.getElementsByTagName(t)[0];
        g.src=BASE_URL+"/packs/js/sdk.js";
        g.defer = true;
        g.async = true;
        s.parentNode.insertBefore(g,s);
        g.onload=function(){
console.log("hi")
          window.chatwootSDK.run({
            websiteToken: 'Ukj88H1PQuQoaJPmyEuVQxYs',
            baseUrl: BASE_URL
          })
        }
      })(document,"script");
</script>
    `
  const jsIntercom = `
<script>
  window.intercomSettings = {
    api_base: "https://api-iam.intercom.io",
    app_id: "f4bi2i2v",
  };
</script>

<script>
// We pre-filled your app ID in the widget URL: 'https://widget.intercom.io/widget/f4bi2i2v'
(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/f4bi2i2v';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
</script>
`
  return (
    <>
      { children }
      <div dangerouslySetInnerHTML={{__html:js}} />
    </>
  );
}
